import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function ModuleIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon
            width="51"
            height="51"
            viewBox="0 0 51 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M43.4727 36.4478C46.7822 36.276 49.5277 33.5146 49.589 30.1886C49.6503 26.6172 46.7821 23.7085 43.2276 23.7085C42.247 23.7085 41.3277 23.9294 40.5065 24.3222C40.4207 24.359 40.3226 24.2976 40.3226 24.1994V13.0802C40.3226 11.767 39.2562 10.6992 37.9447 10.6992H26.852C26.7539 10.6992 26.6927 10.601 26.7294 10.5151C27.1217 9.68057 27.33 8.74783 27.3178 7.766C27.2933 4.3664 24.4373 1.49454 21.0421 1.44544C17.6469 1.39635 14.5826 4.26822 14.5826 7.81509C14.5826 8.77238 14.791 9.69284 15.1709 10.5029C15.2077 10.5888 15.1587 10.687 15.0606 10.687C13.0872 10.687 7.27732 10.687 3.96789 10.687C2.65637 10.687 1.59 11.7547 1.59 13.0679V24.0767C1.59 24.1749 1.68806 24.2363 1.77386 24.1994C2.59509 23.8558 3.50212 23.6717 4.44592 23.6962C7.77986 23.7944 10.5622 26.6172 10.6113 29.9554C10.6726 33.5269 7.80437 36.4355 4.2498 36.4355C3.36728 36.4355 2.5338 36.2514 1.77386 35.9323C1.68806 35.8955 1.59 35.9569 1.59 36.0551V47.0639C1.59 48.3771 2.65637 49.4448 3.96789 49.4448C7.28958 49.4448 13.0872 49.4448 14.6929 49.4448C14.791 49.4448 14.8522 49.3466 14.8155 49.2607C14.4478 48.4384 14.2516 47.518 14.2762 46.5484C14.3374 43.1856 17.1811 40.3506 20.5396 40.3138C24.0941 40.2647 26.9991 43.1365 26.9991 46.6834C26.9991 47.6039 26.803 48.4753 26.4598 49.2607C26.423 49.3466 26.4843 49.4448 26.5823 49.4448C28.2861 49.4448 34.4882 49.4448 37.9325 49.4448C39.244 49.4448 40.2981 48.3771 40.2981 47.0639V35.9446C40.2981 35.8464 40.3962 35.7728 40.482 35.8219C40.9477 36.0305 42.1612 36.4969 43.4605 36.4355L43.4727 36.4478Z"
                stroke="#DA30D8"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
        </SvgIcon>
    );
}
