import React from "react";

import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import useIntercom from "../../../../hooks/useIntercom";

export default function ContactSupport() {
    const intercom = useIntercom();

    return (
        <Typography>
            If this issue persists, please message support.
            <IconButton
                onClick={() => {
                    intercom.newMessage();
                }}
                color="primary"
            >
                <HelpIcon />
            </IconButton>
        </Typography>
    );
}
