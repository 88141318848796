import {
    OffsetResult,
    SessionWithRelations,
    TrainerWithRelations,
} from "@volley/data";

import { fetchApi } from "../../util";

export interface Session {
    trainerId: number;
    sessionId: number;
}

export async function checkForSession(): Promise<Session | null> {
    const sessions = await fetchApi<OffsetResult<SessionWithRelations>>(
        "/api/sessions/me?state=active&limit=1&offset=0",
    );
    const sessionMaybe = sessions.result[0];
    if (!sessionMaybe) {
        return null;
    }

    const tId = sessionMaybe.sessionTrainers[0].trainerId;
    const t = await fetchApi<TrainerWithRelations>(`/api/trainers/${tId}`);
    return { trainerId: t.clientId, sessionId: sessionMaybe.id };
}
