import * as React from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import Typography, {
    TypographyProps as MuiTypographyProps,
} from "@mui/material/Typography";

import useIntercom from "../hooks/useIntercom";

type Props = ButtonProps & {
    preText?: string;
    TypographyProps?: MuiTypographyProps;
};

export default function SupportLink({
    TypographyProps = {},
    preText = "",
    children,
    ...props
}: Props): JSX.Element {
    const intercom = useIntercom();
    return (
        <Typography
            variant="body2"
            align="center"
            sx={{ my: 2 }}
            {...TypographyProps}
        >
            {`${preText ?? ""} `}
            <Button
                {...props}
                type="button"
                onClick={() => intercom.newMessage()}
                variant="text"
                color="info"
            >
                {children}
            </Button>
        </Typography>
    );
}
