import * as React from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { styled, Theme } from "@mui/material/styles";

interface Props {
    value: string;
    onChange: (value: string) => void;
}

const DigitField = styled(TextField)({
    "& label": {
        color: (t: Theme) => t.palette.primary.main,
    },
    "& .MuiOutlinedInput-root": {
        "& input": {
            fontSize: "2.5rem",
            textAlign: "center",
        },
        "&:hover fieldset": {
            borderColor: (t: Theme) => t.palette.primary.main,
        },
        "&.Mui-focused fieldset": {
            borderColor: (t: Theme) => t.palette.primary.main,
        },
    },
    "& .MuiOutlinedInput-root:active, & .MuiOutlinedInput-root:focus, & .MuiOutlinedInput-root:focus-within":
        {
            "& .MuiIconButton-root": {
                color: (t: Theme) => t.palette.primary.main,
            },
        },
});

function useFocus(): [
    React.MutableRefObject<HTMLInputElement | null>,
    () => void,
] {
    const ref = React.useRef<HTMLInputElement | null>(null);
    const setFocus = () => {
        if (ref.current) {
            ref.current.focus();
        }
    };
    return [ref, setFocus];
}

export default function FourDigitInput({
    value,
    onChange,
}: Props): JSX.Element {
    const ref0 = useFocus();
    const ref1 = useFocus();
    const ref2 = useFocus();
    const ref3 = useFocus();
    const focusRefs = React.useMemo(
        () => [ref0, ref1, ref2, ref3],
        [ref0, ref1, ref2, ref3],
    );

    const updateValueAt = React.useCallback(
        (index: number, key: string) => {
            if (Number.isNaN(parseInt(key, 10)) && key !== "Backspace") {
                return;
            }
            const parts = value.split("");
            if (key === "Backspace") {
                if (parts[index] === "#" && index > 0) {
                    parts[index - 1] = "#";
                    focusRefs[index - 1][1]();
                } else {
                    parts[index] = "#";
                    focusRefs[index][1]();
                }
            } else if (!Number.isNaN(parseInt(key, 10))) {
                parts[Math.min(index, 3)] = key;
                focusRefs[Math.min(index + 1, 3)][1]();
            }
            onChange(parts.join(""));
        },
        [focusRefs, value, onChange],
    );

    return (
        <Stack direction="row" spacing={2}>
            {[0, 1, 2, 3].map((i) => (
                <DigitField
                    type="text"
                    margin="normal"
                    name={`fourDigitInput${i + 1}`}
                    value={value.charAt(i) === "#" ? "" : value.charAt(i)}
                    onKeyUp={(e) => updateValueAt(i, e.key)}
                    autoFocus={i === 0}
                    autoComplete="off"
                    placeholder="#"
                    key={i}
                    slotProps={{
                        htmlInput: {
                            ref: focusRefs[i][0],
                            inputMode: "numeric",
                            maxLength: 1,
                            "aria-label": `Code ${i + 1}`,
                        },
                    }}
                />
            ))}
        </Stack>
    );
}
