import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";

import useUnclosableDialog from "./common/useUnclosableDialog";

interface LowPowerDialogProps {
    onPoweredDown: () => void;
    message?: string;
}
export default function LowPowerDialog({
    onPoweredDown,
    message = "The trainer will shut down motor control and then walk you through exchanging the batteries.",
}: LowPowerDialogProps): JSX.Element {
    useUnclosableDialog();
    const { status } = useStatus();

    React.useEffect(() => {
        async function powerDown() {
            try {
                await pairedFetchApi(
                    status?.clientId,
                    "/api/low-power",
                    "POST",
                );
            } catch (e) {
                logFetchError(e, "Failed to power down from LowPowerDialog");
            }
        }

        void powerDown();
    }, [status?.clientId]);

    React.useEffect(() => {
        const powerState = status?.trainer?.powerState;
        // wait for L5 or below
        if (powerState === "L5" || powerState === "L6") {
            onPoweredDown();
        }
    });

    return (
        <>
            <CloseableDialogTitle>Entering Low Power Mode</CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>{message}</DialogContentText>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                    <CircularProgress size={80} color="info" />
                </Box>
            </DialogContent>
        </>
    );
}
