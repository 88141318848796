import * as React from "react";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";

import fetchApi from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import useIntercom from "../hooks/useIntercom";

import useDialog from "./useDialog";

export default function NeedHelpDialog(): JSX.Element {
    const intercom = useIntercom();
    const { onClose, setDialogProps } = useDialog();

    const onDismissClick = React.useCallback(async () => {
        await fetchApi("/api/sos/dismiss", "POST");
        onClose();
    }, [onClose]);

    const onHelpClick = React.useCallback(async () => {
        intercom.newMessage();
        await fetchApi("/api/sos/acknowledge", "POST");
        onClose();
    }, [intercom, onClose]);

    React.useEffect(() => {
        setDialogProps({
            onClose: onDismissClick,
        });
    }, [onDismissClick, setDialogProps]);

    return (
        <>
            <CloseableDialogTitle>We&apos;re here to help</CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Would you like to chat with a real person to work through
                    this issue?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Stack alignItems="center" width="100%" spacing={1}>
                    <Button
                        onClick={onHelpClick}
                        variant="contained"
                        color="secondary"
                        fullWidth
                    >
                        Chat with support
                    </Button>
                    <Button
                        onClick={onDismissClick}
                        variant="contained"
                        color="info"
                        fullWidth
                    >
                        I don&apos;t need help
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
