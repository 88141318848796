import * as React from "react";

import PowerUpDialog from "../PowerUpDialog";
import ReplaceBatteriesDialog from "../ReplaceBatteriesDialog";
import useDialog from "../useDialog";

const enum RemovedBatteryStep {
    ReplaceBatteries,
    PowerUp,
    Done,
}

export default function RemovedBatteryFlow(): JSX.Element | null {
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState(RemovedBatteryStep.ReplaceBatteries);

    if (step === RemovedBatteryStep.ReplaceBatteries) {
        return (
            <ReplaceBatteriesDialog
                onSwapped={() => setStep(RemovedBatteryStep.PowerUp)}
                titleText="Battery removed"
            />
        );
    }

    if (step === RemovedBatteryStep.PowerUp) {
        return (
            <PowerUpDialog
                onPoweredUp={() => setStep(RemovedBatteryStep.Done)}
            />
        );
    }

    if (step === RemovedBatteryStep.Done) {
        setDialogType(null);
    }

    return null;
}
