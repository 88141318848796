import * as React from "react";

import Box from "@mui/material/Box";
import { Theme, useTheme } from "@mui/material/styles";

function getStackColor(theme: Theme): string | null {
    const { hostname } = window.location;

    if (hostname.includes("staging")) {
        return theme.palette.error.main;
    }

    if (hostname.includes("localhost")) {
        return theme.palette.warning.main;
    }

    return null;
}

export default function StackIndicator(): JSX.Element | null {
    const theme = useTheme();
    const background = getStackColor(theme);

    if (!background) {
        return null;
    }

    return (
        <Box
            component="div"
            sx={{
                position: "sticky",
                zIndex: theme.zIndex.modal + 1,
                top: 0,
                height: 5,
                width: "100%",
                background,
            }}
        />
    );
}
