import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import { convert, model } from "@volley/physics/dist";
import { type PhysicsModelName } from "@volley/physics/dist/models";
import { TrainerSim } from "@volley/physics/dist/trainer-sim";
import type { ThrowShot } from "@volley/shared/http/trainer-control";

import logger from "../../../log";
import { pairedFetchApi, logFetchError } from "../../../util/fetchApi";
import { PositionLike } from "../../../util/position-types";
import { calculateTrim } from "../../../util/positionUtil";
import { useSelectedSport } from "../../common/context/sport";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";
import useRanges, { Range } from "../../hooks/ranges";
import { useStatus } from "../../hooks/status";
import useDebounce from "../../hooks/useDebounce";
import { useLift } from "../../hooks/useLift";
import { useTrainerFeatures } from "../../hooks/useTrainerFeatures";

import { mps2mph } from "./conversions";

type InternalThrowState = "idle" | "heightAdjust" | "ready" | "requesting";

interface RPMSuccess {
    ok: true;
    rpm: model.RPM3;
}

interface RPMFailure {
    ok: false;
    error: string;
    rpm: model.RPM3 | null;
}

export type RPMCalculation = RPMSuccess | RPMFailure;

export interface UseThrowNowParams {
    launchSpeed: number;
    spinLevel: number;
    spinAxis: number;
    pan: number;
    tilt: number;
    height?: number;
    plannedPosition?: PositionLike;
    localizedPosition?: PositionLike;
}

export interface UseThrowNowState {
    canThrow: boolean;
    hasThrown: boolean;
    throwing: boolean;
    error: string | null;
    maxSpinLevel: number;
    spinRpm: number;
    calculatedRpm: model.RPM3 | null;
    launchData: model.Shot | null;
    trim: number;
    trimmedPan: number;
    trimEnabled: boolean;
    params: UseThrowNowParams;
    makeShot: (height?: number) => Promise<void>;
}

class WheelRangeError extends Error {
    public rpm: model.RPM3;

    public speedSpin: model.SpeedSpin;

    constructor(message: string, rpm: model.RPM3, ss: model.SpeedSpin) {
        super(message);
        this.rpm = rpm;
        this.speedSpin = ss;
    }
}

function calculateShotRPM(
    launchSpeed: number,
    spinLevel: number,
    spinAxis: number,
    wheelRange: Range,
): RPMCalculation {
    const ss: model.SpeedSpin = {
        speed: launchSpeed,
        spin: null,
        spinAxis,
        spinLevel,
    };

    try {
        const rpm = convert.speedspin2rpm(ss);
        const rpmTooLow =
            rpm.top < wheelRange.min ||
            rpm.left < wheelRange.min ||
            rpm.right < wheelRange.min;
        const rpmTooHigh =
            rpm.top > wheelRange.max ||
            rpm.left > wheelRange.max ||
            rpm.right > wheelRange.max;
        if (rpmTooLow) {
            throw new WheelRangeError("Wheel speed too low.", rpm, ss);
        }
        if (rpmTooHigh) {
            throw new WheelRangeError("Wheel speed too high.", rpm, ss);
        }
        return { ok: true, rpm };
    } catch (ex: unknown) {
        if (ex instanceof WheelRangeError) {
            return { ok: false, error: (ex as Error).message, rpm: ex.rpm };
        }

        logger.warn(JSON.stringify(ex));
        return { ok: false, error: "Try reducing spin level.", rpm: null };
    }
}

export default function useThrowNow({
    launchSpeed,
    pan,
    spinAxis,
    spinLevel,
    tilt,
    height,
    localizedPosition,
    plannedPosition,
}: UseThrowNowParams): UseThrowNowState {
    const [hasThrown, setHasThrown] = React.useState(false);
    const [throwing, setThrowing] = React.useState(false);
    const [error, setError] = React.useState<string | null>(null);
    const { selected: selectedSport } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();
    const [trimCapable, setTrimCapable] = React.useState(false);
    const [throwState, setThrowState] =
        React.useState<InternalThrowState>("idle");
    const debouncedSpeed = useDebounce(launchSpeed, 300);

    const { setHeight } = useLift();

    const { status } = useStatus();

    /*
    This function loads the trainer features and sets the trimCapable to true
    if the trainer supports trim. If this value is false, we will not trim shot
    calculations on the client so they behave the same on client test shots
    as well as when a workout is run on the trainer.
    */
    const features = useTrainerFeatures();
    React.useEffect(() => {
        if (features.includes("trim")) {
            logger.info("Enabling trim for client shot calculation");
            setTrimCapable(true);
        } else {
            logger.info("Disabling trim for client shot calculation");
            setTrimCapable(false);
        }
    }, [features]);

    const { pan: panRange, wheels: wheelRanges } = useRanges();

    const ts = React.useMemo(() => {
        logger.info(
            `Setting physics model: ${physicsModelName} / ${selectedSport}`,
        );
        convert.setPhysicsModel(
            physicsModelName as PhysicsModelName,
            selectedSport,
        );
        return new TrainerSim(
            physicsModelName as PhysicsModelName,
            selectedSport,
        );
    }, [physicsModelName, selectedSport]);

    const maxSpinLevel = React.useMemo(() => {
        let level = 1;
        try {
            level = convert.maxspinlevel(spinAxis, debouncedSpeed);
        } catch (ex) {
            logger.error(
                `Failed to get max spin level for axis: ${spinAxis}, speed: ${debouncedSpeed}`,
                {},
                ex as Error,
            );
        }
        return level;
    }, [spinAxis, debouncedSpeed]);

    const spinRpm = React.useMemo(() => {
        let rpm = 300;
        try {
            rpm = convert.spinlevel2motorspin(spinLevel);
        } catch (ex) {
            logger.error(
                `Failed to calculate spin rpm for level ${spinLevel}`,
                {},
                ex as Error,
            );
        }
        return rpm;
    }, [spinLevel]);

    const { trim, trimmedPan } = React.useMemo(() => {
        let maybeTrim = 0;
        if (trimCapable && plannedPosition && localizedPosition) {
            const diff =
                calculateTrim(plannedPosition.yaw, localizedPosition.yaw) *
                (180 / Math.PI);
            if (Math.abs(diff) < 1) {
                logger.debug(
                    `Yaw difference of ${diff} is under threshold 1 degree, no trim applied`,
                );
            } else {
                maybeTrim = diff;
                logger.debug(`Setting trim to ${maybeTrim} degrees`);
            }
        }

        let trimmed = pan + maybeTrim;
        logger.debug(
            `Applying trim of ${maybeTrim} degrees to shot's base pan ${pan}: ${trimmed}`,
        );
        if (trimmed > panRange.max) {
            logger.warn(
                `Trimmed pan exceeded max range, capping pan to ${panRange.max}`,
            );
            trimmed = panRange.max;
        }
        if (trimmed < panRange.min) {
            logger.warn(
                `Trimmed pan exceeded min range, capping pan to ${panRange.min}`,
            );
            trimmed = panRange.min;
        }
        return { trim: maybeTrim, trimmedPan: trimmed };
    }, [
        panRange.max,
        panRange.min,
        trimCapable,
        plannedPosition,
        localizedPosition,
        pan,
    ]);

    const rpmResult = React.useMemo(
        () =>
            calculateShotRPM(debouncedSpeed, spinLevel, spinAxis, wheelRanges),
        [wheelRanges, spinLevel, spinAxis, debouncedSpeed],
    );

    const launchResult = React.useMemo(() => {
        if (rpmResult.ok && plannedPosition && height) {
            const localizedHeightMeters = convert.launchHeight2HeadHeight(
                (height * 25.4) / 1000,
            );
            const localizedPositionPhysics = convert.localization2physics({
                x: plannedPosition.x,
                y: plannedPosition.y,
                z: localizedHeightMeters * 1000,
            });

            ts.SetPositionManual({
                h: localizedHeightMeters,
                x: localizedPositionPhysics.x,
                y: localizedPositionPhysics.y,
                yaw: plannedPosition.yaw,
            });

            return ts.Launch({
                pitch: tilt,
                yaw: pan,
                rpms: rpmResult.rpm,
                speedSpin: null,
            });
        }

        return undefined;
    }, [ts, rpmResult, plannedPosition, height, pan, tilt]);

    React.useEffect(() => {
        const err = rpmResult.ok ? null : rpmResult.error;
        setError(err);
    }, [rpmResult]);

    const ensureHeadHeight = React.useCallback(async () => {
        if (!height) {
            logger.info("No height specified, throwing immediately");
            setThrowState("ready");
            return;
        }

        logger.info(`Height ${height} requested, adjusting.`);
        setThrowState("heightAdjust");
        await setHeight(height, () => setThrowState("ready"));
    }, [setHeight, height]);

    const doThrow = React.useCallback(async () => {
        if (rpmResult.ok) {
            const shot: ThrowShot = {
                bottomLeftRpm: Math.round(rpmResult.rpm.left),
                bottomRightRpm: Math.round(rpmResult.rpm.right),
                pan: trimmedPan,
                tilt,
                topRpm: Math.round(rpmResult.rpm.top),
            };

            setError(null);
            setThrowing(true);
            setThrowState("requesting");
            try {
                // FIXME: this actually returns shot info - make a type for it and use it here
                await pairedFetchApi(
                    status?.clientId,
                    "/api/throw",
                    "POST",
                    shot,
                    15_000,
                );
                setHasThrown(true);
                setThrowState("idle");
            } catch (ex) {
                logFetchError(ex);
                if (ex instanceof Error) {
                    setError(ex.message);
                } else {
                    setError("Unexpected error throwing shot");
                }
            } finally {
                setThrowing(false);
            }
        } else {
            setError("Shot has not yet been configured.");
        }
    }, [status?.clientId, tilt, trimmedPan, rpmResult]);

    React.useEffect(() => {
        const handleThrowState = async () => {
            if (throwState === "ready") {
                await doThrow();
            }
        };
        void handleThrowState();
    }, [throwState, doThrow]);

    const makeShot = React.useCallback(async () => {
        if (rpmResult.ok) {
            setThrowing(true);
            await ensureHeadHeight();
        } else {
            setError("Shot has not yet been configured.");
        }
    }, [ensureHeadHeight, rpmResult.ok]);

    return {
        calculatedRpm: rpmResult.rpm,
        launchData: launchResult || null,
        canThrow: rpmResult.ok,
        maxSpinLevel,
        spinRpm,
        hasThrown,
        throwing,
        error,
        trim,
        trimmedPan,
        trimEnabled: trimCapable,
        params: {
            launchSpeed,
            pan,
            spinAxis,
            spinLevel,
            tilt,
            height,
            localizedPosition,
            plannedPosition,
        },
        makeShot,
    };
}

export function ThrowDiagnostics({
    calculatedRpm,
    canThrow,
    error,
    maxSpinLevel,
    params,
    spinRpm,
    trim,
    trimmedPan,
    trimEnabled,
}: Omit<UseThrowNowState, "hasThrown" | "throwing" | "makeShot">): JSX.Element {
    const plannedYawDeg = (params.plannedPosition?.yaw ?? 0) * (180 / Math.PI);
    const localizedYawDeg =
        (params.localizedPosition?.yaw ?? 0) * (180 / Math.PI);
    return (
        <TableContainer
            sx={{
                marginBottom: "60px",
                padding: "5px",
            }}
        >
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Property</TableCell>
                        <TableCell align="right">Value</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell>Model</TableCell>
                        <TableCell align="right">
                            {`${convert.getPhysicsModel().name}-${convert.getPhysicsModel().sport}`}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Launch Speed (m/s)</TableCell>
                        <TableCell align="right">
                            {params.launchSpeed.toFixed(2)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Launch Speed (mph)</TableCell>
                        <TableCell align="right">
                            {mps2mph(params.launchSpeed).toFixed(2)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>RPM Top</TableCell>
                        <TableCell align="right">
                            {calculatedRpm?.top.toFixed() ?? "Unknown"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>RPM Left</TableCell>
                        <TableCell align="right">
                            {calculatedRpm?.left.toFixed() ?? "Unknown"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>RPM Right</TableCell>
                        <TableCell align="right">
                            {calculatedRpm?.right.toFixed() ?? "Unknown"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Trim Enabled</TableCell>
                        <TableCell align="right">
                            {JSON.stringify(trimEnabled)}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Planned Yaw</TableCell>
                        <TableCell align="right">{`${plannedYawDeg.toFixed(2)}°`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Localized Yaw</TableCell>
                        <TableCell align="right">{`${localizedYawDeg.toFixed(2)}°`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Trim</TableCell>
                        <TableCell align="right">{`${trim.toFixed(2)}°`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Trim (Untrimmed)</TableCell>
                        <TableCell align="right">{`${params.pan.toFixed(2)}°`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Pan (Trimmed)</TableCell>
                        <TableCell align="right">{`${trimmedPan.toFixed(2)}°`}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Throable?</TableCell>
                        <TableCell align="right">
                            {canThrow ? "Yes" : "No"}
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>{`Max Spin Level at ${params.spinAxis}°`}</TableCell>
                        <TableCell align="right">{maxSpinLevel}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Current Spin RPM</TableCell>
                        <TableCell align="right">{spinRpm.toFixed()}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell colSpan={2}>
                            {error ?? "No Errors"}
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
}

export function ThrowDiagnosticsDrawer({
    open,
    ...state
}: UseThrowNowState & { open: boolean }): JSX.Element {
    return (
        <Drawer
            anchor="bottom"
            variant="persistent"
            open={open}
            sx={{
                ".MuiDrawer-paper": {
                    height: "40%",
                    zIndex: (t) => t.zIndex.appBar - 2,
                },
            }}
        >
            <ThrowDiagnostics {...state} />
        </Drawer>
    );
}

export function ThrowingModal({ open }: { open: boolean }): JSX.Element {
    return (
        <Dialog open={open} fullWidth maxWidth={false}>
            <DialogTitle variant="h4">Test Throw In Progress</DialogTitle>
            <DialogContent>
                <Stack spacing={3}>
                    <Divider />
                    <Box
                        component="div"
                        sx={{
                            margin: "auto",
                            textAlign: "center",
                        }}
                    >
                        <CircularProgress
                            size={80}
                            sx={{
                                color: "primary.light",
                            }}
                        />
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
