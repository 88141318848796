import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import HeightIcon from "@mui/icons-material/Height";
import PersonPinCircleIcon from "@mui/icons-material/PersonPinCircle";
import PlaceIcon from "@mui/icons-material/Place";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { SingleShotConfig } from "@volley/shared/apps/single-shot-models";

import Height from "../../../Shared/Height";
import PlayerPosition from "../../../Shared/PlayerPosition";
import TrainerPosition from "../../../Shared/TrainerPosition";
import { updateLocalHeight } from "../../../localWorkoutState";
import { WorkoutAction, WorkoutForm } from "../reducer";

type TabValue = "trainer" | "player" | "height";

interface Props {
    workout: WorkoutForm;
    dispatch: React.Dispatch<WorkoutAction>;
}

export default function PositionControls({
    workout,
    dispatch,
}: Props): JSX.Element {
    const [tab, setTab] = React.useState<TabValue>("height");

    return (
        <Stack>
            <Tabs
                variant="scrollable"
                scrollButtons="auto"
                value={tab}
                onChange={(_e: Event, value: TabValue) => setTab(value)}
                sx={{
                    "& .MuiTab-root": {
                        fontSize: 10,
                        minHeight: 24,
                        p: 0,
                        pb: 1,
                    },
                    minHeight: 24,
                    mb: tab ? 2 : undefined,
                }}
            >
                <Tab label="Height" value="height" icon={<HeightIcon />} />
                {workout.isOwner && (
                    <Tab label="Trainer" value="trainer" icon={<PlaceIcon />} />
                )}
                {workout.isOwner && (
                    <Tab
                        label="Player"
                        value="player"
                        icon={<PersonPinCircleIcon />}
                    />
                )}
            </Tabs>
            {tab === "height" && (
                <Height
                    selectedHeight={workout.positionHeight}
                    onHeightChanged={(value) => {
                        updateLocalHeight(value, workout.appId, workout.id);
                        dispatch({ type: "positionHeight", value });
                    }}
                    disabled={false}
                />
            )}
            <Dialog open={tab === "trainer" || tab === "player"} fullScreen>
                <DialogContent>
                    {tab === "trainer" && (
                        <TrainerPosition
                            selectedPosition={{
                                x: workout.positionX,
                                y: workout.positionY,
                                yaw: workout.positionYaw,
                            }}
                            onPositionChanged={(value) =>
                                dispatch({ type: "trainerPosition", value })
                            }
                            disableYawControl
                        />
                    )}
                    {tab === "player" && (
                        <PlayerPosition
                            selectedTrainerPosition={{
                                x: workout.positionX,
                                y: workout.positionY,
                                yaw: workout.positionYaw,
                            }}
                            selectedPlayerPosition={
                                (workout.config as unknown as SingleShotConfig)
                                    .playerPosition
                            }
                            onPlayerPositionChanged={(value) =>
                                dispatch({ type: "playerPosition", value })
                            }
                        />
                    )}
                    <Tabs
                        variant="fullWidth"
                        value={tab}
                        onChange={(_e: Event, value: TabValue) => setTab(value)}
                        sx={{
                            "& .MuiTab-root": {
                                fontSize: 10,
                                minHeight: 24,
                                p: 0,
                                pb: 1,
                            },
                            minHeight: 24,
                            pt: 2,
                        }}
                    >
                        <Tab
                            label="Height"
                            value="height"
                            icon={<HeightIcon />}
                        />
                        <Tab
                            label="Trainer"
                            value="trainer"
                            icon={<PlaceIcon />}
                        />
                        <Tab
                            label="Player"
                            value="player"
                            icon={<PersonPinCircleIcon />}
                        />
                    </Tabs>
                    <Button
                        onClick={() => setTab("height")}
                        fullWidth
                        sx={{ mt: 2 }}
                        startIcon={<DoneIcon />}
                    >
                        Done
                    </Button>
                </DialogContent>
            </Dialog>
        </Stack>
    );
}
