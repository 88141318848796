import * as React from "react";

import CircularProgress from "@mui/material/CircularProgress";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatDistance } from "date-fns";

// eslint-disable-next-line import/no-cycle
import { findApp } from "..";
import fetchApi, { logFetchError } from "../../../../../util/fetchApi";
import ProviderAvatar from "../../Shared/ProviderAvatar";
import { AppListItemProps } from "../types";

export default function ListItemWrapper({
    workout,
    selected,
    attributeFilter,
    onClick,
}: AppListItemProps): JSX.Element | null {
    const attributeText = React.useMemo(() => {
        if (attributeFilter) {
            const text = attributeFilter
                .filter((a) => a !== "appId")
                .map((a) => {
                    if (a === "lastUserPlay" && workout[a]) {
                        const date = new Date(workout[a] as unknown as string);
                        return formatDistance(date, new Date(), {
                            addSuffix: true,
                        });
                    }
                    return workout[a];
                })
                .join(", ");

            return text;
        }

        return null;
    }, [attributeFilter, workout]);

    const [loading, setLoading] = React.useState(false);
    const [originalAppId, setOriginalAppId] = React.useState<number | null>(
        null,
    );
    const [error, setError] = React.useState<string | null>(null);

    React.useEffect(() => {
        async function getOriginalAppId() {
            const result = await fetchApi<number>(
                `/api/app-workouts/original/${workout.id}`,
            );
            return result;
        }
        if (!loading) {
            setLoading(true);
            getOriginalAppId()
                .then((appId) => {
                    setOriginalAppId(appId);
                })
                .catch((e) => {
                    logFetchError(e);
                    setError("Failed to load original app ID");
                });
        }
    }, [workout, loading]);

    const { id, name } = workout;

    const Icon = React.useMemo(() => {
        if (attributeText || originalAppId === null) {
            return undefined;
        }

        const app = findApp(originalAppId);
        if (app === null) {
            return undefined;
        }
        return app.IconComponent;
    }, [attributeText, originalAppId]);

    if (error) {
        return null;
    }

    if (originalAppId === null) {
        return <CircularProgress />;
    }

    return (
        <ListItemButton
            dense
            onClick={() =>
                onClick(
                    id,
                    `/content/apps/workouts/plugin/play/${originalAppId}/${id}`,
                )
            }
            sx={{
                padding: "5px",
                backgroundColor: (t) =>
                    selected ? t.palette.info.light : t.palette.primary.dark,
            }}
        >
            {workout.provider && (
                <ListItemAvatar>
                    <ProviderAvatar
                        providerName={workout.provider.name}
                        providerLabel={workout.provider.label}
                    />
                </ListItemAvatar>
            )}
            <ListItemText
                inset={workout.provider === null}
                primary={
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        justifyItems="center"
                    >
                        <Typography
                            variant="h5"
                            color="white"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            {name}
                        </Typography>
                        {attributeText && (
                            <Typography variant="caption" color="white">
                                {attributeText}
                            </Typography>
                        )}
                        {!attributeText && Icon && <Icon />}
                    </Stack>
                }
            />
        </ListItemButton>
    );
}
