import type { Sport } from "@volley/physics/dist/models";

import { CoordWithSys } from "../../../../../../../util/position-types";
import { degToRad } from "../../../../../../../util/positionUtil";

export const POSITION_TABLE: Record<Sport, CoordWithSys[]> = {
    PADEL: [
        { x: -4.2, y: -2.34, sys: "physics" },
        { x: -2.1, y: -2.34, sys: "physics" },
        { x: 0, y: -2.34, sys: "physics" },
        { x: 2.1, y: -2.34, sys: "physics" },
        { x: 4.2, y: -2.34, sys: "physics" },

        { x: -4.2, y: -5, sys: "physics" },
        { x: -2.1, y: -5, sys: "physics" },
        { x: 0, y: -5, sys: "physics" },
        { x: 2.1, y: -5, sys: "physics" },
        { x: 4.2, y: -5, sys: "physics" },

        { x: -4.2, y: -7.8, sys: "physics" },
        { x: -2.1, y: -7.8, sys: "physics" },
        { x: 0, y: -7.8, sys: "physics" },
        { x: 2.1, y: -7.8, sys: "physics" },
        { x: 4.2, y: -7.8, sys: "physics" },

        { x: -4.2, y: -9.6, sys: "physics" },
        { x: -2.1, y: -9.6, sys: "physics" },
        { x: 0, y: -9.6, sys: "physics" },
        { x: 2.1, y: -9.6, sys: "physics" },
        { x: 4.2, y: -9.6, sys: "physics" },
    ],
    PLATFORM_TENNIS: [
        /** ********* Front Center  *********** */
        { x: 0, y: -2.01, sys: "physics" },

        /** ********* Front Boxes  *********** */
        {
            x: -2.22,
            y: -3.56,
            yaw: degToRad(32),
            yawDescription: "Center of Net",
            sys: "physics",
        },
        {
            x: 2.22,
            y: -3.56,
            yaw: degToRad(-32),
            yawDescription: "Center of Net",
            sys: "physics",
        },

        /** ********* Back Corners  *********** */
        // These Serve & Volley coordinates are based on the app config
        // stored in GCS: volley-production-workout-app-config/serve-and-volley-config.json
        // They must be converted using positionUtil.courtToPhysics({ x, y })
        {
            x: -3.14,
            y: -7.15,
            yaw: degToRad(22),
            yawDescription: "Serve and Volley",
            sys: "physics",
        },
        {
            x: 3.14,
            y: -7.15,
            yaw: degToRad(-22),
            yawDescription: "Serve and Volley",
            sys: "physics",
        },

        /** ********* Back Line  *********** */
        {
            x: -2.5,
            y: -7.51,
            yaw: degToRad(0),
            sys: "physics",
        },
        {
            x: -1.25,
            y: -7.51,
            yaw: degToRad(0),
            sys: "physics",
        },
        { x: 0, y: -7.51, sys: "physics" },
        {
            x: 1.25,
            y: -7.51,
            yaw: degToRad(0),
            sys: "physics",
        },
        {
            x: 2.5,
            y: -7.51,
            yaw: degToRad(0),
            sys: "physics",
        },

        /** ********* Fence Corners  *********** */
        {
            x: -4.44,
            y: -9.0,
            yaw: degToRad(41),
            yawDescription: "Far Post, Opposite Side",
            sys: "physics",
        },
        {
            x: 4.44,
            y: -9.0,
            yaw: degToRad(-41),
            yawDescription: "Far Post, Opposite Side",
            sys: "physics",
        },
    ],
    TENNIS: [
        { x: -2.05, y: -2.32, sys: "physics" }, // Front axle 5ft (-1.52m) from net. Trainer rear axle -2.3m
        { x: 0, y: -2.32, sys: "physics" },
        { x: 2.05, y: -2.32, sys: "physics" },

        { x: -2.05, y: -4.15, sys: "physics" }, // Front axle 11ft (-3.35m) from net. Trainer rear axle -4.15m
        { x: 0, y: -4.15, sys: "physics" },
        { x: 2.05, y: -4.15, sys: "physics" },

        { x: -2.05, y: -7.2, sys: "physics" }, // Front axle 21ft (-6.4m) from net (service line). Trainer rear axle -7.2m
        { x: 0, y: -7.2, sys: "physics" },
        { x: 2.05, y: -7.2, sys: "physics" },

        { x: -2.05, y: -9.64, sys: "physics" }, // Front axle 29ft (-8.84m) from net. Trainer rear axle -9.64m
        { x: 0, y: -9.64, sys: "physics" },
        { x: 2.05, y: -9.64, sys: "physics" },

        { x: -4.1, y: -12.69, sys: "physics" }, // Front axle 39ft (-11.89m) from net (baseline). Trainer rear axle -12.69m
        { x: -2.05, y: -12.69, sys: "physics" },
        { x: 0, y: -12.69, sys: "physics" },
        { x: 2.05, y: -12.69, sys: "physics" },
        { x: 4.1, y: -12.69, sys: "physics" },

        { x: -4.1, y: -15.74, sys: "physics" }, // Front axle 49ft (-14.94m) from net. Trainer rear axle -15.74m
        { x: -2.05, y: -15.74, sys: "physics" },
        { x: 0, y: -15.74, sys: "physics" },
        { x: 2.05, y: -15.74, sys: "physics" },
        { x: 4.1, y: -15.74, sys: "physics" },
    ],
    PICKLEBALL: [
        { x: -3.04, y: -2.93, sys: "physics" },
        { x: -1.52, y: -2.93, sys: "physics" },
        { x: 0, y: -2.93, sys: "physics" },
        { x: 1.52, y: -2.93, sys: "physics" },
        { x: 3.04, y: -2.93, sys: "physics" },

        { x: -3.04, y: -5.07, sys: "physics" },
        { x: -1.52, y: -5.07, sys: "physics" },
        { x: 0, y: -5.07, sys: "physics" },
        { x: 1.52, y: -5.07, sys: "physics" },
        { x: 3.04, y: -5.07, sys: "physics" },

        { x: -3.04, y: -7.51, sys: "physics" },
        { x: -2.28, y: -7.51, sys: "physics" },
        { x: -1.52, y: -7.51, sys: "physics" },
        { x: 0, y: -7.51, sys: "physics" },
        { x: 1.52, y: -7.51, sys: "physics" },
        { x: 2.28, y: -7.51, sys: "physics" },
        { x: 3.04, y: -7.51, sys: "physics" },
    ],
};
