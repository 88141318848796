import * as React from "react";

import DoneIcon from "@mui/icons-material/Done";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import logger from "../../../log";
import { fetchApi } from "../../../util";
import { Config } from "../../Signup/types";

/*
 * The following two interfaces are subsets of what GET /signup/invoice-preview accepts,
 * adapted for use of the use case of letting users sign up as "free forever" transparently.
 */

interface InvoicePreviewPayload {
    priceId: string;
    promoCode: string;
}

interface CreateSubscriptionPayload {
    isFreeForever: true;
    priceId: string;
    promoCode: string;
}

interface Props {
    onDone: () => void;
}

export default function PairPromoCodeEntry({ onDone }: Props): JSX.Element {
    const [promoCode, setPromoCode] = React.useState("");
    const [loading, setLoading] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    const onSubmit = React.useCallback(
        async (event: React.FormEvent) => {
            event.preventDefault();
            setErrorMessage("");
            setLoading(true);
            let config: Config;
            try {
                config = await fetchApi<Config>("/api/signup/config");
            } catch (e) {
                setErrorMessage(
                    "Error configuring account to pair with this trainer",
                );
                setLoading(false);
                logger.error((e as Error).message);
                return;
            }

            try {
                const payload: InvoicePreviewPayload = {
                    priceId: config.prices.platform_tennis_yearly.id,
                    promoCode,
                };
                await fetchApi("/api/signup/invoice-preview", "POST", payload);
            } catch (e) {
                setErrorMessage("Invalid code. Please try again");
                setLoading(false);
                logger.error((e as Error).message);
                return;
            }

            try {
                const payload: CreateSubscriptionPayload = {
                    isFreeForever: true,
                    priceId: config.prices.platform_tennis_yearly.id,
                    promoCode,
                };
                await fetchApi("/api/signup/subscriptions", "POST", payload);
            } catch (e) {
                setErrorMessage("Could not complete club registration.");
                logger.error((e as Error).message);
                return;
            } finally {
                setLoading(false);
            }

            onDone();
        },
        [promoCode, onDone],
    );

    return (
        <Stack spacing={2} pt={2} component="form" onSubmit={onSubmit}>
            <TextField
                disabled={loading}
                name="promoCode"
                id="promoCode"
                label="Code"
                value={promoCode}
                onChange={(e) => setPromoCode(e.currentTarget.value)}
                error={!!errorMessage}
                helperText={errorMessage}
            />
            <Button
                type="submit"
                color="secondary"
                variant="contained"
                disabled={loading || promoCode.length < 3}
                startIcon={<DoneIcon />}
            >
                Submit
            </Button>
        </Stack>
    );
}
