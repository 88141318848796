export function capitalizeFirstLetter(
    [first, ...rest]: string,
    locale = navigator.language,
): string {
    return `${first.toLocaleUpperCase(locale)}${rest.join("")}`;
}

export function pluralize(count: number, noun: string, suffix = "s") {
    if (count === 1) {
        return noun;
    }

    return `${noun}${suffix}`;
}
