import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function MultiLevelIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon
            width="74"
            height="61"
            viewBox="0 0 74 61"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M59.1851 1.06006L64.9981 1.18643C65.5939 1.19546 66.0272 1.73705 65.9279 2.32377L64.9259 7.92921"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M55.1954 24.4839H47.8568C46.9396 24.4839 46.196 25.2275 46.196 26.1448V57.3493C46.196 58.2666 46.9396 59.0102 47.8568 59.0102H55.1954C56.1126 59.0102 56.8563 58.2666 56.8563 57.3493V26.1448C56.8563 25.2275 56.1126 24.4839 55.1954 24.4839Z"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M70.766 15.5293H63.4275C62.5102 15.5293 61.7667 16.2729 61.7667 17.1902V57.349C61.7667 58.2663 62.5102 59.0098 63.4275 59.0098H70.766C71.6833 59.0098 72.4269 58.2663 72.4269 57.349V17.1902C72.4269 16.2729 71.6833 15.5293 70.766 15.5293Z"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M40.5093 33.4019H33.1708C32.2535 33.4019 31.5099 34.1455 31.5099 35.0627V57.3491C31.5099 58.2664 32.2535 59.01 33.1708 59.01H40.5093C41.4266 59.01 42.1702 58.2664 42.1702 57.3491V35.0627C42.1702 34.1455 41.4266 33.4019 40.5093 33.4019Z"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M25.8232 42.8345H18.4847C17.5674 42.8345 16.8238 43.5781 16.8238 44.4953V57.3491C16.8238 58.2663 17.5674 59.0099 18.4847 59.0099H25.8232C26.7405 59.0099 27.4841 58.2663 27.4841 57.3491V44.4953C27.4841 43.5781 26.7405 42.8345 25.8232 42.8345Z"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M11.1462 49.5684H3.80763C2.89035 49.5684 2.14676 50.312 2.14676 51.2292V57.3492C2.14676 58.2665 2.89035 59.0101 3.80763 59.0101H11.1462C12.0634 59.0101 12.8071 58.2665 12.8071 57.3492V51.2292C12.8071 50.312 12.0634 49.5684 11.1462 49.5684Z"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M1.05457 41.7515L30.0657 18.8604L36.6821 24.52L64.3302 2.80225"
                stroke="#56D5EF"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </SvgIcon>
    );
}
