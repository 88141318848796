import * as React from "react";

import { useTheme } from "@mui/material";

import { useSelectedSport } from "../../../../common/context/sport";
import CardWrapper from "../../Shared/CardWrapper";
import { AppListItemProps } from "../types";

export default function ResponsiveCard({
    onClick,
}: AppListItemProps): JSX.Element | null {
    const theme = useTheme();
    const { selected } = useSelectedSport();
    return (
        <CardWrapper
            onClick={onClick}
            workout={{
                appId: 2,
                globalPlayCount: 0,
                id: 1,
                name: "Responsive Play",
                provider: null,
                isFavorite: false,
                lastUserPlay: null,
                overview: "",
                sportName: selected,
                userPlayCount: 0,
            }}
            backgroundColor={theme.palette.common.white}
            backgroundImage="beige-paper"
        />
    );
}
