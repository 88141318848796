import * as React from "react";

import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import cdnUrl from "../../../../CampaignClubs/cdnUrl";
import { Sport, useSelectedSport } from "../../../../common/context/sport";
import ProviderAvatar from "../../Shared/ProviderAvatar";
import { AppListItemProps } from "../types";

const WIDTH = "100%";

function getCdnUrl(name: string, id: number, primary: boolean): string {
    const ptiUrl = cdnUrl("hero-images/PTI.jpg");
    const workoutUrl = cdnUrl(`hero-images/WORKOUT_${id}.jpg`);
    if (primary) {
        if (name.indexOf("PTI") > 0) {
            return cdnUrl("hero-images/PTI.jpg");
        }

        return cdnUrl(`hero-images/WORKOUT_${id}.jpg`);
    }

    const defaultUrl = cdnUrl("hero-images/DEFAULT.jpg");
    if (name.indexOf("PTI") > 0) {
        return `url(${ptiUrl}), url(${defaultUrl})`;
    }
    return `url(${workoutUrl}), url(${defaultUrl})`;
}

export default function HeroWrapper({
    onClick,
    workout,
    order,
}: AppListItemProps): JSX.Element | null {
    const { appId, id, name } = workout;

    const { selected, updateSelected } = useSelectedSport();

    const content = (
        <CardMedia
            src={getCdnUrl(name, id, true)}
            sx={{
                aspectRatio: "16 / 9",
                minWidth: WIDTH,
                position: "relative",
                "&.MuiCardMedia-root": {
                    backgroundImage: getCdnUrl(name, id, false),
                },
            }}
        >
            <CardContent
                sx={{
                    pt: 1,
                    pr: 1,
                    pl: 1,
                    pb: 0,
                }}
            >
                <Typography
                    variant="button"
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        width: "150px",
                        textAlign: "center",
                        transform: "translate(25%, 100%) rotate(45deg)",
                        color: "white",
                        backgroundColor: "primary.light",
                    }}
                >
                    Featured
                </Typography>
            </CardContent>
            <CardActions
                sx={{
                    minHeight: 48,
                    width: "100%",
                    position: "absolute",
                    bottom: 0,
                    backgroundColor: "rgba(0, 0, 0, 0.65)",
                }}
            >
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    spacing={1}
                    width="100%"
                >
                    {workout.provider && (
                        <ProviderAvatar
                            providerName={workout.provider.name}
                            providerLabel={workout.provider.label}
                        />
                    )}
                    <Typography
                        sx={{
                            whiteSpace: "normal",
                            textTransform: "none",
                            overflowWrap: "break-word",
                        }}
                        variant="h4"
                        component="div"
                        color="white"
                        maxWidth="75%"
                    >
                        {name}
                    </Typography>
                    <Box flexGrow={1} />
                    <Box>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            sx={{
                                color: "primary.main",
                            }}
                            onClick={(e) => {
                                e.stopPropagation();
                                onClick(
                                    workout.id,
                                    `/content/apps/workouts/plugin/play/${appId}/${id}`,
                                );
                            }}
                            endIcon={<PlayArrowIcon />}
                        >
                            Play
                        </Button>
                    </Box>
                </Stack>
            </CardActions>
        </CardMedia>
    );

    return (
        <Card
            sx={{
                minWidth: WIDTH,
                aspectRatio: "16 / 9",
                borderRadius: "10px",
                order: order ?? undefined,
            }}
            variant="outlined"
            onClick={() => {
                if (selected !== workout.sportName) {
                    updateSelected(workout.sportName as Sport);
                }
                onClick(
                    workout.id,
                    `/content/apps/workouts/plugin/play/${appId}/${id}`,
                );
            }}
        >
            {content}
        </Card>
    );
}
