import * as React from "react";
import { useNavigate } from "react-router-dom";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import Stack from "@mui/material/Stack";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useCurrentUser } from "../hooks/currentUser";

import useDialog from "./useDialog";

export default function FinishedDialog(): JSX.Element {
    const { onClose, setDialogType } = useDialog();

    const { isPro, isAdmin } = useCurrentUser();
    const navigate = useNavigate();

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={onClose}>
                Ready to End Your Session?
            </CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    Disconnect if someone else is ready to use the trainer, this
                    will leave the trainer on. Shut down if you are the last to
                    use it.
                </DialogContentText>
                <Stack spacing={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() => setDialogType("NoConfirmUnpair")}
                    >
                        Disconnect
                    </Button>

                    <Button
                        variant="contained"
                        color="info"
                        size="large"
                        fullWidth
                        onClick={() => setDialogType("NoConfirmShutdown")}
                    >
                        Shut Down
                    </Button>

                    {(isPro() || isAdmin()) && (
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            fullWidth
                            onClick={() => {
                                setDialogType(null);
                                navigate("/content/history-stats/mine", {
                                    state: { showCurrent: true },
                                });
                            }}
                        >
                            Share Workouts
                        </Button>
                    )}
                </Stack>
            </DialogContent>
        </>
    );
}
