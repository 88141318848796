import * as React from "react";

import RestartAltIcon from "@mui/icons-material/RestartAlt";
import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import logger from "../../log";
import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";

import LowerHeadDialog from "./LowerHeadDialog";
import useDialog from "./useDialog";

const enum VisionFaultRestartFlowStep {
    GetConfirmation,
    LowerHead,
    PerformRestart,
}

interface VisionFaultDialogProps {
    primaryText: string;
    step: VisionFaultRestartFlowStep;
    changeStep: (newStep: VisionFaultRestartFlowStep) => void;
}

export function VisionFaultDialog({
    primaryText,
    step,
    changeStep,
}: VisionFaultDialogProps): JSX.Element {
    const { status } = useStatus();
    const { onClose } = useDialog();
    const { unpair } = usePairingContext();

    const [loading, setLoading] = React.useState(false);
    const [error, setError] = React.useState(false);

    const onClick = React.useCallback(() => {
        changeStep(VisionFaultRestartFlowStep.LowerHead);
    }, [changeStep]);

    React.useEffect(() => {
        async function restart() {
            await pairedFetchApi(status?.clientId, "/api/reboot", "POST");
        }

        if (!loading && step === VisionFaultRestartFlowStep.PerformRestart) {
            logger.info("Head lowered, requesting reboot.");
            setLoading(true);
            restart()
                .then(() => {
                    logger.info("Reboot command accepted, unpairing.");
                    unpair();
                })
                .catch((ex) => {
                    logFetchError(ex);
                    setError(true);
                    setLoading(false);
                });
        }
    }, [loading, status?.clientId, step, unpair]);

    const restartFailed = [
        "We were unable to restart the trainer.",
        "Please press the red stop button on the trainer until the light turns off.",
        "Then press the green button to power it back on.",
    ].join(" ");

    const text = error ? restartFailed : primaryText;

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={onClose}>
                Camera Unavailable
            </CloseableDialogTitle>
            <DialogContent dividers sx={{ mx: "auto" }}>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant="contained"
                    color="secondary"
                    fullWidth
                    loading={loading}
                    onClick={onClick}
                    sx={{ mb: 2 }}
                    startIcon={<RestartAltIcon />}
                >
                    Restart
                </LoadingButton>
            </DialogActions>
        </>
    );
}

interface Props {
    primaryText: string;
}

function VisionFaultRestartFlowCore({
    primaryText,
}: Props): JSX.Element | null {
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState(
        VisionFaultRestartFlowStep.GetConfirmation,
    );

    if (step !== VisionFaultRestartFlowStep.LowerHead) {
        return (
            <VisionFaultDialog
                primaryText={primaryText}
                step={step}
                changeStep={(s) => setStep(s)}
            />
        );
    }

    if (step === VisionFaultRestartFlowStep.LowerHead) {
        return (
            <LowerHeadDialog
                message="The trainer arm is being lowered before restarting the trainer."
                reason="unpair"
                onLowered={() =>
                    setStep(VisionFaultRestartFlowStep.PerformRestart)
                }
                onStopped={() => setDialogType(null)}
            />
        );
    }

    return null;
}

export function VisionFaultRestartServeAndVolleyFlow(): JSX.Element | null {
    const text =
        "You can restart the trainer to attempt to fix this problem, or play a different workout. Serve and Volley requires the camera.";

    return <VisionFaultRestartFlowCore primaryText={text} />;
}

export function VisionFaultRestartFlow(): JSX.Element | null {
    const text =
        "You can restart the trainer to attempt to fix this problem, or keep using the trainer without the camera.";

    return <VisionFaultRestartFlowCore primaryText={text} />;
}
