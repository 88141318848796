import * as React from "react";

export default function useDebounce<T>(state: T, delay = 500): T {
    const [value, setValue] = React.useState(state);
    React.useEffect(() => {
        const h = setTimeout(() => setValue(state), delay);
        return () => clearTimeout(h);
    }, [state, delay]);
    return value;
}
