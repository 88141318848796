import * as React from "react";
import { useNavigate, useParams, Link as RouterLink } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

import { convert } from "@volley/physics";
import type {
    SingleShotConfig,
    SingleShotParameters,
    SingleShotState,
} from "@volley/shared/apps/single-shot-models";
import { JSONObject } from "@volley/shared/common-models";
import { PlayerPosition } from "@volley/shared/dist/apps/app-common-models";

import logger from "../../../../../log";
import fetchApi, { logFetchError } from "../../../../../util/fetchApi";
import Loading from "../../../../common/Loading";
import Label from "../../../../common/Visualizer/Label";
import LocalizeWorkoutVisualizer from "../../../../common/Visualizer/LocalizeWorkoutVisualizer";
import Overlay from "../../../../common/Visualizer/Overlay";
import { singleShotToVisualizer } from "../../../../common/Visualizer/utils";
import { useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { usePairingContext } from "../../../../hooks/pairingStatus";
import useRanges from "../../../../hooks/ranges";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import { useTrainerFeatures } from "../../../../hooks/useTrainerFeatures";
import {
    mirrorPlayer,
    mirrorShot,
    mirrorTrainer,
    sideKeys,
} from "../../../Position/util";
import useThrowNow from "../../../util/useThrowNow";
import { SMALLE_MAX_BALLS } from "../../Accordions/ParamsAccordion";
import CaptureToast from "../../Shared/CaptureToast";
import DetailsButton from "../../Shared/DetailsButton";
import FavoriteButton from "../../Shared/FavoriteButton";
import FeatureNotifier from "../../Shared/FeatureNotifier";
import PlayAppBar from "../../Shared/PlayAppBar";
import WorkoutDeleteConfirmation from "../../Shared/WorkoutDeleteConfirmation";
import useAppWorkouts, {
    appWorkoutToCreate,
    appWorkoutToUpdate,
} from "../../db";
import LocalWorkouts from "../../localWorkoutState";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import { KnownFeatures, makeWorkoutCompatible } from "../../util";
import makeSafeSpinLevel from "../util";

import EditControls from "./EditControls";
import PlayControls from "./PlayControls";
import SingleShotSaveAsDialog from "./SingleShotSaveAsDialog";
import {
    createNewSingleShot,
    DEFAULT_PARAMETERS,
    reducer,
    WorkoutForm,
} from "./reducer";

export default function SingleShotPlayScreen(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const { status: pairingStatus } = usePairingContext();
    const validId = parseInt(id ?? "", 10);
    const navigate = useNavigate();
    const features = useTrainerFeatures();
    const { lift } = useRanges();
    const {
        addWorkout,
        deleteWorkout,
        updateWorkout,
        loadSingleShot,
        getWorkout,
        loading,
    } = useAppWorkouts();
    const { currentUser } = useCurrentUser();
    const {
        selected: selectedSport,
        getIdFromSport,
        getDefaultPosition,
    } = useSelectedSport();
    const { physicsModelName } = usePhysicsModelContext();

    const [missingFeatures, setMissingFeatures] = React.useState<
        KnownFeatures[]
    >([]);
    const [workout, workoutDispatch] = React.useReducer(reducer, null);
    const [duplicateId, setDuplicateId] = React.useState<number | null>(null);
    const [mode, setMode] = React.useState<"edit" | "play">("play");
    const [toDelete, setToDelete] = React.useState<WorkoutForm | null>(null);
    const [saveBeforePlayOpen, setSaveBeforePlayOpen] = React.useState(false);

    const workoutForVisualizer = React.useMemo(() => {
        if (workout) {
            return singleShotToVisualizer(
                workout,
                workout.parameters.playMode,
                selectedSport,
            );
        }

        return undefined;
    }, [workout, selectedSport]);
    const [hasLocalized, setHasLocalized] = React.useState(false);

    const {
        error: localizationError,
        improve,
        position: localizedPosition,
    } = usePosition();

    const workoutPosition = React.useMemo(() => {
        // localized position can be a cached value from a previous localization attempt
        // if we pass this along to the useAppWorkoutPlay hook, it can result in unintentional trim application
        // If the selected sport is not platform tennis, or the user hasn't localized for this workout yet,
        // or if localization reports errors or need for improvement... don't use the localized position value
        if (
            selectedSport !== "PLATFORM_TENNIS" ||
            !hasLocalized ||
            improve ||
            localizationError
        ) {
            return undefined;
        }

        return localizedPosition;
    }, [
        selectedSport,
        localizationError,
        improve,
        localizedPosition,
        hasLocalized,
    ]);

    const parameters = React.useMemo(
        () => workout?.parameters ?? DEFAULT_PARAMETERS,
        [workout],
    );

    const { stop: stopLift, checkForLift } = useLift();

    const {
        pause,
        start,
        stop,
        pauseDisabled,
        playDisabled,
        playState,
        playInitiated,
        workoutState,
        captureVideo,
        captureDisabled,
        captureStatus,
    } = useAppWorkoutPlay({
        workout,
        parameters: (workout?.parameters ??
            DEFAULT_PARAMETERS) as unknown as JSONObject,
        localizedPosition: workoutPosition,
    });
    const singleShotState = workoutState as unknown as SingleShotState | null;
    const ballsThrown = singleShotState?.currentShotNumber ?? 0;
    const totalBalls = React.useMemo(() => {
        const params = parameters as unknown as SingleShotParameters;
        return params.numberOfBalls === SMALLE_MAX_BALLS
            ? "All"
            : params.numberOfBalls;
    }, [parameters]);
    const shotSummary = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const pan = React.useMemo(
        () =>
            workout?.parameters.playMode === "mirror"
                ? ((workout?.config as unknown as SingleShotConfig)?.shot.pan ??
                      0) * -1
                : ((workout?.config as unknown as SingleShotConfig)?.shot.pan ??
                  0),
        [workout?.parameters.playMode, workout?.config],
    );

    const { canThrow, throwing, makeShot } = useThrowNow({
        launchSpeed:
            (workout?.config as unknown as SingleShotConfig)?.shot
                .launchSpeed ?? 0,
        pan,
        tilt: (workout?.config as unknown as SingleShotConfig)?.shot.tilt ?? 0,
        spinAxis:
            (workout?.config as unknown as SingleShotConfig)?.shot
                .spinDirection ?? 0,
        spinLevel:
            (workout?.config as unknown as SingleShotConfig)?.shot.spinLevel ??
            0,
        height: workout?.positionHeight ?? lift.min,
    });

    const fetchWorkout = React.useCallback(async () => {
        if (!Number.isNaN(validId)) {
            const loaded = await loadSingleShot(validId);
            if (loaded) {
                const { workout: data } = loaded;
                const feedParams = LocalWorkouts.getFeed();
                const params: SingleShotParameters = feedParams
                    ? { ...DEFAULT_PARAMETERS, ...feedParams }
                    : { ...DEFAULT_PARAMETERS };
                workoutDispatch({
                    type: "set",
                    value: {
                        ...data,
                        parameters: params,
                        isOwner: data.createdBy === currentUser?.email,
                    },
                });
                setDuplicateId(null);
            }
        } else {
            setMode("edit");
            let position = getDefaultPosition();
            if (selectedSport === "PLATFORM_TENNIS") {
                const converted = convert.physics2localization({
                    x: position.x,
                    y: position.y,
                    z: lift.min * 0.0254,
                });
                position = { x: converted.x, y: converted.y, sys: "court" };
            }
            workoutDispatch({
                type: "set",
                value: createNewSingleShot(
                    currentUser?.email ?? "",
                    physicsModelName,
                    selectedSport,
                    position,
                    getIdFromSport(selectedSport),
                    lift.min,
                ),
            });
            setDuplicateId(null);
        }
    }, [
        validId,
        loadSingleShot,
        currentUser?.email,
        physicsModelName,
        getDefaultPosition,
        selectedSport,
        getIdFromSport,
        lift.min,
    ]);

    React.useEffect(() => {
        fetchWorkout().catch((e) =>
            logFetchError(e, "Failed to fetch workout."),
        );
    }, [fetchWorkout]);

    const resetToDefault = React.useCallback(async () => {
        if (workout?.id === 0) {
            navigate(-1);
        }
        if (playState !== "stopped") {
            await stop();
        }
        const original = await getWorkout(validId);
        if (original) {
            workoutDispatch({
                type: "set",
                value: {
                    ...original,
                    parameters: DEFAULT_PARAMETERS,
                    isOwner: original.createdBy === currentUser?.email,
                },
            });
            LocalWorkouts.clear();
        }
        setMode("play");
    }, [
        workout?.id,
        playState,
        getWorkout,
        validId,
        navigate,
        stop,
        currentUser?.email,
    ]);

    React.useEffect(() => {
        if (workout === null || features.length === 0) {
            return;
        }

        const { missing, workout: updated } = makeWorkoutCompatible(
            workout,
            features,
        );
        if (missing.length > 0) {
            workoutDispatch({
                type: "set",
                value: {
                    ...updated,
                    parameters: workout.parameters,
                    isOwner: updated.createdBy === currentUser?.email,
                },
            });
            setMissingFeatures(missing);
        }
    }, [workout, features, currentUser]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        await stop();
    }, [stopLift, stop]);

    const handleDeleteConfirmed = React.useCallback(async () => {
        if (toDelete !== null) {
            await deleteWorkout(toDelete);
            navigate(`../play/${toDelete.appId}`, { replace: true });
            setToDelete(null);
        }
    }, [toDelete, deleteWorkout, navigate]);

    const trainerPosition = React.useMemo(() => {
        if (!workout) return undefined;
        return {
            x: workout.positionX,
            y: workout.positionY,
            heightIn: workout.positionHeight,
            yaw: workout.positionYaw,
        };
    }, [workout]);

    const playerPosition = React.useMemo(() => {
        if (!workout?.config) return undefined;
        return (
            (workout.config as unknown as SingleShotConfig).playerPosition ?? {
                x: 0,
                y: 0,
            }
        );
    }, [workout?.config]);

    const side = React.useMemo(() => {
        if (!playerPosition) return "ad";

        if (workout?.parameters.playMode === "dual") {
            return "both";
        }

        const { deuceKey } = sideKeys(
            playerPosition as PlayerPosition,
            selectedSport,
        );
        if (deuceKey === workout?.parameters?.playMode) {
            return "deuce";
        }
        return "ad";
    }, [playerPosition, selectedSport, workout?.parameters?.playMode]);

    const saveWorkout = React.useCallback(
        async (
            asCopy = false,
            workoutName?: string,
            workoutDescription?: string,
        ) => {
            if (!workout || !playerPosition || !trainerPosition) return;
            const { isDirty: modified, ...workoutData } = workout;
            const { shot: originalShot } =
                workout.config as unknown as SingleShotConfig;

            const { spinDirection, spinLevel, launchSpeed } = originalShot;
            const safeSpinLevel = makeSafeSpinLevel(
                spinLevel || 0,
                spinDirection,
                launchSpeed,
            );

            const shot = {
                ...originalShot,
                spinLevel: safeSpinLevel,
            };

            const toSave = asCopy
                ? appWorkoutToCreate(workoutData)
                : appWorkoutToUpdate(workoutData);
            let updatedPlayerPosition = { ...playerPosition };
            let updatedTrainerPosition = { ...trainerPosition };
            let updatedShot = { ...shot };
            if (parameters.playMode === "mirror") {
                logger.info("Mirroring player/trainer position and shot");
                updatedPlayerPosition = mirrorPlayer(
                    playerPosition,
                    selectedSport,
                );
                updatedTrainerPosition = mirrorTrainer(
                    trainerPosition,
                    selectedSport,
                );
                updatedShot = mirrorShot(updatedShot);
                parameters.playMode = "standard";
            }

            if (workoutName) toSave.name = workoutName;
            if (workoutDescription) toSave.description = workoutDescription;
            toSave.config = {
                shot: updatedShot,
                playerPosition: updatedPlayerPosition,
            } as unknown as JSONObject;
            toSave.positionX = updatedTrainerPosition.x;
            toSave.positionY = updatedTrainerPosition.y;
            toSave.positionYaw = updatedTrainerPosition.yaw;

            if (asCopy) {
                toSave.contentProviderId = null;
                const saved = await addWorkout(toSave);
                if (saved !== null) {
                    logger.info(`Successfully saved new workout: ${saved.id}`);
                    if (workout.tags) {
                        try {
                            const tagIds = Object.values(workout.tags).flatMap(
                                (t) => t.map((tag) => tag.id),
                            );
                            await fetchApi(
                                `/api/app-workouts/${saved.id}/tags`,
                                "PUT",
                                {
                                    tagIds,
                                },
                            );
                        } catch (e) {
                            logFetchError(e, "Failed to update tags");
                        }
                    }
                    if (workout.id === 0) {
                        navigate(`../edit/${saved.appId}/${saved.id}`, {
                            replace: true,
                        });
                    } else {
                        setDuplicateId(saved.id);
                        LocalWorkouts.clear();
                    }
                }
            } else {
                const saved = await updateWorkout({
                    id: workout.id,
                    ...toSave,
                });
                if (saved) {
                    const params =
                        parameters as unknown as SingleShotParameters;
                    logger.info(
                        `Successfully updated existing workout: ${saved.id}`,
                    );
                    workoutDispatch({
                        type: "set",
                        value: {
                            ...saved,
                            parameters: params,
                            isOwner: true,
                        },
                    });
                    LocalWorkouts.clear();
                    if (workout.tags) {
                        try {
                            const tagIds = Object.values(workout.tags).flatMap(
                                (t) => t.map((tag) => tag.id),
                            );
                            await fetchApi(
                                `/api/app-workouts/${workout.id}/tags`,
                                "PUT",
                                {
                                    tagIds,
                                },
                            );
                        } catch (e) {
                            logFetchError(e, "Failed to update tags");
                        }
                    }
                }
            }
        },
        [
            workout,
            playerPosition,
            trainerPosition,
            parameters,
            selectedSport,
            addWorkout,
            navigate,
            updateWorkout,
        ],
    );

    const handlePlayClicked = React.useCallback(async () => {
        logger.info(
            `Play clicked, playState: ${playState}, workout state: ${workout?.isDirty}`,
        );
        if (workout?.isDirty && playState !== "stopped") {
            await stop();
            if (workout.id === 0) {
                setSaveBeforePlayOpen(true);
                return;
            }
        }
        checkForLift();
        await start();
    }, [checkForLift, start, playState, workout?.isDirty, workout?.id, stop]);

    if (workout === null && loading) {
        return <Loading />;
    }

    return (
        <Stack
            spacing={1}
            pb={12}
            px={1}
            minHeight="calc(100vh - 66px)"
            sx={{ backgroundColor: "background.default" }}
        >
            {workout && (
                <>
                    <LocalizeWorkoutVisualizer
                        hasLocalized={hasLocalized}
                        setHasLocalized={() => setHasLocalized(true)}
                        playMode={parameters.playMode}
                        workout={workoutForVisualizer}
                        maxHeight={225}
                        side={side}
                        overlay={
                            <Overlay>
                                <FavoriteButton
                                    workoutId={workout.id}
                                    isFavorite={workout.isFavorite}
                                    onClick={() => {
                                        workoutDispatch({
                                            type: "favorite",
                                            value: !workout.isFavorite,
                                        });
                                    }}
                                />
                                <Label text={workout.name} />
                                <DetailsButton workout={workout} />
                            </Overlay>
                        }
                    />
                    {mode === "play" ? (
                        <>
                            <PlayControls
                                workout={workout}
                                dispatch={workoutDispatch}
                                disabled={playState === "playing"}
                                setMode={setMode}
                                onDelete={() => setToDelete(workout)}
                                saveWorkout={saveWorkout}
                            />
                            <PlayAppBar
                                onPlayClicked={handlePlayClicked}
                                onPauseClicked={() => pause()}
                                pauseDisabled={pauseDisabled}
                                playDisabled={playDisabled}
                                showRecord
                                onRecordClicked={() => captureVideo()}
                                playState={playState}
                                playSummary={shotSummary}
                                recordDisabled={captureDisabled}
                                recordingStatus={captureStatus}
                            />
                        </>
                    ) : (
                        <EditControls
                            workout={workout}
                            dispatch={workoutDispatch}
                            editDisabled={playState === "playing"}
                            saveDisabled={!canThrow}
                            onCancel={resetToDefault}
                            onDone={() => setMode("play")}
                            onThrow={() => {
                                makeShot(workout.positionHeight).catch((e) =>
                                    logFetchError(e, "Error throwing test"),
                                );
                            }}
                            throwDisabled={
                                !canThrow ||
                                throwing ||
                                pairingStatus !== "paired"
                            }
                        />
                    )}
                    <LiftModal
                        stop={handleLiftStop}
                        targetHeight={
                            playInitiated ? workout.positionHeight : undefined
                        }
                        message="The trainer is adjusting the head height."
                    />
                    <CaptureToast captureStatus={captureStatus} />
                    <FeatureNotifier missing={missingFeatures} />
                    <Snackbar
                        open={duplicateId !== null}
                        onClose={() => setDuplicateId(null)}
                        autoHideDuration={5000}
                    >
                        <Alert
                            variant="filled"
                            severity="info"
                            action={
                                <IconButton
                                    onClick={() => setDuplicateId(null)}
                                    color="inherit"
                                >
                                    <CloseIcon />
                                </IconButton>
                            }
                        >
                            <AlertTitle>Shot Duplicated</AlertTitle>
                            <Stack direction="row" justifyContent="flex-end">
                                <Button
                                    size="small"
                                    color="inherit"
                                    component={RouterLink}
                                    to={`../play/${workout.appId}/${duplicateId}`}
                                >
                                    View
                                </Button>
                            </Stack>
                        </Alert>
                    </Snackbar>
                    <WorkoutDeleteConfirmation
                        workout={toDelete}
                        onCancel={() => setToDelete(null)}
                        onConfirm={() => handleDeleteConfirmed()}
                    />
                    <SingleShotSaveAsDialog
                        defaultName={workout.name}
                        onClose={() => setSaveBeforePlayOpen(false)}
                        onConfirm={async (name, description) => {
                            await saveWorkout(true, name, description);
                            await handlePlayClicked();
                        }}
                        open={saveBeforePlayOpen}
                        title="Save"
                    />
                </>
            )}
        </Stack>
    );
}
