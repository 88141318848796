import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import NotchedOutline from "../../../common/NotchedOutline";
import SlimNumberInput from "../../util/SlimNumberInput";
import AccordionSlider from "../Shared/AccordionSlider";

type AimVariation = "none" | "slight" | "moderate" | "large";

type VariationText = AimVariation | "yes";

type AimType = "Aim" | "Arc";

const KnownAimVariation: Record<AimVariation, number> = {
    none: 0,
    slight: 2,
    moderate: 8,
    large: 15,
};

function variationText(test: number, aimType: AimType): VariationText {
    const match = Object.keys(KnownAimVariation).find(
        (k) => KnownAimVariation[k as AimVariation] === test,
    );

    if (aimType === "Arc") {
        return match !== "none" ? "yes" : match;
    }
    return (match as AimVariation) ?? "none";
}

interface AimAccordionProps extends Omit<AccordionProps, "children"> {
    aimRange: { min: number; max: number };
    label: AimType;
    selectedAim: number;
    onAimChanged: (value: number) => void;
    selectedAimVariation: number;
    onAimVariationChanged: (value: number) => void;
}

export default function AimAccordion({
    aimRange,
    label,
    selectedAim,
    onAimChanged,
    selectedAimVariation,
    onAimVariationChanged,
    ...props
}: AimAccordionProps): JSX.Element {
    const [userAim, setUserAim] = React.useState(selectedAim);
    const marks = React.useMemo(
        () => [
            {
                value:
                    aimRange.min +
                    Math.floor((10 / 100) * Math.abs(aimRange.min)),
                label: label === "Aim" ? "Left" : "Down",
            },
            {
                value: 0,
                label: label === "Aim" ? "Center" : "Level",
            },
            {
                value: aimRange.max - Math.floor((10 / 100) * aimRange.max),
                label: label === "Aim" ? "Right" : "Up",
            },
        ],
        [label, aimRange.min, aimRange.max],
    );

    React.useEffect(() => {
        setUserAim(selectedAim);
    }, [selectedAim]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!props.expanded && (
                    <Box component="div">
                        <Typography variant="h3" color="primary.main">
                            {`${label}: ${selectedAim.toFixed(1)}°`}
                        </Typography>
                        <Typography variant="h4" color="primary.main">
                            {`Variance: ${variationText(selectedAimVariation, label)}`}
                        </Typography>
                    </Box>
                )}
                {props.expanded && (
                    <Typography variant="h3" color="primary.main">
                        {label}
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3}>
                    <NotchedOutline label="Angle">
                        <Stack spacing={3}>
                            <SlimNumberInput
                                onChange={(value) => {
                                    onAimChanged(value);
                                }}
                                value={userAim}
                                incrementValue={0.5}
                                maxValue={aimRange.max}
                                minValue={aimRange.min}
                                suffix="°"
                            />
                            <AccordionSlider
                                min={aimRange.min}
                                max={aimRange.max}
                                marks={marks}
                                track={false}
                                step={0.5}
                                value={userAim}
                                onChange={(_, v) => setUserAim(v as number)}
                                onChangeCommitted={(_, v) =>
                                    onAimChanged(v as number)
                                }
                                sx={{
                                    "& .MuiSlider-root": {
                                        marginBottom: "24px",
                                    },
                                    maxWidth: "90%",
                                    alignSelf: "center",
                                }}
                            />
                        </Stack>
                        {label === "Aim" && (
                            <Stack
                                spacing={1}
                                sx={{
                                    marginTop: "36px",
                                }}
                            >
                                <Typography variant="caption" align="center">
                                    Add Variance?
                                </Typography>
                                <ButtonGroup fullWidth>
                                    {Object.keys(KnownAimVariation).map((k) => {
                                        const selected =
                                            k ===
                                            variationText(
                                                selectedAimVariation,
                                                label,
                                            );
                                        return (
                                            <Button
                                                key={`${label}-${k}`}
                                                fullWidth
                                                variant={
                                                    selected
                                                        ? "contained"
                                                        : "outlined"
                                                }
                                                sx={{
                                                    color: selected
                                                        ? "common.white"
                                                        : "primary.light",
                                                    backgroundColor: selected
                                                        ? "primary.light"
                                                        : "common.white",
                                                    fontSize: "10px",
                                                    "&:hover": {
                                                        bgcolor:
                                                            "primary.light",
                                                    },
                                                }}
                                                onClick={() => {
                                                    onAimVariationChanged(
                                                        KnownAimVariation[
                                                            k as AimVariation
                                                        ],
                                                    );
                                                }}
                                            >
                                                {k}
                                            </Button>
                                        );
                                    })}
                                </ButtonGroup>
                            </Stack>
                        )}
                        {label === "Arc" && (
                            <Stack
                                direction="row"
                                spacing={1}
                                alignItems="center"
                                justifyContent="center"
                                sx={{
                                    marginTop: "36px",
                                }}
                            >
                                <Typography variant="caption" align="center">
                                    Add Variance?
                                </Typography>
                                <Stack
                                    direction="row"
                                    spacing={1}
                                    alignItems="center"
                                    justifyContent="center"
                                >
                                    <Typography sx={{ color: "primary.light" }}>
                                        No
                                    </Typography>
                                    <Switch
                                        checked={selectedAimVariation === 2}
                                        onChange={(e) => {
                                            const val = e.target.checked
                                                ? 2
                                                : 0;
                                            onAimVariationChanged(val);
                                        }}
                                        sx={{
                                            "& .MuiSwitch-thumb": {
                                                color:
                                                    selectedAimVariation === 2
                                                        ? "primary.light"
                                                        : "common.white",
                                            },
                                            "& .Mui-checked+ .MuiSwitch-track":
                                                {
                                                    backgroundColor:
                                                        selectedAimVariation ===
                                                        2
                                                            ? "#A1C8F5"
                                                            : "grey.500",
                                                },
                                        }}
                                    />
                                    <Typography sx={{ color: "primary.light" }}>
                                        Yes
                                    </Typography>
                                </Stack>
                            </Stack>
                        )}
                    </NotchedOutline>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
