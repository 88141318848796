import { type PositionWithHeight } from "../../../../hooks/usePosition";

interface LatestPositionStateManager {
    getPosition: () => PositionWithHeight | null;
    setPosition: (position: PositionWithHeight) => void;
    clearPosition: () => void;
}

const VOLLEY_LATEST_POSITION = "volley-latest-position";

const LatestPositionState: LatestPositionStateManager = {
    getPosition: () => {
        const objectMaybe = sessionStorage.getItem(VOLLEY_LATEST_POSITION);
        if (objectMaybe) {
            return JSON.parse(objectMaybe) as PositionWithHeight;
        }

        return null;
    },
    setPosition: (position: PositionWithHeight) => {
        const value = JSON.stringify(position);
        sessionStorage.setItem(VOLLEY_LATEST_POSITION, value);
    },
    clearPosition: () => {
        sessionStorage.removeItem(VOLLEY_LATEST_POSITION);
    },
};

export default LatestPositionState;
