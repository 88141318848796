import * as React from "react";

import { datadogRum } from "@datadog/browser-rum";
import { initializeApp, getApps } from "firebase/app";
import { createRoot } from "react-dom/client";

import App from "./components/App";
import config from "./config";

if (config.env !== "local") {
    datadogRum.init(config.datadogRum);
    datadogRum.startSessionReplayRecording();
}

if (getApps().length === 0) {
    initializeApp(config.firebase);
}

if (config.env !== "production") {
    document.title = `${document.title} - ${config.env[0].toUpperCase()}${config.env.slice(1)}`;
}

const root = createRoot(document.getElementById("app") as HTMLElement);
root.render(React.createElement(App, {}, null));
