import * as React from "react";

import type {
    OffsetResult,
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutCreatePayload,
    AppWorkoutUpdatePayload,
    AppWorkoutListItem,
    WorkoutPlay,
} from "@volley/data";
import type { CuratedWorkoutParameters } from "@volley/shared/apps/curated-workout-models";
import {
    ResponsiveWorkout,
    ResponsiveIndexEntry,
} from "@volley/shared/apps/responsive-models";
import { ServeAndVolleyAppConfig } from "@volley/shared/apps/serveandvolley-models";
import type { SingleShotParameters } from "@volley/shared/apps/single-shot-models";
import type { JSONObject } from "@volley/shared/common-models";
import type { CuratedWorkoutConfig } from "@volley/shared/dist/apps/curated-workout-models";

import logger from "../../../log";
import fetchApi, { logFetchError } from "../../../util/fetchApi";
import generateUUID from "../../../util/uuid";
import { useSelectedSport } from "../../common/context/sport";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";

import LocalWorkouts from "./localWorkoutState";
import { isMultiLevelWorkout } from "./util";

export type AppWorkoutState = "BUILD" | "RELEASE" | "TEST" | "SOFT_DELETE";
export type AppWorkoutDifficulty =
    | "BEGINNER"
    | "INTERMEDIATE"
    | "ADVANCED"
    | "PRO";
export type AppWorkoutShotType = "LOB" | "SERVE" | "VOLLEY" | "SCREEN";

export interface MinimalAppWorkout {
    id: number;
    appId: number;
    name: string;
    overview: string;
    config: JSONObject;
}

export interface AppExtendedData {
    difficulty?: AppWorkoutDifficulty;
    shotTypes?: AppWorkoutShotType[];
    promoted?: boolean;
    basedOn?: number;
}

export interface SharedAppWorkout extends AppWorkout {
    originalAppId: number;
    sharedBy: string;
}

export function isSharedWorkout(
    maybe: AppWorkout | SharedAppWorkout,
): maybe is SharedAppWorkout {
    return (maybe as SharedAppWorkout).sharedBy !== undefined;
}

export type WorkoutPlayWithWorkout = WorkoutPlay & {
    workout: AppWorkout;
};

export interface WorkoutWithParams {
    workout: AppWorkout;
    params: JSONObject;
    source: "local" | "history" | "original" | "default";
}

interface WorkoutDB {
    error: string | null;
    loading: boolean;
    addWorkout: (
        workout: AppWorkoutCreatePayload,
    ) => Promise<AppWorkout | null>;
    deleteWorkout: (workout: AppWorkout) => Promise<void>;
    duplicateWorkout: (
        toDuplicate: AppWorkout,
        workouts: AppWorkout[],
    ) => Promise<number | null>;
    fetchWorkouts: () => Promise<AppWorkout[]>;
    fetchWorkoutsByAppId: (appId: number) => Promise<AppWorkout[]>;
    fetchMyWorkouts: () => Promise<AppWorkout[]>;
    fetchMySharedWorkouts: () => Promise<SharedAppWorkout[]>;
    getWorkout: (id: number) => Promise<AppWorkout | null>;
    updateWorkout: (
        workout: AppWorkoutUpdatePayload,
    ) => Promise<AppWorkout | null>;
    loadMultiShot: (id: number) => Promise<WorkoutWithParams | null>;
    loadSingleShot: (id?: number) => Promise<WorkoutWithParams | null>;
    loadModule: (id: number) => Promise<WorkoutWithParams | null>;
    loadResponsiveWorkouts: () => Promise<ResponsiveIndexEntry[]>;
    loadResponsiveWorkout: (
        fileSuffix: string,
    ) => Promise<ResponsiveWorkout | null>;
    loadResponsiveWorkoutList: () => Promise<AppWorkoutListItem[]>;
    loadResponsiveWorkoutApp: (id: number) => Promise<AppWorkoutWithSummary>;
    latestByAppId: (appId: number) => Promise<WorkoutPlayWithWorkout | null>;
    loadServeAndVolleyConfig: () => Promise<ServeAndVolleyAppConfig | null>;
    copyToMyWorkouts: (toCopy: AppWorkout) => Promise<number | null>;
}

export function isAppWorkout(
    maybe: AppWorkout | AppWorkoutCreatePayload,
): maybe is AppWorkout {
    return (maybe as AppWorkout).id !== undefined;
}

export function isUpdate(
    maybe: AppWorkout | AppWorkoutUpdatePayload,
): maybe is AppWorkoutUpdatePayload {
    return !Object.hasOwn(maybe, "_count");
}

export function appWorkoutToUpdate(
    workout: AppWorkout,
): AppWorkoutUpdatePayload {
    const update: AppWorkoutUpdatePayload = {
        appId: workout.appId,
        appName: workout.appName,
        config: workout.config,
        contentProviderId: workout.contentProviderId,
        copiedFromAppWorkoutId: workout.copiedFromAppWorkoutId,
        description: workout.description,
        extendedData: workout.extendedData,
        id: workout.id,
        name: workout.name,
        overview: workout.overview,
        physicsModelName: workout.physicsModelName,
        positionHeight: workout.positionHeight,
        positionX: workout.positionX,
        positionY: workout.positionY,
        positionYaw: workout.positionYaw,
        sport: {
            name: workout.sport.name,
        },
        state: workout.state,
    };
    return update;
}

export function appWorkoutToCreate(
    workout: AppWorkout,
): AppWorkoutCreatePayload {
    const {
        appId,
        appName,
        config,
        description,
        extendedData,
        id,
        name,
        overview,
        physicsModelName,
        positionHeight,
        positionX,
        positionY,
        positionYaw,
        contentProviderId,
    } = workout;
    const create: AppWorkoutCreatePayload = {
        appId,
        appName,
        config,
        contentProviderId,
        copiedFromAppWorkoutId: id,
        description,
        extendedData,
        name,
        overview,
        physicsModelName,
        positionHeight,
        positionX,
        positionY,
        positionYaw,
        sport: {
            name: workout.sport.name,
        },
        state: "RELEASE",
    };
    return create;
}

export function generateDefaultFreeplayWorkout(
    physicsModelName: string,
    positionHeight: number,
    sport: string,
): AppWorkoutCreatePayload {
    return {
        appId: 1,
        appName: "FreePlay",
        config: {
            shot: {
                id: generateUUID(),
                pan: 0,
                panVariation: 0,
                tilt: 0,
                tiltVariation: 0,
                launchSpeed: 6.7,
                launchSpeedVariation: 0,
                spinDirection: 0,
                spinLevel: 0,
            },
        },
        physicsModelName,
        description: "Generated Free Play Workout",
        name: "Free Play",
        overview: "",
        positionHeight,
        positionX: 0,
        positionY: 0,
        positionYaw: 0,
        state: "BUILD",
        contentProviderId: null,
        copiedFromAppWorkoutId: null,
        extendedData: null,
        sport: {
            name: sport,
        },
    };
}

export function generateDefaultFreeplayParams(): SingleShotParameters {
    return {
        initialDelay: 1,
        intervalOverride: 2,
        numberOfBalls: 60,
        playMode: "standard",
    };
}

export function generateDefaultCuratedParams(): CuratedWorkoutParameters {
    return {
        initialDelay: 1,
        intervalOverride: 2,
        numberOfBalls: 60,
        playMode: "standard",
        shuffle: false,
    };
}

export default function useAppWorkouts(): WorkoutDB {
    const { selected } = useSelectedSport();
    const [error, setError] = React.useState<string | null>(null);
    const [loading, setLoading] = React.useState(false);
    const { physicsModelName } = usePhysicsModelContext();
    const fetchWorkouts = React.useCallback(async () => {
        let result: AppWorkout[] = [];
        setLoading(true);
        setError(null);
        try {
            const fetched = await fetchApi<OffsetResult<AppWorkout>>(
                "/api/app-workouts?limit=500&offset=0",
            );
            result = fetched.result;
        } catch (ex) {
            setError("Unable to load workouts.");
            logFetchError(ex, "Error fetching workouts");
        } finally {
            setLoading(false);
        }
        return result;
    }, []);
    const fetchWorkoutsByAppId = React.useCallback(
        async (appId: number) => {
            let result: AppWorkout[] = [];
            setLoading(true);
            setError(null);
            try {
                const fetched = await fetchApi<OffsetResult<AppWorkout>>(
                    `/api/app-workouts/app/${appId}?limit=500&offset=0&sport=${selected}`,
                );
                result = fetched.result;
            } catch (ex) {
                setError("Unable to load workouts.");
                logFetchError(ex, `Error fetching workouts for ${selected}`);
            } finally {
                setLoading(false);
            }
            return result;
        },
        [selected],
    );
    const fetchMyWorkouts = React.useCallback(
        async (appId = 9) => {
            let result: AppWorkout[] = [];
            setLoading(true);
            setError(null);
            try {
                const fetched = await fetchApi<OffsetResult<AppWorkout>>(
                    `/api/app-workouts/mine?limit=500&offset=0&appId=${appId}&sport=${selected}`,
                );
                result = fetched.result;
            } catch (ex) {
                setError("Unable to load user workouts.");
                logFetchError(ex, `Error fetching workouts for ${selected}`);
            } finally {
                setLoading(false);
            }
            return result;
        },
        [selected],
    );
    const fetchMySharedWorkouts = React.useCallback(async () => {
        let result: SharedAppWorkout[] = [];
        setLoading(true);
        setError(null);
        try {
            const fetched = await fetchApi<OffsetResult<SharedAppWorkout>>(
                `/api/app-workouts/shared/mine?limit=500&offset=0&sport=${selected}`,
            );
            result = fetched.result;
        } catch (ex) {
            setError("Unable to load shared workouts");
            logFetchError(ex, `Error fetching workouts for ${selected}`);
        } finally {
            setLoading(false);
        }
        return result;
    }, [selected]);
    const updateWorkout = React.useCallback(
        async (workout: AppWorkoutUpdatePayload) => {
            setLoading(true);
            setError(null);
            let updated: AppWorkout | null = null;
            try {
                updated = await fetchApi<AppWorkout>(
                    `/api/app-workouts/${workout.id}`,
                    "PUT",
                    workout,
                );
            } catch (ex) {
                setError("Failed to save changes to the workout");
                logFetchError(
                    ex,
                    `Error updating workout [${workout.id}]: ${JSON.stringify(workout)}`,
                );
            } finally {
                setLoading(false);
            }
            return updated;
        },
        [],
    );
    const deleteWorkout = React.useCallback(async (workout: AppWorkout) => {
        setLoading(true);
        setError(null);
        try {
            await fetchApi(`/api/app-workouts/${workout.id}`, "DELETE");
        } catch (ex) {
            setError("Failed to delete workout");
            logFetchError(ex, `Error deleting workout id: [${workout.id}]`);
        } finally {
            setLoading(false);
        }
    }, []);
    const addWorkout = React.useCallback(
        async (workout: AppWorkoutCreatePayload) => {
            let created: AppWorkout | null = null;
            try {
                created = await fetchApi<AppWorkout>(
                    "/api/app-workouts",
                    "POST",
                    workout,
                );
            } catch (ex) {
                setError("Failed to save workout");
                logFetchError(
                    ex,
                    `Error saving new workout: ${JSON.stringify(workout)}`,
                );
            } finally {
                setLoading(false);
            }
            return created;
        },
        [],
    );
    const getWorkout = React.useCallback(async (id: number) => {
        let fetched: AppWorkout | null = null;
        setLoading(true);
        setError(null);
        try {
            fetched = await fetchApi<AppWorkout>(`/api/app-workouts/${id}`);
        } catch (ex) {
            setError("Failed to load workout");
            logFetchError(ex, `Error fetching workout id: [${id}]`);
        } finally {
            setLoading(false);
        }

        return fetched;
    }, []);
    const duplicateWorkout = React.useCallback(
        async (toDuplicate: AppWorkout, workouts: AppWorkout[]) => {
            if (toDuplicate) {
                const copyOfRegex = /Copy( \d+)? of /;
                const baseName = toDuplicate.name.replace(copyOfRegex, "");
                let copyName = `Copy 1 of ${baseName}`;
                let count = 2;
                let match = workouts.find((w) => w.name === copyName);
                while (match !== undefined) {
                    copyName = `Copy ${count} of ${baseName}`;
                    count += 1;

                    match = workouts.find((w) => w.name === copyName);
                }
                let originalAppId = toDuplicate.appId;
                if (originalAppId === 7) {
                    try {
                        originalAppId = await fetchApi<number>(
                            `/api/app-workouts/original/${toDuplicate.id}`,
                        );
                    } catch (ex) {
                        logFetchError(
                            ex,
                            `Error fetching original app id for ${toDuplicate.id}`,
                        );
                        originalAppId = isMultiLevelWorkout(toDuplicate)
                            ? 9
                            : 5;
                        logger.warn(
                            `Using appId ${originalAppId} for ${toDuplicate.id}`,
                        );
                    }
                }
                const copy: AppWorkoutCreatePayload = {
                    appId: originalAppId,
                    appName: toDuplicate.appName,
                    config: toDuplicate.config,
                    contentProviderId: null,
                    copiedFromAppWorkoutId: toDuplicate.id,
                    description: toDuplicate.description,
                    extendedData: toDuplicate.extendedData,
                    overview: toDuplicate.overview,
                    physicsModelName: toDuplicate.physicsModelName,
                    positionHeight: toDuplicate.positionHeight,
                    positionX: toDuplicate.positionX,
                    positionY: toDuplicate.positionY,
                    positionYaw: toDuplicate.positionYaw,
                    sport: { name: toDuplicate.sport.name },
                    state: toDuplicate.state,
                    name: copyName,
                };
                const saved = await addWorkout(copy);
                return saved?.id ?? null;
            }

            return null;
        },
        [addWorkout],
    );
    const loadResponsiveWorkouts = React.useCallback(async () => {
        let fetched: ResponsiveIndexEntry[] = [];
        setLoading(true);
        setError(null);
        try {
            fetched = await fetchApi<ResponsiveIndexEntry[]>(
                `/api/app-workouts/responsive/${selected}`,
            );
        } catch (ex) {
            logFetchError(ex, `Error responsive workouts for ${selected}.`);
        } finally {
            setLoading(false);
        }
        return fetched;
    }, [selected]);
    const loadResponsiveWorkoutList = React.useCallback(async () => {
        let fetched: AppWorkoutListItem[] = [];
        try {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/app/2?sport=${selected}&limit=100&offset=0`,
            );
            fetched = results.result;
        } catch (ex) {
            logFetchError(ex, "Error loading responsive workouts list.");
        }
        return fetched;
    }, [selected]);
    const loadResponsiveWorkout = React.useCallback(
        async (fileSuffix: string) => {
            let fetched: ResponsiveWorkout | null = null;
            setLoading(true);
            setError(null);
            try {
                fetched = await fetchApi<ResponsiveWorkout>(
                    `/api/app-workouts/responsive/${selected}/${fileSuffix}`,
                );
            } catch (ex) {
                logFetchError(ex, `Error responsive workouts for ${selected}.`);
            } finally {
                setLoading(false);
            }
            return fetched;
        },
        [selected],
    );
    const loadResponsiveWorkoutApp = React.useCallback((id: number) => {
        return fetchApi<AppWorkoutWithSummary>(`/api/app-workouts/${id}`);
    }, []);
    const loadServeAndVolleyConfig = React.useCallback(async () => {
        let fetched: ServeAndVolleyAppConfig | null = null;
        setLoading(true);
        setError(null);
        try {
            fetched = await fetchApi<ServeAndVolleyAppConfig>(
                "/api/app-workouts/appConfig/serve-and-volley/config",
            );
        } catch (ex) {
            logFetchError(ex, "Error loading serve and volley config.");
        } finally {
            setLoading(false);
        }
        return fetched;
    }, []);
    const copyToMyWorkouts = React.useCallback(
        async (toCopy: AppWorkout) => {
            const workouts = await fetchMyWorkouts();
            const copyOfRegex = /Copy( \d+)? of /;
            const baseName = toCopy.name.replace(copyOfRegex, "");
            let copyName = `Copy 1 of ${baseName}`;
            let count = 2;
            let match = workouts.find((w) => w.name === copyName);
            while (match !== undefined) {
                copyName = `Copy ${count} of ${baseName}`;
                count += 1;

                match = workouts.find((w) => w.name === copyName);
            }

            const {
                appName,
                config,
                description,
                extendedData,
                overview,
                positionHeight,
                positionX,
                positionY,
                positionYaw,
                state,
            } = toCopy;

            const copy: AppWorkoutCreatePayload = {
                name: copyName,
                appId: 9,
                copiedFromAppWorkoutId: toCopy.id,
                contentProviderId: null,
                sport: {
                    name: selected,
                },
                appName,
                config,
                description,
                extendedData,
                overview,
                physicsModelName,
                positionHeight,
                positionX,
                positionY,
                positionYaw,
                state,
            };

            const saved = await addWorkout(copy);
            return saved?.id ?? null;
        },
        [fetchMyWorkouts, addWorkout, selected, physicsModelName],
    );
    const loadMultiShot = React.useCallback(async (id: number) => {
        let state: WorkoutWithParams | null = null;
        const cached = LocalWorkouts.get();
        setLoading(true);
        setError(null);
        if (
            (cached?.appId === 4 ||
                cached?.appId === 6 ||
                cached?.appId === 9) &&
            cached?.id === id &&
            cached.params
        ) {
            const workout = await fetchApi<AppWorkout>(
                `/api/app-workouts/${id}`,
            );
            const defaultParams: CuratedWorkoutParameters = {
                initialDelay: 1,
                intervalOverride:
                    (workout.config as CuratedWorkoutConfig).interval ?? 2,
                numberOfBalls:
                    (workout.config as CuratedWorkoutConfig).shotCount ?? 60,
                playMode: "standard",
                shuffle:
                    (workout.config as CuratedWorkoutConfig).randomize ?? false,
            };
            const params = {
                ...defaultParams,
                ...cached.params,
            };
            state = {
                workout,
                params,
                source: "local",
            };
            setLoading(false);
        } else {
            try {
                const latestPlay = await fetchApi<WorkoutPlayWithWorkout>(
                    `/api/workout-plays/mine/latest/${id}`,
                );
                state = {
                    workout: latestPlay.workout,
                    params: latestPlay.params as JSONObject,
                    source: "history",
                };
            } catch {
                try {
                    const workout = await fetchApi<AppWorkout>(
                        `/api/app-workouts/${id}`,
                    );
                    const params: JSONObject = {
                        initialDelay: 1,
                        intervalOverride:
                            (workout.config as unknown as CuratedWorkoutConfig)
                                .interval || 2,
                        numberOfBalls:
                            (workout.config as unknown as CuratedWorkoutConfig)
                                .shotCount || 60,
                        playMode: "standard",
                        shuffle: false,
                    };

                    state = {
                        workout,
                        params,
                        source: "original",
                    };
                } catch (ex2) {
                    logFetchError(ex2);
                }
            } finally {
                setLoading(false);
            }
        }

        if (state) {
            logger.info(
                `Loaded workout state for workout id ${id} from ${state.source}`,
            );
        } else {
            logger.warn(`Unable to load workout state for ${id}`);
        }
        return state;
    }, []);
    const loadSingleShot = React.useCallback(async (id?: number) => {
        let state: WorkoutWithParams | null = null;
        if (!id) {
            logger.info("Unable to load workout with no id supplied");
            return state;
        }
        setLoading(true);
        setError(null);
        try {
            const quickstart = await fetchApi<AppWorkout>(
                `/api/app-workouts/${id}`,
            );

            const params =
                generateDefaultFreeplayParams() as unknown as JSONObject;

            state = {
                workout: quickstart,
                params,
                source: "original",
            };
        } catch (ex) {
            logFetchError(ex, "Error loading single-shot workout");
        } finally {
            setLoading(false);
        }

        if (state) {
            logger.info(
                `Loaded workout ${state.workout.id} from ${state.source}`,
            );
        } else {
            logger.warn(`Unable to load workout ${id || "no ID supplied"}`);
        }
        return state;
    }, []);
    const loadModule = React.useCallback(async (id: number) => {
        let state: WorkoutWithParams | null = null;
        setLoading(true);
        setError(null);
        try {
            const moduleWorkout = await fetchApi<AppWorkout>(
                `/api/app-workouts/${id}`,
            );
            state = { workout: moduleWorkout, params: {}, source: "original" };
        } catch (ex) {
            logFetchError(ex, "Error loading module workout");
        } finally {
            setLoading(false);
        }

        if (state) {
            logger.info(
                `Loaded workout ${state.workout.id} from ${state.source}`,
            );
        } else {
            logger.warn(`Unable to load workout ${id}`);
        }
        return state;
    }, []);

    const latestByAppId = React.useCallback(
        (appId: number) => {
            const url = `/api/workout-plays/mine/latest?appId=${appId}&sport=${selected}`;
            return fetchApi<WorkoutPlayWithWorkout>(url);
        },
        [selected],
    );

    return {
        error,
        loading,
        fetchWorkouts,
        fetchWorkoutsByAppId,
        fetchMyWorkouts,
        fetchMySharedWorkouts,
        addWorkout,
        deleteWorkout,
        duplicateWorkout,
        getWorkout,
        updateWorkout,
        loadMultiShot,
        loadSingleShot,
        loadModule,
        loadResponsiveWorkout,
        loadResponsiveWorkouts,
        loadResponsiveWorkoutApp,
        loadResponsiveWorkoutList,
        loadServeAndVolleyConfig,
        latestByAppId,
        copyToMyWorkouts,
    };
}
