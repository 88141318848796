import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import aiIcon from "../../../../static/img/backgrounds/ai.svg";
import multiLevelIcon from "../../../../static/img/backgrounds/multi-level.svg";
import multiShotIcon from "../../../../static/img/backgrounds/multi-shot.svg";
import singleShotIcon from "../../../../static/img/backgrounds/single-shot.svg";
import skillIcon from "../../../../static/img/backgrounds/skill.svg";
import { Sport, useSelectedSport } from "../../../common/context/sport";
import { AppListItemProps } from "../apps/types";

const WIDTH = 140;
const HEIGHT = 112;

interface CardWrapperProps extends React.PropsWithChildren, AppListItemProps {}

export default function CardWrapper({
    onClick,
    workout,
    children,
}: CardWrapperProps): JSX.Element | null {
    const { appId, id, name } = workout;

    const { selected, updateSelected } = useSelectedSport();

    const highlightColor = React.useMemo(() => {
        switch (appId) {
            case 5:
                return "#EFA511";
            case 4:
            case 6:
                return "#3CE97C";
            case 8:
            case 10:
                return "#9429FF";
            case 9:
                return "#56D5EF";
            case 11:
                return "#DA30D8";
            default:
                return null;
        }
    }, [appId]);

    const backgrounUrl = React.useMemo(() => {
        switch (appId) {
            case 5:
                return singleShotIcon as string;
            case 4:
            case 6:
                return multiShotIcon as string;
            case 8:
            case 10:
                return aiIcon as string;
            case 9:
                return multiLevelIcon as string;
            case 11:
                return skillIcon as string;
            default:
                return null;
        }
    }, [appId]);

    return (
        <Card
            sx={{
                minWidth: WIDTH,
                width: WIDTH,
                height: HEIGHT,
                backgroundColor: (t) => t.palette.primary.main,
                borderRadius: "10px",
                borderLeftWidth: "5px",
                borderLeftStyle: "solid",
                borderLeftColor: highlightColor,
                backgroundImage: `url('${backgrounUrl}')`,
                backgroundSize: "33%",
                backgroundPosition: "50% 66%",
                backgroundRepeat: "no-repeat",
            }}
            onClick={() => {
                if (selected !== workout.sportName) {
                    updateSelected(workout.sportName as Sport);
                }
                onClick(
                    workout.id,
                    `/content/apps/workouts/plugin/play/${appId}/${id}`,
                );
            }}
        >
            <CardContent
                sx={{
                    pt: 1,
                    pr: 1,
                    pl: 1,
                    pb: 0,
                    width: WIDTH,
                }}
            >
                <Typography
                    sx={{
                        textTransform: "none",
                        fontWeight: (t) => t.typography.fontWeightMedium,
                        lineHeight: 1.2,
                        overflow: "hidden",
                        display: "-webkit-box",
                        WebkitLineClamp: 2,
                        WebkitBoxOrient: "vertical",
                        whiteSpace: "normal",
                    }}
                    variant="body2"
                    color="white"
                >
                    {name}
                </Typography>
                {children}
            </CardContent>
        </Card>
    );
}
