import * as React from "react";

import { useTheme, darken } from "@mui/material";

import CardWrapper from "../../Shared/CardWrapper";
import { AppListItemProps } from "../types";

export default function MultiShotCard({
    onClick,
    workout,
}: AppListItemProps): JSX.Element | null {
    const theme = useTheme();
    return (
        <CardWrapper
            onClick={onClick}
            workout={workout}
            backgroundColor={darken(theme.palette.info.main, 0.2)}
        />
    );
}
