import * as React from "react";

import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { AppWorkoutWithRelations as AppWorkout } from "@volley/data";
import {
    LevelNumber,
    LeveledWorkoutConfig,
    Levels,
} from "@volley/shared/apps/curated-workout-models";

export interface PlayLevelSelectorProps {
    disabled: boolean;
    workout: AppWorkout;
    selectedLevel: LevelNumber;
    setSelectedLevel: (updated: LevelNumber) => void;
}

export default function PlayLevelSelector({
    disabled,
    workout,
    selectedLevel,
    setSelectedLevel,
}: PlayLevelSelectorProps): JSX.Element | null {
    const { levels } = workout.config as unknown as LeveledWorkoutConfig;
    const renderLevels = Levels.filter((l) => levels[l].shots.length > 0);
    if (renderLevels.length === 1) {
        return null;
    }
    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
                variant="caption"
                sx={{
                    minWidth: "30px",
                }}
            >
                Level
            </Typography>
            <ButtonGroup
                disabled={disabled}
                fullWidth
                size="small"
                color="info"
            >
                {renderLevels.map((l) => (
                    <Button
                        key={l}
                        variant={selectedLevel === l ? "contained" : "outlined"}
                        size="small"
                        sx={{
                            padding: "2px",
                            color:
                                selectedLevel === l
                                    ? "common.white"
                                    : "primary.light",
                            backgroundColor:
                                selectedLevel === l
                                    ? "primary.light"
                                    : "common.white",
                            fontSize: "10px",
                        }}
                        onClick={() => setSelectedLevel(l)}
                    >
                        {l}
                    </Button>
                ))}
            </ButtonGroup>
        </Stack>
    );
}
