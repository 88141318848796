import * as React from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import DoneIcon from "@mui/icons-material/Done";
import AppBar from "@mui/material/AppBar";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { Tag, TagCategoryWithRelations } from "@volley/data";

import { fetchApi } from "../../../../util";
import { logFetchError } from "../../../../util/fetchApi";
import { useSelectedSport } from "../../../common/context/sport";
import { useCurrentUser } from "../../../hooks/currentUser";
import TagCategorySelect from "../List/Filter/TagCategorySelect";

interface TaggingProps {
    selectedTags: Record<number, Tag[]>;
    open: boolean;
    onCancel: () => void;
    onFinish: (updated: Record<number, Tag[]>) => void;
}

function getHeading(tagCategory: TagCategoryWithRelations): string {
    return `${tagCategory.label} - (${tagCategory.mode === "SINGLE" ? "Choose One" : "Choose Many"})`;
}

export default function Tagging({
    open,
    selectedTags,
    onCancel,
    onFinish,
}: TaggingProps): JSX.Element {
    const { selected: sport } = useSelectedSport();
    const { isAdmin } = useCurrentUser();
    const [tagCategories, setTagCategories] = React.useState<
        TagCategoryWithRelations[]
    >([]);
    const [localEdits, setLocalEdits] =
        React.useState<Record<number, Tag[]>>(selectedTags);

    React.useEffect(() => {
        setLocalEdits(selectedTags);
    }, [selectedTags]);

    React.useEffect(() => {
        fetchApi<TagCategoryWithRelations[]>(`/api/tags/list?sport=${sport}`)
            .then((data) => {
                setTagCategories(data);
            })
            .catch((e) => {
                logFetchError(e, "Failed to fetch tag categories");
            });
    }, [sport]);

    return (
        <Dialog open={open} fullScreen>
            <AppBar>
                <Toolbar>
                    <Typography variant="h6">Edit Tags</Typography>
                </Toolbar>
            </AppBar>
            <DialogContent
                sx={{
                    paddingTop: 8,
                }}
            >
                <Stack spacing={2}>
                    {tagCategories
                        .filter(
                            (tc) =>
                                !tc.adminOnly || (tc.adminOnly && isAdmin()),
                        )
                        .map((tagCategory) => (
                            <>
                                <Typography key={tagCategory.id}>
                                    {getHeading(tagCategory)}
                                </Typography>
                                <TagCategorySelect
                                    key={`${tagCategory.id}-select`}
                                    tagCategory={tagCategory}
                                    selectedTags={localEdits}
                                    updateSelected={setLocalEdits}
                                />
                            </>
                        ))}
                    <Button
                        onClick={() => {
                            setLocalEdits(selectedTags);
                            onCancel();
                        }}
                        fullWidth
                        sx={{ mt: 2 }}
                        startIcon={<CancelIcon />}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={() => onFinish(localEdits)}
                        fullWidth
                        sx={{ mt: 2 }}
                        startIcon={<DoneIcon />}
                    >
                        Done
                    </Button>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
