/* eslint-disable no-console */
// Copyright Volley LLC, All Rights Reserved.
// Volley CONFIDENTIAL

import * as React from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";

import { sim, convert, model } from "@volley/physics";

import courtImage from "../../../../../static/img/court-model-geomcorrect.png";
import trainerRingImageGreen from "../../../../../static/img/indicator-open-green.png";
import trainerIndicatorImageGreen from "../../../../../static/img/indicator-v-green.png";
import { pairedFetchApi } from "../../../../../util/fetchApi";
import { CoordLike } from "../../../../../util/position-types";
import { useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { usePairingContext } from "../../../../hooks/pairingStatus";
import { useStatus } from "../../../../hooks/status";
import { useLift, LiftModal } from "../../../../hooks/useLift";
import usePrevious from "../../../../hooks/usePrevious";
import { ThrowingModal } from "../../../util/useThrowNow";

// CellGeometry
// Geometry of a rectangular target cell (pixels)
// A rectangle (offsets x=+right, y=+down wrt upper left of court image)
interface CellGeometry {
    w: number;
    h: number;
    xOffset: number;
    yOffset: number;
}

// PhysicsShot
// A 'physics shot':
//    * The shot we are modeling
//    * Physics-computed estimated launch params that generate shot in the real world
//    * isValid: physics can compute shots trainer can't do (e.g 80 deg pitch)
//    * Specific valid "sub flags" that together determine validity
interface ModeledShot {
    targetResult: model.TargetResult;
    isValid: boolean;
    rpmValid: boolean;
    yawValid: boolean;
    pitchValid: boolean;
}
type PhysicsShot = ModeledShot | null;

// Angles corresponding to selectable lob height
const LobAngles = {
    max: 54,
    high: 48,
    med: 42,
    low: 35,
};

// Selectable target cell (square) size
const cellSizesFeet = [2, 4];

const defaultHeadHeightInches = 53;

// Dimensions of scalable entities
// (pixels unless named otherwise)
const dims = {
    // width/height of court area image
    widthFeet: 30,
    heightFeet: 60,
    width: 400,
    height: 800,
    // offset of play area (pixels)
    xOffset: 68,
    yOffset: 107,
    // sizes of trainer position graphics (pixels)
    trainerMarkerWidth: 48,
    trainerMarkerHeight: 48,
    trainerRingWidth: 66,
    trainerRingHeight: 98,
};

// Scale dimensions according to a single
// scale factor for rendering (so we can shrink/grow
// entire court as needed for UI)
const scale = 0.7;
const scaled = {
    width: dims.width * scale,
    height: dims.height * scale,
    // PPF: pixels-per-foot
    widthPPF: (dims.width * scale) / dims.widthFeet,
    heightPPF: (dims.height * scale) / dims.heightFeet,
    xOffset: dims.xOffset * scale,
    yOffset: dims.yOffset * scale,
    trainerMarkerWidth: dims.trainerMarkerWidth * scale,
    trainerMarkerHeight: dims.trainerMarkerHeight * scale,
    trainerRingWidth: dims.trainerRingWidth * scale,
    trainerRingHeight: dims.trainerRingHeight * scale,
};

// Physics Model and Simulator
// Set up the physics model simulation parameters for doing
// reverse-physics, and create the simulator that launches the
// simulated shots.
console.log("lobApp: setting initial physics model to atom / platform");
const simTrainer: sim.TrainerSim = new sim.TrainerSim(
    "physics-20B-base",
    "PLATFORM_TENNIS",
);
const physicsModel = convert.getPhysicsModel();
physicsModel.simParams.stopDist = 0.01;
physicsModel.simParams.stepDecay = 0.65;
physicsModel.simParams.maxSteps = 55;
physicsModel.simParams.wallBounceEnabled = false;

// Trainer Position
// Right now we are not localizing, instead we are assuming
// trainer is manually placed @ center of back baseline facing forward
const testTrainerPosition: model.TrainerPosition = {
    x: 0,
    y: -6.7056,
    yaw: 0,
    // trainer sim height is bottom of head (end of lift arm),
    // but trainer/motion height is launch point
    h: convert.launchHeightInches2HeadHeight(defaultHeadHeightInches),
};
simTrainer.SetPositionManual(testTrainerPosition);

/**
 * LobApp Component
 */
export default function LobApp(): JSX.Element {
    const { trainerId } = usePairingContext();
    const { status } = useStatus();
    const {
        stop: stopLift,
        height: currentLiftHeight,
        isLifting,
        setHeight,
        checkForLift,
    } = useLift();
    const wasLifting = usePrevious(isLifting);
    const { physicsModelName } = usePhysicsModelContext();

    const yawRange = React.useMemo(
        () => status?.trainer.pan.range ?? { min: 0, max: 0 },
        [status?.trainer.pan.range],
    );
    const tiltRange = React.useMemo(
        () => status?.trainer.tilt.range ?? { min: 0, max: 0 },
        [status?.trainer.tilt.range],
    );
    const liftRange = React.useMemo(
        () => status?.trainer.lift.range ?? { min: 0, max: 0 },
        [status?.trainer.lift.range],
    );

    // _________________________________
    // Refs
    // image of court area
    const courtImageRef = React.useRef<HTMLImageElement>(null);
    // canvas that overlays image
    const plotRef = React.useRef<HTMLCanvasElement>(null);
    // trainer positioning images
    const trainerImageGreenRef = React.useRef<HTMLImageElement>(null);
    const trainerRingGreenRef = React.useRef<HTMLImageElement>(null);
    // current shot parameters
    const headHeightRef = React.useRef<HTMLInputElement>(null);
    const rpmRef = React.useRef<HTMLInputElement>(null);
    const peakHeightRef = React.useRef<HTMLInputElement>(null);
    const speedRef = React.useRef<HTMLInputElement>(null);
    const yawRef = React.useRef<HTMLInputElement>(null);
    const pitchRef = React.useRef<HTMLInputElement>(null);

    // _________________________________
    // State
    // have images loaded?
    const [courtLoaded, setCourtLoaded] = React.useState(false);
    const [trainerGreenLoaded, setTrainerGreenLoaded] = React.useState(false);
    const [ringGreenLoaded, setRingGreenLoaded] = React.useState(false);
    // user touch position (physics coords, pixels)

    const [touchPosition, setTouchPosition] = React.useState<CoordLike | null>(
        null,
    );
    // lob peak height (feet)
    const [lobAngle, setLobAngle] = React.useState(LobAngles.med);
    const [, setLiftStopped] = React.useState(true);

    // target cell size (feet)
    const [cellSize, setCellSize] = React.useState(cellSizesFeet[1]);
    // head height (inches)
    const [headHeight, setHeadHeight] = React.useState<number>(
        defaultHeadHeightInches,
    );

    // trainer position (physics coords, meters)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [trainerPos, setTrainerPos] =
        React.useState<model.TrainerPosition>(testTrainerPosition);
    // target color (green=valid shot, red=invalid)
    const [targetColor, setTargetColor] =
        React.useState<string>("lightseagreen");
    // throwing state
    const [throwing, setThrowing] = React.useState(false);
    const [throwError, setThrowError] = React.useState<string | null>(null);

    const { selected: selectedSport } = useSelectedSport();

    // _________________________________
    // Event Handlers
    const handleLobHeightChange = (event: SelectChangeEvent) => {
        const angle: number = +event.target.value;
        setLobAngle(angle);
    };
    const handleCellSizeChange = (event: SelectChangeEvent) => {
        const cellFeet: number = +event.target.value;
        setCellSize(cellFeet);
    };
    const handleHeadHeightChange = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        const enteredH: number = parseInt(event.target.value, 10);
        let setH: number = enteredH;
        if (Number.isNaN(headHeight) || enteredH < liftRange.min) {
            setH = liftRange.min;
        } else if (enteredH > liftRange.max) {
            setH = liftRange.max;
        }
        if (setH !== enteredH && headHeightRef.current) {
            headHeightRef.current.value = setH.toString();
        }
        console.log(`setting head height to ${setH}`);
        setHeadHeight(setH);
    };
    // when canvas is clicked we'll compute the "touch position" as
    // the center of the clicked grid cell
    const handleCanvasClick = (event: MouseEvent) => {
        const plot = plotRef.current as HTMLCanvasElement;
        // convert click position to physics coords (pixels)
        const rect = plot.getBoundingClientRect();
        // x, y position wrt upper-right corner of playing surface (pixels)
        const x =
            ((event.clientX - rect.left) / (rect.right - rect.left)) *
            scaled.width;
        const y =
            ((event.clientY - rect.top) / (rect.bottom - rect.top)) *
            scaled.height;
        // x, y wrt center-of-court physics origin (pixels)
        const xPP = x - scaled.width / 2.0;
        const yPP = scaled.height / 2.0 - y;
        setTouchPosition({ x: xPP, y: yPP });
    };

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        setLiftStopped(true);
    }, [stopLift, setLiftStopped]);

    const ensureHeadHeight = React.useCallback(
        async (targetHeight: number) => {
            if (Math.abs(targetHeight - currentLiftHeight) > 0.9) {
                checkForLift();
                setLiftStopped(false);
                await setHeight(targetHeight);
                return false;
            }
            return true;
        },
        [checkForLift, setHeight, currentLiftHeight, setLiftStopped],
    );

    const liftModalText = "Adjusting head height for shot";

    const showLoading = React.useMemo(
        () => !courtLoaded && !trainerGreenLoaded && !ringGreenLoaded,
        [courtLoaded, trainerGreenLoaded, ringGreenLoaded],
    );

    // targetCellGeometry
    // Compute geometry of user-selected target grid rectangle (CellGeometry)
    const targetCellGeometry: CellGeometry | null = React.useMemo(() => {
        if (!touchPosition) {
            return null;
        }
        // compute the x,y offset of the target cell from cellSize and touchPosition
        const cellWp = scaled.widthPPF * cellSize;
        const cellHp = scaled.heightPPF * cellSize;
        // left side of selected cell in physics coord pixels

        const cellLeftXpp = Math.floor(touchPosition.x / cellWp);
        // top of selected cell in physics coord pixels

        const cellTopYpp = Math.floor(touchPosition.y / cellHp);
        // pixel offset of selected cell from upper left of canvas
        const cellXoff = scaled.width / 2.0 + cellLeftXpp * cellWp;
        const cellYoff = scaled.height / 2.0 - (cellTopYpp + 1) * cellHp;
        return {
            w: cellWp,
            h: cellHp,
            xOffset: cellXoff,
            yOffset: cellYoff,
        };
    }, [cellSize, touchPosition]);

    // setShotLabels
    // Sets the values/colors of the shot parameters in UI
    // (green/red to denote valid/invalid)
    // NOTE: this is not memoized, it's called from memoized 'currentShot' below
    const setShotLabels = (pShot: Partial<PhysicsShot>) => {
        if (
            peakHeightRef.current &&
            rpmRef.current &&
            speedRef.current &&
            yawRef.current &&
            pitchRef.current &&
            headHeightRef.current
        ) {
            rpmRef.current.value =
                pShot?.targetResult?.launchParams?.rpms?.top.toFixed(0) ?? "";
            speedRef.current.value =
                pShot?.targetResult?.launchParams?.speedSpin?.speed.toFixed(
                    1,
                ) ?? "";
            yawRef.current.value =
                pShot?.targetResult?.launchParams?.yaw.toFixed(1) ?? "";
            pitchRef.current.value =
                pShot?.targetResult?.launchParams?.pitch.toFixed(1) ?? "";
            if (pShot?.targetResult?.peakHeight) {
                peakHeightRef.current.value = (
                    pShot.targetResult.peakHeight * 3.28084
                ).toFixed(1);
            }

            rpmRef.current.style.backgroundColor = pShot?.rpmValid
                ? "lightseagreen"
                : "red";
            yawRef.current.style.backgroundColor = pShot?.yawValid
                ? "lightseagreen"
                : "red";
            pitchRef.current.style.backgroundColor = pShot?.pitchValid
                ? "lightseagreen"
                : "red";
        }
    };

    const clearShotLabels = () => {
        if (
            peakHeightRef.current &&
            rpmRef.current &&
            speedRef.current &&
            yawRef.current &&
            pitchRef.current &&
            headHeightRef.current
        ) {
            rpmRef.current.value = "";
            speedRef.current.value = "";
            yawRef.current.value = "";
            pitchRef.current.value = "";
            peakHeightRef.current.value = "";
            rpmRef.current.style.backgroundColor = "red";
            yawRef.current.style.backgroundColor = "red";
            pitchRef.current.style.backgroundColor = "red";
        }
    };

    // currentShot
    // Compute the current physics shot according to currently selected target cell, lob height, trainer head height
    const currentShot: Partial<PhysicsShot> = React.useMemo(() => {
        if (!targetCellGeometry) {
            return null;
        }
        const cShot: Partial<PhysicsShot> = {};
        //
        // Compute the planned shot
        // convert center of the selected cell into the shot target position
        const txPixels =
            targetCellGeometry.xOffset +
            targetCellGeometry.w / 2.0 -
            scaled.width / 2.0;
        const tyPixels =
            scaled.height / 2.0 -
            (targetCellGeometry.yOffset + targetCellGeometry.h / 2.0);
        // pixels --> feet --> meters
        const txMeters = (txPixels / scaled.widthPPF) * 0.3048;
        const tyMeters = (tyPixels / scaled.heightPPF) * 0.3048;
        console.log(`Planned Shot has Angle: ${lobAngle}`);
        const pShot: model.PlannedShot = {
            target: { x: txMeters, y: tyMeters, z: 0 },
            spin: 0,
            spinAxis: 0,
            peakHeight: null,
            launchAngle: lobAngle,
        };
        //
        // Set simulated trainer position
        const simTrainerPos: model.TrainerPosition = {
            x: trainerPos.x,
            y: trainerPos.y,
            h: convert.launchHeightInches2HeadHeight(headHeight),
            yaw: trainerPos.yaw,
        };
        simTrainer.SetPositionManual(simTrainerPos);
        const tp: model.TrainerPosition = simTrainer.GetPosition();
        const lp: model.CourtPoint = simTrainer.GetLaunchPoint();
        console.log(
            `Simulated Trainer position: x=${tp.x}, y=${tp.y}, yaw=${tp.yaw}, h=${tp.h}`,
        );
        console.log(
            `Simulated Trainer launch point: ${lp.x}, ${lp.y}, ${lp.z}`,
        );
        console.log(
            `Simulated Trainer target point: ${txMeters}, ${tyMeters}, 0`,
        );
        //
        // Compute physics-generated launch params
        // using physics lib Target() method (reverse physics)
        const targetResult: model.TargetResult = simTrainer.Target(pShot);
        console.log(
            `Simulated Trainer target peak height: ${+targetResult.peakHeight}`,
        );

        if (targetResult.isValid) {
            cShot.targetResult = targetResult;
            cShot.yawValid =
                targetResult.launchParams.yaw >= yawRange.min &&
                targetResult.launchParams.yaw <= yawRange.max;
            cShot.pitchValid =
                targetResult.launchParams.pitch >= tiltRange.min &&
                targetResult.launchParams.pitch <= tiltRange.max;

            cShot.rpmValid =
                targetResult.launchParams.rpms!.top > 0 &&
                targetResult.launchParams.rpms!.top <
                    convert.getPhysicsModel().limits.RPM.maxSafe;
            cShot.isValid =
                cShot.yawValid && cShot.pitchValid && cShot.rpmValid;
            setShotLabels(cShot);
        } else {
            console.warn(
                `can not target illegal shot: ${targetResult.invalidMessage}`,
            );
            cShot.isValid = false;
            clearShotLabels();
            setThrowError(
                `can not target illegal shot: ${targetResult.invalidMessage}`,
            );
        }

        //
        // Update UI
        setTargetColor(cShot.isValid ? "lightseagreen" : "red");
        return cShot;
    }, [
        targetCellGeometry,
        lobAngle,
        yawRange.min,
        yawRange.max,
        tiltRange.min,
        tiltRange.max,
        trainerPos,
        headHeight,
    ]);

    // LaunchShot
    // Actually throw the shot
    const LaunchShot = React.useCallback(async () => {
        if (
            currentShot &&
            currentShot.targetResult &&
            currentShot.targetResult.launchParams &&
            currentShot.targetResult.launchParams.rpms
        ) {
            setThrowError(null);
            try {
                await pairedFetchApi(trainerId, "/api/throw", "POST", {
                    pan: currentShot.targetResult.launchParams.yaw,
                    tilt: currentShot.targetResult.launchParams.pitch,
                    topRpm: currentShot.targetResult.launchParams.rpms.top,
                    bottomLeftRpm:
                        currentShot.targetResult.launchParams.rpms.left,
                    bottomRightRpm:
                        currentShot.targetResult.launchParams.rpms.right,
                });
                setThrowing(false);
            } catch (ex) {
                setThrowError(`Error throwing shot: ${JSON.stringify(ex)}`);
            } finally {
                setThrowing(false);
            }
        }
    }, [trainerId, currentShot]);

    // Throw was clicked
    // Adjust height(if needed), then throw the shot
    const handleThrowClicked = React.useCallback(async () => {
        console.log(`current lift height is ${currentLiftHeight}`);
        console.log(`changing head height to ${headHeight}`);
        setThrowing(true);
        const heightCorrect = await ensureHeadHeight(headHeight);
        if (heightCorrect) {
            await LaunchShot();
        }
    }, [headHeight, LaunchShot, ensureHeadHeight, currentLiftHeight]);

    // If lifting was needed, then we will shoot shot when it's done
    React.useEffect(() => {
        async function doShot() {
            if (!isLifting && wasLifting && throwing) {
                await LaunchShot();
            }
        }
        void doShot();
    }, [isLifting, wasLifting, throwing, LaunchShot]);

    // _________________________________
    // UseEffect Hooks
    // One-Time: install canvas click handler
    React.useEffect(() => {
        const plot = plotRef.current as HTMLCanvasElement;
        plot.addEventListener("click", handleCanvasClick);
        return () => {
            plot.removeEventListener("click", handleCanvasClick);
        };
    }, []);

    // 1. Render the court
    React.useEffect(() => {
        if (courtLoaded) {
            console.log("== Drawing Court ==");
            const plot = plotRef.current as HTMLCanvasElement;
            const img = courtImageRef.current as HTMLImageElement;
            const ctx = plot.getContext("2d") as CanvasRenderingContext2D;
            ctx.clearRect(0, 0, scaled.width, scaled.height);
            ctx.drawImage(img, 0, 0, scaled.width, scaled.height);
        }
    }, [
        courtLoaded,
        touchPosition,
        cellSize,
        trainerPos,
        lobAngle,
        headHeight,
        targetColor,
    ]);

    // 2. Render the trainer position
    React.useEffect(() => {
        if (trainerGreenLoaded && ringGreenLoaded) {
            console.log(
                `== Drawing Trainer Location (pos=${trainerPos.x}, ${trainerPos.y}) ==`,
            );
            const plot = plotRef.current as HTMLCanvasElement;
            const ctx = plot.getContext("2d") as CanvasRenderingContext2D;
            const img = trainerImageGreenRef.current as HTMLImageElement;
            const rImg = trainerRingGreenRef.current as HTMLImageElement;
            // compute trainer position offset (pixels)
            const txPixels = trainerPos.x * 3.28084 * scaled.widthPPF;
            const tyPixels = trainerPos.y * 3.28084 * scaled.heightPPF;
            const txOffset = scaled.width / 2.0 + txPixels;
            const tyOffset = scaled.height / 2.0 - tyPixels;
            // render trainer symbol
            const imageX = txOffset - scaled.trainerMarkerWidth / 2;
            const imageY = tyOffset - scaled.trainerMarkerHeight / 2;
            ctx.drawImage(
                img,
                imageX,
                imageY,
                scaled.trainerMarkerWidth,
                scaled.trainerMarkerHeight,
            );
            // render trainer ring
            const ringX = scaled.trainerRingWidth / 2;
            const ringY = (scaled.trainerRingHeight * 2) / 3;
            ctx.save();
            ctx.translate(txOffset, tyOffset);
            ctx.rotate(trainerPos.yaw);
            ctx.drawImage(
                rImg,
                -ringX,
                -ringY,
                scaled.trainerRingWidth,
                scaled.trainerRingHeight,
            );
            ctx.restore();
        }
    }, [
        courtLoaded,
        trainerGreenLoaded,
        ringGreenLoaded,
        touchPosition,
        cellSize,
        trainerPos,
        lobAngle,
        headHeight,
        targetColor,
    ]);

    // 3. Render the target square
    React.useEffect(() => {
        if (touchPosition && targetCellGeometry) {
            console.log(`== Drawing Target Square (color=${targetColor})==`);
            const plot = plotRef.current as HTMLCanvasElement;
            const ctx = plot.getContext("2d") as CanvasRenderingContext2D;
            ctx.fillStyle = targetColor;
            const alpha = ctx.globalAlpha;
            ctx.globalAlpha = 0.5;
            ctx.fillRect(
                targetCellGeometry.xOffset,
                targetCellGeometry.yOffset,
                targetCellGeometry.w,
                targetCellGeometry.h,
            );
            ctx.globalAlpha = alpha;
        }
    }, [
        courtLoaded,
        trainerGreenLoaded,
        ringGreenLoaded,
        touchPosition,
        cellSize,
        trainerPos,
        lobAngle,
        headHeight,
        targetColor,
        targetCellGeometry,
    ]);

    // Update physics model to match selected sport
    React.useEffect(() => {
        console.log(
            `Updating physics model to: ${physicsModelName} / ${selectedSport}`,
        );
        convert.setPhysicsModel(
            physicsModelName as model.PhysicsModelName,
            selectedSport,
        );
    }, [selectedSport, physicsModelName]);

    return (
        <Box
            component="div"
            sx={{
                backgroundColor: "background.default",
            }}
        >
            <div>
                <Snackbar
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    open={throwError !== null}
                    autoHideDuration={5000}
                    onClose={() => setThrowError(null)}
                >
                    <Alert severity="error">{throwError}</Alert>
                </Snackbar>
                <ThrowingModal open={throwing} />
                <FormControl variant="standard" sx={{ m: 1, width: 90 }}>
                    <InputLabel id="label-lobHeight">Lob Height</InputLabel>
                    <Select
                        labelId="label-lobHeight"
                        id="select-lobHeight"
                        value={`${lobAngle}`}
                        onChange={handleLobHeightChange}
                        label="Lob Height"
                    >
                        <MenuItem value={LobAngles.max}>Max</MenuItem>
                        <MenuItem value={LobAngles.high}>High</MenuItem>
                        <MenuItem value={LobAngles.med}>Med</MenuItem>
                        <MenuItem value={LobAngles.low}>Low</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 90 }}>
                    <InputLabel id="label-cellSize">Target Size</InputLabel>
                    <Select
                        labelId="label-cellSize"
                        id="select-cellSize"
                        value={`${cellSize}`}
                        onChange={handleCellSizeChange}
                        label="Target Size"
                    >
                        <MenuItem
                            value={cellSizesFeet[0]}
                        >{`${cellSizesFeet[0]} Feet`}</MenuItem>
                        <MenuItem
                            value={cellSizesFeet[1]}
                        >{`${cellSizesFeet[1]} Feet`}</MenuItem>
                    </Select>
                </FormControl>
                <FormControl variant="standard" sx={{ m: 1, width: 90 }}>
                    <TextField
                        inputRef={headHeightRef}
                        size="small"
                        id="text-height"
                        label="Head Height (in)"
                        variant="standard"
                        defaultValue={`${defaultHeadHeightInches}`}
                        onBlur={handleHeadHeightChange}
                        type="number"
                        slotProps={{
                            inputLabel: { shrink: true },
                        }}
                    />
                </FormControl>
            </div>
            <Box
                component="div"
                sx={{
                    width: `${scaled.width}px`,
                    height: `${scaled.height}px`,
                    textAlign: "center",
                    position: "relative",
                    background: showLoading ? "rgba(0, 0, 0, 0.2)" : "white",
                }}
            >
                {showLoading && (
                    <CircularProgress
                        sx={{
                            color: "info",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-20px",
                            marginLeft: "-20px",
                        }}
                    />
                )}
                <img
                    alt="Trainer Indicator - Green"
                    style={{ display: "none" }}
                    onLoad={() => setTrainerGreenLoaded(true)}
                    ref={trainerImageGreenRef}
                    src={trainerIndicatorImageGreen as string}
                    width={`${scaled.trainerMarkerWidth}px`}
                    height={`${scaled.trainerMarkerHeight}px`}
                />
                <img
                    alt="Trainer Direction - Green"
                    style={{ display: "none" }}
                    onLoad={() => setRingGreenLoaded(true)}
                    ref={trainerRingGreenRef}
                    src={trainerRingImageGreen as string}
                    width={`${scaled.trainerRingWidth}px`}
                    height={`${scaled.trainerRingHeight}px`}
                />
                <img
                    alt="Court Diagram"
                    style={{ display: "none" }}
                    onLoad={() => setCourtLoaded(true)}
                    ref={courtImageRef}
                    src={courtImage as string}
                    width={scaled.width}
                    height={scaled.height}
                />
                <canvas
                    ref={plotRef}
                    width={scaled.width}
                    height={scaled.height}
                />
                <Box component="div">
                    <TextField
                        inputRef={peakHeightRef}
                        id="text-peakheight"
                        label="H (ft)"
                        defaultValue=""
                        variant="standard"
                        sx={{ m: 1, width: 35 }}
                        size="small"
                        slotProps={{
                            input: { readOnly: true, disableUnderline: true },
                            inputLabel: { shrink: true },
                        }}
                    />
                    <TextField
                        inputRef={rpmRef}
                        id="text-rpm"
                        label="RPM"
                        defaultValue=""
                        variant="standard"
                        sx={{ m: 1, width: 40 }}
                        size="small"
                        slotProps={{
                            input: { readOnly: true, disableUnderline: true },
                            inputLabel: { shrink: true },
                        }}
                    />
                    <TextField
                        inputRef={speedRef}
                        id="text-speed"
                        label="Speed"
                        defaultValue=""
                        variant="standard"
                        sx={{ m: 1, width: 35 }}
                        size="small"
                        slotProps={{
                            input: { readOnly: true, disableUnderline: true },
                            inputLabel: { shrink: true },
                        }}
                    />
                    <TextField
                        inputRef={yawRef}
                        id="text-yaw"
                        label="Yaw"
                        defaultValue=""
                        variant="standard"
                        sx={{ m: 1, width: 35 }}
                        size="small"
                        slotProps={{
                            input: { readOnly: true, disableUnderline: true },
                            inputLabel: { shrink: true },
                        }}
                    />
                    <TextField
                        inputRef={pitchRef}
                        id="text-pitch"
                        label="Pitch"
                        defaultValue=""
                        focused={false}
                        variant="standard"
                        sx={{ m: 1, width: 40 }}
                        size="small"
                        slotProps={{
                            input: { readOnly: true, disableUnderline: true },
                            inputLabel: { shrink: true },
                        }}
                    />
                </Box>
                <Box
                    component="div"
                    sx={{
                        textAlign: "center",
                    }}
                >
                    <Button
                        variant="contained"
                        disabled={!currentShot?.isValid || throwing}
                        onClick={() => handleThrowClicked()}
                    >
                        Launch
                    </Button>
                </Box>
                <LiftModal
                    stop={handleLiftStop}
                    targetHeight={headHeight}
                    message={liftModalText}
                />
            </Box>
        </Box>
    );
}
