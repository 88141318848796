import type { LeveledWorkoutConfig } from "@volley/shared/apps/curated-workout-models";
import type { JSONObject } from "@volley/shared/common-models";
import type { CuratedWorkoutParameters } from "@volley/shared/dist/apps/curated-workout-models";

import { SMALLE_MAX_BALLS } from "../../Accordions/ParamsAccordion";

export function getDefaultMultiLevelParams() {
    const defaultMultiLevelParams: CuratedWorkoutParameters = {
        initialDelay: 1,
        intervalOverride: 3,
        numberOfBalls: SMALLE_MAX_BALLS,
        playMode: "standard",
        shuffle: false,
    };

    return defaultMultiLevelParams as unknown as JSONObject;
}

export function getDefaultMultiLevelConfig() {
    const defaultMultiLevelConfig: LeveledWorkoutConfig = {
        levels: {
            1: { number: 1, shots: [] },
            2: { number: 2, shots: [] },
            3: { number: 3, shots: [] },
            4: { number: 4, shots: [] },
            5: { number: 5, shots: [] },
        },
    };

    return defaultMultiLevelConfig as unknown as JSONObject;
}
