import * as React from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import noPersonImg from "../../../../../static/img/sv-no-person.png";
import tooManyPeopleImg from "../../../../../static/img/sv-too-many.png";
import trainerLocationImg from "../../../../../static/img/sv-trainer-location.jpg";
import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";

interface Props {
    instructionDialogOpen: boolean;
    setInstructionDialogOpen: (open: boolean) => void;
}

export default function InstructionDialog({
    instructionDialogOpen,
    setInstructionDialogOpen,
}: Props) {
    return (
        <Dialog open={instructionDialogOpen}>
            <CloseableDialogTitle
                onClose={() => setInstructionDialogOpen(false)}
            />
            <DialogContent>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Objective
                    </Typography>
                    <Typography>
                        Practice the volley that occurs after a serve.
                    </Typography>
                </Box>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography variant="h4" mb={1}>
                        Setup
                    </Typography>
                    <Typography>
                        The trainer must be placed in the location shown below.
                        Front axle is centered where the singles line meets the
                        baseline. Then angle the trainer so that it points
                        towards the center of the net.
                    </Typography>
                </Box>

                <Typography mb={3}>
                    Only one player should be on the court at a time. The
                    following icons indicate errors for when the trainer
                    can&apos;t find a player or there&apos;s too many players in
                    the service box.
                </Typography>

                <Stack mb={3} direction="row" spacing={2}>
                    <Box
                        component="img"
                        src={noPersonImg as string}
                        alt="No person icon"
                    />

                    <Box
                        component="img"
                        src={tooManyPeopleImg as string}
                        alt="Too many people icon"
                    />
                </Stack>

                <Box
                    component="img"
                    src={trainerLocationImg as string}
                    alt="Trainer location"
                    sx={{ width: "100%", mb: 2 }}
                />
            </DialogContent>
        </Dialog>
    );
}
