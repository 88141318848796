import * as React from "react";

import GpsFixedIcon from "@mui/icons-material/GpsFixed";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";

import type { PlayMode } from "@volley/shared/dist/apps/app-common-models";
import type { Side } from "@volley/shared/http/side";

import { useSelectedSport } from "../context/sport";

import LocalizationDialog from "./LocalizeModal";
import ResizableWorkoutVisualizer, {
    ResizableContainerProps,
} from "./ResizableWorkoutVisualizer";

export type LocalizeWorkoutVisualizerProps = Omit<
    ResizableContainerProps,
    "positionProximity"
> & {
    playMode: PlayMode;
    side: Side | "both";
    hasLocalized: boolean;
    setHasLocalized: () => void;
    oneShot?: boolean;
};

export default function LocalizeWorkoutVisualizer({
    workout,
    overlay,
    maxHeight,
    playMode,
    hasLocalized,
    setHasLocalized,
    side,
    oneShot = false,
}: LocalizeWorkoutVisualizerProps): JSX.Element {
    const { selected: selectedSport } = useSelectedSport();
    const [dialogOpen, setDialogOpen] = React.useState(false);

    return (
        <Box component="div" height={maxHeight}>
            <ResizableWorkoutVisualizer
                positionProximity="Unavailable"
                workout={dialogOpen ? undefined : workout}
                maxHeight={maxHeight}
                overlay={overlay}
            />
            {selectedSport === "PLATFORM_TENNIS" && (
                <Fab
                    size="small"
                    color="secondary"
                    sx={{
                        position: "relative",
                        bottom: "45px",
                        left: "5px",
                    }}
                    onClick={() => setDialogOpen(true)}
                >
                    <GpsFixedIcon />
                </Fab>
            )}
            <LocalizationDialog
                onClose={() => setDialogOpen(false)}
                open={dialogOpen}
                playMode={playMode}
                workout={workout}
                side={side}
                hasLocalized={hasLocalized}
                setHasLocalized={setHasLocalized}
                oneShot={oneShot}
            />
        </Box>
    );
}
