import * as React from "react";
import { useNavigate } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// import SwipeLeftIcon from "@mui/icons-material/SwipeLeft";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { HomeScreenCategory } from "./HomeScreen";
import CreateWorkoutCard from "./Trainer/AppWorkouts/Shared/CreateWorkoutCard";
import { findApp } from "./Trainer/AppWorkouts/apps";

interface HomeScreenRowProps {
    category: HomeScreenCategory;
}

export default function HomeScreenRow({
    category,
}: HomeScreenRowProps): JSX.Element {
    const navigate = useNavigate();
    // const [hasScrolled, setHasScrolled] = React.useState(false);
    // const scrollableRef = React.useRef<HTMLDivElement>(null);

    // React.useEffect(() => {
    //     console.log(
    //         `Category ${category.name} has ${category.results.result.length} items`,
    //     );
    //     console.log(
    //         `Category ${category.name} scroll ref status: ${scrollableRef.current}`,
    //     );
    //     const handleScroll = () => {
    //         if (scrollableRef.current) {
    //             const scrollLeft = scrollableRef.current.scrollLeft;
    //             setHasScrolled(scrollLeft > 0);
    //             scrollableRef.current.removeEventListener("scroll", handleScroll);
    //         }
    //     };

    //     if (scrollableRef.current) {
    //         scrollableRef.current.addEventListener("scroll", handleScroll);
    //     }

    //     const lastRef = scrollableRef.current;
    //     return () => {
    //         if (lastRef) {
    //             lastRef.removeEventListener(
    //                 "scroll",
    //                 handleScroll,
    //             );
    //         }
    //     };
    // }, []);

    // const showSwipeLeft = React.useMemo(() => {
    //     return category.results.result.length > 2 &&
    //         !hasScrolled;
    // }, [category, hasScrolled]);

    return (
        <Stack key={category.name}>
            <Stack direction="row">
                {category.navUrl === undefined && (
                    <Typography
                        variant="button"
                        color="white"
                        sx={{ textTransform: "none" }}
                    >
                        {category.label}
                    </Typography>
                )}
                {category.navUrl && (
                    <Button
                        endIcon={<KeyboardArrowRightIcon />}
                        onClick={() => {
                            if (category.setFilter) {
                                category.setFilter();
                            }
                            navigate(
                                category.navUrl ??
                                    "/content/apps/workouts/search",
                            );
                        }}
                        color="primary"
                        sx={{
                            padding: 0,
                            color: "white",
                        }}
                    >
                        <Stack
                            direction="row"
                            spacing={2}
                            minWidth="80%"
                            justifyContent="flex-start"
                        >
                            <Typography
                                variant="button"
                                color="white"
                                sx={{
                                    textTransform: "none",
                                    overflowX: "hidden",
                                    textOverflow: "ellipsis",
                                    whiteSpace: "nowrap",
                                    display: "block",
                                }}
                            >
                                {category.label}
                            </Typography>
                            <Typography
                                variant="button"
                                color="white"
                                sx={{
                                    textTransform: "none",
                                    display: "block",
                                }}
                            >
                                all
                            </Typography>
                        </Stack>
                    </Button>
                )}
            </Stack>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    padding: "4px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    scrollSnapType: "x mandatory",
                    "& > *": {
                        scrollSnapAlign: "center",
                        scrollSnapStop: "always",
                    },
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    maskImage:
                        "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                }}
            >
                {/*showSwipeLeft && (
                    <SwipeLeftIcon
                        sx={{
                            position: "relative",
                            right: 0,
                            top: "50%",
                            color: "white",
                            "@keyframes swipe": {
                                "0%": {
                                    transform: "rotate(45deg)",
                                },
                                "50%": {
                                    transform: "rotate(0deg)",
                                },
                                "100%": {
                                    transform: "rotate(-45deg)",
                                },
                            },
                            animation: "swipe 1000ms linear infinite",
                        }}
                    />
                )*/}
                {category.name === "my_workouts" && <CreateWorkoutCard />}
                {category.results.result.map((p) => {
                    const app = findApp(p.appId);
                    if (!app || app.CardComponent === null) {
                        return null;
                    }
                    return (
                        <app.CardComponent
                            key={p.id}
                            workout={p}
                            onClick={(id, navigateUrl) => navigate(navigateUrl)}
                        />
                    );
                })}
            </Stack>
        </Stack>
    );
}
