"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.assert = void 0;
// TODO: include this from somewhere else?
function assert(assertion, message) {
    if (!assertion) {
        const errorForStack = new Error(`Assertion Failed: ${message}`);
        console.error(`Assertion failed: ${message}\n${errorForStack.stack}`);
        throw errorForStack;
    }
}
exports.assert = assert;
