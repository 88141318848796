import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    AppWorkoutWithRelations as AppWorkout,
    AppWorkoutCreatePayload,
} from "@volley/data";
import { AppParameters } from "@volley/shared/app-models";
import type {
    PickleballGameState,
    PickleballGameParameters,
    PickleballGameConfig,
    DifficultyLevel,
} from "@volley/shared/apps/pickleball-game-models";
import { JSONObject } from "@volley/shared/common-models";

import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useCurrentUser } from "../../../../hooks/currentUser";
import { useStatus } from "../../../../hooks/status";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import usePrevious from "../../../../hooks/usePrevious";
import BasicLevelSelector from "../../Shared/BasicLevelSelector";
import CaptureToast from "../../Shared/CaptureToast";
import OptionSelector from "../../Shared/OptionSelector";
import PlayAppBar from "../../Shared/PlayAppBar";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";

import InstructionDialog from "./InstructionDialog";
import Scoreboard from "./ScoreBoard";
import workoutShots from "./shots.json";

type BaseWorkoutApp = Omit<
    AppWorkoutCreatePayload,
    | "config"
    | "positionX"
    | "positionY"
    | "positionYaw"
    | "positionHeight"
    | "physicsModelName"
>;
const baseWorkout: BaseWorkoutApp = {
    appId: 8,
    appName: "PickleballGame",
    description: "A fun game to practice your kitchen drop shots.",
    name: "Pickleball Kitchen Drop Game",
    sport: { name: "PICKLEBALL" },
    contentProviderId: null,
    copiedFromAppWorkoutId: null,
    extendedData: null,
    overview: "",
    state: "BUILD",
};

const singlePlayerPosition = {
    x: -0.383402489626556,
    y: 6.8309,
    sys: "physics",
};

const adPosition = {
    x: -1.75,
    y: 6.8309,
    sys: "physics",
};

const defaultTrainerPosition = {
    positionX: 0,
    positionY: -7.51,
    positionYaw: 0,
    positionHeight: 54,
};

const singlePlayerEasyShots = [
    {
        id: "36afd6f2-8bdc-4da2-ad3c-5467401084de",
        pan: -2.5,
        tilt: 20,
        spinLevel: 0,
        launchSpeed: 13.18730442556996,
        panVariance: 0,
        tiltVariance: 0,
        spinDirection: 0,
        launchSpeedVariance: 0,
    },
    {
        id: "db7290ce-7fdb-4f0e-9744-02b3b1873483",
        pan: 2.5,
        tilt: 20,
        spinLevel: 0,
        launchSpeed: 13.18730442556996,
        panVariance: 0,
        tiltVariance: 0,
        spinDirection: 0,
        launchSpeedVariance: 0,
    },
];

const twoPlayerAdShots = [
    {
        id: "2cf2bd21-57f9-4cac-899b-6dbd56ffbc51",
        pan: -10,
        tilt: 16.5,
        spinLevel: 0,
        launchSpeed: 13.18730442556996,
        panVariance: 0,
        tiltVariance: 0,
        spinDirection: 0,
        launchSpeedVariance: 0,
    },

    {
        id: "83bf2f46-de60-4dcb-be5c-61ff7f53923f",
        pan: -5,
        tilt: 16.5,
        spinLevel: 0,
        launchSpeed: 13.18730442556996,
        panVariance: 0,
        tiltVariance: 0,
        spinDirection: 0,
        launchSpeedVariance: 0,
    },
];

const twoPlayerDeuceShots = [
    {
        id: "36afd6f2-8bdc-4da2-ad3c-5467401084de",
        pan: 10,
        tilt: 16.5,
        spinLevel: 0,
        launchSpeed: 13.18730442556996,
        panVariance: 0,
        tiltVariance: 0,
        spinDirection: 0,
        launchSpeedVariance: 0,
    },
    {
        id: "10cda2be-1648-42a5-bb40-a329e7e14f3a",
        pan: 5,
        tilt: 16.5,
        spinLevel: 0,
        launchSpeed: 13.18730442556996,
        panVariance: 0,
        tiltVariance: 0,
        spinDirection: 0,
        launchSpeedVariance: 0,
    },
];

// TODO (cbley) - we should fetch this from GCS
const defaultWorkout: Omit<AppWorkoutCreatePayload, "physicsModelName"> = {
    ...baseWorkout,
    config: {
        adShots: singlePlayerEasyShots,
        deuceShots: [],
        interval: 3_250,
        playerPosition: singlePlayerPosition,
    } as unknown as JSONObject,
    ...defaultTrainerPosition,
};

const defaultTwoPlayerWorkout: Omit<
    AppWorkoutCreatePayload,
    "physicsModelName"
> = {
    ...baseWorkout,
    config: {
        adShots: twoPlayerAdShots,
        deuceShots: twoPlayerDeuceShots,
        interval: 3_250,
        playerPosition: adPosition,
    } as unknown as JSONObject,
    ...defaultTrainerPosition,
};

type PlayerCount = 1 | 2;

interface DifficultyLevelActionValue {
    player: keyof PickleballGameParameters["difficultyLevels"];
    value: DifficultyLevel;
}

type ParamsAction =
    | { type: "shots"; value: number }
    | { type: "playerCount"; value: PlayerCount }
    | { type: "difficultyLevels"; value: DifficultyLevelActionValue }
    | {
          type: "player1Position";
          value: PickleballGameParameters["player1Position"];
      };

function paramsReducer(
    state: PickleballGameParameters,
    action: ParamsAction,
): PickleballGameParameters {
    switch (action.type) {
        case "shots":
            return { ...state, numberOfBalls: action.value };
        case "playerCount":
            return { ...state, playerCount: action.value };
        case "player1Position":
            return { ...state, player1Position: action.value };
        case "difficultyLevels":
            return {
                ...state,
                difficultyLevels: {
                    ...state.difficultyLevels,
                    [action.value.player]: action.value.value,
                },
            };
        default:
            return state;
    }
}

const defaultParameters: PickleballGameParameters = {
    numberOfBalls: 20,
    playerCount: 1,
    initialDelay: 4000,
    player1Position: "ad",
    difficultyLevels: {
        player1: 1,
        player2: 1,
    },
};

type KnownBallCount = "5" | "10" | "15" | "20" | "All";
const KnownBallCounts: Record<KnownBallCount, number> = {
    5: 5,
    10: 10,
    15: 15,
    20: 20,
    All: 60,
};

function mirrorShots(
    shots: PickleballGameConfig["adShots"],
): PickleballGameConfig["deuceShots"] {
    return shots.map((s) => ({
        ...s,
        pan: -s.pan,
    }));
}

function generateWorkout(
    params: PickleballGameParameters,
    physicsModelName: string,
): AppWorkoutCreatePayload {
    const interval = 3_250;
    let w = params.playerCount === 1 ? defaultWorkout : defaultTwoPlayerWorkout;

    if (params.playerCount === 1) {
        let shots: PickleballGameConfig["adShots"] = [];
        if (params.player1Position === "ad") {
            shots = workoutShots.half[params.difficultyLevels.player1 - 1]
                .shots as PickleballGameConfig["adShots"];
        } else if (params.player1Position === "deuce") {
            shots = mirrorShots(
                workoutShots.half[params.difficultyLevels.player1 - 1]
                    .shots as PickleballGameConfig["adShots"],
            );
        } else {
            // full court
            shots =
                workoutShots.full[params.difficultyLevels.player1 - 1].shots;
        }

        w = {
            ...w,
            config: {
                adShots: [],
                deuceShots: shots,
                interval,
                playerPosition: singlePlayerPosition,
            } as unknown as JSONObject,
        };
    } else if (params.playerCount === 2) {
        w = {
            ...w,
            config: {
                adShots:
                    workoutShots.half[params.difficultyLevels.player1 - 1]
                        .shots,
                deuceShots: mirrorShots(
                    workoutShots.half[params.difficultyLevels.player2 - 1]
                        .shots,
                ),
                interval,
                playerPosition: adPosition,
            } as unknown as JSONObject,
        };
    }

    return { ...w, physicsModelName };
}

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

interface ParametersSectionProps {
    children: React.ReactNode;
    label?: string;
}

function ParametersSection({
    label = "",
    children,
}: ParametersSectionProps): JSX.Element {
    return (
        <Box component="div" sx={{ mb: 2 }}>
            {label && (
                <Typography variant="h5" mb={1}>
                    {label}
                </Typography>
            )}
            {children}
        </Box>
    );
}

export default function PickleballGame(): JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        defaultParameters,
    );
    const parameters = paramsState as unknown as AppParameters;
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [playClicked, setPlayClicked] = React.useState<boolean>(false);
    const [instructionDialogOpen, setInstructionDialogOpen] =
        React.useState<boolean>(false);
    const { status } = useStatus();
    const { isVisionStarting } = usePosition();
    const { checkForLift, stop: stopLift } = useLift();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const { physicsModelName } = usePhysicsModelContext();
    const { isAdmin } = useCurrentUser();

    // we use this ref to track if the settings have been changed and we should start a new game
    const modifiedRef = React.useRef(true);

    const { addWorkout } = useAppWorkouts();

    const {
        start,
        pause,
        playState,
        playInitiated,
        workoutState,
        playDisabled,
        pauseDisabled,
        captureDisabled,
        captureVideo,
        captureStatus,
        stop: stopWorkout,
    } = useAppWorkoutPlay({
        workout,
        parameters,
    });

    const lastPlayState = usePrevious(playState);

    const liftTargetHeight = React.useMemo(() => {
        if (playInitiated) {
            return workout?.positionHeight;
        }

        return undefined;
    }, [workout, playInitiated]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await stopWorkout();
        }
    }, [playInitiated, playState, stopLift, stopWorkout]);

    const saveWorkout = React.useCallback(async () => {
        if (modifiedRef.current) {
            const w = generateWorkout(paramsState, physicsModelName);
            const newWorkout = await addWorkout({
                ...w,
                physicsModelName,
            });

            if (newWorkout !== null) {
                setWorkout(newWorkout);
                setPlayClicked(true);
                modifiedRef.current = false;
            }
        } else {
            setPlayClicked(true);
        }
    }, [paramsState, addWorkout, physicsModelName]);

    const handlePlayClicked = React.useCallback(async () => {
        await saveWorkout();
    }, [saveWorkout]);

    // Start the workout when the play button is clicked
    React.useEffect(() => {
        if (playClicked && workout) {
            setPlayClicked(false);
            checkForLift();
            void start();
        }
    }, [checkForLift, playClicked, start, workout]);

    React.useEffect(() => {
        if (lastPlayState === "playing" && playState === "stopped") {
            modifiedRef.current = true;
        }
    }, [lastPlayState, playState]);

    const pickleWorkoutState =
        workoutState as unknown as PickleballGameState | null;
    const ballsThrown = pickleWorkoutState?.currentShotNumber ?? 0;
    const ballsToThrow = paramsState.playerCount * paramsState.numberOfBalls;
    const summaryText = playInitiated ? `${ballsThrown}/${ballsToThrow}` : "";
    const score = pickleWorkoutState?.score ?? { p1: 0, p2: 0 }; // TODO (cbley) - type
    const difficultyLevelsLabel =
        paramsState.playerCount === 1
            ? "Difficulty Level"
            : "Difficulty Levels";

    return (
        <>
            <Stack
                sx={{
                    backgroundColor: "background.default",
                }}
            >
                <Typography variant="h3" mb={1}>
                    Third Shot Drop Game
                </Typography>
                <Box component="div" mb={2}>
                    <Button onClick={() => {}} variant="text">
                        <Typography
                            variant="h4"
                            color="info.main"
                            onClick={() => setInstructionDialogOpen(true)}
                        >
                            View Instructions
                        </Typography>
                    </Button>
                </Box>

                <Typography variant="h3" mb={2} color="primary.main">
                    Setup
                </Typography>

                <ParametersSection>
                    <OptionSelector
                        label="Player Count"
                        labelWrapperSx={{ flex: 1 }}
                        toggleButtonSx={{ flex: 2 }}
                        options={[
                            { value: 1, label: "1 Player" },
                            { value: 2, label: "2 Player" },
                        ]}
                        selected={paramsState.playerCount}
                        setOption={(value) => {
                            paramsDispatch({ type: "playerCount", value });
                            modifiedRef.current = true;
                        }}
                    />
                </ParametersSection>

                {paramsState.playerCount === 1 && (
                    <ParametersSection>
                        <OptionSelector
                            label="Player Position"
                            labelWrapperSx={{ flex: 1 }}
                            toggleButtonSx={{ flex: 2 }}
                            options={[
                                { value: "full", label: "Full Court" },
                                { value: "ad", label: "Ad" },
                                { value: "deuce", label: "Deuce" },
                            ]}
                            selected={paramsState.player1Position}
                            setOption={(value) => {
                                if (value !== null) {
                                    paramsDispatch({
                                        type: "player1Position",
                                        value,
                                    });
                                    modifiedRef.current = true;
                                }
                            }}
                        />
                    </ParametersSection>
                )}

                <ParametersSection label={difficultyLevelsLabel}>
                    <BasicLevelSelector
                        label={
                            paramsState.playerCount === 1 ? "Level" : "Ad Side"
                        }
                        labelWrapperSx={{ flex: 1 }}
                        level={paramsState.difficultyLevels.player1}
                        toggleButtonSx={{ flex: 2 }}
                        setLevel={(value) => {
                            if (value !== null) {
                                paramsDispatch({
                                    type: "difficultyLevels",
                                    value: {
                                        player: "player1",
                                        value: value as DifficultyLevel,
                                    },
                                });
                                modifiedRef.current = true;
                            }
                        }}
                        wrapperSx={
                            parameters.playerCount === 2 ? { mb: 1 } : {}
                        }
                    />
                    {paramsState.playerCount === 2 && (
                        <BasicLevelSelector
                            label="Deuce Side"
                            labelWrapperSx={{ flex: 1 }}
                            level={paramsState.difficultyLevels.player2}
                            toggleButtonSx={{ flex: 2 }}
                            setLevel={(value) => {
                                if (value !== null) {
                                    paramsDispatch({
                                        type: "difficultyLevels",
                                        value: {
                                            player: "player2",
                                            value: value as DifficultyLevel,
                                        },
                                    });
                                    modifiedRef.current = true;
                                }
                            }}
                        />
                    )}
                </ParametersSection>

                <ParametersSection>
                    <ThrowCount
                        disabled={false}
                        selectedThrowCount={paramsState.numberOfBalls}
                        onUserThrowCountChanged={(number) => {
                            paramsDispatch({ type: "shots", value: number });
                            modifiedRef.current = true;
                        }}
                        marks={ballCountMarks}
                        min={1}
                        max={20}
                    />
                </ParametersSection>

                <Box component="div" sx={{ mb: 8 }}>
                    <Typography variant="h3" color="primary.main">
                        Score
                    </Typography>
                    <Scoreboard
                        playerCount={paramsState.playerCount}
                        adScore={score.p2}
                        deuceScore={score.p1}
                    />
                </Box>

                <PlayAppBar
                    onPauseClicked={() => pause()}
                    onPlayClicked={handlePlayClicked}
                    pauseDisabled={pauseDisabled}
                    playDisabled={visionUnavailable || playDisabled}
                    playState={playState}
                    showRecord={isAdmin()}
                    onRecordClicked={() => captureVideo()}
                    playSummary={summaryText}
                    recordDisabled={visionUnavailable || captureDisabled}
                    recordingStatus={captureStatus}
                />
                <LiftModal
                    stop={handleLiftStop}
                    targetHeight={liftTargetHeight}
                    message="The trainer is adjusting the head height"
                />
                <CaptureToast captureStatus={captureStatus} />
            </Stack>
            <InstructionDialog
                open={instructionDialogOpen}
                onClose={() => setInstructionDialogOpen(false)}
            />
        </>
    );
}
