import * as React from "react";

import { useSelectedSport } from "../common/context/sport";

import { useStatus } from "./status";

export interface Range {
    min: number;
    max: number;
}

export interface Ranges {
    pan: Range;
    tilt: Range;
    lift: Range;
    wheels: Range;
}

export const DefaultRanges: Ranges = {
    pan: { min: -34, max: 34 },
    lift: { min: 32.6, max: 86.6 },
    tilt: { min: -38, max: 56 },
    wheels: { min: 300, max: 5000 },
};

export default function useRanges(): Ranges {
    const { selected: selectedSport } = useSelectedSport();
    const { status } = useStatus();

    const wheels = React.useMemo(() => {
        if (selectedSport === "PICKLEBALL") {
            return {
                min: 300,
                max: 3750,
            };
        }

        return status?.trainer.wheels.top.range ?? DefaultRanges.wheels;
    }, [status?.trainer.wheels.top.range, selectedSport]);

    return {
        lift: status?.trainer.lift.range ?? DefaultRanges.lift,
        pan: status?.trainer.pan.range ?? DefaultRanges.pan,
        tilt: status?.trainer.tilt.range ?? DefaultRanges.tilt,
        wheels,
    };
}
