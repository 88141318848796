import * as React from "react";

import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

interface Props {
    name: string;
    updateName: (name: string) => void;
}

export default function ShotDetails({ name, updateName }: Props): JSX.Element {
    return (
        <Stack spacing={2}>
            <TextField
                type="text"
                label="Shot Name"
                name="shotName"
                value={name}
                onChange={(e) => updateName(e.currentTarget.value)}
                autoFocus
            />
        </Stack>
    );
}
