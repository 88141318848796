import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import HourglassEmptyIcon from "@mui/icons-material/HourglassEmpty";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

import fetchApi, { logFetchError } from "../../../../util/fetchApi";

export interface FavoriteButtonProps {
    workoutId: number;
    isFavorite: boolean;
    onClick: () => void;
}

export default function FavoriteButton({
    workoutId,
    isFavorite,
    onClick,
}: FavoriteButtonProps): JSX.Element {
    const [loading, setLoading] = React.useState(false);
    const [status, setStatus] = React.useState<"success" | "fail" | null>(null);

    const toggleFavorite = React.useCallback(async () => {
        setLoading(true);
        const url = `/api/app-workouts/mine/${workoutId}/favorite`;
        try {
            if (isFavorite) {
                await fetchApi(url, "DELETE");
            } else {
                await fetchApi(url, "POST");
            }
            setStatus("success");
            onClick();
        } catch (ex) {
            logFetchError(
                ex,
                `Failed to toggle favorite for workout ${workoutId}`,
            );
            setStatus("fail");
        } finally {
            setLoading(false);
        }
    }, [isFavorite, workoutId, onClick]);

    const icon = React.useMemo(() => {
        if (loading) {
            return (
                <HourglassEmptyIcon sx={{ color: "white", fontSize: "16px" }} />
            );
        }

        if (isFavorite) {
            return <StarIcon sx={{ color: "white", fontSize: "16px" }} />;
        }

        return <StarBorderIcon sx={{ color: "white", fontSize: "16px" }} />;
    }, [isFavorite, loading]);

    return (
        <>
            <IconButton
                disabled={loading}
                sx={{
                    padding: "2px",
                    backgroundColor: "primary.main",
                    width: "20px",
                    height: "20px",
                    "&:hover": {
                        backgroundColor: "primary.main",
                    },
                    "&:disabled": {
                        backgroundColor: "primary.main",
                    },
                }}
                onClick={() => toggleFavorite()}
                size="small"
            >
                {icon}
            </IconButton>
            <Snackbar
                autoHideDuration={3000}
                open={status !== null}
                onClose={() => setStatus(null)}
                anchorOrigin={{
                    horizontal: "center",
                    vertical: "top",
                }}
            >
                <Alert
                    severity={status === "success" ? "success" : "error"}
                    action={
                        <IconButton
                            size="small"
                            aria-label="close"
                            color="inherit"
                            onClick={() => setStatus(null)}
                        >
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    }
                >
                    {status === "success"
                        ? `Workout ${isFavorite ? "added to" : "removed from"} favorites`
                        : "Failed to update favorite"}
                </Alert>
            </Snackbar>
        </>
    );
}
