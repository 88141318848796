import * as React from "react";

import ClearObstructionDialog from "../ClearObstructionDialog";
import useDialog from "../useDialog";

const enum ObstructionSteps {
    Start,
    Clear,
    Done,
}

export default function ObstructionFlow(): JSX.Element | null {
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState(ObstructionSteps.Start);

    React.useEffect(() => {
        setStep(ObstructionSteps.Clear);
    }, []);

    if (step === ObstructionSteps.Clear) {
        return (
            <ClearObstructionDialog
                onCleared={() => setStep(ObstructionSteps.Done)}
            />
        );
    }

    if (step === ObstructionSteps.Done) {
        setDialogType(null);
    }

    return null;
}
