import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import { round } from "../../../../util/positionUtil";
import NotchedOutline from "../../../common/NotchedOutline";
import { useSelectedSport } from "../../../common/context/sport";
import SlimNumberInput from "../../util/SlimNumberInput";
import AccordionSlider from "../Shared/AccordionSlider";

interface SpeedAccordionProps extends Omit<AccordionProps, "children"> {
    selectedSpeed: number;
    onSpeedChanged: (value: number) => void;
    selectedSpeedVariation: number;
    onSpeedVariationChanged: (value: number) => void;
}

export default function SpeedAccordion({
    selectedSpeed,
    onSpeedChanged,
    selectedSpeedVariation,
    onSpeedVariationChanged,
    ...props
}: SpeedAccordionProps): JSX.Element {
    const {
        limits: { speed },
    } = useSelectedSport();

    const sliderMarks = React.useMemo(() => {
        const { minLaunchSpeed, maxLaunchSpeed } = speed;

        const marks: { label: string; value: number }[] = [];

        for (let i = minLaunchSpeed; i <= maxLaunchSpeed; i += 5) {
            marks.push({ label: i.toString(), value: i });
        }

        return marks;
    }, [speed]);

    const displaySpeed = round(selectedSpeed, 0.5);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!props.expanded && (
                    <Box component="div">
                        <Typography variant="h3" color="primary.main">
                            {`Speed: ${displaySpeed.toFixed(1)}mph`}
                        </Typography>
                        <Typography variant="h4" color="primary.main">
                            {`Variance: ${selectedSpeedVariation > 0 ? "Yes" : "No"}`}
                        </Typography>
                    </Box>
                )}
                {props.expanded && (
                    <Typography variant="h3" color="primary.main">
                        Speed
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <NotchedOutline label="Ball Speed">
                    <Stack spacing={3}>
                        <SlimNumberInput
                            onChange={(value) => {
                                onSpeedChanged(value);
                            }}
                            label="mph"
                            value={displaySpeed}
                            incrementValue={0.5}
                            maxValue={speed.maxLaunchSpeed}
                            minValue={speed.minLaunchSpeed}
                        />
                        <AccordionSlider
                            min={speed.minLaunchSpeed}
                            max={speed.maxLaunchSpeed}
                            marks={sliderMarks}
                            step={1}
                            value={displaySpeed}
                            onChange={(_, v) => onSpeedChanged(v as number)}
                            onChangeCommitted={(_, v) =>
                                onSpeedChanged(v as number)
                            }
                            sx={{
                                maxWidth: "90%",
                                alignSelf: "center",
                            }}
                        />
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="caption">
                                Add variance to ball speed?
                            </Typography>
                            <Typography
                                sx={{
                                    color:
                                        selectedSpeedVariation === 1.5
                                            ? "grey"
                                            : "primary.main",
                                }}
                                variant="body2"
                            >
                                No
                            </Typography>
                            <Switch
                                checked={selectedSpeedVariation === 1.5}
                                onChange={(e) => {
                                    const updated = e.target.checked ? 1.5 : 0;
                                    onSpeedVariationChanged(updated);
                                }}
                                sx={{
                                    "& .MuiSwitch-thumb": {
                                        color:
                                            selectedSpeedVariation === 1.5
                                                ? "primary.light"
                                                : "common.white",
                                    },
                                    "& .Mui-checked+ .MuiSwitch-track": {
                                        backgroundColor:
                                            selectedSpeedVariation === 1.5
                                                ? "#A1C8F5"
                                                : "grey.500",
                                    },
                                }}
                            />
                            <Typography
                                sx={{
                                    color:
                                        selectedSpeedVariation === 1.5
                                            ? "primary.light"
                                            : "grey",
                                }}
                                variant="body2"
                            >
                                Yes
                            </Typography>
                        </Stack>
                    </Stack>
                </NotchedOutline>
            </AccordionDetails>
        </Accordion>
    );
}
