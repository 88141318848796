import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";

import NotchedOutline from "../../common/NotchedOutline";

interface NumberInputProps {
    label?: string;
    value: number;
    minValue?: number;
    maxValue?: number;
    incrementValue?: number;
    onChange(value: number): void;
    disabled?: boolean;
    suffix?: string;
    showDecimal?: boolean;
    showInfinity?: boolean;
    reverseIncrement?: boolean;
    decimalPrecision?: number;
}

function SlimNumberInput({
    label,
    value,
    minValue,
    maxValue,
    incrementValue = 1,
    onChange,
    disabled = false,
    showDecimal = true,
    showInfinity = false,
    suffix,
    reverseIncrement = false,
    decimalPrecision = 1,
}: NumberInputProps): JSX.Element {
    const [numberValue, setNumberValue] = React.useState(
        value?.toFixed(1) ?? 0,
    );
    const [infinityTouched, setInfinityTouched] = React.useState(false);
    const numberChange = React.useCallback(
        (newValue: number) => {
            if (Number.isNaN(newValue)) {
                setNumberValue(
                    showDecimal ? value.toFixed(1) : value?.toFixed(0),
                );
                return;
            }
            if (minValue !== undefined && newValue < minValue) {
                onChange(minValue);
            } else if (minValue !== undefined && newValue > maxValue!) {
                onChange(maxValue!);
            } else {
                onChange(newValue);
            }
        },
        [maxValue, minValue, onChange, setNumberValue, value, showDecimal],
    );
    const decrementClick = React.useCallback(() => {
        if (reverseIncrement) {
            const decremented = value + incrementValue!;
            numberChange(decremented);
        } else {
            const decremented = value - incrementValue!;
            numberChange(decremented);
        }
    }, [incrementValue, value, reverseIncrement, numberChange]);
    const incrementClick = React.useCallback(() => {
        if (reverseIncrement) {
            const incremented = value - incrementValue!;
            numberChange(incremented);
        } else {
            const incremented = value + incrementValue!;
            numberChange(incremented);
        }
    }, [incrementValue, value, reverseIncrement, numberChange]);
    React.useEffect(() => {
        if (showDecimal) {
            setNumberValue(value.toFixed(decimalPrecision));
        } else {
            setNumberValue(value.toFixed(0));
        }
    }, [value, showDecimal, decimalPrecision]);

    const numberInputRef = React.useRef<HTMLInputElement>();

    const infinity = React.useMemo(() => {
        if (showInfinity) {
            if (infinityTouched) {
                return false;
            }

            return value === maxValue;
        }

        return false;
    }, [value, maxValue, showInfinity, infinityTouched]);

    React.useEffect(() => {
        if (infinityTouched) {
            numberInputRef.current?.focus();
        }
    }, [infinityTouched, numberInputRef]);

    const theme = useTheme();
    const arrowButton = {
        border: "1px solid",
        color: "white",
        background: theme.palette.primary.light,
        margin: ".5em",
        "& .MuiSvgIcon-root": {
            width: "0.75em",
            height: "0.75em",
        },
        "&:hover": {
            backgroundColor: theme.palette.primary.light,
        },
    };
    const textInput = {
        "& .MuiInputBase-input": {
            textAlign: "center",
            fontSize: "2.75em",
            fontWeight: "bold",
            color: theme.palette.primary.light,
        },
    };

    const decrementIcon = reverseIncrement ? <AddIcon /> : <RemoveIcon />;

    const incrementIcon = reverseIncrement ? <RemoveIcon /> : <AddIcon />;

    const decrementDisabled = reverseIncrement
        ? value === maxValue
        : value === minValue;

    const incrementDisabled = reverseIncrement
        ? value === minValue
        : value === maxValue;

    return (
        <Grid
            item
            container
            direction="column"
            sx={{
                maxWidth: "90%",
            }}
            alignSelf="center"
        >
            <NotchedOutline
                label={label}
                position="bottom"
                centerLabel
                elliptical
            >
                <Grid item container direction="row">
                    <Grid item container justifyContent="center" xs={3} md={2}>
                        <IconButton
                            color="primary"
                            onClick={decrementClick}
                            disabled={disabled || decrementDisabled}
                            size="large"
                            sx={arrowButton}
                        >
                            {decrementIcon}
                        </IconButton>
                    </Grid>
                    <Grid item container justifyContent="center" xs={6} md={8}>
                        {infinity && (
                            <TextField
                                variant="standard"
                                value="∞"
                                onFocus={() => setInfinityTouched(true)}
                                sx={textInput}
                                slotProps={{
                                    input: {
                                        disableUnderline: true,
                                    },
                                }}
                            />
                        )}
                        {!infinity && (
                            <TextField
                                variant="standard"
                                type="number"
                                onChange={(evt) =>
                                    setNumberValue(evt.target.value)
                                }
                                onBlur={() => {
                                    numberChange(parseInt(numberValue, 10));
                                    setInfinityTouched(false);
                                }}
                                onFocus={(evt) => evt.target.select()}
                                value={numberValue}
                                inputRef={numberInputRef}
                                sx={textInput}
                                slotProps={{
                                    input: {
                                        disableUnderline: true,
                                        endAdornment: (
                                            <InputAdornment
                                                position="end"
                                                sx={{
                                                    "& .MuiTypography-root": {
                                                        fontSize: "3.5em",
                                                        color: theme.palette
                                                            .primary.light,
                                                    },
                                                }}
                                            >
                                                {suffix}
                                            </InputAdornment>
                                        ),
                                    },
                                }}
                            />
                        )}
                    </Grid>
                    <Grid item container justifyContent="center" xs={3} md={2}>
                        <IconButton
                            color="primary"
                            onClick={incrementClick}
                            disabled={disabled || incrementDisabled}
                            size="large"
                            sx={arrowButton}
                        >
                            {incrementIcon}
                        </IconButton>
                    </Grid>
                </Grid>
            </NotchedOutline>
        </Grid>
    );
}

export default SlimNumberInput;
