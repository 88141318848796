import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import NotchedOutline from "../../../common/NotchedOutline";
import useRanges from "../../../hooks/ranges";
import SlimNumberInput from "../../util/SlimNumberInput";
import AccordionSlider from "../Shared/AccordionSlider";

interface UserTrimAccordionProps extends Omit<AccordionProps, "children"> {
    trim: number;
    onTrimChanged: (value: number) => void;
}

export default function UserTrimAccordion({
    trim,
    onTrimChanged,
    ...props
}: UserTrimAccordionProps): JSX.Element {
    const [userTrim, setUserTrim] = React.useState(trim);
    const { pan: aimRange } = useRanges();

    const marks = React.useMemo(
        () => [
            {
                value:
                    aimRange.min +
                    Math.floor((10 / 100) * Math.abs(aimRange.min)),
                label: "Left",
            },
            {
                value: 0,
                label: "Center",
            },
            {
                value: aimRange.max - Math.floor((10 / 100) * aimRange.max),
                label: "Right",
            },
        ],
        [aimRange.min, aimRange.max],
    );

    React.useEffect(() => {
        setUserTrim(trim);
    }, [trim]);
    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {props.expanded && (
                    <Typography color="primary.main" variant="h3">
                        Aim Adjustment
                    </Typography>
                )}
                {!props.expanded && (
                    <Box component="div">
                        <Typography color="primary.main" variant="h4">
                            Aim Adjustment
                        </Typography>
                        <Typography color="primary.main" variant="h3">
                            {`${trim}°`}
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <NotchedOutline label="Angle">
                    <Stack>
                        <SlimNumberInput
                            onChange={(value) => {
                                onTrimChanged(value);
                            }}
                            value={userTrim}
                            incrementValue={0.5}
                            maxValue={aimRange.max}
                            minValue={aimRange.min}
                            suffix="°"
                        />
                        <AccordionSlider
                            min={aimRange.min}
                            max={aimRange.max}
                            marks={marks}
                            track={false}
                            step={0.5}
                            value={userTrim}
                            onChange={(_, v) => setUserTrim(v as number)}
                            onChangeCommitted={(_, v) =>
                                onTrimChanged(v as number)
                            }
                            sx={{
                                "& .MuiSlider-root": {
                                    marginBottom: "24px",
                                },
                                maxWidth: "90%",
                                alignSelf: "center",
                            }}
                        />
                    </Stack>
                </NotchedOutline>
            </AccordionDetails>
        </Accordion>
    );
}
