import * as React from "react";

import Box from "@mui/material/Box";
import Container, { ContainerProps } from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import logger from "../../log";
import useDialog from "../Dialog/useDialog";
import { Slug, TutorialsStepper } from "../Tutorials";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";

import CloseableDialogTitle from "./CloseableDialogTitle";
import TopNavBar from "./TopNavBar";
import { trainerCompatibleWithSport, useSelectedSport } from "./context/sport";

const SLUGS: Slug[] = [
    "app-welcome",
    "app-menu",
    "app-support",
    "app-sport",
    "app-connect",
];

export default function UserPage({
    children,
    ...props
}: ContainerProps): JSX.Element {
    const { status } = useStatus();
    const { dialogType, setDialogType, batteryWarningDismissed } = useDialog();
    const { status: pairingStatus } = usePairingContext();
    const { selected: selectedSport, hasSelected } = useSelectedSport();
    const [tutorialsPending, setTutorialsPending] = React.useState(false);
    const [tutorialsOpen, setTutorialsOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    React.useEffect(() => {
        if (
            pairingStatus === "paired" &&
            (dialogType === "Device" || dialogType === null)
        ) {
            if (!trainerCompatibleWithSport(selectedSport, status)) {
                setDialogType("InvalidSportSelection");
            }
        }
    }, [pairingStatus, status, selectedSport, dialogType, setDialogType]);

    React.useEffect(() => {
        if (
            status?.trainer.battery.level &&
            status?.ready !== "INIT" &&
            status?.trainer.battery.level <= 30 &&
            dialogType !== "NoConfirmReplaceBatteries" &&
            !batteryWarningDismissed
        ) {
            setDialogType("LowBatteryWarning");
        }
    }, [status, batteryWarningDismissed, dialogType, setDialogType]);

    React.useEffect(() => {
        if (!hasSelected) {
            logger.info("[UserPage] No sport selected, showing welcome dialog");
            setDialogType("SportSelectorWelcome");
            setTutorialsPending(true);
        }
    }, [hasSelected, setDialogType]);

    // After sport selection, open tutorials
    React.useEffect(() => {
        if (!dialogType && tutorialsPending) {
            setTutorialsPending(false);
            setTutorialsOpen(true);
        }
    }, [dialogType, tutorialsPending]);

    return (
        <Box
            component="div"
            sx={{ backgroundColor: "primary.dark", minHeight: "100vh" }}
        >
            <TopNavBar />
            <Box component="main" sx={{ pl: { sm: "240px" } }}>
                <Container {...props} sx={{ px: 1 }}>
                    {children}
                </Container>
            </Box>
            <Dialog
                open={tutorialsOpen}
                fullScreen={fullScreen}
                keepMounted={false}
            >
                <CloseableDialogTitle onClose={() => setTutorialsOpen(false)}>
                    Getting Started
                </CloseableDialogTitle>
                <DialogContent sx={{ padding: 0 }}>
                    <TutorialsStepper
                        slugs={SLUGS}
                        onDone={() => setTutorialsOpen(false)}
                    />
                </DialogContent>
            </Dialog>
        </Box>
    );
}
