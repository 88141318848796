import * as React from "react";
import { useNavigate } from "react-router-dom";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

import icon from "../../static/img/backgrounds/pti.svg";
import fetchApi from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";

const WIDTH = 140;
const HEIGHT = 112;
const HIGHLIGHT_COLOR = "#4150E8";

interface PTICardProps {
    urlSegment: string;
    name: string;
}

export default function PTICard({
    urlSegment,
    name,
}: PTICardProps): JSX.Element | null {
    const navigate = useNavigate();
    const { setDialogType } = useDialog();

    const navigateToApta = React.useCallback(
        async (view: string, playerIdArg?: string) => {
            let playerId = playerIdArg;
            if (!playerId) {
                try {
                    const res = await fetchApi<{ playerId: string }>(
                        "/api/pti/player-id/mine",
                    );
                    ({ playerId } = res);
                } catch {
                    setDialogType("APTAId");
                    return;
                }
            }

            navigate(`/player-pti/${view}`, {
                state: { aptaPlayerId: parseInt(playerId, 10) },
            });
        },
        [navigate, setDialogType],
    );

    return (
        <Card
            sx={{
                minWidth: WIDTH,
                width: WIDTH,
                height: HEIGHT,
                backgroundColor: (t) => t.palette.primary.main,
                borderRadius: "10px",
                borderLeftWidth: "5px",
                borderLeftStyle: "solid",
                borderLeftColor: HIGHLIGHT_COLOR,
                backgroundImage: `url('${icon}')`,
                backgroundSize: "45%",
                backgroundPosition: "50% 66%",
                backgroundRepeat: "no-repeat",
            }}
            onClick={() => {
                navigateToApta(urlSegment);
            }}
        >
            <CardContent
                sx={{
                    pt: 1,
                    pr: 1,
                    pl: 1,
                    pb: 0,
                }}
            >
                <Typography
                    sx={{
                        textTransform: "none",
                        overflowX: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: (t) => t.typography.fontWeightMedium,
                    }}
                    variant="body2"
                    color="white"
                >
                    {name}
                </Typography>
            </CardContent>
        </Card>
    );
}
