import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { Failure } from "@volley/shared/coach-models";

import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import SupportLink from "../common/SupportLink";
import { useStatus } from "../hooks/status";

import useUnclosableDialog from "./common/useUnclosableDialog";
import useDialog from "./useDialog";

interface PowerUpDialogProps {
    onPoweredUp: () => void;
}
export default function PowerUpDialog({
    onPoweredUp,
}: PowerUpDialogProps): JSX.Element {
    useUnclosableDialog();
    // const { post, error: fetchError } = useFetch();
    const [fetchError, setFetchError] = React.useState<string>();
    const { status, hasFault } = useStatus();
    const { setDialogType } = useDialog();
    const [poweredUp, setPoweredUp] = React.useState(false);

    function shouldSetDialogType(failures: Failure[] | undefined): boolean {
        return (
            failures !== undefined &&
            !failures.some(
                (f) =>
                    f.type === "LowBattery" ||
                    f.type === "RemovedBattery" ||
                    f.type === "PowerIssue",
            )
        );
    }

    React.useEffect(() => {
        if (hasFault && shouldSetDialogType(status?.fault?.failures)) {
            setDialogType("Device");
        }
    }, [hasFault, setDialogType, status]);

    React.useEffect(() => {
        async function powerup() {
            try {
                await pairedFetchApi(status?.clientId, "/api/power-up", "POST");
            } catch (ex) {
                logFetchError(ex, "Failed to power up from PowerUpDialog");
                if (ex instanceof Error) {
                    setFetchError(ex.message);
                } else {
                    const msg = `Unknown error attempting to power up: ${JSON.stringify(ex)}`;
                    setFetchError(msg);
                }
            }
        }
        void powerup();
    }, [status?.clientId]);

    React.useEffect(() => {
        async function calibrate() {
            await pairedFetchApi(status?.clientId, "/api/calibrate", "POST");
        }

        const powerState = status?.trainer?.powerState;
        const motionState = status?.trainer.state;
        if (
            powerState === "L0" &&
            (motionState === "UNCALIBRATED" || motionState === "IDLE") &&
            !poweredUp
        ) {
            setPoweredUp(true);
            void calibrate();
        }
    }, [status, status?.clientId, poweredUp]);

    React.useEffect(() => {
        if (poweredUp && status?.trainer.calibrated === "Calibrated") {
            // we're done
            onPoweredUp();
        }
    }, [onPoweredUp, poweredUp, status?.trainer.calibrated]);

    React.useEffect(() => {
        if (fetchError) {
            setDialogType("FatalError");
        }
    }, [fetchError, setDialogType]);

    return (
        <>
            <CloseableDialogTitle>Powering Up</CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    Please wait one moment. The trainer is powering back up. The
                    head will move as it recalibrates.
                </DialogContentText>
                <Box sx={{ textAlign: "center", mt: 2 }}>
                    <CircularProgress size={80} color="info" />
                </Box>
            </DialogContent>
            <SupportLink preText="Having Trouble?">Click here.</SupportLink>
        </>
    );
}
