import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import type {
    CuratedWorkoutShot,
    LevelNumber,
} from "@volley/shared/apps/curated-workout-models";

import { PositionLike } from "../../../../util/position-types";
import NotchedOutline from "../../../common/NotchedOutline";
import LevelSelector from "../Shared/LevelSelector";
import ShotList from "../ShotList";

interface ShotsAccordionProps extends Omit<AccordionProps, "children"> {
    defaultInterval: number;
    plannedPosition: PositionLike | undefined;
    height: number;
    randomize: boolean;
    selectedShots: CuratedWorkoutShot[];
    onRandomizeChange: (randomize: boolean) => void;
    onSelectedShotsChanged: (shots: CuratedWorkoutShot[]) => void;
    level: LevelNumber;
    setLevel: (level: LevelNumber) => void;
    copyLevel: (type: "shots" | "feed", fromLevel: LevelNumber) => void;
}

export default function ShotsAccordion({
    defaultInterval,
    plannedPosition,
    height,
    randomize,
    selectedShots,
    onRandomizeChange,
    onSelectedShotsChanged,
    level,
    setLevel,
    copyLevel,
    ...props
}: ShotsAccordionProps): JSX.Element {
    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {props.expanded && (
                    <Typography color="primary.main" variant="h3">
                        Shots
                    </Typography>
                )}
                {!props.expanded && (
                    <Box component="div">
                        <Typography color="primary.main" variant="h4">
                            Shots
                        </Typography>
                        <Typography color="primary.main" variant="h3">
                            {selectedShots.length}
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <LevelSelector
                        type="shots"
                        level={level}
                        setLevel={setLevel}
                        onCopyLevel={copyLevel}
                    />
                    <ShotList
                        defaultInterval={defaultInterval}
                        plannedPosition={plannedPosition}
                        height={height}
                        shots={selectedShots}
                        updateShots={(updated) =>
                            onSelectedShotsChanged(updated)
                        }
                    />
                    <NotchedOutline label="Shot Order">
                        <Stack
                            direction="row"
                            spacing={1}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography sx={{ color: "primary.light" }}>
                                Sequential
                            </Typography>
                            <Switch
                                checked={randomize}
                                onChange={(e) => {
                                    onRandomizeChange(e.target.checked);
                                }}
                                sx={{
                                    "& .MuiSwitch-thumb": {
                                        color: randomize
                                            ? "primary.light"
                                            : "common.white",
                                    },
                                    "& .Mui-checked+ .MuiSwitch-track": {
                                        backgroundColor: randomize
                                            ? "#A1C8F5"
                                            : "grey.500",
                                    },
                                }}
                            />
                            <Typography sx={{ color: "primary.light" }}>
                                Randomized
                            </Typography>
                        </Stack>
                    </NotchedOutline>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
