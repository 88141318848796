import * as React from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { AppWorkoutListItem } from "@volley/data/dist/types/app-workout";

export interface WorkoutPickerProps {
    fullWidth: boolean;
    loading: boolean;
    options: AppWorkoutListItem[];
    selected: number | null;
    onChange: (id: number) => void;
}

export default function WorkoutPicker({
    fullWidth,
    loading,
    options,
    selected,
    onChange,
}: WorkoutPickerProps): JSX.Element {
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement>();

    const icon = React.useMemo(() => {
        if (options.length === 0) {
            return undefined;
        }

        if (anchorEl) {
            return (
                <KeyboardArrowUpIcon
                    sx={{
                        fontSize: "18px",
                    }}
                />
            );
        }

        return (
            <KeyboardArrowDownIcon
                sx={{
                    fontSize: "18px",
                }}
            />
        );
    }, [anchorEl, options]);

    const buttonText = React.useMemo(() => {
        if (loading) {
            return "Loading...";
        }
        if (selected) {
            const match = options.find((o) => o.id === selected);
            return match?.name ?? "Unknown";
        }

        if (options.length) {
            return "Select Workout";
        }

        return "No Workouts Available";
    }, [loading, options, selected]);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(undefined);
        } else {
            setAnchorEl(event.currentTarget);
        }
    };

    return (
        <>
            <Button
                disabled={loading}
                fullWidth={fullWidth}
                onClick={handleClick}
                disableElevation
                sx={{
                    color: "primary.main",
                    fontSize: "18px",
                }}
                startIcon={icon}
            >
                {buttonText}
            </Button>
            <Menu
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                }}
                open={anchorEl !== undefined}
                onClose={() => setAnchorEl(undefined)}
            >
                {options.map((o) => (
                    <MenuItem
                        key={o.id}
                        onClick={() => {
                            onChange(o.id);
                            setAnchorEl(undefined);
                        }}
                        sx={{
                            color: "primary.main",
                        }}
                    >
                        {o.name}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
}
