import React from "react";

import Intercom, {
    show,
    hide,
    onUnreadCountChange,
    showNewMessage,
    shutdown,
    onShow,
} from "@intercom/messenger-js-sdk";
import Alert from "@mui/material/Alert";
import Snackbar, { SnackbarCloseReason } from "@mui/material/Snackbar";

import config from "../../config";
import logger from "../../log";
import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";

import { AuthState, useCurrentUser } from "./currentUser";

export interface IntercomAPI {
    ready: boolean;
    unreadCount: number;
    show: () => void;
    hide: () => void;
    logOut: () => void;
    newMessage: () => void;
}

export const IntercomContext = React.createContext<IntercomAPI>({
    ready: false,
    unreadCount: 0,
    show: () => {},
    hide: () => {},
    logOut: () => {},
    newMessage: () => {},
});

interface AlertState {
    open: boolean;
    message: string;
}

interface ChatInit {
    hash: string;
    volley_home_location?: string;
    volley_self_described_home?: string;
    volley_phone?: string;
}

export const IntercomProvider = React.memo(
    ({ children }: React.PropsWithChildren) => {
        const { authState, currentUser } = useCurrentUser();
        const [ready, setReady] = React.useState(false);
        const [unreadCount, setUnreadCount] = React.useState(0);
        const [alert, setAlert] = React.useState<AlertState>({
            open: false,
            message: "",
        });

        const handleClose = (
            _?: React.SyntheticEvent | Event,
            reason?: SnackbarCloseReason,
        ) => {
            if (reason === "clickaway") {
                return;
            }

            setAlert({ ...alert, open: false });
        };

        React.useEffect(() => {
            async function fetchHMAC() {
                logger.info(
                    "[Intercom] Fetching HMAC to support widget signing",
                );
                const response = await fetchApi<ChatInit>(
                    "/api/integrations/support-chat",
                );
                logger.info("[Intercom] HMAC Fetch Succeeded");
                return response;
            }

            if (authState === AuthState.AUTHENTICATED && currentUser) {
                const { name } = config.intercom;
                logger.info(
                    `[Intercom] Initializing for ${currentUser.username} (${name})`,
                );
                fetchHMAC()
                    .then((chatInit) => {
                        const { appId } = config.intercom;
                        Intercom({
                            app_id: appId,
                            user_id: currentUser.id.toString(),
                            name: `${currentUser.firstName} ${currentUser.lastName}`,
                            email: currentUser.email,
                            hide_default_launcher: true,
                            user_hash: chatInit.hash,
                            volley_username: currentUser.username,
                            volley_phone: chatInit.volley_phone,
                            volley_home_location: chatInit.volley_home_location,
                            volley_self_described_home:
                                chatInit.volley_self_described_home,
                        });
                        onShow(() => {
                            logger.info("[Intercom] Widget shown.");
                        });
                        onUnreadCountChange((count: number) => {
                            logger.info(
                                `[Intercom] unread count changed: ${count}`,
                            );
                            setUnreadCount(count);
                        });
                        setReady(true);
                    })
                    .catch((error) => {
                        logFetchError(
                            error,
                            "[Intercom] Failed to fetch HMAC for support widget signing.",
                        );
                    });
            }
        }, [authState, currentUser]);

        const value = React.useMemo<IntercomAPI>(
            () => ({
                ready,
                unreadCount,
                show: () => {
                    if (ready) {
                        logger.info("[Intercom] Showing widget");
                        show();
                    } else {
                        setAlert({
                            open: true,
                            message: "Support chat is not ready yet.",
                        });
                    }
                },
                hide: () => {
                    logger.info("[Intercom] Hiding widget");
                    hide();
                },
                logOut: () => shutdown(),
                newMessage: () => {
                    showNewMessage("");
                },
            }),
            [ready, unreadCount],
        );

        return (
            <IntercomContext.Provider value={value}>
                {children}
                <Snackbar
                    open={alert.open}
                    autoHideDuration={3000}
                    onClose={handleClose}
                >
                    <Alert
                        onClose={handleClose}
                        severity="warning"
                        variant="filled"
                        sx={{ width: "100%" }}
                    >
                        {alert.message}
                    </Alert>
                </Snackbar>
            </IntercomContext.Provider>
        );
    },
);

IntercomProvider.displayName = "IntercomProvider";

export default function useIntercom() {
    return React.useContext(IntercomContext);
}
