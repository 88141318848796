import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

const ReactLazyError = React.memo(() => (
    <>
        <Typography sx={{ my: 2 }}>
            It looks like we had a problem loading this page. Our app was likely
            updated recently, so click below or refresh this page to get the
            latest update.
        </Typography>
        <Box component="div">
            <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={() => {
                    const url = new URL(window.location.href);
                    url.searchParams.set("t", Date.now().toString());
                    window.location.replace(url.toString());
                }}
            >
                Refresh the Volley App
            </Button>
        </Box>
    </>
));

ReactLazyError.displayName = "ReactLazyError";

export default ReactLazyError;
