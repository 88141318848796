import * as React from "react";

import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import Typography from "@mui/material/Typography";

const stateToStepIndex: Record<string, number> = {
    START: 0,
    PRE_SERVE: 0,
    SERVE_WAIT: 1,
    RETURN: 2,
};

interface Props {
    currentState: string | null;
}

export default function WorkflowSteps({ currentState }: Props) {
    return (
        <Stack>
            <Typography variant="h3" mb={1}>
                Steps
            </Typography>
            <Stepper
                orientation="vertical"
                activeStep={stateToStepIndex[(currentState as string) ?? ""]}
                nonLinear
            >
                <Step expanded>
                    <StepLabel>
                        <Typography variant="h4" mb={1}>
                            Enter Serve Box
                        </Typography>
                    </StepLabel>
                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                        <Typography>
                            Stand in the serve box, shown in pink below.
                        </Typography>
                    </StepContent>
                </Step>
                <Step expanded>
                    <StepLabel>
                        <Typography variant="h4" mb={1}>
                            Serve
                        </Typography>
                    </StepLabel>
                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                        <Typography>Serve the ball</Typography>
                    </StepContent>
                </Step>
                <Step expanded>
                    <StepLabel>
                        <Typography variant="h4" mb={1}>
                            Return
                        </Typography>
                    </StepLabel>
                    <StepContent TransitionProps={{ unmountOnExit: false }}>
                        <Typography>
                            Return the ball by moving into the yellow zone
                            below, then go back to step 1.
                        </Typography>
                    </StepContent>
                </Step>
            </Stepper>
        </Stack>
    );
}
