import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function SingleShotIcon(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon
            width="45"
            height="50"
            viewBox="0 0 45 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M32.1869 41.4106C37.597 41.4106 41.9827 37.0248 41.9827 31.6147C41.9827 26.2046 37.597 21.8188 32.1869 21.8188C26.7768 21.8188 22.391 26.2046 22.391 31.6147C22.391 37.0248 26.7768 41.4106 32.1869 41.4106Z"
                stroke="#EFA511"
                strokeWidth="2"
                strokeMiterlimit="10"
            />
            <path
                d="M25.6476 17.0592L12.2788 3.69043"
                stroke="#EFA511"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M19.082 18.4969L2.25891 1.67383"
                stroke="#EFA511"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M16.524 25.0753L3.70898 12.2603"
                stroke="#EFA511"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
            <path
                d="M44 48.0942H1.88965"
                stroke="#EFA511"
                strokeWidth="2"
                strokeMiterlimit="10"
                strokeLinecap="round"
            />
        </SvgIcon>
    );
}
