import * as React from "react";

import Typography from "@mui/material/Typography";

import { ValidationErrors } from "./reducer";

interface Props {
    errors: ValidationErrors;
}

export default function ModuleEditValidationErrors({
    errors,
}: Props): JSX.Element {
    const message = Object.values(errors).join("\n");
    return (
        <Typography color="error" sx={{ whiteSpace: "pre-wrap" }} p={2}>
            {message}
        </Typography>
    );
}
