import * as React from "react";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import useDialog from "./useDialog";

export default function LowBatteryWarning(): JSX.Element {
    const { setDialogType, setBatteryWarningDismissed } = useDialog();

    return (
        <>
            <DialogTitle variant="h4" sx={{ p: 2 }} color="primary.main">
                Batteries Running Low
            </DialogTitle>
            <DialogContent dividers>
                <DialogContentText sx={{ mb: 2 }}>
                    <Stack spacing={2}>
                        <Typography>
                            The batteries are running low. You can swap them now
                            or keep playing.
                        </Typography>
                    </Stack>
                </DialogContentText>
                <Stack spacing={2}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={() =>
                            setDialogType("NoConfirmReplaceBatteries")
                        }
                    >
                        Swap Now
                    </Button>
                    <Button
                        variant="contained"
                        color="info"
                        size="large"
                        fullWidth
                        onClick={() => {
                            setBatteryWarningDismissed(true);
                            setDialogType(null);
                        }}
                    >
                        Keep Playing
                    </Button>
                </Stack>
            </DialogContent>
        </>
    );
}
