import * as React from "react";
import { To, useLocation, useNavigate } from "react-router-dom";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import CircleIcon from "@mui/icons-material/Circle";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import ErrorIcon from "@mui/icons-material/Error";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HomeIcon from "@mui/icons-material/Home";
import MenuIcon from "@mui/icons-material/Menu";
import AppBar from "@mui/material/AppBar";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

import type { OffsetResult, SessionWithRelations } from "@volley/data";

import logger from "../../log";
import fetchApi, { pairedFetchApi } from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";
import { TutorialsStepper } from "../Tutorials";
import { usePairingContext } from "../hooks/pairingStatus";
import { useStatus } from "../hooks/status";
import useIntercom from "../hooks/useIntercom";

import CloseableDialogTitle from "./CloseableDialogTitle";
import UserHamburgerMenu from "./UserHamburgerMenu";
import VolleyV from "./icons/VolleyV";

export interface TopBarProps {
    optionalText?: string;
}

type BackNavState = "ready" | "waiting";

export default function TopBar({
    optionalText = "",
}: TopBarProps): JSX.Element {
    const intercom = useIntercom();
    const {
        status: pairingStatus,
        promptToPair,
        pairingCancelled,
    } = usePairingContext();
    const { dialogType, setDialogType, forceDeviceDialog } = useDialog();
    const { status } = useStatus();
    const headerText = optionalText || `Trainer ${status?.clientId ?? "?"}`;
    const navigate = useNavigate();
    const location = useLocation();

    const [menuOpen, setMenuOpen] = React.useState(false);

    const [backReadyState, setBackReadyState] =
        React.useState<BackNavState>("ready");
    const navigateTarget = React.useRef<string | number>(-1);
    const [tutorialsOpen, setTutorialsOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

    const showNavigation = React.useMemo(() => {
        if (location.pathname === "/") {
            return false;
        }

        if (dialogType === "Device") {
            return false;
        }

        return true;
    }, [location, dialogType]);

    const onHeaderClick = React.useCallback(() => {
        if (dialogType === "Device") {
            setDialogType(null);
        } else {
            setDialogType("Device");
        }
    }, [dialogType, setDialogType]);

    const showConnectButton = pairingStatus === "unpaired";

    const handleNav = React.useCallback(async () => {
        if (status?.workouts.playState === "playing") {
            logger.info(
                "[Top Nav Bar] - Workout in play, pausing before navigating.",
            );
            await pairedFetchApi(
                status?.clientId,
                "/api/apps/workouts/pause",
                "POST",
            );
            setBackReadyState("waiting");
        } else if (backReadyState === "ready") {
            logger.info(
                `[Top Nav Bar] - Navigating ${navigateTarget.current} from handleNav.`,
            );
            navigate(navigateTarget.current as To);
        } else {
            logger.info(
                "[Top Nav Bar] - handleNav called while not in 'ready' state.",
            );
        }
    }, [status, backReadyState, navigate]);

    React.useEffect(() => {
        if (backReadyState === "waiting") {
            logger.info("[Top Nav Bar] - Checking play state.");
            const playState = status?.workouts.playState;
            if (playState === "paused" || playState === "stopped") {
                logger.info(
                    "[Top Nav Bar] - Play state is no longer playing, navigating back.",
                );
                setBackReadyState("ready");
                navigate(navigateTarget.current as To);
            }
        }
    }, [backReadyState, status, navigate]);

    const statusIcon = React.useMemo(() => {
        if (status && status.fault) {
            if (status.fault.failures.every((f) => f.source === "vision")) {
                return <CircleIcon color="success" />;
            }

            return <ErrorIcon color="error" />;
        }

        return <CircleIcon color="success" />;
    }, [status]);

    const onConnectClick = React.useCallback(async () => {
        logger.info(`Connect Clicked from ${location.pathname}`);
        if (!window.localStorage.getItem("volley.hasCheckedSessionCount")) {
            const recent = await fetchApi<OffsetResult<SessionWithRelations>>(
                "/api/sessions/me?limit=1&offset=0",
            );
            window.localStorage.setItem(
                "volley.hasCheckedSessionCount",
                "true",
            );
            if (recent.count === 0) {
                logger.info(
                    "Showing pre-pair tutorials dialog to user for first session",
                );
                setTutorialsOpen(true);
                return;
            }
        }
        promptToPair();
    }, [promptToPair, location.pathname]);

    const onTutorialsClosed = React.useCallback(() => {
        promptToPair();
        setTutorialsOpen(false);
    }, [promptToPair]);

    const onClose = React.useCallback(() => {
        setMenuOpen(false);
    }, []);

    return (
        <AppBar
            id="volley-app-bar"
            position="sticky"
            sx={{
                width: { sm: "calc(100% - 240px)" },
                ml: { sm: "240px" },
                backgroundColor: (t) => t.palette.primary.dark,
                zIndex: (t) => t.zIndex.drawer + 1,
            }}
            elevation={0}
        >
            <Toolbar sx={{ px: 0 }}>
                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    flex={1}
                >
                    <Box component="div" width="20%">
                        {showNavigation && backReadyState === "ready" && (
                            <IconButton
                                onClick={async () => {
                                    navigateTarget.current = -1;
                                    await handleNav();
                                }}
                                color="inherit"
                                aria-label="back"
                            >
                                <ChevronLeftIcon />
                            </IconButton>
                        )}
                        {showNavigation &&
                            backReadyState !== "ready" &&
                            navigateTarget.current === -1 && (
                                <CircularProgress
                                    size={24}
                                    color="secondary"
                                    aria-busy="true"
                                    role="alert"
                                />
                            )}
                        {!showNavigation && dialogType !== "Device" && (
                            <IconButton
                                onClick={() => setMenuOpen(!menuOpen)}
                                color="inherit"
                                aria-label="menu"
                                sx={{ display: { sm: "none" } }}
                            >
                                <MenuIcon />
                            </IconButton>
                        )}
                    </Box>
                    <Box component="div">
                        {showConnectButton && (
                            <Button
                                variant="contained"
                                color="secondary"
                                onClick={onConnectClick}
                                startIcon={<VolleyV />}
                                fullWidth
                                sx={{
                                    color: (t) => t.palette.primary.dark,
                                }}
                            >
                                Connect
                            </Button>
                        )}
                        {["requested", "validating"].includes(
                            pairingStatus,
                        ) && (
                            <Button
                                color="warning"
                                onClick={() => pairingCancelled()}
                                fullWidth
                            >
                                Cancel
                            </Button>
                        )}
                        {pairingStatus === "paired" && dialogType === null && (
                            <Button
                                onClick={onHeaderClick}
                                sx={{
                                    display: "inline-flex",
                                    color: "inherit",
                                    fontSize: "1.2rem",
                                }}
                                startIcon={statusIcon}
                                endIcon={
                                    <ExpandMoreIcon
                                        sx={{ color: "common.white" }}
                                    />
                                }
                            >
                                {headerText}
                            </Button>
                        )}
                        {pairingStatus === "paired" &&
                            dialogType === "Device" && (
                                <Button
                                    onClick={() => setDialogType(null)}
                                    fullWidth
                                    disabled={forceDeviceDialog}
                                    sx={{
                                        color: "inherit",
                                        "&.Mui-disabled": {
                                            color: theme.palette.grey[500],
                                        },
                                    }}
                                    endIcon={<ExpandLessIcon />}
                                >
                                    Close
                                </Button>
                            )}
                    </Box>
                    <Box
                        component="div"
                        width="20%"
                        display="flex"
                        justifyContent="flex-end"
                    >
                        <Badge
                            badgeContent={intercom.unreadCount}
                            color="error"
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                        >
                            <IconButton
                                onClick={() => intercom.show()}
                                disabled={!intercom.ready}
                                color="inherit"
                                aria-label="help"
                            >
                                <ContactSupportIcon />
                            </IconButton>
                        </Badge>
                        {showNavigation && backReadyState === "ready" && (
                            <IconButton
                                onClick={async () => {
                                    navigateTarget.current = "/";
                                    await handleNav();
                                }}
                                color="inherit"
                                aria-label="home"
                            >
                                <HomeIcon />
                            </IconButton>
                        )}
                        {showNavigation &&
                            backReadyState !== "ready" &&
                            navigateTarget.current === "/" && (
                                <CircularProgress
                                    size={24}
                                    color="secondary"
                                    aria-busy="true"
                                    role="alert"
                                />
                            )}
                    </Box>
                </Stack>
            </Toolbar>
            {/* Tutorials Dialog */}
            <Dialog
                open={tutorialsOpen}
                onClose={onTutorialsClosed}
                fullScreen={fullScreen}
                keepMounted={false}
            >
                <CloseableDialogTitle onClose={onTutorialsClosed}>
                    Your First Session
                </CloseableDialogTitle>
                <DialogContent sx={{ px: 0.8 }}>
                    <TutorialsStepper
                        onDone={onTutorialsClosed}
                        slugs={[
                            "trainer-batteries",
                            "trainer-buttons",
                            "trainer-buttons-green",
                            "trainer-billboard",
                            "trainer-billboard-countdown",
                            "trainer-pairing-code",
                            "trainer-tomohopper-pin",
                            "trainer-tomohopper-arms",
                            "trainer-tomohopper-arms2",
                            "trainer-pairing-code-entry",
                            "trainer-calibrate",
                            "trainer-loading-balls-flip",
                            "trainer-loading-balls-worn",
                            "trainer-loading-balls-cover",
                            "trainer-end-session",
                        ]}
                    />
                </DialogContent>
            </Dialog>
            <UserHamburgerMenu open={menuOpen} onClose={onClose} />
        </AppBar>
    );
}
