import * as React from "react";

import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatDistance } from "date-fns";

import ModuleIcon from "../apps/11-module/Icon";
import MultiShotIcon from "../apps/curated/Icon";
import MultiLevelIcon from "../apps/multi-level/Icon";
import SingleShotIcon from "../apps/single-shot/Icon";
import { AppListItemProps } from "../apps/types";

import ProviderAvatar from "./ProviderAvatar";

export default function ListItemWrapper({
    workout,
    selected,
    attributeFilter,
    onClick,
}: AppListItemProps): JSX.Element | null {
    const attributeText = React.useMemo(() => {
        if (attributeFilter) {
            const text = attributeFilter
                .filter((a) => a !== "appId")
                .map((a) => {
                    if (a === "lastUserPlay" && workout[a]) {
                        const date = new Date(workout[a] as unknown as string);
                        return formatDistance(date, new Date(), {
                            addSuffix: true,
                        });
                    }
                    return workout[a];
                })
                .join(", ");

            return text;
        }

        return null;
    }, [attributeFilter, workout]);

    const attributeIcon = React.useMemo(() => {
        if (attributeFilter && attributeFilter[0] === "appId") {
            switch (workout.appId) {
                case 5:
                    return (
                        <SingleShotIcon
                            sx={{
                                color: (t) =>
                                    selected
                                        ? t.palette.info.main
                                        : t.palette.primary.dark,
                            }}
                        />
                    );
                case 4:
                case 6:
                    return (
                        <MultiShotIcon
                            sx={{
                                color: (t) =>
                                    selected
                                        ? t.palette.info.main
                                        : t.palette.primary.dark,
                            }}
                        />
                    );
                case 9:
                    return (
                        <MultiLevelIcon
                            sx={{
                                color: (t) =>
                                    selected
                                        ? t.palette.info.main
                                        : t.palette.primary.dark,
                            }}
                        />
                    );
                case 11:
                    return (
                        <ModuleIcon
                            sx={{
                                color: (t) =>
                                    selected
                                        ? t.palette.info.main
                                        : t.palette.primary.dark,
                            }}
                        />
                    );
                default:
                    return null;
            }
        }

        return null;
    }, [attributeFilter, workout, selected]);

    const { appId, id, name } = workout;

    return (
        <ListItemButton
            dense
            onClick={() =>
                onClick(id, `/content/apps/workouts/plugin/play/${appId}/${id}`)
            }
            sx={{
                padding: "5px",
                backgroundColor: (t) =>
                    selected ? t.palette.info.main : t.palette.primary.dark,
            }}
        >
            {workout.provider && (
                <ListItemAvatar>
                    <ProviderAvatar
                        providerName={workout.provider.name}
                        providerLabel={workout.provider.label}
                    />
                </ListItemAvatar>
            )}
            <ListItemText
                inset={workout.provider === null}
                primary={
                    <Stack
                        direction="row"
                        spacing={1}
                        justifyContent="space-between"
                        justifyItems="center"
                    >
                        <Typography
                            variant="h5"
                            color="white"
                            sx={{
                                textTransform: "none",
                            }}
                        >
                            {name}
                        </Typography>
                        {attributeText && (
                            <Typography variant="caption" color="white">
                                {attributeText}
                            </Typography>
                        )}
                        {attributeIcon}
                    </Stack>
                }
            />
        </ListItemButton>
    );
}
