"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.tableSpinAdjustMap = exports.launchDeflectionCorrection = exports.deflectionAngleAtom = void 0;
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable import/prefer-default-export */
/* eslint-disable import/prefer-default-export */
/*
    Spin Adjustment Functions
    These functions model the trainer-specific way that spin interacts with motor geometry to cause
    the speed/spin of a shot to deviate from the "independent-motor" mathematical model.
*/
const assert_1 = require("./assert");
const util_1 = require("./util");
// ____________________________________________________________________________
// ATOM
// Is spin axis aligned with a single motor (e.g. topsin)?
function isOneMotorAtom(axis) {
    return (axis === 0 || axis === 120 || axis === 240 || axis === 360
        || axis === -120 || axis === -240 || axis === -360);
}
// Is spin axis aligned with two motors (e.g. backspin)?
function isTwoMotorAtom(axis) {
    return (axis === 60 || axis === 180 || axis === 300
        || axis === -60 || axis === -180 || axis === -300);
}
// compute the launch deflection angle for a given spin rotation axis (degrees)
// and launch speed (MPH).
// NOTE: this function was determined via cubic polynomial fit of empirical
// deflection data.
function deflectionAngleAtom(axisDeg, speedMPH) {
    let angDeg = 0;
    // one motor: e.g. topspin
    if (isOneMotorAtom(axisDeg)) {
        angDeg = (2.049e-05 * speedMPH * speedMPH * speedMPH
            + 0.002078 * speedMPH * speedMPH
            - 0.1768 * speedMPH
            - 0.01364);
    }
    // two motor: e.g. backspin
    if (isTwoMotorAtom(axisDeg)) {
        angDeg = (6.453e-05 * speedMPH * speedMPH * speedMPH
            - 0.01165 * speedMPH * speedMPH
            + 0.4449 * speedMPH
            - 0.01287);
    }
    return angDeg;
}
exports.deflectionAngleAtom = deflectionAngleAtom;
/**
 * launchDeflectionCorrection
 * When we apply ball spin around an axis (e.g. axis 0 degrees = topspin), the motor differential (e.g. topspin
 * has top motor spinning much faster than bottom 2 equal-RPM motors) causes a launch "deflection"; i.e. for a
 * straight-on shot the ball is launched not straight but down with a "deflection angle" caused by the motor
 * differential. This deflection is always "with the spin" (e.g. topspin --> down, backspin --> up).
 * To undo this deflection and shoot straight, a corrective pitch and/or yaw must be applied to any shot
 * with spin.
 * This function converts a (spin axis, deflection angle degrees) pair into the (pitch, yaw) adjustments that
 * must be added to a shot to correct for launch deflection.
 *
 * @param axisDeg: spin axis of rotation in degrees (0=topspin, 180=backspin), clockwise
 * @param defDeg: deflection angle in degrees: must be in (-90, 90)
 * @returns { pitch, yaw, dvect }: pitch and yaw angles (degrees) to ADD to the shot, and
 * the deflection vector (in launch coords) that was corrected (for debugging).
 */
function launchDeflectionCorrection(axisDeg, defDeg) {
    // console.log(`launchDeflectionCorrection: AxisDeg=${axisDeg} deg, def=${defDeg}`);
    const out = {
        pitch: 0,
        yaw: 0,
        dvect: { x: 0, y: 0, z: 0 },
    };
    if (defDeg === 0) {
        return out;
    }
    // NOTE: avoid gimbal lock, and deflection will never be close to 90 in any case
    (0, assert_1.assert)(Math.abs(axisDeg) <= 360, `Illegal spin axis ${axisDeg} deg (must be in (-360, 360))`);
    (0, assert_1.assert)(Math.abs(defDeg) < 90, `Illegal deflection angle ${defDeg} deg (must be in (-90, 90))`);
    // Rotate the launch coord system so that x axis aligns with spin rotation axis
    // Now we have a rotated coord system with basis cols: [ xrot, y, zrot ].
    const xv = { x: 1, y: 0, z: 0 };
    const zv = { x: 0, y: 0, z: 1 };
    const xrot = (0, util_1.cartesianRotation)(xv, "y", -axisDeg);
    const zrot = (0, util_1.cartesianRotation)(zv, "y", -axisDeg);
    // Our deflection vector is a rotation of the unit y "launch direction" vector
    // by angle of -defDeg about x axis. Negative because deflection is "with the spin".
    const yv = { x: 0, y: 1, z: 0 };
    const dvr = (0, util_1.cartesianRotation)(yv, "x", -defDeg);
    // console.log(`deflectionToYawPitch: dvr=${dvr.x},${dvr.y},${dvr.z}`);
    // Get the coords of deflection vector wrt orignal launch coord frame
    const dvsx = xrot.x * dvr.x + yv.x * dvr.y + zrot.x * dvr.z;
    const dvsy = xrot.y * dvr.x + yv.y * dvr.y + zrot.y * dvr.z;
    const dvsz = xrot.z * dvr.x + yv.z * dvr.y + zrot.z * dvr.z;
    // console.log(`launchDeflectionCorrection: deflection vector: ${dvsx}, ${dvsy}, ${dvsz}`);
    (0, assert_1.assert)(dvsy > 0, "Illegal 0 or backward deflection vector");
    // note: divisions are well-conditioned as long as dvsy non-zero
    const yawDeg = -1 * (Math.atan(dvsx / dvsy) * (180.0 / Math.PI));
    const pitchDeg = -1 * (Math.atan(dvsz / Math.sqrt(dvsx * dvsx + dvsy * dvsy)) * (180.0 / Math.PI));
    // console.log(`launchDeflectionCorrection: yaw: ${yawDeg}, pitch: ${pitchDeg} degrees`);
    out.yaw = yawDeg;
    out.pitch = pitchDeg;
    out.dvect = { x: dvsx, y: dvsy, z: dvsz };
    return out;
}
exports.launchDeflectionCorrection = launchDeflectionCorrection;
// deflectionCorrectionAtom
// Determines the correction that must be applied to a shot with spin to offset the
// launch deflection that occurs as a result of ball spin.
// The pitchAdjust and yawAdjust (degrees) fields of the output are the values that
// should be added to the shot settings in order to produce a straight shot.
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function deflectionCorrectionAtom(axisDeg, launchSpeedMPS) {
    const sMPH = launchSpeedMPS * 2.23694;
    const correction = {
        axis: axisDeg,
        speed: launchSpeedMPS,
        deflectionAngle: 0,
        deflectionVector: { x: 0, y: 0, z: 0 },
        pitchAdjust: 0,
        yawAdjust: 0,
    };
    // outside this range deflection negligible according to our measurements
    if (sMPH > 55 || sMPH <= 0) {
        return correction;
    }
    // NOTE: function deflectionAngleAtom takes in a clockwise axis in degrees with 0 = up;
    // axisDeg coming in is counterclockwise degrees with 0 = up
    const cwAxis = 360 - axisDeg;
    // get the deflection angle for this launch
    // NOTE: the deflection angle AngDeg is >=0 for backspin ("up") and <=0 for topspin ("down").
    const angDeg = deflectionAngleAtom(cwAxis, sMPH);
    correction.deflectionAngle = angDeg;
    // Compute yaw and tilt correction and deflection vector from angular deflection
    // NOTE: correction "knows" that the deflection is "with the spin", so it needs only
    // deflection magnitude.
    const { pitch, yaw, dvect } = launchDeflectionCorrection(cwAxis, Math.abs(angDeg));
    correction.pitchAdjust = pitch;
    correction.yawAdjust = yaw;
    correction.deflectionVector = dvect;
    return correction;
}
// scale spin to better match empirical measurements that vary from the
// mathematical model (due to dual-motor vs single-motor spins).
function spinDampingFactorAtom(spinAxisDeg) {
    const cwAxis = 360 - spinAxisDeg;
    const M = 0.44;
    const arad = cwAxis * (Math.PI / 180.0);
    const magFactor = (M / 2.0) * Math.sin(3.0 * arad - Math.PI / 2.0) + (M / 2.0) + 1;
    const dampFactor = 1.0 / magFactor;
    return dampFactor;
}
// Returns a speed damping factor
function speedDampingFactorAtom(spinAxisDeg) {
    const cwAxis = 360 - spinAxisDeg;
    const M = 0.09;
    const arad = cwAxis * (Math.PI / 180.0);
    const factor = (M / 2.0) * Math.sin(3.0 * arad - Math.PI / 2.0) + (M / 2.0) + 1;
    return factor;
}
// Atom shot speed adjustment based on spin and speed
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function speedAdjustAtom(speedspin) {
    (0, assert_1.assert)(speedspin.spin !== null, "speedAdjustAtom: speedspin 'spin' value must be non-null");
    // no spin: nothing to do
    if (speedspin.spin === null || speedspin.spin === 0) {
        return speedspin.speed;
    }
    const { speed, spin } = speedspin;
    // we model ONLY "on-motor" spins
    const isSingleMotor = isOneMotorAtom(speedspin.spinAxis);
    const isDualMotor = isTwoMotorAtom(speedspin.spinAxis);
    if (!isSingleMotor && !isDualMotor) {
        return speed;
    }
    let newspeed = speed;
    // DUAL MOTOR
    // ramp speed scale factor from 1 to 1.11 over spins [0, 1500]
    if (isDualMotor) {
        const delta = (1.11 - 1.0) / 1500;
        if (spin > 1500) {
            newspeed = speed * 1.11;
        }
        else {
            newspeed = speed * (1.0 + delta * spin);
        }
    }
    // SINGLE MOTOR
    // if spin > 1500, ramp speed scale factor from 0.83 to 1 over speeds [0, 30]
    if (isSingleMotor) {
        const delta = (1.0 - 0.83) / 13.41;
        if (spin < 1500) {
            newspeed = speed;
        }
        if (speed > 13.41) {
            newspeed = speed;
        }
        else {
            newspeed = speed * (0.83 + delta * speed);
        }
    }
    return newspeed;
}
// Atom shot spin adjustment based on spin and speed
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function spinAdjustAtom(speedspin) {
    (0, assert_1.assert)(speedspin.spin !== null, "spinAdjustAtom: speedspin 'spin' value must be non-null");
    // no spin: nothing to do
    if (speedspin.spin === null || speedspin.spin === 0) {
        return 0;
    }
    const { spin, speed } = speedspin;
    // we model ONLY "on-motor" spins
    const isSingleMotor = isOneMotorAtom(speedspin.spinAxis);
    const isDualMotor = isTwoMotorAtom(speedspin.spinAxis);
    if (!isSingleMotor && !isDualMotor) {
        return spin;
    }
    let newspin = spin;
    // DUAL MOTOR
    // ramp spin scale factor from 1 to 0.689 over spins [0, 1500]
    if (isDualMotor) {
        const delta = -1.0 * (0.311 / 1500);
        if (spin > 1500) {
            newspin = spin * 0.689;
        }
        else {
            newspin = spin * (1.0 + delta * spin);
        }
    }
    // SINGLE MOTOR
    // if spin is > 1500 RPM: ramp spin scale factor from 1 to 0.83 over [30, 45] MPH
    if (isSingleMotor) {
        const delta = (0.83 - 1.0) / (20.11 - 13.41);
        if (spin < 1500) {
            newspin = spin;
        }
        if (speed < 13.41) {
            newspin = spin;
        }
        else if (speed > 20.11) {
            newspin = spin * 0.83;
        }
        else {
            newspin = spin * (1 + (speed - 13.41) * delta);
        }
    }
    return newspin;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function speedAdjustAtomInverse(speedspin) {
    (0, assert_1.assert)(speedspin.spin !== null, "speedAdjustAtom: speedspin 'spin' value must be non-null");
    // no spin: nothing to do
    if (speedspin.spin === null || speedspin.spin === 0) {
        return speedspin.speed;
    }
    const { speed, spin } = speedspin;
    // we model ONLY "on-motor" spins
    const isSingleMotor = isOneMotorAtom(speedspin.spinAxis);
    const isDualMotor = isTwoMotorAtom(speedspin.spinAxis);
    if (!isSingleMotor && !isDualMotor) {
        return speed;
    }
    let newspeed = speed;
    // DUAL MOTOR
    if (isDualMotor) {
        const delta = (1.11 - 1.0) / 1500;
        if (spin > 1500) {
            newspeed = speed / 1.11;
        }
        else {
            newspeed = speed / (1.0 + delta * spin);
        }
    }
    // SINGLE MOTOR
    if (isSingleMotor) {
        const delta = (1.0 - 0.83) / 13.41;
        if (spin < 1500) {
            newspeed = speed;
        }
        if (speed > 13.41) {
            newspeed = speed;
        }
        else {
            newspeed = (-0.83 + Math.sqrt((0.83 * 0.83) + 4 * delta * speed)) / (2 * delta);
        }
    }
    return newspeed;
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
function spinAdjustAtomInverse(speedspin) {
    (0, assert_1.assert)(speedspin.spin !== null, "spinAdjustAtom: speedspin 'spin' value must be non-null");
    // no spin: nothing to do
    if (speedspin.spin === null || speedspin.spin === 0) {
        return 0;
    }
    const { spin, speed } = speedspin;
    // we model ONLY "on-motor" spins
    const isSingleMotor = isOneMotorAtom(speedspin.spinAxis);
    const isDualMotor = isTwoMotorAtom(speedspin.spinAxis);
    if (!isSingleMotor && !isDualMotor) {
        return spin;
    }
    let newspin = spin;
    // DUAL MOTOR
    if (isDualMotor) {
        const delta = -1 * (0.311 / 1500);
        if (spin > 1500 * 0.689) {
            newspin = spin * (1.0 / 0.689);
        }
        else {
            newspin = (-1.0 + Math.sqrt(1 + 4 * delta * spin)) / (2 * delta);
        }
    }
    // SINGLE MOTOR
    if (isSingleMotor) {
        const delta = (0.83 - 1.0) / (20.11 - 13.41);
        if (speed < 13.41) {
            newspin = spin;
        }
        else {
            let oldspin = 0;
            if (speed > 20.11) {
                oldspin = spin * (1.0 / 0.83);
            }
            else {
                oldspin = spin / (1 + (speed - 13.41) * delta);
            }
            // adjust was only applied to spins > 1500
            if (oldspin < 1500) {
                newspin = spin;
            }
            else {
                newspin = oldspin;
            }
        }
    }
    return newspin;
}
const spinSpeedAdjustAtom = {
    spinDampingFactor: spinDampingFactorAtom,
    speedDampingFactor: speedDampingFactorAtom,
    deflectionCorrection: null,
};
const spinSpeedAdjustNone = {
    spinDampingFactor: null,
    speedDampingFactor: null,
    deflectionCorrection: null,
};
const SPINSPEED_ADJUST_ATOM_20ABCDEF = {
    PLATFORM_TENNIS: spinSpeedAdjustAtom,
    PADEL: spinSpeedAdjustNone,
    TENNIS: spinSpeedAdjustNone,
    PICKLEBALL: spinSpeedAdjustNone,
};
const SPINSPEED_ADJUST_ATOM_30A = {
    PLATFORM_TENNIS: spinSpeedAdjustNone,
    PADEL: spinSpeedAdjustNone,
    TENNIS: spinSpeedAdjustNone,
    PICKLEBALL: spinSpeedAdjustNone,
};
exports.tableSpinAdjustMap = {
    "physics-20B-base": SPINSPEED_ADJUST_ATOM_20ABCDEF,
    "physics-20C-base": SPINSPEED_ADJUST_ATOM_20ABCDEF,
    "physics-20D-base": SPINSPEED_ADJUST_ATOM_20ABCDEF,
    "physics-20E-base": SPINSPEED_ADJUST_ATOM_20ABCDEF,
    "physics-20F-base": SPINSPEED_ADJUST_ATOM_20ABCDEF,
    "physics-30A-base": SPINSPEED_ADJUST_ATOM_30A,
};
