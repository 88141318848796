import { BufferAttribute, Vector2, Color } from "three";
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial";

import padelModelPath from "../../../static/models/courts/padel-court-transformed.glb";
import pickleballModelPath from "../../../static/models/courts/pickleball-court-transformed.glb";
import platformModelPath from "../../../static/models/courts/platform-court-transformed.glb";
import tennisModelPath from "../../../static/models/courts/tennis-court-transformed.glb";
import playerModelPath from "../../../static/models/player/figure-with-racquet-transformed.glb";
import padelTonedBlueCourtImage from "../../../static/models/textures/padel-blue-twotone.jpg";
import padelBlueCourtImage from "../../../static/models/textures/padel-blue.jpg";
import padelGreenCourtImage from "../../../static/models/textures/padel-green.jpg";
import pickleballCourtImage from "../../../static/models/textures/pickeball-court.jpg";
import platformCourtImage from "../../../static/models/textures/platform-court.jpg";
import reserveBannerPath from "../../../static/models/textures/reserve-banner.png";
import tennisCourtImage from "../../../static/models/textures/tennis-court.jpg";
import armModelPath from "../../../static/models/trainer/arm-transformed.glb";
import baseModelPath from "../../../static/models/trainer/base-transformed.glb";
import headModelPath from "../../../static/models/trainer/head-transformed.glb";
import throwerModelPath from "../../../static/models/trainer/thrower-transformed.glb";
import type { Sport } from "../context/sport";

export const reserveBanner = reserveBannerPath as string;

// After transforming glb models wih gltfjsx the surface UV mappings are might be skewed
// So we will set the `uv` mapping attribute of the important geometries on our owm
const padelSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
    2,
);
const platformSurfaceUVMapping = new BufferAttribute(
    new Float32Array([0, 1, 0, 0, 1, 1, 1, 0]),
    2,
);
const tennisSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
    2,
);
const pickleballSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 0, 0, 0, 1, 1, 0, 1]),
    2,
);
// Court 3D models by the sport
export const CourtSurfaceMappings: Record<Sport, BufferAttribute> = {
    PADEL: padelSurfaceUVMapping,
    PLATFORM_TENNIS: platformSurfaceUVMapping,
    TENNIS: tennisSurfaceUVMapping,
    PICKLEBALL: pickleballSurfaceUVMapping,
};

export const BannerSurfaceUVMapping = new BufferAttribute(
    new Float32Array([1, 1, 1, 0, 0, 1, 0, 0]),
    2,
);
//                                                                   up    [1, 0, 0, 0, 1, 1, 0, 1]
//                                                                   left  [0, 1, 0, 0, 1, 1, 1, 0]
//                                                                   down  [0, 1, 1, 1, 0, 0, 1, 0]
//                                                                   right [1, 1, 1, 0, 0, 1, 0, 0]

// Buffer geometry and default material for all of the shots tracks
export const lineMaterial = new LineMaterial({
    color: 0xff5733,
    linewidth: 0.1, // in world units with size attenuation, pixels otherwise
    worldUnits: true,
    vertexColors: false,
    transparent: true,
    opacity: 0.9,
    resolution: new Vector2(256, 256),
    alphaToCoverage: false,
});

// Court surface textures
export enum TextureColor {
    DEFAULT = "DEFAULT",
    GREEN = "GREEN",
    TONEDBLUE = "TONEDBLUE",
}
type AtLeastOne<T, K extends keyof T> = Partial<T> & Pick<T, K>;
export const CourtTextures: Record<
    Sport,
    AtLeastOne<Record<TextureColor, string>, TextureColor.DEFAULT>
> = {
    PADEL: {
        DEFAULT: padelBlueCourtImage as string,
        GREEN: padelGreenCourtImage as string,
        TONEDBLUE: padelTonedBlueCourtImage as string,
    },
    PLATFORM_TENNIS: { DEFAULT: platformCourtImage as string },
    TENNIS: { DEFAULT: tennisCourtImage as string },
    PICKLEBALL: { DEFAULT: pickleballCourtImage as string },
};

// Court 3D models by the sport
export const Court3DModels: Record<Sport, string> = {
    PADEL: padelModelPath as string,
    PLATFORM_TENNIS: platformModelPath as string,
    TENNIS: tennisModelPath as string,
    PICKLEBALL: pickleballModelPath as string,
};

// Player 3D model
export const player3DModel = playerModelPath as string;

// Trainer 3D model parts
export type TrainerElements = "BASE" | "ARM" | "HEAD" | "THROWER";
export const TrainerModelParts: Record<TrainerElements, string> = {
    BASE: baseModelPath as string,
    ARM: armModelPath as string,
    HEAD: headModelPath as string,
    THROWER: throwerModelPath as string,
};

export type TrainerRenderType = "GHOST" | "FULL" | "GRAY";
export const TrainerColors: Record<TrainerRenderType, THREE.Color> = {
    FULL: new Color("#010101"),
    GHOST: new Color("#FFFFFF"),
    GRAY: new Color("#3CE97C"),
};
