import * as React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SaveIcon from "@mui/icons-material/Save";
import Button from "@mui/material/Button";

import SingleShotSaveAsDialog from "./SingleShotSaveAsDialog";

interface Props {
    defaultName: string;
    disabled?: boolean;
    title: "New" | "Save" | "Duplicate" | "Save As";
    onConfirm: (name?: string, description?: string) => Promise<void>;
}

export default function SingleShotDuplicateButton({
    defaultName,
    disabled = false,
    title,
    onConfirm,
}: Props): JSX.Element {
    const [open, setOpen] = React.useState(false);

    return (
        <>
            <Button
                disabled={disabled}
                size="small"
                startIcon={
                    title === "Duplicate" ? <ContentCopyIcon /> : <SaveIcon />
                }
                onClick={() => setOpen(true)}
            >
                {title}
            </Button>
            <SingleShotSaveAsDialog
                defaultName={defaultName}
                open={open}
                title={title}
                onClose={() => setOpen(false)}
                onConfirm={onConfirm}
            />
        </>
    );
}
