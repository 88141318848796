import * as React from "react";

import logger from "../../log";
import { useSelectedSport } from "../common/context/sport";

import { useStatus } from "./status";

export const PHYSICS_MODEL_NAME_PREFIX = "physics-";
export const PHYSICS_MODEL_DEFAULT = "20B-base";
export const PICKLEBALL_PHYSICS_MODEL_DEFAULT = "30A-base";
export interface PhysicsModelContext {
    physicsModelName: string;
}

export const PHYSICS_MODEL_CONTEXT_DEFAULT: PhysicsModelContext = {
    physicsModelName: PHYSICS_MODEL_NAME_PREFIX + PHYSICS_MODEL_DEFAULT,
};

export const physicsModelContext = React.createContext<PhysicsModelContext>(
    PHYSICS_MODEL_CONTEXT_DEFAULT,
);

export const PhysicsModelProvider = React.memo(
    ({ children }: React.PropsWithChildren) => {
        const { selected: selectedSport } = useSelectedSport();
        const baseModel = React.useMemo(
            () =>
                selectedSport === "PICKLEBALL"
                    ? PICKLEBALL_PHYSICS_MODEL_DEFAULT
                    : PHYSICS_MODEL_DEFAULT,
            [selectedSport],
        );
        const [physicsModel, setPhysicsModel] = React.useState({
            physicsModelName: `${PHYSICS_MODEL_NAME_PREFIX}${baseModel}`,
        });

        React.useEffect(() => {
            setPhysicsModel({
                physicsModelName: `${PHYSICS_MODEL_NAME_PREFIX}${baseModel}`,
            });
        }, [baseModel]);

        const { status } = useStatus();

        React.useEffect(() => {
            if (
                status?.physics &&
                status.physics.modelName !== physicsModel.physicsModelName
            ) {
                logger.info(
                    `Setting physics model to ${status.physics.modelName} from Coach Status`,
                );
                setPhysicsModel({
                    physicsModelName: status.physics.modelName,
                });
            }
        }, [status?.physics, physicsModel.physicsModelName]);

        return (
            <physicsModelContext.Provider value={physicsModel}>
                {children}
            </physicsModelContext.Provider>
        );
    },
);

PhysicsModelProvider.displayName = "PhysicsModelProvider";

export function usePhysicsModelContext(): PhysicsModelContext {
    return React.useContext(physicsModelContext);
}
