import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import logger from "../../../../log";
import { CoordWithSys, PositionLike } from "../../../../util/position-types";
import {
    degToRad,
    physicsToCourt,
    radToDeg,
} from "../../../../util/positionUtil";
import { Sport, useSelectedSport } from "../../../common/context/sport";
import TrainerCourtPosition from "../../Position/TrainerCourtPosition";
import AccordionSlider from "../Shared/AccordionSlider";

interface TouchOnlyPositionAccordionProps
    extends Omit<AccordionProps, "children"> {
    selectedPosition: PositionLike | undefined;
    onPositionChanged: (value: PositionLike) => void;
}

type HelpTextType = "manual" | "confirm" | "confirmed";

const HelpText: Record<HelpTextType, string> = {
    manual: "Select the marker where you'd like to position the trainer. Point the trainer directly forward.",
    confirm:
        '"Confirm Position" will set the trainer\'s location for this workout',
    confirmed: "Position has been set",
};

interface PositionState {
    confirmDisabled: boolean;
    helpText: string;
    userSelectedPosition: PositionLike | undefined;
}

type CoordWithSysAndYaw = CoordWithSys & { yaw: number };

type PositionAction =
    | { type: "confirm" }
    | {
          type: "set-manual";
          value: { coord: CoordWithSysAndYaw | undefined; sport: Sport };
      }
    | { type: "set-saved"; value: { coord: PositionLike; sport: Sport } };

const defaultPositionState: PositionState = {
    confirmDisabled: true,
    helpText: HelpText.manual,
    userSelectedPosition: undefined,
};

function queueManualPosition(
    state: PositionState,
    newPosition: CoordWithSysAndYaw | undefined,
    sport: Sport,
): PositionState {
    let updated: PositionLike | undefined;
    if (newPosition) {
        const { sys, yaw } = newPosition;

        let { x, y } = newPosition;
        if (sys === "physics" && sport === "PLATFORM_TENNIS") {
            const court = physicsToCourt(newPosition);
            x = court.x;
            y = court.y;
        }
        updated = { x, y, yaw };
    }

    logger.info(`new manual position: ${JSON.stringify(updated)}`);

    return {
        ...state,
        helpText: HelpText.confirm,
        confirmDisabled: updated === undefined,
        userSelectedPosition: updated,
    };
}

function initializeWithSaved(
    state: PositionState,
    position: PositionLike,
): PositionState {
    return {
        ...state,
        confirmDisabled: true,
        helpText: HelpText.confirmed,
        userSelectedPosition: position,
    };
}

function positionStateReducer(
    state: PositionState,
    action: PositionAction,
): PositionState {
    switch (action.type) {
        case "set-manual":
            return queueManualPosition(
                state,
                action.value.coord,
                action.value.sport,
            );
        case "set-saved":
            return initializeWithSaved(state, action.value.coord);
        default:
            throw new Error(
                "Invalid state change action for position accordion",
            );
    }
}

export default function TouchOnlyPositionAccordion({
    selectedPosition,
    onPositionChanged,
    ...props
}: TouchOnlyPositionAccordionProps): JSX.Element {
    const sliderMarks = React.useMemo(() => {
        const marks: { label: string; value: number }[] = [];
        for (let i = -75; i <= 75; i += 25) {
            marks.push({
                label: i === 0 ? "Forward" : `${i}°`,
                value: i,
            });
        }
        return marks;
    }, []);

    const { manualPositions, selected: selectedSport } = useSelectedSport();
    const [positionState, dispatch] = React.useReducer(
        positionStateReducer,
        defaultPositionState,
    );

    React.useEffect(() => {
        if (selectedPosition) {
            dispatch({
                type: "set-saved",
                value: {
                    coord: { ...selectedPosition },
                    sport: selectedSport,
                },
            });
        }
    }, [selectedPosition, selectedSport]);

    const showSlider = React.useMemo(
        () =>
            positionState.userSelectedPosition &&
            selectedSport === "PICKLEBALL",
        [positionState.userSelectedPosition, selectedSport],
    );

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {props.expanded && (
                    <Typography color="primary.main" variant="h3">
                        Trainer Position
                    </Typography>
                )}
                {!props.expanded && (
                    <Box component="div">
                        <Typography color="primary.main" variant="h4">
                            Trainer Position
                        </Typography>
                    </Box>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={2}>
                    <Typography variant="body1">
                        {positionState.helpText}
                    </Typography>

                    <Stack>
                        <TrainerCourtPosition
                            interactive
                            mode="manual"
                            points={manualPositions}
                            selectedPosition={
                                positionState.userSelectedPosition ??
                                selectedPosition
                            }
                            scale={selectedSport === "PICKLEBALL" ? 0.6 : 0.8}
                            size="HALF"
                            onPointSelected={(selected) => {
                                const coord: CoordWithSysAndYaw | undefined =
                                    selected?.at(0)
                                        ? { ...selected[0], yaw: 0 }
                                        : undefined;
                                const value = {
                                    coord,
                                    sport: selectedSport,
                                };
                                dispatch({ type: "set-manual", value });
                            }}
                        />
                        {showSlider && (
                            <AccordionSlider
                                min={-75}
                                max={75}
                                value={radToDeg(
                                    positionState.userSelectedPosition?.yaw ??
                                        0,
                                )}
                                track={false}
                                marks={sliderMarks}
                                step={1}
                                onChange={(_, val) => {
                                    if (positionState.userSelectedPosition) {
                                        const updated: CoordWithSysAndYaw = {
                                            ...positionState.userSelectedPosition,
                                            yaw: degToRad(val as number),
                                            sys: "physics",
                                        };
                                        const value = {
                                            coord: updated,
                                            sport: selectedSport,
                                        };
                                        dispatch({ type: "set-manual", value });
                                    }
                                }}
                            />
                        )}
                    </Stack>
                    <Button
                        disabled={positionState.confirmDisabled}
                        color="secondary"
                        fullWidth
                        variant="contained"
                        onClick={() => {
                            logger.info(
                                `Confirming: ${JSON.stringify(positionState.userSelectedPosition)}`,
                            );
                            onPositionChanged(
                                positionState.userSelectedPosition as PositionLike,
                            );
                        }}
                    >
                        Confirm Position
                    </Button>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
