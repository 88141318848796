import * as React from "react";
import {
    DragDropContext,
    Draggable,
    DropResult,
    Droppable,
} from "react-beautiful-dnd";

import { Theme, useTheme } from "@mui/material/styles";

interface Identifiable {
    id: string;
}

interface Props<T extends Identifiable> {
    items: T[];
    setItems: (items: T[]) => void;
    jsxElements: JSX.Element[];
}

function reorder<T>(list: T[], startIndex: number, endIndex: number): T[] {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
}

const getListStyle = (isDraggingOver: boolean, theme: Theme) => ({
    background: isDraggingOver ? theme.palette.primary.main : "lightgrey",
});

export default function VerticalDragDrop<T extends Identifiable>({
    items,
    setItems,
    jsxElements,
}: Props<T>): JSX.Element {
    const theme = useTheme();

    const onDragEnd = (result: DropResult) => {
        // dropped outside the list
        if (!result.destination) {
            return;
        }

        setItems(reorder(items, result.source.index, result.destination.index));
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver, theme)}
                    >
                        {items.map((item, index) => (
                            <Draggable
                                key={item.id}
                                draggableId={item.id}
                                index={index}
                            >
                                {(draggableProvided, draggableSnapshot) => (
                                    <div
                                        {...draggableProvided.draggableProps}
                                        {...draggableProvided.dragHandleProps}
                                        ref={draggableProvided.innerRef}
                                        style={{
                                            userSelect: "none",
                                            background:
                                                draggableSnapshot.isDragging
                                                    ? "#d3d3d3"
                                                    : "white",
                                            padding: 0,
                                            margin: 0,
                                            ...draggableProvided.draggableProps
                                                .style,
                                        }}
                                    >
                                        {index < jsxElements.length
                                            ? jsxElements[index]
                                            : null}
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
}
