import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

import logger from "../../../log";

import { OverlayProps } from "./Overlay";
import WorkoutVisualizer, {
    WorkoutVisualizerProps,
    fallbackRender,
} from "./WorkoutVisualizer";

export type ResizableContainerProps = Omit<
    WorkoutVisualizerProps,
    "width" | "height" | "onLoad"
> & { overlay?: React.ReactElement<OverlayProps>; maxHeight?: number };

export default function ResizableWorkoutVisualizer({
    workout,
    positionProximity,
    overlay,
    maxHeight,
    renderCameraControls,
    animationConfig,
    sport,
    physicsModel,
    improveMode,
}: ResizableContainerProps): JSX.Element {
    const [width, setWidth] = React.useState(200);
    const [height, setHeight] = React.useState(200);
    const [loading, setLoading] = React.useState(true);
    const [showCanvas, setShowCanvas] = React.useState(false);
    const visualizerContainerRef = React.useRef<HTMLDivElement>(null);

    const updateDimensions = React.useCallback(() => {
        if (visualizerContainerRef.current) {
            const size = visualizerContainerRef.current.clientWidth;
            const heightOverride = maxHeight ? Math.min(maxHeight, size) : size;
            setWidth(size);
            setHeight(heightOverride);
        }
    }, [maxHeight]);

    React.useEffect(() => {
        if (workout) {
            const timeout = setTimeout(() => {
                if (workout !== undefined) {
                    setShowCanvas(true);
                }
            }, 400);

            return () => clearTimeout(timeout);
        }

        setShowCanvas(false);
        return () => {};
    }, [workout]);

    React.useEffect(() => {
        // This call handles Skeleton with & height adjustment while WebGlRenderer is being initialized
        updateDimensions();
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, [updateDimensions]);

    return (
        <Box
            component="div"
            sx={{
                position: "relative",
                minHeight: height,
            }}
            ref={visualizerContainerRef}
        >
            <ErrorBoundary
                fallbackRender={fallbackRender}
                onError={(e) => logger.error(e.message)}
            >
                {(loading || workout === undefined) && (
                    <Skeleton
                        sx={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: (t) => t.zIndex.drawer - 1,
                        }}
                        animation="wave"
                        width={width}
                        height={height}
                        variant="rectangular"
                    />
                )}
                {showCanvas && workout && (
                    <WorkoutVisualizer
                        workout={workout}
                        positionProximity={positionProximity}
                        improveMode={improveMode}
                        onLoad={() => {
                            setLoading(false);
                        }}
                        width={width}
                        height={height}
                        renderCameraControls={renderCameraControls}
                        animationConfig={animationConfig}
                        sport={sport}
                        physicsModel={physicsModel}
                    />
                )}
                {overlay && overlay}
            </ErrorBoundary>
        </Box>
    );
}
