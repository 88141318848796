import * as React from "react";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Button, { ButtonProps } from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";

export interface DropdownButtonPartialProps {
    labelPrefix: string;
    selectedIndex: number;
    options: string[];
    onChanged: (selectedIndex: number) => void;
    onSubmit: () => void;
}

export type DropdownButtonProps = DropdownButtonPartialProps & ButtonProps;

export default function DropdownButton({
    labelPrefix,
    selectedIndex,
    options,
    onChanged,
    onSubmit,
    ...buttonProps
}: DropdownButtonProps): JSX.Element {
    const anchorRef = React.useRef<HTMLDivElement>(null);
    const [menuOpen, setMenuOpen] = React.useState(false);

    if (options.length === 1) {
        return (
            <Button
                onClick={() => onSubmit()}
                color="secondary"
                variant="contained"
                fullWidth
                {...buttonProps}
            >
                {`${labelPrefix} ${options[0]}`}
            </Button>
        );
    }

    return (
        <>
            <ButtonGroup
                variant="contained"
                ref={anchorRef}
                fullWidth
                sx={{
                    marginTop: "15px",
                }}
            >
                <Button onClick={() => onSubmit()} color="secondary">
                    {`${labelPrefix} ${options[selectedIndex]}`}
                </Button>
                <Button
                    size="small"
                    color="secondary"
                    onClick={() => setMenuOpen(!menuOpen)}
                    sx={{
                        maxWidth: "20px",
                    }}
                >
                    <ArrowDropDownIcon />
                </Button>
            </ButtonGroup>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={menuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                                placement === "bottom"
                                    ? "center top"
                                    : "center bottom",
                        }}
                    >
                        <Paper>
                            <ClickAwayListener
                                onClickAway={() => setMenuOpen(false)}
                            >
                                <MenuList autoFocusItem>
                                    {options.map((option, index) => (
                                        <MenuItem
                                            key={option}
                                            selected={index === selectedIndex}
                                            onClick={() => {
                                                setMenuOpen(false);
                                                onChanged(index);
                                            }}
                                        >
                                            {`${labelPrefix} ${option}`}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
}
