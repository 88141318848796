import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AccordionSlider from "./AccordionSlider";

export interface ThrowIntervalProps {
    disabled: boolean;
    selectedThrowInterval: number;
    onUserThrowIntervalChanged: (updated: number) => void;
}

export default function ThrowInterval({
    disabled,
    selectedThrowInterval,
    onUserThrowIntervalChanged,
}: ThrowIntervalProps): JSX.Element {
    function valueLabelFormat(value: number) {
        const inv = 1.0 / 0.1;
        const scaledValue = Math.round(value * inv) / inv;

        return `${scaledValue} sec`;
    }

    function calculateValue(value: number) {
        return 2 ** value;
    }

    return (
        <Stack direction="row" spacing={0.5} alignItems="center">
            <Typography
                variant="caption"
                sx={{
                    minWidth: "30px",
                }}
            >
                Rate
            </Typography>
            <Box
                component="div"
                sx={{
                    flexGrow: 1,
                }}
            >
                <AccordionSlider
                    disabled={disabled}
                    min={0}
                    max={3}
                    marks={[
                        { value: Math.log2(1), label: "1 sec." },
                        { value: Math.log2(2), label: "2 sec." },
                        { value: Math.log2(4), label: "4 sec." },
                        { value: Math.log2(8), label: "8 sec." },
                    ]}
                    scale={(v) => calculateValue(v)}
                    getAriaValueText={(v) => valueLabelFormat(v)}
                    valueLabelFormat={(v) => valueLabelFormat(v)}
                    valueLabelDisplay="auto"
                    step={0.1}
                    value={Math.log2(selectedThrowInterval)}
                    track={false}
                    onChange={(_, v) => {
                        const inv = 1.0 / 0.25;
                        const scaledValue =
                            Math.round(2 ** (v as number) * inv) / inv;
                        onUserThrowIntervalChanged(scaledValue);
                    }}
                    sx={{
                        display: "table",
                        margin: "0px auto 20px auto",
                        maxWidth: "80%",
                        ".MuiSlider-track": {
                            color: "primary.light",
                        },
                        "& .MuiSlider-valueLabel": {
                            fontSize: "32px",
                        },
                    }}
                />
            </Box>
        </Stack>
    );
}
