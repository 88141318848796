import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import { OffsetResult } from "@volley/data";
import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutListItem,
    ContentProvider,
} from "@volley/data";
import type { AppParameters } from "@volley/shared/app-models";
import type {
    ResponsiveState,
    ResponsiveParameters,
    DifficultyLevel,
    ResponsiveIndexEntry,
} from "@volley/shared/apps/responsive-models";
import { JSONObject } from "@volley/shared/common-models";

import logger from "../../../log";
import fetchApi, { logFetchError } from "../../../util/fetchApi";
import SportPicker from "../../SportPicker";
import NotchedOutline from "../../common/NotchedOutline";
import { useSelectedSport } from "../../common/context/sport";
import { usePhysicsModelContext } from "../../hooks/PhysicsModelProvider";
import { useStatus } from "../../hooks/status";
import usePosition from "../../hooks/usePosition";
import SlimNumberInput from "../util/SlimNumberInput";

import AccordionSlider from "./Shared/AccordionSlider";
import CaptureToast from "./Shared/CaptureToast";
import PlayAppBar from "./Shared/PlayAppBar";
import WorkoutPicker from "./apps/responsive/WorkoutPicker";
import useAppWorkouts from "./db";
import useAppWorkoutPlay from "./useAppWorkoutPlay";

type ParamsAction =
    | { type: "shots"; value: number }
    | { type: "mode"; value: "follow" | "probability" }
    | { type: "difficulty"; value: DifficultyLevel };

function paramsReducer(
    state: ResponsiveParameters,
    action: ParamsAction,
): ResponsiveParameters {
    switch (action.type) {
        case "shots":
            return { ...state, numberOfBalls: action.value };
        case "difficulty":
            return { ...state, difficulty: action.value };
        case "mode":
            return { ...state, mode: action.value, difficulty: "easy" };
        default:
            throw new Error(
                "Invalid action to perform on a Responsive Parameters set.",
            );
    }
}

const defaultParameters: ResponsiveParameters = {
    numberOfBalls: 60,
    initialDelay: 5,
    difficulty: "easy",
    mode: "probability",
};

type KnownBallCount = "1" | "15" | "30" | "45" | "All";
const KnownBallCounts: Record<KnownBallCount, number> = {
    1: 1,
    15: 15,
    30: 30,
    45: 45,
    All: 60,
};

const ballCountMarks = Object.keys(KnownBallCounts).map((k) => ({
    label: k,
    value: KnownBallCounts[k as KnownBallCount],
}));

export default function ResponsivePlayApp(): JSX.Element {
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        defaultParameters,
    );
    const [loading, setLoading] = React.useState(false);
    const parameters = paramsState as unknown as AppParameters;
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const { physicsModelName } = usePhysicsModelContext();
    const { selected: selectedSport } = useSelectedSport();
    const modifiedRef = React.useRef(true);
    const { isVisionStarting } = usePosition();
    const { status } = useStatus();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const [availableWorkouts, setAvailableWorkouts] = React.useState<
        AppWorkoutListItem[] | null
    >(null);

    const {
        addWorkout,
        loadResponsiveWorkout,
        loadResponsiveWorkouts,
        loadResponsiveWorkoutList,
        loadResponsiveWorkoutApp,
    } = useAppWorkouts();

    const {
        start,
        pause,
        captureDisabled,
        requestError,
        playInitiated,
        playDisabled,
        pauseDisabled,
        playState,
        statusText,
        workoutState,
        captureVideo,
        captureStatus,
    } = useAppWorkoutPlay({
        workout,
        parameters,
    });

    async function fetchAndMigrateWorkouts() {
        logger.info(
            "[responsive play] Fetching responsive workout list format",
        );
        const workoutList = await loadResponsiveWorkoutList();
        if (workoutList.length === 0) {
            logger.info(
                "[responsive play] No published workouts found, attempting to load from old format",
            );
            const oldWorkouts = await loadResponsiveWorkouts();
            if (oldWorkouts.length === 0) {
                logger.info(
                    "[responsive play] No old workouts found, nothing to migrate",
                );
                return [];
            }
            logger.info(
                "[responsive play] Found old workouts, fetching VOLLEY provider info",
            );
            const providers = await fetchApi<OffsetResult<ContentProvider>>(
                "/api/content-providers?limit=100&offset=0",
            );
            const volleyProvider = providers.result.find(
                (p) => p.name === "VOLLEY",
            );
            if (volleyProvider === undefined) {
                logger.error(
                    "[responsive play] VOLLEY provider not found, cannot migrate workouts",
                );
                return [];
            }

            async function migrateWorkout(indexEntry: ResponsiveIndexEntry) {
                const workout = await loadResponsiveWorkout(
                    indexEntry.fileSuffix,
                );
                if (workout !== null) {
                    const { trainer, ...config } = workout;
                    logger.info(
                        `[responsive play] Migrating workout ${indexEntry.displayName}`,
                    );
                    logger.info(
                        `[responsive play] Saving workout ${JSON.stringify(config)}`,
                    );
                    await addWorkout({
                        appId: 2,
                        appName: "ResponsivePlay",
                        config: config as unknown as JSONObject,
                        description: "Responsive Play Workout",
                        name: `${indexEntry.displayName}`,
                        overview: "",
                        positionHeight: trainer.positionHeight,
                        positionX: trainer.positionX,
                        positionY: trainer.positionY,
                        positionYaw: 0,
                        state: "BUILD",
                        physicsModelName,
                        sport: { name: selectedSport },
                        contentProviderId: volleyProvider?.id || null,
                        copiedFromAppWorkoutId: null,
                        extendedData: null,
                    });
                }
            }
            const promises = oldWorkouts.map(migrateWorkout);
            const migrationResults = await Promise.allSettled(promises);
            migrationResults.forEach((result, i) => {
                if (result.status === "rejected") {
                    logger.error(
                        `[responsive play] Migration ${oldWorkouts[i].displayName} failed: ${result.reason}`,
                    );
                } else {
                    logger.info(
                        `[responsive play] Migration ${oldWorkouts[i].displayName} successful`,
                    );
                }
            });
            const migratedResults = await loadResponsiveWorkoutList();
            logger.info(
                `[responsive play] Migrated ${migratedResults.length} workouts`,
            );
            return migratedResults;
        } else {
            logger.info(
                "[responsive play] Found responsive workout list - no migration required",
            );
            return workoutList;
        }
    }

    React.useEffect(() => {
        setLoading(true);
        fetchAndMigrateWorkouts()
            .then((results) => {
                setAvailableWorkouts(results);
                return results;
            })
            .catch((e) =>
                logFetchError(
                    e,
                    "Failed to fetch list of responsive workouts.",
                ),
            )
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setWorkout(null);
        setLoading(true);
        fetchAndMigrateWorkouts()
            .then((results) => setAvailableWorkouts(results))
            .catch((e) =>
                logFetchError(
                    e,
                    "Failed to fetch list of responsive workouts.",
                ),
            )
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSport]);

    const responsiveWorkoutState =
        workoutState as unknown as ResponsiveState | null;
    const ballsThrown = responsiveWorkoutState?.currentShotNumber ?? 0;
    const totalBalls =
        (parameters as unknown as ResponsiveParameters).numberOfBalls ?? 0;
    const summaryText = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const handlePlayClicked = React.useCallback(async () => {
        if (workout) {
            await start();
        }
    }, [workout, start]);

    return (
        <Stack
            sx={{
                pb: "90px",
            }}
        >
            {requestError && (
                <Typography color="error.main">{requestError}</Typography>
            )}
            {visionUnavailable && (
                <Typography color="info.main" variant="caption">
                    Play will be enabled when paired and cameras fully
                    initialized
                </Typography>
            )}
            <Accordion expanded disabled={playState === "playing"}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h4" color="primary.main">
                        {playInitiated
                            ? statusText
                            : "Responsive Play Parameters"}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Stack spacing={2}>
                        <NotchedOutline label="Sport Select">
                            <SportPicker
                                afterSportChanged={() => {
                                    modifiedRef.current = true;
                                }}
                            />
                        </NotchedOutline>
                        <NotchedOutline label="Workout">
                            <WorkoutPicker
                                fullWidth
                                loading={loading}
                                options={availableWorkouts ?? []}
                                selected={workout?.id ?? null}
                                onChange={(id) => {
                                    setLoading(true);
                                    loadResponsiveWorkoutApp(id)
                                        .then((result) => {
                                            setWorkout(result);
                                        })
                                        .catch((e) => {
                                            logFetchError(
                                                e,
                                                `Failed to load workout ${id}`,
                                            );
                                        })
                                        .finally(() => setLoading(false));
                                }}
                            />
                        </NotchedOutline>
                        <NotchedOutline label="Difficulty">
                            <ToggleButtonGroup
                                disabled={paramsState.mode === "follow"}
                                fullWidth
                                color="primary"
                                value={paramsState.difficulty}
                                exclusive
                                onChange={(_, v) => {
                                    if (v) {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "difficulty",
                                            value: v as DifficultyLevel,
                                        });
                                    }
                                }}
                            >
                                <ToggleButton value="easy">Easy</ToggleButton>
                                <ToggleButton value="medium">
                                    Medium
                                </ToggleButton>
                                <ToggleButton value="hard">
                                    Difficult
                                </ToggleButton>
                            </ToggleButtonGroup>
                        </NotchedOutline>
                        <NotchedOutline label="Number of Balls">
                            <Stack>
                                <SlimNumberInput
                                    onChange={(value) => {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "shots",
                                            value,
                                        });
                                    }}
                                    value={paramsState.numberOfBalls}
                                    incrementValue={1}
                                    maxValue={60}
                                    minValue={1}
                                    showDecimal={false}
                                    showInfinity
                                />
                                <AccordionSlider
                                    min={1}
                                    max={60}
                                    marks={ballCountMarks}
                                    value={paramsState.numberOfBalls}
                                    onChange={(_, v) => {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "shots",
                                            value: v as number,
                                        });
                                    }}
                                    onChangeCommitted={(_, v) => {
                                        modifiedRef.current = true;
                                        paramsDispatch({
                                            type: "shots",
                                            value: v as number,
                                        });
                                    }}
                                    sx={{
                                        display: "table",
                                        margin: "0px auto 20px auto",
                                        maxWidth: "80%",
                                    }}
                                />
                            </Stack>
                        </NotchedOutline>
                    </Stack>
                </AccordionDetails>
            </Accordion>

            <PlayAppBar
                onPauseClicked={() => pause()}
                onPlayClicked={() => handlePlayClicked()}
                pauseDisabled={pauseDisabled}
                playDisabled={
                    visionUnavailable || playDisabled || workout === null
                }
                playState={playState}
                showRecord
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />

            <CaptureToast captureStatus={captureStatus} />
        </Stack>
    );
}
