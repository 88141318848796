export enum CourtDirection {
    X,
    Y,
}

export enum CircleResult {
    None,
    Touching,
    Intersecting,
}

export interface Circle {
    centerX: number;
    centerY: number;
    radius: number;
}

export interface Coord {
    x: number;
    y: number;
}

export type CoordSys = "physics" | "court";

export interface CoordWithSys extends Coord {
    yaw?: number;
    yawDescription?: string;
    sys: CoordSys;
}

export interface PositionLike {
    x: number;
    y: number;
    yaw: number;
}

export type TrainerPositionLike = PositionLike & {
    heightIn: number;
};

export interface CoordLike {
    x: number;
    y: number;
}
