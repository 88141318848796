import { convert } from "@volley/physics";

import logger from "../../../../log";

export default function makeSafeSpinLevel(
    spinLevel: number,
    spinAxis: number,
    launchSpeed: number,
): number {
    logger.info(
        `[spin validator]: Calculating max spin level for angle ${spinAxis} at speed ${launchSpeed}`,
    );
    let maxLevel = 0;
    try {
        maxLevel = convert.maxspinlevel(spinAxis, launchSpeed);
    } catch (e) {
        logger.error(
            `Failed to calculate max spin level for angle ${spinAxis} at speed ${launchSpeed}`,
            undefined,
            e as Error,
        );
        return spinLevel;
    }

    logger.info(
        `[spin validator]: Max spin level for angle ${spinAxis} at speed ${launchSpeed} is ${maxLevel}`,
    );
    const clamped = Math.min(spinLevel, maxLevel);
    if (clamped < spinLevel) {
        logger.warn(
            `[spin validator]: Requested spin level: ${spinLevel} clamped to: ${clamped} for angle ${spinAxis} at speed ${launchSpeed}`,
        );
    } else {
        logger.info(
            `[spin validator]: Requested spin level: ${spinLevel} is valid for angle ${spinAxis} at speed ${launchSpeed}`,
        );
    }
    return clamped;
}
