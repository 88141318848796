import * as React from "react";
import { Link as RouterLink } from "react-router-dom";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";

interface Props {
    appId: number;
    duplicateId: number | null;
    setDuplicateId: React.Dispatch<React.SetStateAction<number | null>>;
}

export default function ModuleEditDuplicateAlert({
    appId,
    duplicateId,
    setDuplicateId,
}: Props): JSX.Element {
    return (
        <Snackbar
            open={duplicateId !== null}
            onClose={() => setDuplicateId(null)}
            autoHideDuration={5_000}
        >
            <Alert
                variant="filled"
                severity="info"
                action={
                    <IconButton
                        onClick={() => setDuplicateId(null)}
                        color="inherit"
                    >
                        <CloseIcon />
                    </IconButton>
                }
            >
                <AlertTitle>Module Duplicated</AlertTitle>
                <Stack direction="row" justifyContent="flex-end">
                    <Button
                        size="small"
                        color="inherit"
                        component={RouterLink}
                        to={`../edit/${appId}/${duplicateId}`}
                    >
                        View
                    </Button>
                </Stack>
            </Alert>
        </Snackbar>
    );
}
