import type {
    SingleShotConfig,
    SingleShotParameters,
    SingleShotShot,
} from "@volley/shared/apps/single-shot-models";
import type { JSONObject } from "@volley/shared/common-models";

interface LocalWorkoutState {
    appId: number;
    config?: JSONObject;
    height?: number;
    id?: number;
    params?: JSONObject;
}

interface LocalWorkoutStateManager {
    setFeed: (intervalOverride: number, numberOfBalls: number) => void;
    getFeed: () => { intervalOverride: number; numberOfBalls: number } | null;
    set: (workoutWithParams: LocalWorkoutState) => void;
    get: () => LocalWorkoutState | null;
    clear: () => void;
}

const VOLLEY_WORKOUT_STATE_KEY = "volley-workout-state";
const VOLLEY_SINGLE_SHOT_STATE_KEY = "volley-single-shot-state";

const LocalWorkouts: LocalWorkoutStateManager = {
    setFeed: (intervalOverride: number, numberOfBalls: number) => {
        const value = JSON.stringify({ intervalOverride, numberOfBalls });
        sessionStorage.setItem(VOLLEY_SINGLE_SHOT_STATE_KEY, value);
    },
    getFeed: () => {
        const objectMaybe = sessionStorage.getItem(
            VOLLEY_SINGLE_SHOT_STATE_KEY,
        );
        if (objectMaybe) {
            return JSON.parse(objectMaybe) as {
                intervalOverride: number;
                numberOfBalls: number;
            };
        }

        return null;
    },
    set: (workoutWithParams: LocalWorkoutState) => {
        const value = JSON.stringify(workoutWithParams);
        sessionStorage.setItem(VOLLEY_WORKOUT_STATE_KEY, value);
    },
    get: () => {
        const objectMaybe = sessionStorage.getItem(VOLLEY_WORKOUT_STATE_KEY);
        if (objectMaybe) {
            return JSON.parse(objectMaybe) as LocalWorkoutState;
        }

        return null;
    },
    clear: () => {
        if (sessionStorage.getItem(VOLLEY_WORKOUT_STATE_KEY)) {
            sessionStorage.removeItem(VOLLEY_WORKOUT_STATE_KEY);
        }
    },
};

export function updateLocalHeight(
    incoming: number,
    appId: number,
    id?: number,
) {
    const current = LocalWorkouts.get();

    let params = {};
    let config = {};
    if (current?.appId !== appId || current?.id !== id) {
        LocalWorkouts.clear();
    } else {
        params = current?.params as JSONObject;
        config = current?.config as JSONObject;
    }

    LocalWorkouts.set({
        id,
        appId,
        params,
        config,
        height: incoming,
    });
}

export function updateLocalConfig(
    incoming: Partial<SingleShotShot>,
    appId: number,
    id?: number,
) {
    const current = LocalWorkouts.get();

    const updated: JSONObject = {};
    let shot = incoming;
    if (current?.appId !== appId || current?.id !== id) {
        LocalWorkouts.clear();
    } else {
        updated.playerPosition = current?.config?.playerPosition as JSONObject;

        if ((current?.config as unknown as SingleShotConfig)?.shot) {
            shot = (current?.config as unknown as SingleShotConfig).shot;
        }

        shot = {
            ...shot,
            ...incoming,
        };
    }

    updated.shot = shot as unknown as JSONObject;

    LocalWorkouts.set({
        id,
        appId,
        params: current?.params,
        config: updated,
        height: current?.height,
    });
}

export function updateLocalParams(
    incoming: Partial<SingleShotParameters>,
    appId: number,
    id?: number,
) {
    const current = LocalWorkouts.get();

    let updated = {};
    if (current?.appId !== appId || current.id !== id) {
        LocalWorkouts.clear();
        updated = {
            ...incoming,
        };
    } else {
        updated = {
            ...current?.params,
            ...incoming,
        };
    }

    LocalWorkouts.set({
        id,
        appId,
        params: updated as JSONObject,
        config: current?.config,
        height: current?.height,
    });
}

export default LocalWorkouts;
