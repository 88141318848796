import * as React from "react";

import { useStatus } from "../../hooks/status";
import { useLift } from "../../hooks/useLift";
import LowerHeadDialog from "../LowerHeadDialog";
import RefillBallBinDialog from "../RefillBallBin";
import useDialog from "../useDialog";

const enum RefillBallBinStep {
    Refill,
    LowerHead,
    Done,
}

interface RefillBallBinFlowProps {
    manual?: boolean;
}
export default function RefillBallBinFlow({
    manual = false,
}: RefillBallBinFlowProps): JSX.Element | null {
    const { setDialogType } = useDialog();
    const { hasFault } = useStatus();
    const { height, refillHeight } = useLift();
    const lowEnough = React.useMemo(
        () => height <= refillHeight,
        [height, refillHeight],
    );
    const [step, setStep] = React.useState(RefillBallBinStep.Refill);

    // close the dialog if there's no more fault and this isn't a manual refil.
    // this can happen if the user pressed the hardware "resume" button on the trainer
    React.useEffect(() => {
        if (!hasFault && !manual) {
            setDialogType(null);
        }
    }, [hasFault, manual, setDialogType]);

    React.useEffect(() => {
        if (step === RefillBallBinStep.Done) {
            setDialogType(null);
        }
    }, [lowEnough, setDialogType, step]);

    if (step === RefillBallBinStep.Refill) {
        return (
            <RefillBallBinDialog
                onFilled={() => setStep(RefillBallBinStep.Done)}
                onLowerHead={() => setStep(RefillBallBinStep.LowerHead)}
                manual={manual}
            />
        );
    }

    if (step === RefillBallBinStep.LowerHead) {
        return (
            <LowerHeadDialog
                message="The trainer arm is moving to a more convenient height to refill the bin."
                reason="refill"
                onLowered={() => setStep(RefillBallBinStep.Done)}
                onStopped={() => setDialogType(null)}
            />
        );
    }

    return null;
}
