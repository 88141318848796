"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TrainerSim = void 0;
/* eslint-disable object-curly-newline */
/* eslint-disable operator-assignment */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable no-mixed-operators */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-console */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-param-reassign */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* _____________________________________________________________________________
  TrainerSim: Physics-based trainer simulation

  Functionality
    Path Prediction (forward physics)
        Launch and predict ball path (including bounces)
    Shot Generation (reverse physics)
        Target a spot and compute launch parameters

  Coordinate Systems and Units: see conversions.ts
  _____________________________________________________________________________
*/
const pm = __importStar(require("./models"));
const conversions_1 = require("./conversions");
const util_1 = require("./util");
const assert_1 = require("./assert");
/**
 * TrainerSim
 */
class TrainerSim {
    // _______________________________________________________
    // Constructor
    constructor(physicsModelName, sport) {
        // launch point (physics coords)
        this.m_launchPoint = TrainerSim.DefaultCourtPoint;
        (0, conversions_1.setPhysicsModel)(physicsModelName, sport);
        this.m_model = (0, conversions_1.getPhysicsModel)();
        this.m_position = {
            x: 0, y: 0, yaw: 0, h: this.m_model.trainerGeometry.liftOrigin.z,
        };
        this.m_armAngle = 0;
        this.m_localizedCameraPosition = {
            x: 0,
            y: 0,
            z: 0,
            roll: 0,
            yaw: 0,
            pitch: 90 - this.m_model.trainerGeometry.cameraDownwardPitch,
        };
        this.SetPositionManual(this.m_position);
    }
    /**
   * SetPositionManual
   * Manually set the trainer position. This function should be called ONLY when
   * the trainer position is being manually set/reset via user input due to localization
   * failure. If localization succeeds, trainer position should be set by calling
   * SetPositionLocalized().
   *
   * Input: Trainer Position in Physics Coords
   *    x: x court position of trainer origin in meters
   *    y: y court position of trainer origin in meters
   *    yaw: trainer yaw angle in degrees (0=facing forward, +=right)
   *    h: height of trainer head above the ground in meters (end of lift arm).
   *
   * Physics coordinates (meters): origin is center of court, +x=right, +y=forward, +z=up
   *
   * @param position Trainer position
   */
    SetPositionManual(position) {
        this.m_position = position;
        // compute localized camera position
        this.m_localizedCameraPosition = this._computeLocalizedCameraPosition(true);
        // compute launch point: with no launch pitch/yaw
        this.m_launchPoint = this._computeLaunchPoint(0, 0);
    }
    /**
   * SetPositionLocalized
   * Set the trainer position using a camera position obtained via vision
   * system localization. Call this function to position the trainer
   * if at all possible (use SetPositionManual() ONLY as a fallback if
   * localization fails).
   *
   * The input is a camera position in LOCALIZATION COORDS
   *    Coordinates: origin (0,0,0) is top left court corner (looking from above)
   *    Axes: left-handed, +x is right, +z is up, +y into court
   *    Units: Same as physics except using MILLIMETERS for x,y,z
   * Angles: Degrees
   *    yaw (rot about z): 0=looking "forward" (-y direction), +=right
   *    pitch (rot about x): 0=looking DOWN at ground (-z direction), +=up
   *    roll (rot about y): 0=x axis perp to z, +=right tilt (looking forward)
   * @param localizedCameraPosition camera position in localized coords
   */
    SetPositionLocalized(localizedCameraPosition) {
        // set the localized camera position
        this.m_localizedCameraPosition = localizedCameraPosition;
        // localized cam pos in mm
        const camPos_l = {
            x: this.m_localizedCameraPosition.x,
            y: this.m_localizedCameraPosition.y,
            z: this.m_localizedCameraPosition.z,
        };
        // get camera position in trainer coords
        const camPos_t = this._getCameraLocationInTrainerCoords(this.m_localizedCameraPosition.z);
        // get camera position in physics coords
        const camPos_p = (0, conversions_1.localization2physics)(camPos_l);
        // determine the origin of the trainer system in physics coords
        // we can do this because we have the camera position in 2 different coord systems (hence
        // we can compute the trainer origin as the offset between the systems).
        const rotationPhysics2Trainer = { x: 0, y: 0, z: -1.0 * this.m_localizedCameraPosition.yaw };
        const trainerOrigin = (0, util_1.coordOffset)(camPos_p, camPos_t, rotationPhysics2Trainer);
        // Set the trainer position
        this.m_position.x = trainerOrigin.x;
        this.m_position.y = trainerOrigin.y;
        this.m_position.yaw = this.m_localizedCameraPosition.yaw;
        // compute head height
        const { cameraOffsetFromHead } = this.m_model.trainerGeometry;
        this.m_position.h = (this.m_localizedCameraPosition.z / 1000.0) - cameraOffsetFromHead.z;
        // compute the launch point corresponding to the new position
        // with no launch pitch/yaw
        this.m_launchPoint = this._computeLaunchPoint(0, 0);
    }
    /**
   * GetPosition
   * Return the trainer position.
   */
    GetPosition() {
        return this.m_position;
    }
    /**
   * GetArmAngle
   * Return the trainer arm's angle in the arm coordinate system.
   * NOTE: The returned angle is in degrees.
   */
    GetArmAngle() {
        return this.m_armAngle;
    }
    /**
   * SetHeadHeight
   * Set the trainer head height (meters).
   * NOTE: This function enables moving the head up/down without re-localizing.
   *
   * Use: after setting trainer position via SetPositionLocalized() or SetPosition(),
   * call this function to set the head height as the physical head is moved up/down.
   * The simulator will adjust the localized camera position and launch point
   * accordingly. Finally, prior to workout start call GetLocalizedCameraPosition()
   * and send the resulting head-adjusted position to vision as the known position.
   *
   * @param height
   */
    SetHeadHeight(height) {
        this.m_position.h = height;
        // compute localized camera position
        // but do NOT change pitch and roll of camera
        this.m_localizedCameraPosition = this._computeLocalizedCameraPosition(false);
        // compute launch point with no launch pitch/yaw
        this.m_launchPoint = this._computeLaunchPoint(0, 0);
    }
    /**
   * GetLaunchPoint
   * Get the trainer launch point (physics coords).
   */
    GetLaunchPoint() {
        return this.m_launchPoint;
    }
    /**
   * SetLaunchPoint
   * Set the trainer launch point (physics coords), using specified
   * launch yaw and pitch.
   */
    SetLaunchPoint(yawDeg, pitchDeg) {
        (0, assert_1.assert)(Math.abs(yawDeg) <= 90 && Math.abs(pitchDeg) <= 90, `SetLaunchPoint: yaw and pitch (${yawDeg}, ${pitchDeg}) must be in [-90, 90]`);
        this.m_launchPoint = this._computeLaunchPoint(yawDeg, pitchDeg);
    }
    /**
   * GetHeadHeight
   * Return the trainer head height (meters).
   */
    GetHeadHeight() {
        return this.m_position.h;
    }
    /**
   * GetPhysicsModel
   * Return the trainer physics model
   */
    GetConfig() {
        return this.m_model;
    }
    /**
   * GetLocalizedCameraPosition
   * Get the localized camera position (localized coords)
   * @returns the localized camera position
   */
    GetLocalizedCameraPosition() {
        return this.m_localizedCameraPosition;
    }
    /**
   * Target: Target a point on the court with a planned shot.
   * @param plannedShot: the desired shot
   * @returns TargetResult: the result of the targeted shot
   */
    Target(plannedShot) {
        (0, assert_1.assert)((plannedShot.launchAngle !== null || plannedShot.peakHeight !== null), "planned shot must have exactly 1 of (launch angle, peak height)");
        (0, assert_1.assert)((plannedShot.launchAngle === null || plannedShot.peakHeight === null), "planned shot must have exactly 1 of (launch angle, peak height)");
        //
        // initialize target result
        // NOTE: targetResult launch point is 'forward' (no pitch/yaw)
        const targetResult = this._createTargetResult(plannedShot);
        if (!targetResult.isValid) {
            console.debug(`Target: invalid shot: ${targetResult.invalidMessage}`);
            return targetResult;
        }
        if (plannedShot.peakHeight !== null) {
            if (this.m_model.ballParams.drag !== 0 || plannedShot.spin > 0) {
                // Hard case, must solve iteratively
                // console.debug(`Target (peak height): drag=${this.m_model.ballParams.drag}, spin=${plannedShot.spin}), using iterative shot prediction`);
                this._targetIterativePeakHeight(targetResult);
            }
            else {
                // Easy case, use ideal closed-form solution
                // console.debug(`Target (peak height): drag and spin are zero, using ideal shot prediction`);
                this._targetIdealPeakHeight(targetResult);
            }
        }
        else {
            console.debug("Target (launch angle): using iterative shot prediction");
            this._targetIterativeAngle(targetResult);
        }
        return targetResult;
    }
    /**
   * Launch: Launch a simulated shot from the current trainer position
   * (i.e. "forward physics").
   * @param launchParams  Params representing direction and speed of launch
   * @returns Shot: The simulated shot that contains the ball's path (x,y,z) position
   *    in time, including bounces off walls and court.
   */
    Launch(launchParams) {
        // validate speed/rpm
        (0, assert_1.assert)((launchParams.rpms !== null || launchParams.speedSpin !== null), "Launch: one of (speedSpin, rpms) must be set");
        // validate finish criterion
        const { duration } = this.m_model.simParams;
        const maxBounces = this.m_model.simParams.bounces;
        (0, assert_1.assert)((duration !== null && duration >= 0) || (maxBounces !== null && maxBounces > 0), "Launch: one or both of (duration>=0, bounces>0) must be specified");
        // Physics uses speed and spin to drive the simulation,
        // so if we have only RPMs we need to convert.
        if (launchParams.speedSpin === null) {
            // console.debug(`Launch: converting RPMs to speed/spin`)
            launchParams.speedSpin = (0, conversions_1.rpm2speedspin)(launchParams.rpms);
        }
        // Fill in RPMs if they weren't given
        if (launchParams.rpms === null) {
            launchParams.rpms = (0, conversions_1.speedspin2rpm)(launchParams.speedSpin);
        }
        // init output: simulated shot
        const shot = {
            ballPath: [],
            bounces: [],
            range: 0,
            peakHeight: 0,
            landingPoint: { x: 0, y: 0, z: -1 },
            launchPoint: { x: 0, y: 0, z: 0 },
            launchSpeed: 0,
            spinAxis: 0,
            spin: 0,
            pitch: 0,
            yaw: 0,
            pitchDeflection: 0,
            yawDeflection: 0,
        };
        // initialize the launch parameters
        const ldata = this._initializeLaunch(launchParams);
        //
        // console.log(`Launch:
        //    lp=(${lparams.launchPoint.x},${lparams.launchPoint.y},${lparams.launchPoint.z}),
        //    ivx=${lparams.initVelocity.x},
        //    ivy=${lparams.initVelocity.y},
        //    ivz=${lparams.initVelocity.z},
        //    rt=${lparams.rangeTime},
        //    r=${lparams.range},
        //    mht=${lparams.maxHeightTime},
        //    mh=${lparams.maxHeight}`
        // );
        //
        shot.range = ldata.range;
        shot.launchPoint = Object.assign({}, ldata.launchPoint);
        shot.launchSpeed = launchParams.speedSpin.speed;
        shot.spinAxis = launchParams.speedSpin.spinAxis;
        shot.spin = ldata.spinRPM;
        shot.pitch = ldata.pitch;
        shot.yaw = ldata.yaw;
        shot.pitchDeflection = ldata.pitchDeflection;
        shot.yawDeflection = ldata.yawDeflection;
        // initialize for time-based simulation
        let doneTime = false;
        let doneBounces = false;
        let outOfBounds = false;
        const dt = this.m_model.simParams.timeStep;
        shot.ballPath.push({ t: 0.0, pos: ldata.launchPoint }); // start point
        // Simulation loop
        for (let time = dt; !doneTime && !doneBounces && !outOfBounds; time += dt) {
            outOfBounds = this._tick(shot, ldata, time);
            doneTime = (duration !== null && time >= duration);
            doneBounces = (maxBounces !== null && shot.bounces.length === maxBounces);
        }
        // return the simulated shot
        return shot;
    }
    // ___________________________________________________________________________
    // Private helpers
    // ___________________________________________________________________________
    /**
   * _getCameraLocationInTrainerCoords
   * Get the camera position (x,y,z) in trainer coords from the localized camera Z coord
   * Trainer coords: origin is on court below center of rear axle, +x is right wrt trainer,
   * +y is forward, +z is up.
   * @param localizedCameraZ: localized camera Z coord (height in millimeters above ground)
   * @returns: camera position point in trainer coords
   */
    _getCameraLocationInTrainerCoords(localizedCameraZ) {
        // compute head height
        const cameraHeight = (localizedCameraZ / 1000.0);
        const headHeight = cameraHeight - this.m_model.trainerGeometry.cameraOffsetFromHead.z;
        // get head position in lift coords
        const headPos_lift = this._headPositionFromHeight(headHeight);
        // compute camera position in lift coords
        const camPos_lift = {
            x: headPos_lift.x + this.m_model.trainerGeometry.cameraOffsetFromHead.x,
            y: headPos_lift.y + this.m_model.trainerGeometry.cameraOffsetFromHead.y,
            z: headPos_lift.z + this.m_model.trainerGeometry.cameraOffsetFromHead.z,
        };
        // Convert lift coords to trainer coords
        const camPosTrainerCoords = (0, conversions_1.lift2trainer)(camPos_lift);
        return camPosTrainerCoords;
    }
    // __________________________________________________________________________
    // _computeLocalizedCameraPosition
    // Compute the trainer's camera position (localized coords)
    // from the trainer position (physics coords).
    // changePitchRoll: true if localized camera pitch/roll should be changed to default
    // constant values (this should be true only when setting the trainer position manually).
    _computeLocalizedCameraPosition(changePitchRoll = false) {
        // camera height (meters) = head height + camera offset from head
        const camOffsetFromHead = this.m_model.trainerGeometry.cameraOffsetFromHead;
        const camHeightMeters = this.m_position.h + camOffsetFromHead.z;
        // get camera location in trainer coords (note: conversion to millimeters)
        const camLocation_trainer = this._getCameraLocationInTrainerCoords(camHeightMeters * 1000.0);
        // convert trainer to physics
        const tOrigin_p = { x: this.m_position.x, y: this.m_position.y, z: 0 };
        const camLoc_p = (0, conversions_1.trainer2physics)(camLocation_trainer, tOrigin_p, this.m_position.yaw);
        // convert physics to localized
        const camLoc_l = (0, conversions_1.physics2localization)(camLoc_p);
        const camDownwardPitch = this.m_model.trainerGeometry.cameraDownwardPitch;
        // note: localized camera position uses pitch 0 = down, camDownwardPitch
        // is downward from level, conversion done here
        const lcp = {
            x: camLoc_l.x,
            y: camLoc_l.y,
            z: camLoc_l.z,
            roll: (changePitchRoll) ? 0.0 : this.m_localizedCameraPosition.roll,
            pitch: (changePitchRoll) ? (90.0 - camDownwardPitch) : this.m_localizedCameraPosition.pitch,
            yaw: this.m_position.yaw,
        };
        return lcp;
    }
    /**
   * headPositionFromHeight
   * Get the head position (lift coords) from the head height above ground.
   * Note: the head follows a circular path in space in the y-z plane
   * @param headHeight  height of the head
   * @returns the position of the head in lift coords.
   */
    _headPositionFromHeight(headHeight) {
        const headZ_lift = (headHeight - this.m_model.trainerGeometry.liftOrigin.z);
        const headX_lift = 0; // head is centered on lift
        // Note: headY_lift is always <=0 because the lift origin is defined with
        // the lift arm at max extension.
        const headY_lift = -1.0 * this._computeLiftArmRetraction(headZ_lift);
        const headPos_lift = { x: headX_lift, y: headY_lift, z: headZ_lift };
        // Calculate arm angle in the lift coords
        const armAngleRad = Math.atan2(headPos_lift.z, this.m_model.trainerGeometry.liftArmLength + headPos_lift.y);
        this.m_armAngle = armAngleRad * 180 / Math.PI;
        return headPos_lift;
    }
    // __________________________________________________________________________
    // _computeLaunchPoint
    // Compute the ball launch point (physics coords) from the trainer position
    // and current launch angles (pitch, yaw)
    _computeLaunchPoint(launchYawDeg, launchPitchDeg) {
        // get the head position in lift coords
        // this is the head position on the circular path in space expressed in lift coords.
        const headPos_lift = this._headPositionFromHeight(this.m_position.h);
        // get the launch point from the launch angles in launch coords
        // this point is with respect to the motor assembly origin of rotation
        const lp_launch = (0, conversions_1.yawpitch2launchpoint)(launchYawDeg, launchPitchDeg);
        // Now we need to get the launch position in lift coords.
        // NOTE: the launch coord origin is at a fixed offset wrt the head position
        const launchOrigin_lift = {
            x: headPos_lift.x + this.m_model.trainerGeometry.launchOriginOffset.x,
            y: headPos_lift.y + this.m_model.trainerGeometry.launchOriginOffset.y,
            z: headPos_lift.z + this.m_model.trainerGeometry.launchOriginOffset.z,
        };
        // now add the launch-coordinate values to the launch origin to get
        // the launch point in lift coords
        const lp_lift = {
            x: launchOrigin_lift.x + lp_launch.x,
            y: launchOrigin_lift.y + lp_launch.y,
            z: launchOrigin_lift.z + lp_launch.z,
        };
        // finally, get the launch point in physics coords by going
        // lift coords --> trainer coords --> physics coords
        const lp_t = (0, conversions_1.lift2trainer)(lp_lift);
        const tOrigin_p = { x: this.m_position.x, y: this.m_position.y, z: 0 };
        const lp_p = (0, conversions_1.trainer2physics)(lp_t, tOrigin_p, this.m_position.yaw);
        return lp_p;
    }
    /**
   * computeLiftArmRetraction
   * Compute the retraction of the lift arm in meters given head z-position in
   * lift coords.
   *
   * Retraction: distance (meters) arm is short of full extension
   * (0 = full = horizontal arm). Lift arm follows a circular path in space, so
   * retraction is a value >=0 that ranges from [0, arm length]
   * (e.g. if the arm points straight up/down retraction = arm length)
   *
   * @param z: vertical distance of arm endpoint above horizontal=0
   * @returns: arm retraction (meters) = distance along -y axis of lift coords
   */
    _computeLiftArmRetraction(headZ_lift) {
        const radius = this.m_model.trainerGeometry.liftArmLength;
        (0, assert_1.assert)((Math.abs(headZ_lift) <= radius), `arm pos Z in lift coords ${headZ_lift} must be in [-${radius}, ${radius}]`);
        // extension will be in [-radius, 0]
        const ext = -radius + Math.sqrt(radius * radius - headZ_lift * headZ_lift);
        return -ext;
    }
    /**
   * createTargetResult
   * Generate an initial TargetResult corresponding to a planned shot.
   * Creates an returns a initial pre-simulation TargetResult corresponding
   * to the current trainer position and the specified planned shot. Specifically,
   * initializes the TargetResult, and then sets 'isValid' and 'invalidMessage'
   * based on whether the requested shot is valid (i.e. possible).
   * @param plannedShot : the planned shot being targeted
   * @returns TargetResult: initial target result
   */
    _createTargetResult(plannedShot) {
        const targetResult = {
            // NOTE: launch point is "forward" (no pitch/yaw) launch point
            launchPoint: this._computeLaunchPoint(0, 0),
            plannedShot,
            launchParams: {
                yaw: 0,
                pitch: 0,
                speedSpin: { speed: 0, spin: 0, spinAxis: 0, spinLevel: null },
                rpms: { left: 0, right: 0, top: 0 },
            },
            isValid: true,
            invalidMessage: "",
            isAccurate: false,
            errorDistance: -1,
            simIterations: -1,
            peakHeight: -1,
            pitchDeflection: 0,
            yawDeflection: 0,
        };
        // shot can not be behind trainer
        const dx = plannedShot.target.x - this.m_launchPoint.x;
        const dy = plannedShot.target.y - this.m_launchPoint.y;
        const range = Math.sqrt(dx * dx + dy * dy);
        if (dy <= 0) {
            targetResult.isValid = false;
            targetResult.invalidMessage = "target must be in front of trainer";
        }
        // target point can't be same as launch point
        else if (range < 0.10) {
            targetResult.isValid = false;
            targetResult.invalidMessage = "launch and target point must be different";
        }
        else if (plannedShot.peakHeight !== null) {
            const hdiff = plannedShot.peakHeight - this.m_launchPoint.z;
            if (hdiff < 0) {
                targetResult.isValid = false;
                targetResult.invalidMessage = "peak height must be >= launch height";
            }
        }
        return targetResult;
    }
    /**
   * _targetIdealPeakHeight
   * Compute the equal-RPM launch parameters that will execute a planned shot that specifies
   * a peak height (not an angle), using ideal (no drag) physics (closed form solution).
   * @param targetResult : result of targeting operation
   */
    _targetIdealPeakHeight(targetResult) {
        (0, assert_1.assert)(targetResult.plannedShot.peakHeight !== null, "_targetIdealPeakHeight requires planned shot with peak height");
        (0, assert_1.assert)(targetResult.isValid, "_targetIdealPeakHeight: invalid shot");
        // NOTE: this launchPoint is the "forward" (no pitch/yaw) launch point
        const lp = targetResult.launchPoint;
        const launchH = targetResult.launchPoint.z;
        const tp = targetResult.plannedShot.target;
        const peakH = targetResult.plannedShot.peakHeight;
        const hDiff = (peakH - launchH);
        const g = this.m_model.constants.gravity;
        // console.log(`Target:
        //    launch point: (${lp.x},${lp.y},${lp.z})
        //    target point: (${tp.x},${tp.y},${tp.z})
        //    peak height: ${peakH}`
        // );
        // First, we need to determine the vector from our current launch position
        // to the target position (yaw angle and magnitude), on the court (not in 3D)
        const dx = tp.x - lp.x;
        const dy = tp.y - lp.y;
        const range = Math.sqrt(dx * dx + dy * dy);
        const cosYaw = dy / range;
        const yawDegrees = Math.sign(dx) * Math.acos(cosYaw) * (180.0 / Math.PI);
        // console.log(`Target: range: ${range}, yaw: ${yawDegrees} degrees`);
        // Now we have range and peak height:
        // determine launch angle and speed and launch angle
        const C = hDiff + Math.sqrt(peakH * hDiff);
        const tanPhi = (2.0 * C) / range;
        const pitchDegrees = Math.atan(tanPhi) * (180.0 / Math.PI);
        // speed (this one was fun to derive ... not)
        const speed = Math.sqrt(2.0 * g * hDiff * (range * range + 4 * C * C)) / (2.0 * C);
        console.log(`[Physics Engine] - Calculated launch speed: ${speed}`);
        targetResult.launchParams.yaw = yawDegrees;
        targetResult.launchParams.pitch = pitchDegrees;
        targetResult.launchPoint = this._computeLaunchPoint(yawDegrees, pitchDegrees);
        targetResult.launchParams.speedSpin.speed = speed;
        targetResult.launchParams.speedSpin.spin = 0;
        targetResult.launchParams.speedSpin.spinAxis = 0;
        targetResult.launchParams.rpms = (0, conversions_1.speedspin2rpm)(targetResult.launchParams.speedSpin);
        // this._printLaunchParams(targetResult.launchParams,"Target Launch Params");
        console.log(`[Physics Engine] - Ideal Launch Params: ${JSON.stringify(targetResult.launchParams)}`);
    }
    /**
   * calculateLiftCoefficient
   * Calculate the ball lift coefficient resulting from ball speed/spin.
   * @param ballRadius  Radius of the ball (m)
   * @param angularVelocity  Angular velocity of ball spin (rads/sec)
   * @param ballSpeed  Speed of ball (m/sec)
   * @returns  Lift coefficient factor of Magnus force
   */
    _calculateLiftCoefficient(ballRadius, angularVelocity, ballSpeed) {
        const cl = -0.05 + Math.sqrt(0.0025 + 0.36 * ballRadius * Math.abs(angularVelocity) / Math.abs(ballSpeed));
        return cl;
    }
    /**
   * _printLaunchParams
   * Pretty print launch params to console for debugging.
   * @param launchParams  launch params to dump
   * @param msg  preface output with a message
   */
    _printLaunchParams(launchParams, msg) {
        console.log(`${msg}:
        yaw: ${launchParams.yaw} deg,
        pitch: ${launchParams.pitch},
        speed: ${launchParams.speedSpin.speed},
        rpm: t=${launchParams.rpms.top}, l=${launchParams.rpms.left}, r=${launchParams.rpms.right}`);
    }
    /**
   * _landingPoint
   * Return a simulated shot's landing point on the court.
   * Note: shot must contain a court bounce.
   * @param shot
   * @returns CourtPoint where shot landed
   */
    _landingPoint(shot) {
        (0, assert_1.assert)((shot.bounces.length > 0), "simulated shot must bounce");
        (0, assert_1.assert)((shot.bounces[0].surface === pm.CourtSurface.Court), "simulated must bounce on court first");
        return shot.bounces[0].timePosition.pos;
    }
    /**
   * _computeYaw
   * Compute the yaw (left/right) angle in degrees from a launch point to a targeted
   * point on the court.
   * @param launchPt
   * @param targetPt
   * @returns
   */
    _computeYaw(launchPt, targetPt) {
        const dx = Math.abs(launchPt.x - targetPt.x);
        const dy = Math.abs(launchPt.y - targetPt.y);
        const sign = (launchPt.x - targetPt.x <= 0) ? 1 : -1;
        const angleDeg = sign * Math.atan(dx / dy) * 180.0 / Math.PI;
        return angleDeg;
    }
    /**
   *  _shotError
   * Compute the error between a simulated shot and planned (target) shot
   * @param simulatedShot  simulated shot (will not be perfect)
   * @param plannedShot  planned shot (target we want to hit)
   * @returns  [distErr, rangeErr, angleErr]: "missed by" distance,
   *    range error (+/- = far/short)), and angleErr (degrees off +/- for left/right).
   */
    _shotError(simulatedShot, plannedShot) {
        // get real/planned landing points
        const realLanding = simulatedShot.landingPoint;
        const plannedLanding = plannedShot.target;
        // "missed by" distance
        const distErr = Math.sqrt((plannedLanding.x - realLanding.x) * (plannedLanding.x - realLanding.x)
            + (plannedLanding.y - realLanding.y) * (plannedLanding.y - realLanding.y));
        // range error (+ = shot went too far)
        const realRange = Math.sqrt((simulatedShot.launchPoint.x - realLanding.x) * (simulatedShot.launchPoint.x - realLanding.x)
            + (simulatedShot.launchPoint.y - realLanding.y) * (simulatedShot.launchPoint.y - realLanding.y));
        const plannedRange = Math.sqrt((simulatedShot.launchPoint.x - plannedLanding.x) * (simulatedShot.launchPoint.x - plannedLanding.x)
            + (simulatedShot.launchPoint.y - plannedLanding.y) * (simulatedShot.launchPoint.y - plannedLanding.y));
        const rangeErr = realRange - plannedRange;
        // yaw error (+ = shot went right)
        const realYaw = this._computeYaw(simulatedShot.launchPoint, realLanding);
        const plannedYaw = this._computeYaw(simulatedShot.launchPoint, plannedLanding);
        const angleErr = realYaw - plannedYaw;
        return [distErr, rangeErr, angleErr];
    }
    /**
   * _targetIterativeAngle
   * Return the target result that executes a specified planned shot (using
   * iterative numerical solution). This method is used to solve reverse-physics
   * in the case where drag and/or spin is being modeled, and the planned shot
   * specifies the launch angle.
   * @param targetResult : result of target simulation
   */
    _targetIterativeAngle(targetResult) {
        (0, assert_1.assert)(targetResult.plannedShot.launchAngle !== null, "_targetIterativeAngle requires planned shot with launchAngle");
        (0, assert_1.assert)(targetResult.isValid, "_targetIterativeAngle: invalid shot");
        // set up the initial shot: use 20% of max speed to start
        const initSpeed = this.m_model.limits.MPS.limit / 5.0;
        // First, we need to determine the vector from our current launch position
        // to the target position (yaw angle and magnitude), on the court surface
        // NOTE: right now, launch point is "forward": no pitch/yaw
        const dx = targetResult.plannedShot.target.x - targetResult.launchPoint.x;
        const dy = targetResult.plannedShot.target.y - targetResult.launchPoint.y;
        const range = Math.sqrt(dx * dx + dy * dy);
        const cosYaw = dy / range;
        const yawDegrees = Math.sign(dx) * Math.acos(cosYaw) * (180.0 / Math.PI);
        // console.log(`TargetIterAngle: distance to target: ${range}, yaw: ${yawDegrees} degrees`);
        // Set up initial launch params
        const ss = {
            spinAxis: targetResult.plannedShot.spinAxis,
            speed: initSpeed,
            spin: targetResult.plannedShot.spin,
            spinLevel: null,
        };
        targetResult.launchParams = {
            pitch: targetResult.plannedShot.launchAngle,
            yaw: yawDegrees,
            speedSpin: ss,
            rpms: (0, conversions_1.speedspin2rpm)(ss),
        };
        // prepare to iteratively step closer to solution
        const { maxSteps } = this.m_model.simParams;
        const { stepDecay } = this.m_model.simParams;
        const { stopDist } = this.m_model.simParams;
        let nSteps = 0;
        let stepFraction = 1;
        const initStep = this.m_model.limits.MPS.limit / 8.0;
        let initAngleStep = 0;
        let distErr = 0;
        let rangeErr = 0;
        let angleErr = 0;
        const minSpeed = 2.0;
        targetResult.simIterations = 0;
        // Launch shots that are (hopefully) progressively closer to
        // the target. Stop if we've taken maxSteps shots or we're
        // within stopDist meters of the target.
        // eslint-disable-next-line no-constant-condition
        while (true) {
            // launch a shot (forward physics) for this iteration
            const shot = this.Launch(targetResult.launchParams);
            // const landingPt = shot.landingPoint;
            // console.log(`TargetIterAngle: speed: ${targetResult.launchParams.speedSpin!.speed}, landingPoint: (${landingPt.x},${landingPt.y},${landingPt.z})`);
            // compute error
            [distErr, rangeErr, angleErr] = this._shotError(shot, targetResult.plannedShot);
            // update target result with results from the shot
            nSteps += 1;
            targetResult.peakHeight = shot.peakHeight;
            targetResult.pitchDeflection = shot.pitchDeflection;
            targetResult.yawDeflection = shot.yawDeflection;
            targetResult.launchPoint = shot.launchPoint;
            targetResult.errorDistance = distErr;
            targetResult.simIterations = nSteps;
            targetResult.isAccurate = (distErr <= stopDist);
            // summarize iteration/convergence results
            // const lp = targetResult.launchPoint
            // console.log(`TargetIterPeakHeight: step=${nSteps}: lp=${lp.x},${lp.y},${lp.z}, currDist=${distErr}, stopDist=${stopDist}, rangeErr=${rangeErr}, angErr=${angleErr}, stepsize=${speedStep}, Astepsize=${angleStep}\n`);
            // done with this step: quit if we are close enough
            if (targetResult.isAccurate || nSteps === maxSteps) {
                break;
            }
            // Not converged: update Launch params for next shot
            if (initAngleStep === 0) {
                initAngleStep = Math.abs(angleErr);
            }
            const speedStep = -1.0 * Math.sign(rangeErr) * initStep * stepFraction;
            const angleStep = -1.0 * Math.sign(angleErr) * initAngleStep * stepFraction;
            // console.log(`TargetIterAngle: step=${nSteps}: currDist=${distErr}, stopDist=${stopDist}, rangeErr=${rangeErr}, angErr=${angleErr}, stepsize=${speedStep}, Astepsize=${angleStep}\n`);
            let newSpeed = targetResult.launchParams.speedSpin.speed + speedStep;
            newSpeed = (newSpeed > this.m_model.limits.MPS.maxSafe) ? this.m_model.limits.MPS.maxSafe : newSpeed;
            newSpeed = (newSpeed < minSpeed) ? minSpeed : newSpeed;
            targetResult.launchParams.speedSpin.speed = newSpeed;
            targetResult.launchParams.yaw += angleStep;
            targetResult.launchParams.rpms = (0, conversions_1.speedspin2rpm)(targetResult.launchParams.speedSpin);
            // reduce steps as we iterate so that we converge
            stepFraction *= stepDecay;
        }
        // we finished but did not get within the desired distance of target: bummer!
        if (nSteps === maxSteps) {
            console.warn(`** WARNING **: targeting failed to converge: ${maxSteps} iters: err= ${distErr}m > tolerance ${stopDist})m`);
        }
    }
    /**
   * _targetIterativePeakHeight
   * Compute target result of a specified planned shot (using
   * iterative numerical solution). This method is used to solve reverse-physics
   * in the case where drag and/or spin is being modeled, and the planned shot
   * specifies a peak height.
   * @param targetResult : result of the targeting operation
   */
    _targetIterativePeakHeight(targetResult) {
        (0, assert_1.assert)(targetResult.plannedShot.peakHeight !== null, "_targetIterativePeakHeight requires planned shot with peak height");
        (0, assert_1.assert)(targetResult.isValid, "_targetIterativePeakHeight: invalid shot");
        // Set initial launch conditions as "no spin" shot at ideal peak height at the
        // specified target. Sets:
        //      targetResult.launchPoint
        //      targetResult.launchParams.yaw
        //      targetResult.launchParams.pitch
        //      targetResult.launchParams.speedSpin.speed
        //      targetResult.launchParams.speedSpin.spin = 0 (no spin)
        //      targetResult.launchParams.speedSpin.spinAxis = 0 (no spin)
        //      targetResult.launchParams.rpms (no spin shot)
        this._targetIdealPeakHeight(targetResult);
        //
        // console.log(`TargetIterPeakHeight:
        //    init launch point: (${targetResult.launchPoint.x},${targetResult.launchPoint.y},${targetResult.launchPoint.z})
        //    target point: (${plannedShot.target.x},${plannedShot.target.y},${plannedShot.target.z})
        //    peak height: ${plannedShot.peakHeight}, spin Axis: ${plannedShot.spinAxis}, spin RPM: ${plannedShot.spin}`
        // );
        //
        // NOTE: the initial launch params in targetResult are for no-spin case.
        // If we have spin, let's change the corresponding settings in the initial guess.
        // NOTE: rpms are changed to impart spin (to non-equal)
        if (targetResult.plannedShot.spin > 0) {
            // console.log(`TargetIterPeakHeight: adding spin RPM=${targetResult.plannedShot.spin} around axis ${targetResult.plannedShot.spinAxis}`);
            // console.log(`TargetIterPeakHeight: no-spin RPMs: ${targetResult.launchParams.rpms!.top},${targetResult.launchParams.rpms!.left},${targetResult.launchParams.rpms!.right}`);
            // console.log(`TargetIterPeakHeight: no-spin speed: ${targetResult.launchParams.speedSpin!.speed}`);
            targetResult.launchParams.speedSpin.spin = targetResult.plannedShot.spin;
            targetResult.launchParams.speedSpin.spinAxis = targetResult.plannedShot.spinAxis;
            targetResult.launchParams.rpms = (0, conversions_1.speedspin2rpm)(targetResult.launchParams.speedSpin);
            // console.log(`TargetIterPeakHeight: with-spin RPMs: t=${targetResult.launchParams.rpms.top},l=${targetResult.launchParams.rpms.left},r=${targetResult.launchParams.rpms.right}`);
        }
        // prepare to iteratively step closer to solution
        const { maxSteps } = this.m_model.simParams;
        const { stepDecay } = this.m_model.simParams;
        const { stopDist } = this.m_model.simParams;
        let nSteps = 0;
        let stepFraction = 1;
        let initStep = 0;
        let initAngleStep = 0;
        let distErr = 0;
        let rangeErr = 0;
        let angleErr = 0;
        // Launch shots that are (hopefully) progressively closer to
        // the target. Stop if we've taken maxSteps shots or we're
        // within stopDist meters of the target.
        // eslint-disable-next-line no-constant-condition
        while (true) {
            // launch a shot (forward physics) for this iteration
            // NOTE: this launch operation updates trainer launch point
            const shot = this.Launch(targetResult.launchParams);
            // const landingPt = shot.landingPoint;
            // console.log(`TargetIterPeakHeight: landingPoint: (${landingPt.x},${landingPt.y},${landingPt.z})`);
            // compute error
            [distErr, rangeErr, angleErr] = this._shotError(shot, targetResult.plannedShot);
            // update target result with results from the shot
            nSteps += 1;
            targetResult.peakHeight = shot.peakHeight;
            targetResult.pitchDeflection = shot.pitchDeflection;
            targetResult.yawDeflection = shot.yawDeflection;
            targetResult.launchPoint = shot.launchPoint;
            targetResult.errorDistance = distErr;
            targetResult.simIterations = nSteps;
            targetResult.isAccurate = (distErr <= stopDist);
            // summarize iteration/convergence results
            // const lp = targetResult.launchPoint
            // console.log(`TargetIterPeakHeight: step=${nSteps}: lp=${lp.x},${lp.y},${lp.z}, currDist=${distErr}, stopDist=${stopDist}, rangeErr=${rangeErr}, angErr=${angleErr}, stepsize=${speedStep}, Astepsize=${angleStep}\n`);
            // done with this step: quit if we are close enough
            if (targetResult.isAccurate || nSteps === maxSteps) {
                break;
            }
            // Not converged: update Launch params for next shot
            // Determine how much we need to adjust the speed
            // Use the derivative of ideal range function at the speed we just launched with,
            // scaled up to account for drag.
            const dRangedSpeed = this._idealRangeDerivative(shot.launchSpeed, shot.pitch);
            if (initStep === 0) {
                initStep = Math.abs((rangeErr / dRangedSpeed) * 4.0);
                initAngleStep = Math.abs(angleErr);
            }
            // change the speed and angle in the direction that will reduce range error
            const speedStep = -1.0 * Math.sign(rangeErr) * initStep * stepFraction;
            const angleStep = -1.0 * Math.sign(angleErr) * initAngleStep * stepFraction;
            targetResult.launchParams.speedSpin.speed += speedStep;
            targetResult.launchParams.yaw += angleStep;
            targetResult.launchParams.rpms = (0, conversions_1.speedspin2rpm)(targetResult.launchParams.speedSpin);
            // reduce step size for next iteration so we can converge
            stepFraction *= stepDecay;
        }
        // we finished but did not get within the desired distance of target: bummer!
        if (nSteps === maxSteps) {
            console.warn(`** WARNING **: targeting failed to converge: ${maxSteps} iters: err= ${distErr}m > tolerance ${stopDist})m`);
        }
    }
    /**
   * _idealRangeDerivative
   * Returns the derivative of the ideal (no drag/spin) range function with
   * respect to launch speed at the launch speed specified. Used for
   * by reverse physics to determine step size for numerical iteration.
   * @param lspeed  speed at which to evaluate the derivative
   * @param pitchAngle launch angle at which to evaluate derivative
   * @returns derivative of ideal range function wrt launch speed at the
   *    specified launch speed.
   */
    _idealRangeDerivative(lspeed, pitchAngle) {
        const g = this.m_model.constants.gravity;
        const h = this.m_launchPoint.z;
        const phiRad = pitchAngle * Math.PI / 180.0;
        const sinPhi = Math.sin(phiRad);
        const sinPhiSq = sinPhi * sinPhi;
        return (g * sinPhi) + (lspeed * sinPhiSq) / (Math.sqrt(lspeed * lspeed * sinPhiSq + 2 * g * h));
    }
    /**
   * initializeLaunch Perform all pre-launch computations
   * @param lp  The LaunchParams that provide direction, speed, spin of launch
   * @returns  LaunchData: internal data structure used for launch modeling
   */
    _initializeLaunch(lp) {
        // Set the launch point
        this.m_launchPoint = this._computeLaunchPoint(lp.yaw, lp.pitch);
        // grab gravity and launch height for convenience
        const g = this.m_model.constants.gravity;
        const h = this.m_launchPoint.z;
        // Physics below uses the spin (ball RPM) representation of
        // spin, not the spinlevel, so if our launch params have >0 spinLevel
        // and the spin is null, we need to convert the spinlevel to spin.
        if (lp.speedSpin && (lp.speedSpin.spinLevel !== null)
            && (lp.speedSpin.spinLevel > 0) && (lp.speedSpin.spin === null)) {
            lp.speedSpin.spin = (0, conversions_1.spinlevel2ballspin)(lp.speedSpin.spinLevel);
        }
        // Shot has spin: model the launch deflection
        const { deflectionCorrection } = this.m_model.spinAdjust;
        let pitchAdjust = 0.0;
        let yawAdjust = 0.0;
        if (lp.speedSpin && lp.speedSpin.spin && lp.speedSpin.spin > 0 && deflectionCorrection) {
            const corr = deflectionCorrection(lp.speedSpin.spinAxis, lp.speedSpin.speed);
            pitchAdjust = corr.pitchAdjust;
            yawAdjust = corr.yawAdjust;
            // console.log(`initializeLaunch: deflecting launch: dp=${-pitchAdjust}, dy=${-yawAdjust}`);
        }
        // NOTE: we are subtracting the correction;
        // i.e. introducing deflection to model the real trainer
        const shotYaw = lp.yaw - yawAdjust;
        const shotPitch = lp.pitch - pitchAdjust;
        // compute initial component velocities (trig!)
        // NOTE: To ensure accurate rendering of shots in the physics coordinates,
        //       it is crucial to integrate the current yaw of the trainer in theta calculation
        const thetaRad = (this.m_position.yaw + shotYaw) * Math.PI / 180.0;
        const phiRad = shotPitch * Math.PI / 180.0;
        const init_vz = lp.speedSpin.speed * Math.sin(phiRad);
        const init_vr = lp.speedSpin.speed * Math.cos(phiRad);
        const init_vx = init_vr * Math.sin(thetaRad);
        const init_vy = init_vr * Math.cos(thetaRad);
        // get the axis of rotation wrt ball-center coords for magnus force
        // computation. If spin RPMs are negative (clockwise spin), then this vector is
        // the same as the spin axis; if spin RPMs are positive then it's
        // the opposite of the spin axis. The axis is in the X-Z plane
        const [ax, az] = (0, conversions_1.angle2Vector)(lp.speedSpin.spinAxis);
        let magnusAxis = { x: ax, y: 0, z: az };
        if (lp.speedSpin.spin > 0) {
            magnusAxis.x *= -1;
            magnusAxis.y *= -1;
            magnusAxis.z *= -1;
        }
        // console.log(`magnusAxis pre-rot: ${magnusAxis.x}, ${magnusAxis.y}, ${magnusAxis.z}`);
        // Now we need to transform the magnus axis into world coordinates by
        // applying the yaw and pitch rotations so that the axis is oriented
        // properly for launch. Yaw rotation is around Z, and
        // pitch rotation is around X.
        // NOTE: simulator sign conventions for yaw and pitch are
        // clockwise = +, so we need to negate these angles (sheesh!).
        magnusAxis = (0, util_1.cartesianRotation)(magnusAxis, "z", -1.0 * shotYaw);
        magnusAxis = (0, util_1.cartesianRotation)(magnusAxis, "x", -1.0 * shotPitch);
        // console.log(`magnusAxis pst-rot: ${magnusAxis.x}, ${magnusAxis.y}, ${magnusAxis.z}`);
        // compute ball range:
        // NOTE: based on no-drag physics
        const range_time = (init_vz + Math.sqrt((init_vz * init_vz) + 2.0 * g * h)) / g;
        const range = init_vr * range_time;
        // compute max height
        // NOTE: based on no-drag physics
        const max_height_time = init_vz / g;
        const max_height = h + ((init_vz * init_vz) / (2 * g));
        // compute drag coefficient
        // D = (air density) * (ball drag coeff) * (ball x-sectional area) / 2.0
        const ballArea = (Math.PI * (this.m_model.ballParams.diameter / 2.0) * (this.m_model.ballParams.diameter / 2.0));
        const dragC = 0.5 * (this.m_model.constants.airDensity) * (this.m_model.ballParams.drag) * ballArea;
        // note: if there's no spin, we'll zero out the magnus constant to "turn off" spin
        const magnusC = (lp.speedSpin.spin === 0) ? 0 : (0.5 * (this.m_model.constants.airDensity) * ballArea);
        const ldata = {
            launchPoint: this.m_launchPoint,
            launchParams: lp,
            initVelocity: { x: init_vx, y: init_vy, z: init_vz },
            currVelocity: { x: init_vx, y: init_vy, z: init_vz },
            dragCoeff: dragC,
            magnusCoeff: magnusC,
            magnusAxis,
            spinRPM: lp.speedSpin.spin,
            rangeTime: range_time,
            range,
            maxHeightTime: max_height_time,
            maxHeight: max_height,
            pitchDeflection: -1 * pitchAdjust,
            yawDeflection: -1 * yawAdjust,
            yaw: shotYaw,
            pitch: shotPitch, // actual, including deflection
        };
        return ldata;
    }
    _ballOutOfBounds(tp) {
        const PW = this.m_model.courtGeometry.PLATFORM_WIDTH;
        const PL = this.m_model.courtGeometry.PLATFORM_LENGTH;
        const delta = 0.1;
        return ((tp.pos.x > PW / 2.0 + delta)
            || (tp.pos.x < -PW / 2.0 - delta)
            || (tp.pos.y > PL / 2.0 + delta)
            || (tp.pos.y < -PL / 2.0 - delta));
    }
    /**
   * Detect a bounce (off court or wall)
   * @param shot  Current simulated shot
   * @param params  Launch params (sim state)
   * return value: true/false if ball is out/in bounds of the playing area
   */
    _detectBounce(shot, params) {
        const btpos = shot.ballPath[shot.ballPath.length - 1];
        const ppos = shot.ballPath[shot.ballPath.length - 2];
        let didBounce = false;
        // ground bounce
        if (btpos.pos.z <= 0.0) {
            btpos.pos.z = 0.0; // required to prevent "double bounces"
            params.currVelocity.z = -1 * params.currVelocity.z * this.m_model.constants.courtRestCoeff;
            shot.bounces.push({ timePosition: btpos, surface: pm.CourtSurface.Court });
            didBounce = true;
            // we've never hit the court yet
            if (shot.landingPoint.z === -1) {
                shot.landingPoint = btpos.pos;
            }
        }
        const NH = this.m_model.courtGeometry.NET_HEIGHT_CENTER;
        const NC = this.m_model.constants.netRestCoeff;
        const SWH = this.m_model.courtGeometry.WALL_HEIGHT;
        const BWH = (this.m_model.courtGeometry.TALL_WALL_HEIGHT === 0)
            ? this.m_model.courtGeometry.WALL_HEIGHT
            : this.m_model.courtGeometry.TALL_WALL_HEIGHT;
        const PW = this.m_model.courtGeometry.PLATFORM_WIDTH;
        const PL = this.m_model.courtGeometry.PLATFORM_LENGTH;
        const WC = this.m_model.constants.wallRestCoeff;
        if (this.m_model.simParams.wallBounceEnabled) {
            // left wall
            if (btpos.pos.x <= -1 * PW / 2.0 && (btpos.pos.z < SWH)) {
                btpos.pos.x = -1 * PW / 2.0;
                params.currVelocity.x = -1 * params.currVelocity.x * WC;
                shot.bounces.push({ timePosition: btpos, surface: pm.CourtSurface.LeftWall });
                didBounce = true;
                // console.log(`LEFT: t: ${btpos.t}, x=${btpos.pos.x}, y=${btpos.pos.y}, z=${btpos.pos.z}`);
            }
            // right wall
            else if (btpos.pos.x >= PW / 2.0 && (btpos.pos.z < SWH)) {
                btpos.pos.x = PW / 2.0;
                params.currVelocity.x = -1 * params.currVelocity.x * WC;
                shot.bounces.push({ timePosition: btpos, surface: pm.CourtSurface.RightWall });
                didBounce = true;
                // console.log(`RIGHT: t: ${btpos.t}, x=${btpos.pos.x}, y=${btpos.pos.y}, z=${btpos.pos.z}`);
            }
            // back wall
            else if (btpos.pos.y >= PL / 2.0 && (btpos.pos.z < BWH)) {
                btpos.pos.y = PL / 2.0;
                params.currVelocity.y = -1 * params.currVelocity.y * WC;
                shot.bounces.push({ timePosition: btpos, surface: pm.CourtSurface.BackWall });
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                didBounce = true;
                // console.log(`BACK: t: ${btpos.t}, x=${btpos.pos.x}, y=${btpos.pos.y}, z=${btpos.pos.z}`);
            }
            // net: previous postion --> current position crossed net at height <= net height
            else if (ppos.pos.y < 0 && btpos.pos.y >= 0 && btpos.pos.z < NH) {
                // net totally stops forward progress
                btpos.pos.y = 0;
                params.currVelocity.y = -1 * params.currVelocity.y * NC;
                params.currVelocity.x = params.currVelocity.x * NC;
                params.currVelocity.z = params.currVelocity.z * NC;
                shot.bounces.push({ timePosition: btpos, surface: pm.CourtSurface.Net });
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                didBounce = true;
                // console.log(`NET: t: ${btpos.t}, x=${btpos.pos.x}, y=${btpos.pos.y}, z=${btpos.pos.z}`);
            }
        }
        // if (didBounce) {
        //    console.log(`BOUNCE: t: ${btpos.t}, x=${btpos.pos.x}, y=${btpos.pos.y}, z=${btpos.pos.z}`);
        // }
        const outOfBounds = this._ballOutOfBounds(btpos);
        return outOfBounds;
    }
    /**
   * tick
   * Advance shot simulation time
   * @param shot  Current simulated shot
   * @param params  Launch params (sim state)
   * @param time  Current simulation time
   * return value: true/false if ball is out/in bounds of the playing area
   */
    _tick(shot, params, time) {
        // get the current ball position, timestep
        const cp = shot.ballPath[shot.ballPath.length - 1];
        const dt = this.m_model.simParams.timeStep;
        // compute magnitude of the current velocity (for drag)
        const magVelocity = Math.sqrt(params.currVelocity.x * params.currVelocity.x
            + params.currVelocity.y * params.currVelocity.y
            + params.currVelocity.z * params.currVelocity.z);
        // magnus force computations
        let amx = 0;
        let amy = 0;
        let amz = 0;
        if (params.magnusCoeff !== 0) {
            // magnus force dir = magnusAxis x velocity vector (note: 'x' = cross product)
            const magnusForceDir = (0, conversions_1.crossProduct)(params.magnusAxis, params.currVelocity);
            const angVel = (params.spinRPM / 60.0) * 2 * Math.PI;
            const liftC = this._calculateLiftCoefficient(this.m_model.ballParams.diameter / 2.0, angVel, magVelocity);
            // console.log(`magnus ball vel: ${params.currVelocity.x}, ${params.currVelocity.y}, ${params.currVelocity.z}`);
            // console.log(`magnus force dir: ${magnusForceDir.x}, ${magnusForceDir.y}, ${magnusForceDir.z}`);
            // calculate coefficient of lift
            // need angular velocity of spin in radian/sec for lift calculation
            // (RPM/60.0) rev/sec * 2*PI rads/rev = rads/sec
            const magnusForceMag = params.magnusCoeff * liftC * magVelocity * magVelocity;
            amx = (magnusForceMag * magnusForceDir.x) / this.m_model.ballParams.mass;
            amy = (magnusForceMag * magnusForceDir.y) / this.m_model.ballParams.mass;
            amz = (magnusForceMag * magnusForceDir.z) / this.m_model.ballParams.mass;
            // console.log(`magnus: cl=${liftC}, amx=${amx}, amy=${amy}, amz=${amz}`);
        }
        // compute new accelerations
        // gravity, drag, magnus
        const tmp = -1.0 * (params.dragCoeff / this.m_model.ballParams.mass) * magVelocity;
        const adx = tmp * params.currVelocity.x; // drag
        const ady = tmp * params.currVelocity.y; // drag
        const adz = tmp * params.currVelocity.z - this.m_model.constants.gravity; // drag and gravity
        // console.log(`drag+grav: adx=${adx}, ady=${ady}, amz=${adz}`);
        // compute composite acceleration due to drag + magnus
        const ax = adx + amx;
        const ay = ady + amy;
        const az = adz + amz;
        // update velocity
        params.currVelocity.x = params.currVelocity.x + ax * dt;
        params.currVelocity.y = params.currVelocity.y + ay * dt;
        params.currVelocity.z = params.currVelocity.z + az * dt;
        // update position
        const nextPoint = {
            x: cp.pos.x + params.currVelocity.x * dt + (0.5 * ax * dt * dt),
            y: cp.pos.y + params.currVelocity.y * dt + (0.5 * ay * dt * dt),
            z: cp.pos.z + params.currVelocity.z * dt + (0.5 * az * dt * dt),
        };
        // update shot peakHeight
        if (nextPoint.z > shot.peakHeight) {
            shot.peakHeight = nextPoint.z;
        }
        // add the current position to the ball path
        shot.ballPath.push({ t: time, pos: nextPoint });
        /// const btp = shot.ballPath[shot.ballPath.length - 1]
        // console.log(`np: t: ${btp.t}: x=${btp.pos.x}, y=${btp.pos.y}, z=${btp.pos.z}`);
        // detect bounces and change params accordingly if we hit a surface
        // also detect out of bounds
        const outOfBounds = this._detectBounce(shot, params);
        return outOfBounds;
    }
}
exports.TrainerSim = TrainerSim;
// _______________________________________________________
// Class vars
TrainerSim.DefaultCourtPoint = { x: 0, y: 0, z: 0 };
