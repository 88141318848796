export interface Filter {
    tagIds: number[];
    providerIds: number[];
    position: boolean;
    appId: number | null;
}

interface FilterStateManager {
    getFilter: (key: string) => Filter | null;
    setFilter: (key: string, filter: Filter) => void;
    clearFilter: (key?: string) => void;
    getSort: (key: string) => string | null;
    setSort: (key: string, sort: string) => void;
    clearSort: (key?: string) => void;
}

const VOLLEY_USER_FILTER = "volley-user-filter";
const VOLLEY_USER_SORT = "volley-user-sort";

function generateKey(base: string, key: string): string {
    return `${base}-${key}`;
}

const FilterState: FilterStateManager = {
    getFilter: (key: string) => {
        const objectMaybe = sessionStorage.getItem(
            generateKey(VOLLEY_USER_FILTER, key),
        );
        if (objectMaybe) {
            return JSON.parse(objectMaybe) as Filter;
        }

        return null;
    },
    setFilter: (key: string, filter: Filter) => {
        const value = JSON.stringify(filter);
        sessionStorage.setItem(generateKey(VOLLEY_USER_FILTER, key), value);
    },
    clearFilter: (key?: string) => {
        if (key) {
            sessionStorage.removeItem(generateKey(VOLLEY_USER_FILTER, key));
        } else {
            for (let i = 0; i < sessionStorage.length; i += 1) {
                const keyMaybe = sessionStorage.key(i);
                if (keyMaybe && keyMaybe.startsWith(VOLLEY_USER_FILTER)) {
                    sessionStorage.removeItem(keyMaybe);
                }
            }
        }
    },
    getSort: (key: string) =>
        sessionStorage.getItem(generateKey(VOLLEY_USER_SORT, key)),
    setSort: (key: string, sort: string) => {
        sessionStorage.setItem(generateKey(VOLLEY_USER_SORT, key), sort);
    },
    clearSort: (key?: string) => {
        if (key) {
            sessionStorage.removeItem(generateKey(VOLLEY_USER_SORT, key));
        } else {
            for (let i = 0; i < sessionStorage.length; i += 1) {
                const keyMaybe = sessionStorage.key(i);
                if (keyMaybe && keyMaybe.startsWith(VOLLEY_USER_SORT)) {
                    sessionStorage.removeItem(keyMaybe);
                }
            }
        }
    },
};

export default FilterState;
