import * as React from "react";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import logger from "../../log";
import fetchApi from "../../util/fetchApi";
import SportPicker from "../SportPicker";
import FilterState from "../Trainer/AppWorkouts/apps/shared/filterState";
import CloseableDialogTitle from "../common/CloseableDialogTitle";

import useDialog from "./useDialog";

interface SportSelectorProps {
    closable: boolean;
    selector: JSX.Element;
}

const TITLE = "What would you like to play?";

export function SportSelector({
    closable,
    selector,
}: SportSelectorProps): JSX.Element {
    const { setDialogType } = useDialog();

    return (
        <>
            {closable && (
                <CloseableDialogTitle onClose={() => setDialogType(null)}>
                    {TITLE}
                </CloseableDialogTitle>
            )}
            {!closable && (
                <DialogTitle sx={{ p: 2 }} color="primary.main">
                    {TITLE}
                </DialogTitle>
            )}
            <DialogContent>
                <Stack spacing={2}>
                    <Typography>
                        You can change this any time from the menu on the home
                        screen.
                    </Typography>
                </Stack>
            </DialogContent>
            <DialogActions>{selector}</DialogActions>
        </>
    );
}

export function SportSelectorWelcome(): JSX.Element {
    const { setDialogType } = useDialog();
    return (
        <SportSelector
            closable={false}
            selector={
                <SportPicker
                    showOnlyCompatible
                    afterSportChanged={async (sport) => {
                        if (sport === "PLATFORM_TENNIS") {
                            try {
                                const { playerId } = await fetchApi<{
                                    playerId: string;
                                }>("/api/pti/player-id/mine");
                                logger.info(
                                    `Found APTA number ${playerId} for user, not prompting`,
                                );
                                setDialogType(null);
                            } catch {
                                logger.info(
                                    "No APTA number found for user, prompting",
                                );
                                setDialogType("InitialAPTAId");
                            }
                        } else {
                            setDialogType(null);
                        }
                    }}
                />
            }
        />
    );
}

export function SportSelectorChange(): JSX.Element {
    const { setDialogType } = useDialog();
    return (
        <SportSelector
            closable
            selector={
                <SportPicker
                    showOnlyCompatible
                    afterSportChanged={async (sport) => {
                        FilterState.clearFilter();
                        if (sport === "PLATFORM_TENNIS") {
                            try {
                                const { playerId } = await fetchApi<{
                                    playerId: string;
                                }>("/api/pti/player-id/mine");
                                logger.info(
                                    `Found APTA number ${playerId} for user, not prompting`,
                                );
                                setDialogType(null);
                            } catch {
                                logger.info(
                                    "No APTA number found for user, prompting",
                                );
                                setDialogType("APTAId");
                            }
                        } else {
                            setDialogType(null);
                        }
                    }}
                />
            }
        />
    );
}
