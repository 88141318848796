import * as React from "react";

import Avatar from "@mui/material/Avatar";

import cdnUrl from "../../../CampaignClubs/cdnUrl";

export interface ProviderAvatarProps {
    providerName: string;
    providerLabel: string;
}

export default function ProviderAvatar({
    providerName,
    providerLabel,
}: ProviderAvatarProps): JSX.Element {
    const url = cdnUrl(`publisher-avatars/${providerName}.png`);
    return (
        <Avatar
            src={url}
            alt={providerLabel}
            sx={{
                backgroundColor: "white",
                boxShadow: "2px 2px 2px 0px rgba(0,0,0,0.75)",
                width: 32,
                height: 32,
            }}
            variant="square"
        />
    );
}
