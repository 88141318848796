import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";

import type { Sport as DBSport } from "@volley/data";
import type { CoachStatus } from "@volley/shared/coach-models";

import logger from "../../../log";
import { fetchApi } from "../../../util";
import { logFetchError } from "../../../util/fetchApi";
import { CoordWithSys } from "../../../util/position-types";
import { AuthState, useCurrentUser } from "../../hooks/currentUser";
import { usePairingContext } from "../../hooks/pairingStatus";
import { useStatus } from "../../hooks/status";

export const ValidSportNames = [
    "PLATFORM_TENNIS",
    "PADEL",
    "TENNIS",
    "PICKLEBALL",
] as const;
export type Sport = (typeof ValidSportNames)[number];
export type SportId = number;
export function isValidSportName(
    maybeSportName: string,
): maybeSportName is Sport {
    return ValidSportNames.includes(maybeSportName as Sport);
}

export type SpinIntensityName = "none" | "slight" | "moderate" | "fast";

export const PlatformSpinIntensity: Record<SpinIntensityName, number> = {
    none: 0,
    slight: 500,
    moderate: 1000,
    fast: 1500,
};

const SpinTable: Record<Sport, Record<SpinIntensityName, number>> = {
    PLATFORM_TENNIS: PlatformSpinIntensity,
    PADEL: PlatformSpinIntensity,
    TENNIS: PlatformSpinIntensity,
    PICKLEBALL: PlatformSpinIntensity,
};

export interface Speed {
    minLaunchSpeed: number;
    maxLaunchSpeed: number;
    variance: number;
}

const PlatformSpeed: Speed = {
    maxLaunchSpeed: 55,
    minLaunchSpeed: 15,
    variance: 1.5,
};

const TennisSpeed: Speed = {
    maxLaunchSpeed: 80,
    minLaunchSpeed: 15,
    variance: 2,
};

const PadelSpeed: Speed = {
    maxLaunchSpeed: 80,
    minLaunchSpeed: 15,
    variance: 2,
};

const PickleballSpeed: Speed = {
    maxLaunchSpeed: 45,
    minLaunchSpeed: 11,
    variance: 1.5,
};

const SpeedTable: Record<Sport, Speed> = {
    PLATFORM_TENNIS: PlatformSpeed,
    PADEL: PadelSpeed,
    TENNIS: TennisSpeed,
    PICKLEBALL: PickleballSpeed,
};

export interface SportLimits {
    speed: Speed;
    spin: Record<SpinIntensityName, number>;
}

const DefaultPostionIndex: Record<Sport, number> = {
    PADEL: 12,
    PLATFORM_TENNIS: 8,
    TENNIS: 14,
    PICKLEBALL: 7,
};

const PositionTable: Record<Sport, CoordWithSys[]> = {
    PADEL: [
        { x: -4.2, y: -2.34, sys: "physics" },
        { x: -2.1, y: -2.34, sys: "physics" },
        { x: 0, y: -2.34, sys: "physics" },
        { x: 2.1, y: -2.34, sys: "physics" },
        { x: 4.2, y: -2.34, sys: "physics" },

        { x: -4.2, y: -5, sys: "physics" },
        { x: -2.1, y: -5, sys: "physics" },
        { x: 0, y: -5, sys: "physics" },
        { x: 2.1, y: -5, sys: "physics" },
        { x: 4.2, y: -5, sys: "physics" },

        { x: -4.2, y: -7.8, sys: "physics" },
        { x: -2.1, y: -7.8, sys: "physics" },
        { x: 0, y: -7.8, sys: "physics" },
        { x: 2.1, y: -7.8, sys: "physics" },
        { x: 4.2, y: -7.8, sys: "physics" },

        { x: -4.2, y: -9.6, sys: "physics" },
        { x: -2.1, y: -9.6, sys: "physics" },
        { x: 0, y: -9.6, sys: "physics" },
        { x: 2.1, y: -9.6, sys: "physics" },
        { x: 4.2, y: -9.6, sys: "physics" },
    ],
    PLATFORM_TENNIS: [
        { x: -1.22, y: -2.01, sys: "physics" },
        { x: 0, y: -2.01, sys: "physics" },
        { x: 1.22, y: -2.01, sys: "physics" },

        { x: -1.22, y: -4.46, sys: "physics" },
        { x: 0, y: -4.46, sys: "physics" },
        { x: 1.22, y: -4.46, sys: "physics" },

        { x: -2.44, y: -7.51, sys: "physics" },
        { x: -1.22, y: -7.51, sys: "physics" },
        { x: 0, y: -7.51, sys: "physics" },
        { x: 1.22, y: -7.51, sys: "physics" },
        { x: 2.44, y: -7.51, sys: "physics" },

        { x: -2.44, y: -8.53, sys: "physics" },
        { x: -1.22, y: -8.53, sys: "physics" },
        { x: 0, y: -8.53, sys: "physics" },
        { x: 1.22, y: -8.53, sys: "physics" },
        { x: 2.44, y: -8.53, sys: "physics" },
    ],
    TENNIS: [
        { x: -2.05, y: -2.32, sys: "physics" }, // Front axle 5ft (-1.52m) from net. Trainer rear axle -2.3m
        { x: 0, y: -2.32, sys: "physics" },
        { x: 2.05, y: -2.32, sys: "physics" },

        { x: -2.05, y: -4.15, sys: "physics" }, // Front axle 11ft (-3.35m) from net. Trainer rear axle -4.15m
        { x: 0, y: -4.15, sys: "physics" },
        { x: 2.05, y: -4.15, sys: "physics" },

        { x: -2.05, y: -7.2, sys: "physics" }, // Front axle 21ft (-6.4m) from net (service line). Trainer rear axle -7.2m
        { x: 0, y: -7.2, sys: "physics" },
        { x: 2.05, y: -7.2, sys: "physics" },

        { x: -2.05, y: -9.64, sys: "physics" }, // Front axle 29ft (-8.84m) from net. Trainer rear axle -9.64m
        { x: 0, y: -9.64, sys: "physics" },
        { x: 2.05, y: -9.64, sys: "physics" },

        { x: -4.1, y: -12.69, sys: "physics" }, // Front axle 39ft (-11.89m) from net (baseline). Trainer rear axle -12.69m
        { x: -2.05, y: -12.69, sys: "physics" },
        { x: 0, y: -12.69, sys: "physics" },
        { x: 2.05, y: -12.69, sys: "physics" },
        { x: 4.1, y: -12.69, sys: "physics" },

        { x: -4.1, y: -15.74, sys: "physics" }, // Front axle 49ft (-14.94m) from net. Trainer rear axle -15.74m
        { x: -2.05, y: -15.74, sys: "physics" },
        { x: 0, y: -15.74, sys: "physics" },
        { x: 2.05, y: -15.74, sys: "physics" },
        { x: 4.1, y: -15.74, sys: "physics" },
    ],
    PICKLEBALL: [
        { x: -3.04, y: -2.93, sys: "physics" },
        { x: -1.52, y: -2.93, sys: "physics" },
        { x: 0, y: -2.93, sys: "physics" },
        { x: 1.52, y: -2.93, sys: "physics" },
        { x: 3.04, y: -2.93, sys: "physics" },

        { x: -3.04, y: -5.07, sys: "physics" },
        { x: -1.52, y: -5.07, sys: "physics" },
        { x: 0, y: -5.07, sys: "physics" },
        { x: 1.52, y: -5.07, sys: "physics" },
        { x: 3.04, y: -5.07, sys: "physics" },

        { x: -3.04, y: -7.51, sys: "physics" },
        { x: -2.28, y: -7.51, sys: "physics" },
        { x: -1.52, y: -7.51, sys: "physics" },
        { x: 0, y: -7.51, sys: "physics" },
        { x: 1.52, y: -7.51, sys: "physics" },
        { x: 2.28, y: -7.51, sys: "physics" },
        { x: 3.04, y: -7.51, sys: "physics" },
    ],
};

export interface SportInfo {
    available: Partial<Record<Sport, string>>;
    selected: Sport;
    hasSelected: boolean;
    friendlyName: string;
    limits: SportLimits;
    manualPositions: CoordWithSys[];
    updateSelected: (sport: Sport) => void;
    getSportFromId: (id: SportId) => Sport;
    getIdFromSport: (sport: Sport) => number;
    getFriendlyName: (sport: Sport | SportId) => string;
    getDefaultPosition: () => CoordWithSys;
}

export const SportContext = React.createContext<SportInfo>({
    available: { PLATFORM_TENNIS: "Platform Tennis" },
    selected: "PLATFORM_TENNIS",
    hasSelected: false,
    friendlyName: "None Selected",
    limits: {
        speed: SpeedTable.PLATFORM_TENNIS,
        spin: SpinTable.PLATFORM_TENNIS,
    },
    manualPositions: PositionTable.PLATFORM_TENNIS,
    updateSelected: () => {},
    getSportFromId: () => "PLATFORM_TENNIS",
    getIdFromSport: () => 1,
    getFriendlyName: () => "Platform Tennis",
    getDefaultPosition: () => PositionTable.PLATFORM_TENNIS[8],
});

const LOCAL_STORAGE_KEY = "VOLLEY_SELECTED_SPORT";

export function trainerCompatibleWithSport(
    sport: Sport,
    status: CoachStatus | null,
) {
    if (status?.trainer?.sports === undefined) {
        return true;
    }

    return status.trainer.sports.includes(sport.toLocaleLowerCase());
}

export const SportProvider = React.memo(
    ({ children }: React.PropsWithChildren) => {
        const { status } = useStatus();
        const { status: pairingStatus } = usePairingContext();
        const { features, authState } = useCurrentUser();

        const [toastMessage, setToastMessage] = React.useState("");
        const [toastOpen, setToastOpen] = React.useState(false);

        const [sports, setSports] = React.useState<DBSport[]>([]);
        const [loading, setLoading] = React.useState(false);
        React.useEffect(() => {
            if (
                sports.length === 0 &&
                !loading &&
                authState === AuthState.AUTHENTICATED
            ) {
                setLoading(true);
                fetchApi<DBSport[]>("/api/sports")
                    .then((results) => setSports(results))
                    .catch((e) => logFetchError(e))
                    .finally(() => setLoading(false));
            }
        }, [loading, sports, authState]);

        React.useEffect(() => {
            const pickleball = sports.find((s) => s.name === "PICKLEBALL");

            if (
                pickleball &&
                features.length > 0 &&
                !features.includes("PICKLEBALL")
            ) {
                logger.info("Removing pickleball from available sports");
                setSports(sports.filter((s) => s.name !== "PICKLEBALL"));
            }
        }, [sports, features]);

        let defaultValue = "PLATFORM_TENNIS" as Sport;
        let defaultHasSelected = false;
        const sessionValue = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (sessionValue !== null) {
            defaultValue = sessionValue as Sport;
            defaultHasSelected = true;
        }
        const [selectedSport, setSelectedSport] =
            React.useState<Sport>(defaultValue);
        const [hasSelected, setHasSelected] =
            React.useState(defaultHasSelected);

        const updateSelected = React.useCallback(
            (updated: Sport) => {
                if (
                    pairingStatus === "paired" &&
                    !trainerCompatibleWithSport(updated, status)
                ) {
                    const name =
                        sports.find((s) => s.name === updated)?.label ??
                        "Platform Tennis";
                    const m = `You are not using a ${name} trainer. ${name} balls will not work with this trainer.`;
                    setToastMessage(m);
                    setToastOpen(true);
                    return;
                }

                localStorage.setItem(LOCAL_STORAGE_KEY, updated);
                setSelectedSport(updated);
                setHasSelected(true);
            },
            [pairingStatus, status, sports],
        );

        const getSportFromId = React.useCallback(
            (id: SportId) =>
                (sports.find((s) => s.id === id)?.name as Sport) ??
                "PLATFORM_TENNIS",
            [sports],
        );

        const getIdFromSport = React.useCallback(
            (sport: Sport) => sports.find((s) => s.name === sport)?.id ?? 1,
            [sports],
        );

        const getFriendlyName = React.useCallback(
            (sport: Sport | SportId) => {
                if (typeof sport === "number") {
                    return (
                        sports.find((s) => s.id === sport)?.label ??
                        "Platform Tennis"
                    );
                }

                return (
                    sports.find((s) => s.name === sport)?.label ??
                    "Platform Tennis"
                );
            },
            [sports],
        );

        const getDefaultPosition = React.useCallback(() => {
            const index = DefaultPostionIndex[selectedSport];
            return PositionTable[selectedSport][index];
        }, [selectedSport]);

        const available = React.useMemo(
            () =>
                Object.fromEntries(
                    sports
                        .filter((s) => isValidSportName(s.name))
                        .map((s) => [s.name, s.label]),
                ),
            [sports],
        );

        const value = React.useMemo<SportInfo>(
            () => ({
                available,
                selected: selectedSport,
                hasSelected,
                friendlyName: available[selectedSport],
                limits: {
                    speed: SpeedTable[selectedSport],
                    spin: SpinTable[selectedSport],
                },
                manualPositions: PositionTable[selectedSport],
                updateSelected,
                getSportFromId,
                getIdFromSport,
                getFriendlyName,
                getDefaultPosition,
            }),
            [
                available,
                selectedSport,
                hasSelected,
                updateSelected,
                getSportFromId,
                getIdFromSport,
                getFriendlyName,
                getDefaultPosition,
            ],
        );

        return (
            <SportContext.Provider value={value}>
                {children}
                <Snackbar
                    open={toastOpen}
                    onClose={() => setToastOpen(false)}
                    anchorOrigin={{
                        horizontal: "center",
                        vertical: "top",
                    }}
                    sx={{
                        top: 60,
                    }}
                >
                    <Alert
                        action={
                            <IconButton
                                size="small"
                                aria-label="close"
                                color="inherit"
                                onClick={() => setToastOpen(false)}
                            >
                                <CloseIcon />
                            </IconButton>
                        }
                        severity="warning"
                    >
                        {toastMessage}
                    </Alert>
                </Snackbar>
            </SportContext.Provider>
        );
    },
);

SportProvider.displayName = "SportProvider";

export function useSelectedSport(): SportInfo {
    return React.useContext(SportContext);
}
