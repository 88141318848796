import * as React from "react";

import { UserEvent } from "@volley/shared/events/user-event";

import logger from "../../log";
import { pairedFetchApi } from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";

import { useStatus } from "./status";

export type FeatureName = string;

export const UserEventsContext = React.createContext(undefined);

export const UserEventsProvider = React.memo(
    ({ children }: React.PropsWithChildren) => {
        const { setDialogType } = useDialog();
        const { status } = useStatus();
        const reconnectAttempts = React.useRef(0);

        // setup event source for notifications
        React.useEffect(() => {
            const eventSrc = new EventSource("/api/events/user");
            eventSrc.onopen = (e: Event) => {
                logger.info("[UserEvents] SOS event source opened", e);
            };

            eventSrc.onmessage = async (e: MessageEvent<string>) => {
                const parsedData = JSON.parse(e.data) as UserEvent;
                if (parsedData.data.type === "sos" && parsedData.userId) {
                    logger.info("[UserEvents] SOS event source message", e);
                    setDialogType("NeedHelp");
                    if (status?.clientId) {
                        // if we're in a session, pause potential workout
                        await pairedFetchApi(
                            status?.clientId,
                            "/api/apps/workouts/pause",
                            "POST",
                        );
                    }
                } else if (parsedData.data.type === "close") {
                    eventSrc.close();
                }
            };

            eventSrc.onerror = (e: Event) => {
                eventSrc?.close();
                logger.warn("[UserEvents] SOS event source error", e);

                setTimeout(
                    () => {
                        logger.info(
                            `[UserEvents] Reconnecting to event source, attempt ${reconnectAttempts.current + 1}`,
                        );
                        reconnectAttempts.current += 1;
                    },
                    // backoff but max 10s
                    Math.min(reconnectAttempts.current + 1 * 1000, 10_000),
                );
            };

            return () => {
                eventSrc?.close();
            };
        }, [setDialogType, status?.clientId]);

        return (
            <UserEventsContext.Provider value={undefined}>
                {children}
            </UserEventsContext.Provider>
        );
    },
);

UserEventsProvider.displayName = "UserEventsProvider";

export default function useUserEvents() {
    return React.useContext(UserEventsContext);
}
