interface Options {
    id: number;
    value: "Too weak" | "Weak" | "Medium" | "Strong";
    minDiversity: number;
    minLength: number;
}

const defaultOptions: Options[] = [
    {
        id: 0,
        value: "Too weak",
        minDiversity: 0,
        minLength: 0,
    },
    {
        id: 1,
        value: "Weak",
        minDiversity: 3,
        minLength: 8,
    },
    {
        id: 2,
        value: "Medium",
        minDiversity: 3,
        minLength: 10,
    },
    {
        id: 3,
        value: "Strong",
        minDiversity: 4,
        minLength: 12,
    },
];

export default function passwordStrength(
    passwordInput: string,
    optionsInput = defaultOptions,
) {
    const options = optionsInput;
    const password = passwordInput || "";

    options[0].minDiversity = 0;
    options[0].minLength = 0;

    const rules = [
        {
            regex: /[a-zA-Z]/,
            message: "lowercase",
        },
        {
            regex: /[0-9]/,
            message: "number",
        },
        {
            regex: /[!"#$%&'()*+,-./:;<=>?@[\\\]^_`{|}~]/,
            message: "symbol",
        },
        {
            regex: /[A-Z]/,
            message: "uppercase",
        },
    ];

    const contains = rules
        .filter((rule) => rule.regex.test(password))
        .map((rule) => rule.message);
    const { length } = password;
    const fulfilledOptions = options
        .filter((option) => contains.length >= option.minDiversity)
        .filter((option) => length >= option.minLength)
        .sort((o1, o2) => o2.id - o1.id)
        .map((option) => ({ id: option.id, value: option.value }));

    return { contains, length, ...fulfilledOptions[0] };
}
