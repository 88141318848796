import * as React from "react";

import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";

export default function BallSquare(props: SvgIconProps): JSX.Element {
    return (
        <SvgIcon viewBox="0 0 200 200" {...props}>
            <radialGradient
                id="a"
                cx="-341.91"
                cy="962.08"
                r=".603"
                gradientTransform="matrix(200 1.8 1.8 -200 66764 193114)"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#F5EF63" offset="0" />
                <stop stopColor="#CCCB29" offset=".692" />
                <stop stopColor="#C8DB34" offset="1" />
            </radialGradient>
            <path
                d="m100 0c55.221 0 100 44.779 100 100 0 55.221-44.779 100-100 100-55.221 0-100-44.779-100-100 0-55.221 44.779-100 100-100z"
                fill="url(#a) #C8DB34"
                strokeWidth=".88496"
            />
        </SvgIcon>
    );
}
