import * as React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import PTICard from "./Card";

export default function PTIHomeRow(): JSX.Element {
    const cards = [
        {
            name: "Summary",
            urlSegment: "summary",
        },
        {
            name: "PTI Graph",
            urlSegment: "pti-graph",
        },
        {
            name: "Matches",
            urlSegment: "matches",
        },
        {
            name: "Partners",
            urlSegment: "partners",
        },
        {
            name: "Opponents",
            urlSegment: "opponents",
        },
        {
            name: "Locations",
            urlSegment: "locations",
        },
        {
            name: "Spread",
            urlSegment: "spread",
        },
    ];
    return (
        <Stack>
            <Typography
                variant="button"
                color="white"
                sx={{ textTransform: "none" }}
            >
                PTI
            </Typography>
            <Stack
                direction="row"
                spacing={1}
                sx={{
                    padding: "4px",
                    overflowX: "auto",
                    whiteSpace: "nowrap",
                    scrollbarWidth: "none",
                    msOverflowStyle: "none",
                    "&::-webkit-scrollbar": {
                        display: "none",
                    },
                    maskImage:
                        "linear-gradient(to right, rgba(0, 0, 0, 1) 90%, rgba(0, 0, 0, 0) 100%)",
                }}
            >
                {cards.map((card) => (
                    <PTICard key={card.urlSegment} {...card} />
                ))}
            </Stack>
        </Stack>
    );
}
