import * as React from "react";

import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Stack from "@mui/material/Stack";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import SupportLink from "../common/SupportLink";
import { usePairingContext } from "../hooks/pairingStatus";

import useDialog from "./useDialog";

interface ShutdownChecklistDialogProps {
    shutdown: boolean;
}

function ChecklistDialog({
    shutdown,
}: ShutdownChecklistDialogProps): JSX.Element {
    const { unpair } = usePairingContext();
    const { dialogType, setDialogType } = useDialog();

    React.useEffect(() => {
        unpair();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClose = React.useCallback(() => {
        setDialogType(null);
    }, [setDialogType]);

    return (
        <>
            <CloseableDialogTitle variant="h4" onClose={handleClose}>
                Please Clean-up the Court
            </CloseableDialogTitle>
            <DialogContent dividers>
                <List
                    sx={{
                        listStyleType:
                            dialogType === "ShutdownCleanupChecklist"
                                ? "decimal"
                                : "disc",
                        pl: 3,
                        "& .MuiListItem-root": {
                            display: "list-item",
                        },
                    }}
                >
                    <ListItem>Empty the ball bin if necessary.</ListItem>
                    <ListItem>
                        Store the trainer and balls in the designated area.
                    </ListItem>
                    {shutdown && (
                        <ListItem>
                            It may take up to a minute for the two lights in the
                            back to turn off. You can put the trainer away in
                            the meantime.
                        </ListItem>
                    )}
                </List>
                <Stack direction="column" spacing={1}>
                    <Button
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                        onClick={handleClose}
                    >
                        Finished
                    </Button>
                </Stack>
            </DialogContent>
            <SupportLink preText="Having Trouble?">Click here.</SupportLink>
        </>
    );
}

export function ShutdownChecklistDialog(): JSX.Element {
    return <ChecklistDialog shutdown />;
}

export function DisconnectCleanupChecklistDialog(): JSX.Element {
    return <ChecklistDialog shutdown={false} />;
}
