import * as React from "react";

import AutorenewIcon from "@mui/icons-material/Autorenew";
import CancelIcon from "@mui/icons-material/Cancel";
import HelpIcon from "@mui/icons-material/Help";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import LinearProgress from "@mui/material/LinearProgress";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { Coordinate } from "@volley/shared/vision-models";

import useDialog from "../../Dialog/useDialog";
import { usePairingContext } from "../../hooks/pairingStatus";
import { LocalizationStatus } from "../../hooks/usePosition";

interface Props {
    hintText: string;
    localizationStatus: LocalizationStatus;
    localizationError: string | undefined;
    positionRequested: boolean;
    delta: Coordinate | undefined;
    cancel: () => void;
    update: () => void;
}

function getUnsureHelpText(
    localizationError: string | undefined,
    cancelRequested: boolean,
) {
    if (cancelRequested) {
        return "The request to locate the trainer was cancelled. Click the blue button to retry.";
    }

    const errorMessage =
        localizationError === "NOT_READY"
            ? "The cameras aren't ready yet. Please try again in a few moments"
            : "Please move the trainer closer to the location shown in the visual above. Click the blue button to retry.";
    return errorMessage;
}

export default function LocalizationProgress({
    hintText,
    localizationError,
    localizationStatus,
    positionRequested,
    delta,
    cancel,
    update,
}: Props) {
    const { status: pairingStatus, promptToPair } = usePairingContext();
    const [showHelp, setShowHelp] = React.useState(false);
    const [cancelRequested, setCancelRequested] = React.useState(false);
    const { setDialogType } = useDialog();

    const hintToImprove = React.useMemo(() => {
        const moveX = Math.round((delta?.x ?? 0) / 25.4);
        const moveY = Math.round((delta?.y ?? 0) / 25.4);
        const messageX = `${Math.abs(moveX)} inches ${moveX > 0 ? "right" : "left"}`;
        const messageY = `${Math.abs(moveY)} inches ${moveY > 0 ? "back" : "forward"}`;
        let completeMessage = "";
        if (Math.abs(moveX) > 6) {
            completeMessage = completeMessage.concat(`(${messageX}`);
            if (Math.abs(moveY) > 6) {
                completeMessage = completeMessage.concat(` and ${messageY}`);
            }
            completeMessage = completeMessage.concat(")");
        } else if (Math.abs(moveY) > 6) {
            completeMessage = completeMessage.concat(`(${messageY})`);
        }

        return completeMessage;
    }, [delta]);

    const { hintColor, helpText } = React.useMemo(() => {
        switch (hintText) {
            case "Good":
                return {
                    hintColor: "secondary.main",
                };
            case "Improve":
                return {
                    hintColor: "info.main",
                    helpText: [
                        "Please move the trainer closer to the transparent trainer by following the arrow.",
                        hintToImprove,
                        "Click the blue button to retry.",
                    ].join("\n"),
                };
            case "Unsure":
                return {
                    hintColor: "warning.main",
                    helpText: getUnsureHelpText(
                        localizationError,
                        cancelRequested,
                    ),
                };
            case "Success":
                return {
                    hintColor: "secondary.main",
                    helpText: [
                        "Find Position complete.",
                        "Click 'Confirm Position' if the position looks correct, otherwise click retry.",
                    ].join(" "),
                };
            case "Not Ready":
                return {
                    hintColor: "warning.main",
                    helpText: getUnsureHelpText("NOT_READY", false),
                };
            case "Unavailable":
                return {
                    hintColor: "warning.main",
                    helpText: [
                        "The camera has encountered an error.",
                        "Please restart the trainer to make use of this feature.",
                    ].join(" "),
                };
            default:
                return {
                    hintColor: "primary.main",
                };
        }
    }, [cancelRequested, hintText, localizationError, hintToImprove]);

    const icon =
        localizationStatus === "localizing" ? (
            <CancelIcon />
        ) : (
            <AutorenewIcon />
        );

    const checkButtonDisabled =
        pairingStatus === "requested" || pairingStatus === "validating";

    const activeStatusText = React.useMemo(() => {
        if (localizationStatus === "cancelling") return "Cancelling...";
        if (localizationStatus === "error") return "Error";
        return "Checking...";
    }, [localizationStatus]);

    const initialCheck = React.useCallback(() => {
        if (pairingStatus === "paired") {
            update();
        } else {
            promptToPair(true);
        }
    }, [pairingStatus, promptToPair, update]);

    const unavailable = hintText === "Unavailable" || hintText === "Not Ready";
    const prefixText = unavailable ? "Camera: " : "Trainer Position: ";

    if (!positionRequested) {
        return (
            <Button
                fullWidth
                disabled={checkButtonDisabled}
                variant="contained"
                color="secondary"
                onClick={() => initialCheck()}
            >
                Check Position
            </Button>
        );
    }

    return (
        <Stack spacing={1} justifyContent="space-between">
            {["error", "idle"].includes(localizationStatus) ? (
                <>
                    <Box component="div">
                        <Typography
                            variant="body2"
                            color="primary.main"
                            display="inline"
                        >
                            {prefixText}
                        </Typography>
                        <Typography
                            variant="body2"
                            color={hintColor}
                            display="inline"
                        >
                            {hintText}
                        </Typography>
                        {helpText && (
                            <IconButton
                                size="small"
                                edge="end"
                                color="info"
                                aria-label="help"
                                onClick={() => {
                                    if (hintText === "Unavailable") {
                                        setDialogType("VisionFaultDialog");
                                    } else {
                                        setShowHelp(true);
                                    }
                                }}
                            >
                                <HelpIcon />
                            </IconButton>
                        )}
                    </Box>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={icon}
                        color="secondary"
                        onClick={() => {
                            setCancelRequested(false);
                            update();
                        }}
                        disabled={unavailable}
                    >
                        Retry
                    </Button>
                </>
            ) : (
                <>
                    <Stack
                        spacing={1}
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        <Box component="div">
                            <Typography
                                variant="body2"
                                color="primary.main"
                                display="inline"
                            >
                                Trainer Position:&nbsp;
                            </Typography>
                            <Typography
                                variant="body2"
                                color="info.main"
                                display="inline"
                            >
                                {activeStatusText}
                            </Typography>
                        </Box>
                        <LinearProgress color="info" />
                    </Stack>
                    <Button
                        size="small"
                        variant="contained"
                        startIcon={<CancelIcon />}
                        color="info"
                        onClick={() => {
                            setCancelRequested(true);
                            cancel();
                        }}
                        disabled={localizationStatus === "cancelling"}
                    >
                        Cancel
                    </Button>
                </>
            )}
            <Dialog open={showHelp} style={{ whiteSpace: "pre-wrap" }}>
                <DialogTitle>{`Help: ${hintText}`}</DialogTitle>
                <DialogContent>{helpText}</DialogContent>
                <DialogActions>
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => setShowHelp(false)}
                    >
                        Got it!
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
