import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { SxProps, Theme } from "@mui/material/styles";

import {
    LevelNumber,
    Levels,
} from "@volley/shared/apps/curated-workout-models";

interface Props {
    label?: string;
    disabled?: boolean;
    level: LevelNumber;
    setLevel: (level: LevelNumber) => void;
    labelWrapperSx?: SxProps<Theme>;
    toggleButtonSx?: SxProps<Theme>;
    wrapperSx?: SxProps<Theme>;
}

export default function LevelSelector({
    disabled = false,
    label = "Level",
    labelWrapperSx = {},
    level,
    setLevel,
    toggleButtonSx = {},
    wrapperSx = {},
}: Props): JSX.Element {
    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={wrapperSx}>
            <Box component="div" sx={labelWrapperSx}>
                <Typography variant="caption" sx={{ minWidth: "30px" }}>
                    {label}
                </Typography>
            </Box>
            <Box component="div" sx={toggleButtonSx}>
                <ButtonGroup
                    disabled={disabled}
                    fullWidth
                    size="small"
                    color="primary"
                >
                    {Levels.map((l) => (
                        <Button
                            key={l}
                            variant={level === l ? "contained" : "outlined"}
                            size="small"
                            sx={{
                                padding: "2px",
                                color:
                                    level === l
                                        ? "common.white"
                                        : "primary.light",
                                backgroundColor:
                                    level === l
                                        ? "primary.light"
                                        : "common.white",
                                fontSize: "10px",
                                "&:hover": {
                                    bgcolor: "primary.light",
                                },
                            }}
                            onClick={() => setLevel(l)}
                        >
                            {l}
                        </Button>
                    ))}
                </ButtonGroup>
            </Box>
        </Stack>
    );
}
