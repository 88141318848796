import * as React from "react";

import { PositionLike } from "../../../../../util/position-types";

export type FreePlayStep =
    | "shot"
    | "position"
    | "height"
    | "aim"
    | "spin"
    | "play";

export type FreePlayKnownHeight = "low" | "medium" | "high";

export type FreePlayShotType = "serve" | "screen" | "volley" | "lob" | "custom";

export const KnownHeights: Record<FreePlayKnownHeight, number> = {
    high: 73,
    medium: 53,
    low: 33,
};

export const KnownHeightsAtom: Record<FreePlayKnownHeight, number> = {
    high: 86,
    medium: 57,
    low: 33,
};

export const KnownHeightDescriptions: Record<FreePlayKnownHeight, string> = {
    high: "This height is good for serves & overheads",
    medium: "This height is good for drives & returns",
    low: "This height is good for lobs, drives & volleys",
};

export interface FreePlayAim {
    launchSpeed: number;
    pan: number;
    tilt: number;
}

export interface FreePlaySpin {
    spinLevel: number;
    spinAxis: number;
}

export interface FreePlayState {
    step: FreePlayStep;
    prereqsVerified: boolean;
    shotType?: FreePlayShotType;
    shotTypeVerified: boolean;
    position?: PositionLike;
    positionVerified: boolean;
    height?: number;
    heightVerified: boolean;
    aim?: FreePlayAim;
    aimVerified: boolean;
    spin?: FreePlaySpin;
    spinVerified: boolean;
}

export enum FreePlayActionType {
    SetStep,
    ConfirmPrereqs,
    ConfirmShotType,
    ConfirmPosition,
    ConfirmHeight,
    SetAim,
    SetSpin,
    Reset,
}

export interface FreePlayAction {
    type: FreePlayActionType;
    payload?: number | string | PositionLike | FreePlayAim | FreePlaySpin;
}

export function reducer(
    state: FreePlayState,
    action: FreePlayAction,
): FreePlayState {
    switch (action.type) {
        case FreePlayActionType.ConfirmHeight:
            return {
                ...state,
                height: action.payload as number,
                heightVerified: true,
            };
        case FreePlayActionType.ConfirmPosition:
            return {
                ...state,
                position: action.payload as PositionLike,
                positionVerified: true,
            };
        case FreePlayActionType.ConfirmShotType:
            return {
                ...state,
                shotType: action.payload as FreePlayShotType,
                shotTypeVerified: true,
            };
        case FreePlayActionType.SetAim:
            return {
                ...state,
                aim: action.payload as FreePlayAim,
                aimVerified: action.payload !== undefined,
            };
        case FreePlayActionType.SetSpin:
            return {
                ...state,
                spin: action.payload as FreePlaySpin,
                spinVerified: action.payload !== undefined,
            };
        case FreePlayActionType.SetStep:
            return { ...state, step: action.payload as FreePlayStep };
        case FreePlayActionType.ConfirmPrereqs:
            return { ...state, prereqsVerified: true };
        case FreePlayActionType.Reset:
            return initialState;
        default:
            return state;
    }
}

export const initialState: FreePlayState = {
    step: "shot",
    prereqsVerified: false,
    shotTypeVerified: false,
    positionVerified: false,
    heightVerified: false,
    aimVerified: false,
    spinVerified: false,
};

export type FreePlayReducer = React.Reducer<FreePlayState, FreePlayAction>;
function mockDispatch(): void {}
const initialContext: [
    React.ReducerState<FreePlayReducer>,
    React.Dispatch<React.ReducerAction<FreePlayReducer>>,
] = [initialState, mockDispatch];

export const FreePlayContext = React.createContext(initialContext);

export function useWorkoutPlay(): [
    FreePlayState,
    React.Dispatch<FreePlayAction>,
] {
    return React.useContext(FreePlayContext);
}
