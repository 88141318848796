import * as React from "react";

import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";

import type { ModuleShot } from "@volley/shared/apps/module-models";

import type { TrainerPositionLike } from "../../../../../../../util/position-types";
import generateUUID from "../../../../../../../util/uuid";
import VerticalDragDrop from "../../../../../../common/DragAndDrop/VerticalDragDrop";
import NotchedOutline from "../../../../../../common/NotchedOutline";
import { useSelectedSport } from "../../../../../../common/context/sport";
import { mph2mps } from "../../../../../util/conversions";

import ShotControls from "./ShotControls";

interface Props {
    shots: ModuleShot[];
    position: TrainerPositionLike;
    interval: number;
    updateShots: (shots: ModuleShot[]) => void;
    randomize: boolean;
    updateRandomize: (randomize: boolean) => void;
    setEditingShot: (editingShot: boolean) => void;
    onChangeVisualizedShotIds: (shotIds: string[] | null) => void;
}

export default function Shots({
    shots,
    updateShots,
    position,
    interval,
    randomize,
    updateRandomize,
    setEditingShot,
    onChangeVisualizedShotIds,
}: Props): JSX.Element {
    const { limits } = useSelectedSport();
    const [editingId, setEditingId] = React.useState<string | null>(null);
    const maybeEditing = React.useMemo(
        () =>
            shots
                .map((s, i) => [s, i] as [ModuleShot, number])
                .find(([s]) => s.id === editingId),
        [shots, editingId],
    );
    const editing = maybeEditing ? maybeEditing[0] : null;
    const editingIndex = maybeEditing ? maybeEditing[1] : null;

    const addShot = () => {
        const id = generateUUID();
        updateShots([
            ...shots,
            {
                id,
                name: "",
                launchSpeed: mph2mps(limits.speed.minLaunchSpeed),
                launchSpeedVariation: 0,
                pan: 0,
                panVariation: 0,
                spinSpeed: 0,
                spinDirection: 0,
                spinLevel: 0,
                tilt: 0,
                tiltVariation: 0,
            },
        ]);
        setEditingShot(true);
        setEditingId(id);
        onChangeVisualizedShotIds([id]);
    };

    const updateShot = <K extends keyof ModuleShot>(
        property: K,
        value: ModuleShot[K],
    ) => {
        if (!editingId) return;
        const index = shots.findIndex((s) => s.id === editingId);
        const toUpdate = shots[index];
        const nextShots = [...shots];
        nextShots[index] = {
            ...toUpdate,
            [property]: value,
        };
        updateShots(nextShots);
    };

    const onCancel = () => {
        updateShots(shots.filter((s) => s.id !== editingId));
        setEditingId(null);
        setEditingShot(false);
        onChangeVisualizedShotIds(null);
    };

    const onSave = () => {
        if (!editing?.name) {
            onCancel();
        } else {
            setEditingId(null);
            setEditingShot(false);
            onChangeVisualizedShotIds(null);
        }
    };

    const onDuplicate = () => {
        if (!editing) return;
        const sameName = shots.filter((s) => s.name === editing.name);
        const duplicate: ModuleShot = {
            ...editing,
            id: generateUUID(),
            name: `${editing.name} (${sameName.length})`,
        };
        updateShots([...shots, duplicate]);
        setEditingId(null);
        setEditingShot(false);
        onChangeVisualizedShotIds([duplicate.id]);
    };

    const onBack = () => {
        if (editingIndex === null) return;
        const id = shots[editingIndex - 1]?.id ?? null;
        setEditingId(id);
        onChangeVisualizedShotIds([id]);
    };

    const onNext = () => {
        if (editingIndex === null) return;
        const id = shots[editingIndex + 1]?.id ?? null;
        setEditingId(id);
        onChangeVisualizedShotIds([id]);
    };

    if (editing) {
        return (
            <ShotControls
                shot={editing}
                playMode="standard"
                position={position}
                interval={interval}
                updateShot={updateShot}
                onSave={onSave}
                onDuplicate={onDuplicate}
                onCancel={onCancel}
                onBack={editingIndex ? onBack : undefined}
                onNext={
                    editingIndex !== null && editingIndex < shots.length - 1
                        ? onNext
                        : undefined
                }
            />
        );
    }

    return (
        <Stack>
            <List>
                <VerticalDragDrop
                    items={shots}
                    setItems={updateShots}
                    jsxElements={shots.map((s) => (
                        <ListItem
                            key={s.id}
                            secondaryAction={<ChevronRightIcon />}
                        >
                            <ListItemButton
                                onClick={() => {
                                    setEditingId(s.id);
                                    setEditingShot(true);
                                    onChangeVisualizedShotIds([s.id]);
                                }}
                            >
                                <ListItemAvatar>
                                    <Avatar>
                                        <DragIndicatorIcon fontSize="small" />
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText primary={s.name} />
                            </ListItemButton>
                        </ListItem>
                    ))}
                />
            </List>
            <Box
                component="div"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Fab color="secondary" onClick={() => addShot()}>
                    <AddIcon />
                </Fab>
            </Box>
            <NotchedOutline label="Shot Order" color="info.main">
                <Stack
                    direction="row"
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Typography color="info.main">Sequential</Typography>
                    <Switch
                        checked={randomize}
                        onChange={(e) => updateRandomize(e.target.checked)}
                        sx={{
                            "& .MuiSwitch-thumb": {
                                color: randomize ? "info.main" : "common.white",
                            },
                            "& .Mui-checked+ .MuiSwitch-track": {
                                backgroundColor: randomize
                                    ? "#A1C8F5"
                                    : "grey.500",
                            },
                        }}
                    />
                    <Typography color="info.main">Randomized</Typography>
                </Stack>
            </NotchedOutline>
        </Stack>
    );
}
