import * as React from "react";

import useDialog from "../useDialog";

export default function useUnclosableDialog() {
    const { setDisableOnClose } = useDialog();
    React.useEffect(() => {
        setDisableOnClose(true);

        return () => {
            setDisableOnClose(false);
        };
    }, [setDisableOnClose]);
}
