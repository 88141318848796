import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutCreatePayload,
    Tag,
} from "@volley/data";
import type { ModuleConfig } from "@volley/shared/apps/module-models";
import type { JSONObject } from "@volley/shared/common-models";

import type {
    CoordLike,
    PositionLike,
} from "../../../../../../util/position-types";

export type WorkoutFormDataMode = "create" | "edit";

export interface WorkoutFormData {
    isDirty: boolean;
    isOwner: boolean;
    validationErrors: ValidationErrors | null;
    isFavorite: boolean;
    tags: Record<number, Tag[]> | null;
    mode: WorkoutFormDataMode;
}

export type NewWorkoutForm = AppWorkoutCreatePayload &
    WorkoutFormData & {
        mode: "create";
    };

export type EditWorkoutForm = AppWorkout &
    WorkoutFormData & {
        mode: "edit";
    };

export type WorkoutForm = NewWorkoutForm | EditWorkoutForm;

export type WorkoutAction =
    | { type: "name"; value: string }
    | { type: "description"; value: string }
    | { type: "trainerPosition"; value: PositionLike }
    | { type: "playerPosition"; value: CoordLike }
    | { type: "positionHeight"; value: number }
    | { type: "contentProviderId"; value: number | null }
    | { type: "tags"; value: Record<number, Tag[]> }
    | { type: "favorite"; value: boolean }
    | { type: "updateConfig"; value: ModuleConfig }
    | {
          type: "set";
          value:
              | ((AppWorkout | AppWorkoutCreatePayload) & {
                    isOwner: boolean;
                    mode: WorkoutFormDataMode;
                })
              | null;
      }
    | { type: "validationErrors"; value: ValidationErrors | null };

export type ValidationErrors = Partial<
    Record<keyof WorkoutForm | keyof ModuleConfig, string>
>;

export function validate(state: WorkoutForm): ValidationErrors | null {
    const errors: ValidationErrors = {};
    if (!state.name.trim()) errors.name = "Name is required";
    else if (state.name.trim().length < 4)
        errors.name = "Name must be at least 3 characters";

    if (!state.description.trim())
        errors.description = "Short Description is required";
    else if (state.description.trim().length < 4) {
        errors.description = "Short Description must be at least 3 characters";
    }

    const c = state.config as unknown as ModuleConfig;
    if (!c.videoSlug.trim()) errors.videoSlug = "Video Slug is required";
    else if (c.videoSlug.trim().length < 4)
        errors.videoSlug = "Video Slug must be at least 3 characters";

    if (!c.details.trim()) errors.details = "Instructions are required";
    else if (c.details.trim().length < 4)
        errors.details = "Instructions must have at least 3 characters";

    if (!c.shots.length)
        errors.shots = "At least one shot must be added to this module";
    else if (!c.shots.every((s) => s.name.trim().length > 0))
        errors.shots = "Each shot must have a valid name";

    return Object.keys(errors).length ? errors : null;
}

export function stripFormMetadata(state: WorkoutForm) {
    const {
        mode,
        isDirty,
        isFavorite,
        isOwner,
        validationErrors,
        tags,
        ...rest
    } = state;
    return rest;
}

function isInitialized(
    _state: WorkoutForm | null,
    action: WorkoutAction,
): _state is WorkoutForm {
    return action.type === "set";
}

export function reducer(
    state: WorkoutForm | null,
    action: WorkoutAction,
): WorkoutForm | null {
    if (!isInitialized(state, action) && !state) return null;
    switch (action.type) {
        case "name":
            return { ...state, isDirty: true, name: action.value };
        case "description":
            return { ...state, isDirty: true, description: action.value };
        case "trainerPosition":
            return {
                ...state,
                isDirty: true,
                positionX: action.value.x,
                positionY: action.value.y,
                positionYaw: action.value.yaw,
            };
        case "playerPosition": {
            const config: ModuleConfig = {
                ...(state.config as unknown as ModuleConfig),
                playerPosition: action.value,
            };
            return {
                ...state,
                isDirty: true,
                config: config as unknown as JSONObject,
            };
        }
        case "positionHeight":
            return { ...state, isDirty: true, positionHeight: action.value };
        case "contentProviderId":
            return { ...state, isDirty: true, contentProviderId: action.value };
        case "tags":
            return {
                ...state,
                isDirty: state.tags !== null,
                tags: action.value,
            };
        case "favorite":
            return { ...state, isFavorite: action.value };
        case "updateConfig":
            return {
                ...state,
                config: action.value as unknown as JSONObject,
                isDirty: true,
            };
        case "set": {
            if (!action.value) return null;

            if (action.value.mode === "create") {
                const metadata = {
                    isDirty: false,
                    isFavorite: false,
                    isOwner: true,
                    mode: "create",
                    tags: null,
                    validationErrors: null,
                };
                return {
                    ...action.value,
                    ...metadata,
                } as NewWorkoutForm;
            }

            const metadata = {
                isDirty: false,
                isFavorite: (action.value as AppWorkout)._count.favorites > 0,
                isOwner: action.value.isOwner,
                mode: "edit",
                tags: (action.value as AppWorkout).workoutTags.reduce(
                    (acc, wt) => {
                        acc[wt.tag.tagCategoryId] =
                            acc[wt.tag.tagCategoryId] || [];
                        acc[wt.tag.tagCategoryId].push(wt.tag);
                        return acc;
                    },
                    {} as Record<number, Tag[]>,
                ),
                validationErrors: null,
            };
            return {
                ...action.value,
                ...metadata,
            } as EditWorkoutForm;
        }
        case "validationErrors":
            return { ...state, validationErrors: action.value };
        default:
            throw new Error("Invalid Action to Perform on a Workout");
    }
}
