import * as React from "react";

import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import {
    LevelNumber,
    Levels,
} from "@volley/shared/apps/curated-workout-models";

interface Props {
    level: LevelNumber;
    setLevel: (level: LevelNumber) => void;
    type: "shots" | "feed";
    onCopyLevel: (type: "shots" | "feed", toLevel: LevelNumber) => void;
}

export default function LevelSelector({
    type,
    level,
    setLevel,
    onCopyLevel,
}: Props): JSX.Element {
    const [copyDialogOpen, setCopyDialogOpen] = React.useState(false);
    const [levelToCopy, setLevelToCopy] = React.useState(
        (level === 1 ? Levels.length : level - 1) as LevelNumber,
    );

    React.useEffect(() => {
        setLevelToCopy(
            (level === 1 ? Levels.length : level - 1) as LevelNumber,
        );
    }, [level]);

    return (
        <Stack spacing={2}>
            <Stack direction="row" spacing={0.5} alignItems="center">
                <Typography variant="caption" sx={{ minWidth: "30px" }}>
                    Level
                </Typography>
                <ToggleButtonGroup
                    fullWidth
                    size="small"
                    color="primary"
                    exclusive
                    value={level}
                    onChange={(_, v: LevelNumber) => {
                        if (v) {
                            setLevel(v);
                        }
                    }}
                >
                    {Levels.map((l) => (
                        <ToggleButton
                            key={l}
                            size="small"
                            value={l}
                            sx={{ p: "2px" }}
                        >
                            {l}
                        </ToggleButton>
                    ))}
                </ToggleButtonGroup>
            </Stack>
            <Box component="div" display="flex" justifyContent="end">
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<ContentCopyIcon />}
                    onClick={() => setCopyDialogOpen(true)}
                >
                    Copy from…
                </Button>
            </Box>
            <Dialog
                sx={{ "& .MuiDialog-paper": { width: "80%" } }}
                open={copyDialogOpen}
                onClose={() => setCopyDialogOpen(false)}
                maxWidth="xs"
            >
                <DialogTitle>{`Copy ${type} from…`}</DialogTitle>
                <DialogContent dividers>
                    <RadioGroup
                        name="copyLevel"
                        value={levelToCopy}
                        onChange={(_, v) =>
                            setLevelToCopy(parseInt(v, 10) as LevelNumber)
                        }
                    >
                        {Levels.filter((l) => l !== level).map((option) => (
                            <FormControlLabel
                                key={option}
                                value={option}
                                control={<Radio />}
                                label={`Level ${option}`}
                            />
                        ))}
                    </RadioGroup>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => setCopyDialogOpen(false)}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            onCopyLevel(type, levelToCopy);
                            setCopyDialogOpen(false);
                        }}
                    >
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}
