import * as React from "react";

import SportsTennisIcon from "@mui/icons-material/SportsTennis";
import Fab from "@mui/material/Fab";
import { CSSProperties } from "@mui/material/styles/createTypography";

import { usePairingContext } from "../../hooks/pairingStatus";

const fabBase: CSSProperties = {
    position: "fixed",
    fontSize: "small",
    width: 93,
    borderRadius: 32,
    margin: 0,
    opacity: 0.5,
};

export interface ThrowNowFABProps {
    disabled: boolean;
    side: "fabTopLeft" | "fabTopRight" | "fabBottomLeft" | "fabBottomRight";
    onClick: () => void;
}

export default function ThrowNowFAB({
    disabled,
    side,
    onClick,
}: ThrowNowFABProps): JSX.Element {
    const { status, promptToPair } = usePairingContext();

    const handleClick = React.useCallback(() => {
        if (status === "paired") {
            onClick();
        } else {
            promptToPair(true);
        }
    }, [status, onClick, promptToPair]);

    const styles = {
        fabBottomRight: {
            bottom: 80,
            right: 29,
            top: "auto",
            left: "auto",
            ...fabBase,
        },
        fabBottomLeft: {
            bottom: 80,
            right: "auto",
            top: "auto",
            left: 10,
            ...fabBase,
        },
        fabTopRight: {
            right: 29,
            top: 80,
            left: "auto",
            bottom: "auto",
        },
        fabTopLeft: {
            right: "auto",
            left: 10,
            top: 80,
            bottom: "auto",
        },
    };

    return (
        <Fab
            color="info"
            variant="extended"
            disabled={disabled}
            onClick={() => handleClick()}
            sx={styles[side]}
        >
            <SportsTennisIcon
                sx={(theme) => ({ marginRight: theme.spacing(2) })}
                fontSize="small"
            />
            Test
        </Fab>
    );
}
