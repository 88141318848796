import * as React from "react";

import { type MeshLineGeometry, Sphere, Trail } from "@react-three/drei";
import { useFrame, useThree } from "@react-three/fiber";
import * as THREE from "three";
import { Line2, LineGeometry } from "three-stdlib";

import * as convert from "@volley/physics/dist/conversions";
import { CourtSurface, TrainerPosition } from "@volley/physics/dist/models";
import { TrainerSim } from "@volley/physics/dist/trainer-sim";

import generateUUID from "../../../util/uuid";

import { lineMaterial } from "./constants";
import { AnimationConfig, VisualizerShot } from "./types";

interface AnimationState {
    verts: Float32Array;
    currentIndex: number;
    onComplete: () => void;
}

interface Props {
    trainerPosition: TrainerPosition;
    shots: VisualizerShot[];
    sim: TrainerSim;
    animationConfig?: AnimationConfig;
}

export default function BallTrack({
    trainerPosition,
    shots,
    sim,
    animationConfig,
}: Props): JSX.Element | null {
    const { scene } = useThree();

    const trajectoriesRef = React.useRef<THREE.Group>(null!);
    const drawShots = React.useCallback((sequenceVerts: Float32Array[]) => {
        const group = trajectoriesRef.current;
        sequenceVerts.forEach((verts, i) => {
            const trajectoryLine = group.children[i] as Line2 | undefined;
            if (trajectoryLine) {
                // The size of geometry (a GeometryBuffer) isn't mutable, so the buffer
                // has to be disposed and re-instantiated.
                // https://github.com/mrdoob/three.js/issues/21488#issuecomment-804373560
                trajectoryLine.geometry.dispose();
                trajectoryLine.geometry = new LineGeometry().setPositions(
                    verts,
                );
            } else {
                group.add(
                    new Line2(
                        new LineGeometry().setPositions(verts),
                        lineMaterial,
                    ),
                );
            }
        });
        group.children.slice(sequenceVerts.length).forEach((childArg) => {
            const child = childArg as Line2;
            group.remove(child);
            child.geometry.dispose();
            child.material.dispose();
        });
    }, []);

    const stopAnimations = React.useRef(false);
    const animationState = React.useRef<AnimationState | null>(null);
    const isPaused = React.useRef(false);
    const playbackSpeed = React.useRef(1.0);
    const delayTimeout = React.useRef<NodeJS.Timeout | null>(null);
    const shotQueue = React.useRef<
        { verts: Float32Array; delayBefore?: number }[]
    >([]);
    const thrownCount = React.useRef(0);
    const ballRef = React.useRef<THREE.Mesh>(null!);
    const trailRef = React.useRef<MeshLineGeometry>(null!);

    const clearShotScene = React.useCallback(() => {
        trailRef.current.visible = false;
        ballRef.current.visible = false;
    }, []);

    const [id, setId] = React.useState(generateUUID());

    React.useEffect(() => {
        function clearAnimation() {
            stopAnimations.current = true;
            if (delayTimeout.current) clearTimeout(delayTimeout.current);
        }

        function getShotQueue() {
            return (
                shots.map((shotConfig) => {
                    const shot = sim.Launch({
                        pitch: shotConfig.tilt,
                        yaw: shotConfig.pan,
                        rpms: convert.speedspin2rpm({
                            speed: shotConfig.launchSpeed,
                            spinLevel: shotConfig.spinLevel ?? null,
                            spinAxis: shotConfig.spinDirection,
                            spin: null,
                        }),
                        speedSpin: null,
                    });

                    const floorBounces = shot.bounces.filter(
                        (e) => e.surface === CourtSurface.Court,
                    );
                    const secondCourtBounce = floorBounces[1];
                    const tmpVerts: number[] = [];
                    for (const point of shot.ballPath) {
                        // We will only draw the paths up to the 2nd bounce for each shot
                        if (
                            secondCourtBounce &&
                            point.t >= secondCourtBounce.timePosition.t
                        ) {
                            break;
                        }
                        tmpVerts.push(point.pos.x);
                        // Swap the y and z points to account for coordinate systems of physics and the 3d visualizer
                        // Physics: x=width, y=depth (subzero is on player side), z=height
                        // Visualizer: x=width, y=height, z=depth (subzero is on trainer side)
                        tmpVerts.push(point.pos.z);
                        tmpVerts.push(-point.pos.y);
                    }
                    return {
                        verts: new Float32Array(tmpVerts),
                        delayBefore: shotConfig.delayBefore,
                    };
                }) ?? []
            );
        }

        function animateNextShot() {
            if (!shotQueue.current.length || stopAnimations.current) {
                animationConfig?.onComplete();
                return;
            }
            const nextShot = shotQueue.current.shift();
            if (!nextShot) return;
            delayTimeout.current = setTimeout(
                () => {
                    animationState.current = {
                        verts: nextShot.verts,
                        currentIndex: 0,
                        onComplete: animateNextShot,
                    };
                    thrownCount.current += 1;
                    animationConfig?.onProgressChange({
                        thrown: thrownCount.current,
                        total: shots.length ?? 0,
                    });
                    setId(generateUUID());
                },
                Math.round((nextShot.delayBefore ?? 0) / playbackSpeed.current),
            );
        }

        playbackSpeed.current = animationConfig?.playbackSpeed ?? 1.0;

        switch (animationConfig?.action) {
            case "pause": {
                isPaused.current = true;
                break;
            }
            case "play": {
                stopAnimations.current = false;
                if (isPaused.current) {
                    isPaused.current = false;
                } else if (!shotQueue.current.length) {
                    thrownCount.current = 0;
                    shotQueue.current = getShotQueue();
                    ballRef.current.visible = true;
                    trailRef.current.visible = true;
                    animateNextShot();
                }
                break;
            }
            case "stop": {
                isPaused.current = false;
                clearAnimation();
                clearShotScene();
                stopAnimations.current = false;
                thrownCount.current = 0;
                shotQueue.current = [];
                animationConfig.onComplete();
                break;
            }
            default:
                clearAnimation();
                clearShotScene();
                drawShots(getShotQueue().map((s) => s.verts));
                break;
        }
    }, [
        scene,
        trainerPosition,
        sim,
        animationConfig,
        shots,
        drawShots,
        clearShotScene,
    ]);

    useFrame(() => {
        if (!animationState.current) return;
        const { verts, onComplete, currentIndex } = animationState.current;

        if (stopAnimations.current) {
            clearShotScene();
            return;
        }

        if (isPaused.current) return;

        if (currentIndex < verts.length / 3) {
            const index = Math.floor(currentIndex);
            ballRef.current.position.set(
                verts[index * 3],
                verts[index * 3 + 1],
                verts[index * 3 + 2],
            );

            animationState.current.currentIndex +=
                playbackSpeed.current * 3 + 1;
        } else {
            onComplete();
            animationState.current = null;
        }
    });

    const ballInitPosition = React.useMemo(() => {
        if (!animationState.current || !id) return new THREE.Vector3();
        const [x, y, z] = animationState.current.verts;
        return new THREE.Vector3(x, y, z);
    }, [id]);

    return (
        <>
            <group ref={trajectoriesRef} />
            <Trail
                key={id}
                ref={trailRef}
                color={0xffff00}
                length={2}
                width={1}
            >
                <Sphere
                    ref={ballRef}
                    args={[0.11, 32, 32]}
                    position={ballInitPosition}
                >
                    <meshBasicMaterial color={0x00ff00} />
                </Sphere>
            </Trail>
        </>
    );
}
