import { convert } from "@volley/physics";
import { PlayMode } from "@volley/shared/apps/app-common-models";
import { CuratedWorkoutShot } from "@volley/shared/apps/curated-workout-models";
import { SingleShotShot } from "@volley/shared/apps/single-shot-models";
import { Side } from "@volley/shared/http/side";
import type { Position as PositionWithHeight } from "@volley/shared/http/trainer-control";

import {
    Coord,
    CoordWithSys,
    PositionLike,
} from "../../../util/position-types";
import { COURT_WIDTH_MM } from "../../../util/positionUtil";
import { Sport } from "../../common/context/sport";

import { HitBox, ScaledDimensions } from "./types";

export function areEqual(
    a: PositionLike | undefined,
    b: PositionLike | undefined,
) {
    return a?.x === b?.x && a?.y === b?.y && a?.yaw === b?.yaw;
}

/*
    Given a coordinate in either court (mm) or physics (m) and a court width (m),
    calculate:
    - a: the distance from that point to the opposite baseline
    - b: the distance from where a intersects the baseline to the midpoint on the baseline
    - c: the hypotenuse of the triangle formed by a and b
    - yaw: the yaw required to point the trainer at the center of the opposite baseline
    Last, depending on which side of the court we're on, we may need to return a negative value
*/
export function yawToBaseline(
    coord: CoordWithSys,
    width: number,
    height: number,
) {
    const { x, y, sys } = coord;
    let w = width;
    let h = height;
    if (sys === "court") {
        // the values need to be converted from m to mm
        w = width * 1000;
        h = height * 1000;
    }
    // distance to opposite baseline
    const a = sys === "court" ? y : h / 2 - y;
    const b = sys === "court" ? Math.abs(x - w / 2) : Math.abs(x);

    // yaw is calculated as the inverse sin of side b divided by side c
    const c = Math.sqrt(a ** 2 + b ** 2);
    const yaw = Math.asin(b / c);
    if (sys === "court") {
        if (x > w / 2) {
            return -yaw;
        }
        return yaw;
    }

    if (x > 0) {
        return -yaw;
    }

    return yaw;
}

export function pointInPolygon(x: number, y: number, hitBox: HitBox) {
    const xmin = hitBox.x - hitBox.w / 2;
    const xmax = hitBox.x + hitBox.w / 2;
    const ymin = hitBox.y - hitBox.h / 2;
    const ymax = hitBox.y + hitBox.h / 2;

    return x >= xmin && x <= xmax && y >= ymin && y <= ymax;
}

export function projected2Image(
    coord: CoordWithSys,
    scaled: ScaledDimensions,
): Coord {
    const { x, y } = coord;
    let x1 = x;
    let y1 = y;
    if (coord.sys === "court") {
        const physics = convert.localization2physics({ x, y, z: 1000 });

        x1 = physics.x;
        y1 = physics.y;
    }

    const projected = {
        x: x1 * scaled.widthPPM + scaled.xOffset,
        y:
            y1 >= 0
                ? scaled.yOffset - y1 * scaled.heightPPM
                : Math.abs(y1 * scaled.heightPPM) + scaled.yOffset,
    };

    return projected;
}

export function image2Projected(coord: Coord, scaled: ScaledDimensions): Coord {
    const { x, y } = coord;
    return {
        x: (x - scaled.xOffset) / scaled.widthPPM,
        y: (scaled.yOffset - y) / scaled.heightPPM,
    };
}

export function playerSide(coord: Coord, sport: Sport): Side {
    if (sport === "PLATFORM_TENNIS" && coord.x <= COURT_WIDTH_MM / 2) {
        return "deuce";
    }

    if (coord.x < 0) {
        return "deuce";
    }
    return "ad";
}

export function sideKeys(
    coord: Coord,
    sport: Sport,
): { adKey: PlayMode; deuceKey: PlayMode } {
    const side = playerSide(coord, sport);
    if (side === "deuce") {
        return { adKey: "mirror", deuceKey: "standard" };
    }
    return { adKey: "standard", deuceKey: "mirror" };
}

export function mirrorShot<T extends SingleShotShot | CuratedWorkoutShot>(
    shot: T,
): T {
    const { pan } = shot;
    return {
        ...shot,
        pan: -pan,
    };
}

export function mirrorPlayer(coord: Coord, sport: Sport): CoordWithSys {
    const { x, y } = coord;

    // We're assuming positions are still in old mm system for platform tennis
    if (sport === "PLATFORM_TENNIS") {
        return {
            y,
            x: COURT_WIDTH_MM - x,
            sys: "court",
        };
    }
    return {
        y,
        x: -x,
        sys: "physics",
    };
}

export function mirrorTrainer(
    position: PositionWithHeight,
    sport: Sport,
): PositionWithHeight {
    const { x, y, yaw, heightIn } = position;

    // We're assuming positions are still in old mm system for platform tennis
    if (sport === "PLATFORM_TENNIS") {
        return {
            y,
            heightIn,
            yaw: -yaw,
            x: COURT_WIDTH_MM - x,
        };
    }
    return {
        y,
        heightIn,
        yaw: -yaw,
        x: -x,
    };
}

const ALLOWED_DIFF = 0.61; // 2 feet from center line in meters
export function isCenterWorkout(positionX: number, sport: Sport) {
    if (
        sport === "PLATFORM_TENNIS" &&
        Math.abs(positionX - COURT_WIDTH_MM / 2) < ALLOWED_DIFF * 1000
    ) {
        return true;
    }

    if (sport !== "PLATFORM_TENNIS" && Math.abs(positionX) < ALLOWED_DIFF) {
        return true;
    }

    return false;
}
