"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.launchHeightInches2HeadHeight = exports.launchHeight2HeadHeight = exports.cameraHeight2HeadHeight = exports.vector2Angle = exports.angle2Vector = exports.speedspin2rpm = exports.rpm2speedspin = exports.mps2rpm = exports.rpm2mps = exports.maxspinlevel = exports.maxspinlevel_for_rpmspeed = exports.maxmotorspin_for_rpmspeed = exports.spinlevel2ballspin = exports.ballspin2spinlevel = exports.spinlevel2motorspin = exports.motorspin2spinlevel = exports.ballspin2motorspin = exports.motorspin2ballspin = exports.physics2trainer = exports.trainer2physics = exports.physics2localization = exports.localization2physics = exports.lift2trainer = exports.trainer2lift = exports.yawpitch2launchpoint = exports.crossProduct = exports.getPhysicsModel = exports.setPhysicsModel = void 0;
/* eslint-disable object-curly-newline */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable function-paren-newline */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/brace-style */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-param-reassign */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-inferrable-types */
/* eslint-disable @typescript-eslint/dot-notation */
/* _____________________________________________________________________________
  Physics Library Conversions

  Functions use physics library coords/units/direction unless
  explicitly stated otherwise.

  Physics Library Coordinate Systems

  Physics Coordinates
  -------------------------------------------------
    Coordinates: origin (0,0,0) is court center
    Axes: right-handed, +x is right, +z is up, +y referred to as "forward"
    Length Units: meters
    Angles: degrees
        yaw (rot about z): 0=looking forward, +=right
        pitch (rot about x): 0=looking forward, +=up
        roll (rot about y): 0=x axis perp to z, +=right tilt (looking forward)

  Localization Coordinates
  -------------------------------------------------
    Coordinates: origin (0,0,0) is top left court corner (looking from above)
    Axes: left-handed, +x is right, +z is up, +y into court
    Units: Same as physics except using MILLIMETERS for x,y,z
    Angles: Degrees
      yaw (rot about z): 0=looking "forward" (-y direction), +=right
      pitch (rot about x): 0=looking DOWN at ground (-z direction), +=up
      roll (rot about y): 0=x axis perp to z, +=right tilt (looking forward)

  The following 2 coord systems are "private" to the physics lib:

  Trainer Coordinates
  -------------------------------------------------
    Coordinates: origin is "attached to the trainer": point on
          the ground at the midpoint between the back of the
          trainer wheels.
    Axes: right-handed, +x is right, +z is up, +y forward
    Length Units: meters
    Angles: degrees
        yaw (rot about z): 0=looking forward, +=right
        pitch (rot about x): 0=looking forward, +=up
        roll (rot about y): 0=x axis perp to z, +=right tilt (looking forward)

  Lift Coordinates
  -------------------------------------------------
    Coordinates: origin "attached to the trainer" point at the end of the lift arm
          when arm is parallel to ground (fully extended). This system is fixed
          relative to trainer coords (it does not move with the arm/head).
    Axes: right-handed, +x is right, +z is up, +y forward
    Length Units: meters
    Angles: degrees
        yaw (rot about z): 0=looking forward, +=right
        pitch (rot about x): 0=looking forward, +=up
        roll (rot about y): 0=x axis perp to z, +=right tilt (looking forward)

  Launch Coordinates
  ------------------
    Coordinates: origin is the rotation point around which the pan/tilt
        motors perform their rotations to launch a ball. This system MOVES WITH
        THE ARM/HEAD. Its origin is fixed wrt the head and is located several inches
        behind the launch point where the ball exits the wheels. As the pan
        and tilt of the wheel assembly changes, the ball launch point moves
        spherically in this coord system.
    Origin: the wheel assembly rotation point, which moves with lift but is
            at fixed point wrt current head position (end of lift arm).
    Axes: right-handed, +x is right, +z is up, +y forward
    Length units: meters
    Angles: SPHERICAL coord angles in degrees (perfectly models yaw/pitch)
        alpha: yaw (pan) angle in deg: 0 = forward (+y dir), +=right
        beta: pitch (tilt) angle in deg: 0 = forward (+y dir), +=up

  _____________________________________________________________________________
*/
const assert_1 = require("./assert");
const models_1 = require("./models");
// Tables generated by fitting empirical data
const tableRPM2MPH_1 = require("./tableRPM2MPH");
const tableLimits_1 = require("./tableLimits");
const tableTrainerGeometry_1 = require("./tableTrainerGeometry");
const util_1 = require("./util");
const tableSpinAdjust_1 = require("./tableSpinAdjust");
/**
 * PHYSICSMODEL
 * Singleton physics model
 */
const defaultPhysicsModelName = "physics-20B-base";
const defaultSport = "PLATFORM_TENNIS";
(0, assert_1.assert)(defaultPhysicsModelName in tableRPM2MPH_1.tableRPM2MPHMap, `no RPM2MPH mapping for physics model ${defaultPhysicsModelName}`);
(0, assert_1.assert)(defaultSport in tableRPM2MPH_1.tableRPM2MPHMap[defaultPhysicsModelName], `no RPM2MPH mapping for physics model ${defaultPhysicsModelName}, sport ${defaultSport}`);
const defRPM2MPH = tableRPM2MPH_1.tableRPM2MPHMap[defaultPhysicsModelName][defaultSport];
(0, assert_1.assert)(defaultPhysicsModelName in tableLimits_1.tableLimitsMap, `no trainer limits mapping for physics model ${defaultPhysicsModelName}`);
(0, assert_1.assert)(defaultSport in tableLimits_1.tableLimitsMap[defaultPhysicsModelName], `no trainer limits mapping for physics model ${defaultPhysicsModelName}, sport ${defaultSport}`);
const defLimitsRPM = tableLimits_1.tableLimitsMap[defaultPhysicsModelName][defaultSport].RPM;
(0, assert_1.assert)(defaultPhysicsModelName in tableTrainerGeometry_1.tableTrainerGeometryMap, `no trainer geometry mapping for physics model ${defaultPhysicsModelName}`);
const defTrainerGeometry = tableTrainerGeometry_1.tableTrainerGeometryMap[defaultPhysicsModelName];
(0, assert_1.assert)(defaultSport in models_1.CourtGeometryBySport, `no court geometry mapping for sport ${defaultSport}`);
(0, assert_1.assert)(defaultPhysicsModelName in tableSpinAdjust_1.tableSpinAdjustMap, `no spin adjustment mapping for physics model ${defaultPhysicsModelName}`);
(0, assert_1.assert)(defaultSport in tableSpinAdjust_1.tableSpinAdjustMap[defaultPhysicsModelName], `no spin adjustment mapping for physics model ${defaultPhysicsModelName}, sport ${defaultSport}`);
const defSpinAdjust = tableSpinAdjust_1.tableSpinAdjustMap[defaultPhysicsModelName][defaultSport];
const PHYSICSMODEL = {
    name: defaultPhysicsModelName,
    sport: defaultSport,
    ballParams: models_1.BallParamsBySport[defaultSport],
    mapRPM2MPH: defRPM2MPH,
    simParams: models_1.DefaultSimulationParams,
    trainerGeometry: defTrainerGeometry,
    constants: models_1.DefaultPhysicalConstants,
    limits: {
        RPM: defLimitsRPM,
        MPS: {
            limit: defRPM2MPH[defLimitsRPM.limit] * 0.44704,
            maxSafe: defRPM2MPH[defLimitsRPM.maxSafe] * 0.44704,
            minSafe: defRPM2MPH[defLimitsRPM.minSafe] * 0.44704,
        },
    },
    courtGeometry: models_1.CourtGeometryBySport[defaultSport],
    spinAdjust: defSpinAdjust,
};
/**
 * setPhysicsModel
 * Set the internal physics model used to predict/simulate ball launch, etc.
 * @param trainer  trainer type
 * @param sport  sport to model
 */
function setPhysicsModel(physicsModelName, sport) {
    (0, assert_1.assert)(physicsModelName in tableRPM2MPH_1.tableRPM2MPHMap, `no RPM2MPH mapping for physics model ${physicsModelName}`);
    (0, assert_1.assert)(sport in tableRPM2MPH_1.tableRPM2MPHMap[physicsModelName], `no RPM2MPH mapping for physics model ${physicsModelName}, sport ${sport}`);
    (0, assert_1.assert)(physicsModelName in tableLimits_1.tableLimitsMap, `no physicsModelName limits mapping for physics model ${physicsModelName}`);
    (0, assert_1.assert)(sport in tableLimits_1.tableLimitsMap[physicsModelName], `no physicsModelName limits mapping for physics model ${physicsModelName}, sport ${sport}`);
    (0, assert_1.assert)(sport in models_1.CourtGeometryBySport, `no court geometry mapping for sport ${sport}`);
    (0, assert_1.assert)(physicsModelName in tableTrainerGeometry_1.tableTrainerGeometryMap, `no trainer geometry mapping for physics model ${physicsModelName}`);
    (0, assert_1.assert)(physicsModelName in tableSpinAdjust_1.tableSpinAdjustMap, `no spin adjustment mapping for physics model ${physicsModelName}`);
    (0, assert_1.assert)(sport in tableSpinAdjust_1.tableSpinAdjustMap[physicsModelName], `no spin adjustment mapping for sport ${sport}`);
    const limitsRPM = tableLimits_1.tableLimitsMap[physicsModelName][sport].RPM;
    const RPMtoMPH = tableRPM2MPH_1.tableRPM2MPHMap[physicsModelName][sport];
    const trainerGeometry = tableTrainerGeometry_1.tableTrainerGeometryMap[physicsModelName];
    const spinAdjust = tableSpinAdjust_1.tableSpinAdjustMap[physicsModelName][sport];
    // --- set the model ---
    PHYSICSMODEL.name = physicsModelName;
    PHYSICSMODEL.sport = sport;
    PHYSICSMODEL.ballParams = models_1.BallParamsBySport[sport];
    PHYSICSMODEL.mapRPM2MPH = RPMtoMPH;
    PHYSICSMODEL.simParams = models_1.DefaultSimulationParams;
    PHYSICSMODEL.trainerGeometry = trainerGeometry;
    PHYSICSMODEL.constants = models_1.DefaultPhysicalConstants;
    PHYSICSMODEL.limits.RPM = limitsRPM;
    PHYSICSMODEL.courtGeometry = models_1.CourtGeometryBySport[sport];
    PHYSICSMODEL.spinAdjust = spinAdjust;
    // MPS limits are derived from RPM limits (note: converting MPH to MPS)
    PHYSICSMODEL.limits.MPS.limit = RPMtoMPH[limitsRPM.limit] * 0.44704;
    PHYSICSMODEL.limits.MPS.maxSafe = RPMtoMPH[limitsRPM.maxSafe] * 0.44704;
    PHYSICSMODEL.limits.MPS.minSafe = RPMtoMPH[limitsRPM.minSafe] * 0.44704;
}
exports.setPhysicsModel = setPhysicsModel;
/**
 * getPhysicsModel
 * Get the currently used physics model name.
 */
function getPhysicsModel() {
    return PHYSICSMODEL;
}
exports.getPhysicsModel = getPhysicsModel;
/**
 * crossProduct
 * Compute the cross product of 2 non-zero vectors
 * @param a  Vector
 * @param b  Vector
 * Returns: a unit vector that is the cross-product (a x b)
 */
function crossProduct(a, b) {
    const out = {
        x: a.y * b.z - b.y * a.z,
        y: -(a.x * b.z - b.x * a.z),
        z: a.x * b.y - b.x * a.y,
    };
    const mag = Math.sqrt(out.x * out.x + out.y * out.y + out.z * out.z);
    out.x /= mag;
    out.y /= mag;
    out.z /= mag;
    return out;
}
exports.crossProduct = crossProduct;
/**
 * yawpitch2launchpoint
 * Convert trainer yaw (pan) and pitch (tilt) to a launch point.
 * This conversion tranlates the wheel assembly rotations into the point from which
 * the ball will be launched (launch coords).
 * @param: yawDeg: yaw (pan) angle in degrees: forward (+y) is 0, + = right
 * @param: pitchDeg: pitch (tilt) angle in degrees: forward (+y) is 0, + = up
 * @returns the ball launch point in launch coords
 */
function yawpitch2launchpoint(yawDeg, pitchDeg) {
    const R = PHYSICSMODEL.trainerGeometry.launchDistanceFromRotationCenter;
    (0, assert_1.assert)(Math.abs(yawDeg) <= 90 && Math.abs(pitchDeg) <= 90, `yaw and pitch (${yawDeg}, ${pitchDeg}) must be in [-90, 90]`);
    const lp_launch = {
        x: R * Math.cos(pitchDeg * (Math.PI / 180.0)) * Math.sin(yawDeg * (Math.PI / 180.0)),
        y: R * Math.cos(pitchDeg * (Math.PI / 180.0)) * Math.cos(yawDeg * (Math.PI / 180.0)),
        z: R * Math.sin(pitchDeg * (Math.PI / 180.0)),
    };
    return lp_launch;
}
exports.yawpitch2launchpoint = yawpitch2launchpoint;
/**
 * trainer2lift
 * Convert a point in trainer coords to lift coords.
 * Trainer coords: origin is on court below center of rear axle, +x is right wrt trainer, +y is forward, +z is up
 * lift coords: origin is at end of lift arm when arm is parallel to ground (fully extended)
 * @param pTrainer: point in trainer coords
 * @returns same point in lift coords
 */
function trainer2lift(pTrainer) {
    const lpp = PHYSICSMODEL.trainerGeometry.liftPivotPosition;
    const armLen = PHYSICSMODEL.trainerGeometry.liftArmLength;
    const liftOrigin = { x: lpp.x, y: lpp.y + armLen, z: lpp.z };
    const liftRot = { x: 0, y: 0, z: 0 };
    const pLift = (0, util_1.coordChange)(pTrainer, liftOrigin, liftRot);
    return pLift;
}
exports.trainer2lift = trainer2lift;
/**
 * lift2trainer
 * Convert a point in lift to trainer coords.
 * lift coords: origin is at end of lift arm when arm is parallel to ground (fully extended)
 * Trainer coords: origin is on court below center of rear axle, +x is right wrt trainer, +y is forward, +z is up
 * @param pLift: point in lift coords
 * @returns same point in trainer coords
 */
function lift2trainer(pLift) {
    const lpp = PHYSICSMODEL.trainerGeometry.liftPivotPosition;
    const armLen = PHYSICSMODEL.trainerGeometry.liftArmLength;
    const trainerOrigin = { x: -lpp.x, y: -(lpp.y + armLen), z: -lpp.z };
    const trainerRot = { x: 0, y: 0, z: 0 };
    const pTrainer = (0, util_1.coordChange)(pLift, trainerOrigin, trainerRot);
    return pTrainer;
}
exports.lift2trainer = lift2trainer;
/**
 * localization2physics
 * Convert a point in localization coords to physics coords.
 * Localization coords (NOTE: mm): origin is upper-left corner of court, +x right, +y into court, +z up
 * Physics coords (m): origin is court center, +x right, +y toward top baseline, +z up
 * @param pLocalization: point in localization coords
 * @returns point pLocalization in physics coords
 */
function localization2physics(pLocalization) {
    const CW = PHYSICSMODEL.courtGeometry.COURT_WIDTH;
    const CL = PHYSICSMODEL.courtGeometry.COURT_LENGTH;
    const pPoint = { x: 0, y: 0, z: 0 };
    pPoint.x = (pLocalization.x / 1000.0) - CW / 2.0;
    pPoint.y = (-1.0 * (pLocalization.y / 1000.0)) + CL / 2.0;
    pPoint.z = (pLocalization.z / 1000.0);
    return pPoint;
}
exports.localization2physics = localization2physics;
/**
 * physics2localization
 * Convert a point in physics coords to localization coords.
 * Physics coords (m): origin is court center, +x right, +y toward top/far baseline, +z up
 * Localization coords (NOTE: mm): origin is upper-left corner of court, +x right, +y into court, +z up
 * @param pPhysics: point in physics coords
 * @returns same point in localization coords
 */
function physics2localization(pPhysics) {
    const CW = PHYSICSMODEL.courtGeometry.COURT_WIDTH;
    const CL = PHYSICSMODEL.courtGeometry.COURT_LENGTH;
    const lPoint = { x: 0, y: 0, z: 0 };
    lPoint.x = (pPhysics.x + CW / 2.0) * 1000.0;
    lPoint.y = -1.0 * (pPhysics.y - CL / 2.0) * 1000.0;
    lPoint.z = (pPhysics.z * 1000.0);
    return lPoint;
}
exports.physics2localization = physics2localization;
/**
 * trainer2physics
 * Convert a point in trainer coords to physics coords, given the position and orientation
 * of the trainer in physics coords.
 * Physics coords: origin is court center, +x right, +y toward top/far baseline, +z up
 * Trainer coords: origin is on court below center of rear axle, +x is right wrt trainer, +y is forward, +z is up
 * @param pTrainer: point in trainer coords
 * @param pTrainerPosition: the position of the trainer in physics coords
 * @param trainerYaw: the yaw angle of the trainer in degrees (0 = down trainer y axis with +=right)
 * @returns same point in physics coords
 */
function trainer2physics(pTrainer, pTrainerPosition, trainerYaw) {
    // first, rotate the trainer axes to align them with physics coords
    const pAligned = (0, util_1.coordChange)(pTrainer, { x: 0, y: 0, z: 0 }, { x: 0, y: 0, z: trainerYaw });
    // Now the coordinates of physics system origin in aligned coords are simply
    // the inverse of the trainer position passed in
    const newOrigin = { x: -1.0 * pTrainerPosition.x, y: -1.0 * pTrainerPosition.y, z: 0 };
    const pPoint = (0, util_1.coordChange)(pAligned, newOrigin, { x: 0, y: 0, z: 0 });
    return pPoint;
}
exports.trainer2physics = trainer2physics;
/**
 * physics2trainer
 * Convert a point in physics coords to trainer coords, given the position and orientation
 * of the trainer in physics coords.
 * Physics coords: origin is court center, +x right, +y toward top/far baseline, +z up
 * Trainer coords: origin is on court below center of rear axle, +x is right wrt trainer, +y is forward, +z is up
 * @param pPhysics: point in physics coords
 * @param pTrainerPosition: the position of the trainer in physics coords
 * @param trainerYaw: the yaw angle of the trainer in degrees (0 = down trainer y axis with +=right)
 * @returns same point in physics coords
 */
function physics2trainer(pPhysics, pTrainerPosition, trainerYaw) {
    // The new coordinate system origin is the trainer position
    const newOrigin = pTrainerPosition;
    // The rotation that takes physics coords to trainer coords
    const rotZDegrees = -1.0 * trainerYaw;
    const pTrainer = (0, util_1.coordChange)(pPhysics, newOrigin, { x: 0, y: 0, z: rotZDegrees });
    return pTrainer;
}
exports.physics2trainer = physics2trainer;
/**
 * motorspin2ballspin
 * Convert ball spin in motor RPM (motor RPM is the equivalent motor RPM induced by the trainer motors
 * around a spin axis) to ball spin in ball RPMs (ball rotation RPMs). Ball spin RPM is higher than
 * motor RPM proportional to the wheel diameter ratio "gain" (i.e. big motor wheel spinning small ball).
 * @param motorspinRPM: spin magnitude in motor RPM
 * returns: spin magnitude in ball RPM
 */
function motorspin2ballspin(motorspinRPM) {
    const ballDiam = PHYSICSMODEL.ballParams.diameter;
    const wheelDiam = PHYSICSMODEL.ballParams.diameter;
    // diameters of ball and wheel in meters
    (0, assert_1.assert)(motorspinRPM >= 0, `ball spin in motor rpm ${motorspinRPM} must be >=0`);
    (0, assert_1.assert)(ballDiam > 0, `ball diameter ${ballDiam} must be >0`);
    (0, assert_1.assert)(wheelDiam > 0, `wheel diameter ${wheelDiam} must be >0`);
    const ballspinRPM = (wheelDiam / ballDiam) * motorspinRPM;
    return ballspinRPM;
}
exports.motorspin2ballspin = motorspin2ballspin;
/**
 * ballspin2motorspin
 * Convert ball spin in ball RPM to ball spin in motor RPMs.
 * @param ballspinRPM: spin magnitude in motor RPM
 * returns: spin magnitude in motor RPM
 */
function ballspin2motorspin(ballspinRPM) {
    const ballDiam = PHYSICSMODEL.ballParams.diameter;
    const wheelDiam = PHYSICSMODEL.ballParams.diameter;
    // diameters of ball and wheel in meters
    (0, assert_1.assert)(ballspinRPM >= 0, `ball spin in ball rpm ${ballspinRPM} must be >=0`);
    (0, assert_1.assert)(ballDiam > 0, `ball diameter ${ballDiam} must be >0`);
    (0, assert_1.assert)(wheelDiam > 0, `wheel diameter ${wheelDiam} must be >0`);
    const motorspinRPM = (ballDiam / wheelDiam) * ballspinRPM;
    return motorspinRPM;
}
exports.ballspin2motorspin = ballspin2motorspin;
/**
 * motorspin2spinlevel
 * Convert an "analog" ball spin in motor RPM to a "digital" integer spin level in [0, 10] based on
 * the max motor spin RPM achievable by the trainer.  Level 0 (no spin) occurs only for motorspinRPM
 * 0. Positive motorspinRPM values will be mapped to equal-sized bins 1-10 covering the interval
 * (0, maxSpin], where maxSpin is the max spin RPM the trainer can achieve.
 * @param motorspinRPM: spin magnitude in motor RPM
 * returns: integer spin level in [0, 10]
 */
function motorspin2spinlevel(motorspinRPM) {
    const maxMotorSpinRPM = PHYSICSMODEL.limits.RPM.maxSpin;
    (0, assert_1.assert)(motorspinRPM >= 0 && motorspinRPM <= maxMotorSpinRPM, `motor spin RPM (${motorspinRPM}) must in [0, ${maxMotorSpinRPM}]`);
    if (motorspinRPM === 0) {
        return 0;
    }
    const levelFraction = (motorspinRPM / maxMotorSpinRPM);
    // Why is scale factor 9.999 (and the "+1") used instead of scale factor of 10?
    // So that all values in (0, 1/10th of max) map to 1, etc...
    return (Math.floor(levelFraction * 9.999) + 1);
}
exports.motorspin2spinlevel = motorspin2spinlevel;
/**
 * spinlevel2motorspin
 * Convert a "digital" ball spin level in [0, 10] to a specific motor RPM spin. Level 0 yields 0.
 * The motor RPM spins returned for levels 1-10 correspond to the RPMs at the midpoint of
 * the 10 equisized bins covering (0, maxSpin], where maxSpin is the max achievable spin RPM.
 * @param spinLevel: spin level in [0,10]
 * return: spin motor RPM at the midpoint of the interval corresponding to the level
 */
function spinlevel2motorspin(spinLevel) {
    const maxMotorSpinRPM = PHYSICSMODEL.limits.RPM.maxSpin;
    (0, assert_1.assert)(spinLevel >= 0 && spinLevel <= 10 && Number.isInteger(spinLevel), `spin level ${spinLevel} must be an integer in [0,10]`);
    if (spinLevel === 0) {
        return 0;
    }
    return (spinLevel / 10.0 - 0.05) * maxMotorSpinRPM;
}
exports.spinlevel2motorspin = spinlevel2motorspin;
/**
 * ballspin2spinlevel
 * @param ballspinRPM: ball spin in ball RPM
 * @returns spinlevel in [0, 10]
 */
function ballspin2spinlevel(ballspinRPM) {
    const motor_spin = ballspin2motorspin(ballspinRPM);
    return motorspin2spinlevel(motor_spin);
}
exports.ballspin2spinlevel = ballspin2spinlevel;
/**
 * spinlevel2ballspin
 * @param spinlevel:spinlevel in [0, 10]
 * @returns ball spin in ball RPM
 */
function spinlevel2ballspin(spinlevel) {
    const motor_spin = spinlevel2motorspin(spinlevel);
    return motorspin2ballspin(motor_spin);
}
exports.spinlevel2ballspin = spinlevel2ballspin;
/**
 * maxmotorspin_for_limit (private)
 * For a given angle and launch speed in motor RPM, returns the max motor spin RPM
 * attainable constrainted by the specified motor RPM limit.
 * Returns a positive motor spin RPM value.
 * @param angle: spin axis angle in degrees
 * @param launch_speed_rpm: ball launch speed in motor RPM
 * @param rpm_limit: the RPM limit
 * returns: max possible motor RPM spin possible under the limit constraint.
 */
function maxmotorspin_for_limit(angle, launch_speed_rpm, rpm_limit) {
    const minRPM = PHYSICSMODEL.limits.RPM.minSafe;
    const maxRPM = PHYSICSMODEL.limits.RPM.maxSafe;
    (0, assert_1.assert)(angle >= 0 && angle <= 360, `angle ${angle} must be in [0, 360]`);
    (0, assert_1.assert)(launch_speed_rpm >= minRPM && launch_speed_rpm <= maxRPM, `launch speed RPM ${launch_speed_rpm} must be >=${minRPM} and <= ${maxRPM}`);
    (0, assert_1.assert)(rpm_limit >= minRPM && rpm_limit <= maxRPM, `RPM limit ${rpm_limit} must be >= ${minRPM} and <= ${maxRPM}`);
    if (launch_speed_rpm === 0 || launch_speed_rpm === rpm_limit) {
        return 0;
    }
    let spin_t = 1e6;
    let spin_l = 1e6;
    let spin_r = 1e6;
    const deg2rad = (Math.PI / 180.0);
    let spin_t_denom = Math.cos(angle * deg2rad);
    let spin_l_denom = Math.cos(angle * deg2rad) - Math.sqrt(3) * Math.sin(angle * deg2rad);
    let spin_r_denom = Math.cos(angle * deg2rad) + Math.sqrt(3) * Math.sin(angle * deg2rad);
    if (Math.abs(spin_t_denom) < 1.0e-8) {
        spin_t_denom = 0;
    }
    if (Math.abs(spin_l_denom) < 1.0e-8) {
        spin_l_denom = 0;
    }
    if (Math.abs(spin_r_denom) < 1.0e-8) {
        spin_r_denom = 0;
    }
    // flip ensures that we return only positive spins
    const flip = Math.sign(launch_speed_rpm - rpm_limit);
    if (flip * spin_t_denom < 0) {
        spin_t = -1.5 * (launch_speed_rpm - rpm_limit) * (1.0 / spin_t_denom);
    }
    if (flip * spin_l_denom > 0) {
        spin_l = 3.0 * (launch_speed_rpm - rpm_limit) * (1.0 / spin_l_denom);
    }
    if (flip * spin_r_denom > 0) {
        spin_r = 3.0 * (launch_speed_rpm - rpm_limit) * (1.0 / spin_r_denom);
    }
    const max_spin = Math.min(spin_t, spin_l, spin_r);
    return max_spin;
}
/**
 * maxmotorspin_for_rpmspeed
 * For a given angle and launch speed in motor RPM, returns the max spin attainable
 * by the trainer in motor RPM.
 * @param angle: spin axis angle in degrees
 * @param launch_speed_rpm: ball launch speed in motor RPM
 * returns: max possible motor RPM spin for (angle, launch_speed_rpm).
 * NOTE: depends on physics model lower (0) and upper (RPMMaxSafe) limits on
 * individual motor RPM.
 */
function maxmotorspin_for_rpmspeed(angle, launch_speed_rpm) {
    if (launch_speed_rpm === 0) {
        return 0;
    }
    const rpm_limit_low = PHYSICSMODEL.limits.RPM.minSafe;
    const rpm_limit_high = PHYSICSMODEL.limits.RPM.maxSafe;
    const max_spin1 = maxmotorspin_for_limit(angle, launch_speed_rpm, rpm_limit_high);
    const max_spin2 = maxmotorspin_for_limit(angle, launch_speed_rpm, rpm_limit_low);
    return Math.min(max_spin1, max_spin2);
}
exports.maxmotorspin_for_rpmspeed = maxmotorspin_for_rpmspeed;
/**
 * maxspinlevel_for_rpmspeed
 * For a given angle and launch speed in motor RPM, returns the max ball spin
 * level [0-10] attainable by the trainer.
 * @param angle: spin axis angle in degrees
 * @param launch_speed_rpm: ball launch speed in motor RPM
 * @returns the max spin level in [0, 10] achievable for (angle, launch speed)
 */
function maxspinlevel_for_rpmspeed(angle, launch_speed_rpm) {
    if (launch_speed_rpm === 0) {
        return 0;
    }
    const max_motor_spin = maxmotorspin_for_rpmspeed(angle, launch_speed_rpm);
    const max_lev = motorspin2spinlevel(max_motor_spin);
    if (max_lev === 0) {
        return 0;
    }
    // the spin level corresponding to max motor spin might yield spin motor RPM
    // > max motor spin (since max motor spin can land anywhere in the level
    // interval and a level's spin RPM is at 0.95 of the max). In this case, use
    // the next level down.
    const max_lev_rpm = spinlevel2motorspin(max_lev);
    return (max_lev_rpm > max_motor_spin) ? (max_lev - 1) : max_lev;
}
exports.maxspinlevel_for_rpmspeed = maxspinlevel_for_rpmspeed;
/**
 * maxspinlevel
 * For a given angle and launch speed in m/sec, returns the max ball spin
 * level [0-10] attainable by the trainer.
 * @param angle: spin axis angle (degrees)
 * @param speed: launch speed (m/sec)
 */
function maxspinlevel(angle, speed) {
    const speed_rpm = mps2rpm(speed);
    return maxspinlevel_for_rpmspeed(angle, speed_rpm);
}
exports.maxspinlevel = maxspinlevel;
/**
 * rpm2mps
 * Returns the launch speed in m/sec corresponding to the specified trainer
 * motor RPMs (with all motors set to the same RPMs; i.e. a knuckleball).
 * NOTE: input RPM values are rounded to the nearest integer RPM and
 * the speeds read from a table generated by fitting measured data.
 * NOTE: input rpms must be in [-4000,4000] (limits of measured data).
 * @param rpm motor RPM
 * @returns launch speed in m/sec
 */
function rpm2mps(rpm) {
    const minRPM = PHYSICSMODEL.limits.RPM.minSafe;
    const maxRPM = PHYSICSMODEL.limits.RPM.maxSafe;
    // assert(rpm >= physicsModel.RPMMinSafe && rpm <= physicsModel.RPMMaxSafe,
    //     `rpm ${rpm} must be >= ${physicsModel.RPMMinSafe} && <= ${physicsModel.RPMMaxSafe}`);
    (0, assert_1.assert)(rpm >= 0, `rpm is ${rpm}: must be >=0`);
    if (rpm > maxRPM) {
        console.warn(`rpm2mps: ** WARNING **: rpm ${rpm} high, limiting to ${maxRPM}`);
        rpm = maxRPM;
    }
    if (rpm < minRPM) {
        console.warn(`rpm2mps: ** WARNING **: rpm ${rpm} low, limiting to ${minRPM}`);
        rpm = minRPM;
    }
    const irpm = Math.round(rpm);
    // Read from RPM2MPH table and convert to mps
    const mps = PHYSICSMODEL.mapRPM2MPH[irpm] * 0.44704;
    return mps;
}
exports.rpm2mps = rpm2mps;
/**
 * mps2rpm
 * Returns the motor RPM (constant for all motors) that correspond
 * to the specified launch speed in m/sec.
 * NOTE: input mps must be in [-28,28] (limits of measured data).
 * NOTE: values are determined by doing closest-element lookup
 * in table RPM2MPH. This is expensive but assures close to
 * perfect invertibility of the mps2rpm() and rpm2mps()
 * functions.
 * @param mps launch speed in m/sec
 * @returns motor RPM
 */
function mps2rpm(mps) {
    const minMPS = PHYSICSMODEL.limits.MPS.minSafe;
    const maxMPS = PHYSICSMODEL.limits.MPS.maxSafe;
    // assert(mps >= physicsModel.MPSMinSafe && mps <= physicsModel.MPSMaxSafe,
    //     `mps ${mps} must be >= ${physicsModel.MPSMinSafe} && <= ${physicsModel.MPSMaxSafe}`);
    (0, assert_1.assert)(mps >= 0, `mps is ${mps}: must be >=0`);
    if (mps > maxMPS) {
        // eslint-disable-next-line max-len
        console.warn(`mps2rpm: ** WARNING **: internal scaled mps ${mps} high, limiting to ${maxMPS}`);
        mps = maxMPS;
    }
    if (mps < minMPS) {
        // eslint-disable-next-line max-len
        console.warn(`mps2rpm: ** WARNING **: internal scaled mps ${mps} low, limiting to ${minMPS}`);
        mps = minMPS;
    }
    // convert to mph
    const imph = mps / 0.44704;
    // find the RPM that gives the closest mps match
    const [err, rpm] = PHYSICSMODEL.mapRPM2MPH.map((mphVal, ind) => [Math.abs(imph - mphVal), ind])
        .reduce((curMin, diffAndInd) => (((curMin[0] < 0) || (diffAndInd[0] < curMin[0])) ? diffAndInd : curMin), [-1, -1]);
    if (Math.abs(err) > 10) {
        console.warn(`mps2rpm: ** WARNING **: mps=${mps}: large error in RPM value (err: ${err})`);
    }
    const rpmout = rpm;
    // console.log(`mps2rpm: ${mps} --> ${rpmout} (err=${err}])`)
    return rpmout;
}
exports.mps2rpm = mps2rpm;
/**
 * rpm2speedspin
 * Convert from motor RPMs shot representation to "speed and spin" representation.
 * @param rpms  RPM3 object specifying top, left, and right motor RPMs
 * @returns  The speed+spin representation of the shot.
 */
function rpm2speedspin(rpms) {
    const ss = {
        spinAxis: 0, speed: 0, spin: 0, spinLevel: 0,
    };
    const vx = rpms.top - 0.5 * rpms.left - 0.5 * rpms.right;
    const vy = (Math.sqrt(3) / 2.0) * rpms.left - (Math.sqrt(3) / 2.0) * rpms.right;
    const sl_rpm = (rpms.top + rpms.left + rpms.right) / 3.0;
    // console.log(`rpm2speedspin_l: t=${rpms.top}, l=${rpms.left}, r=${rpms.right}`);
    // console.log(`rpm2speedspin_l: launch speed RPM: ${sl_rpm}`);
    // console.log(`rpm2speedspin_l: vx=${vx}, vy=${vy}`);
    ss.speed = rpm2mps(sl_rpm);
    if (sl_rpm === 0 || ss.speed === 0) {
        return ss;
    }
    // extract axis angle from spin vector
    ss.spinAxis = vector2Angle(vx, vy);
    // Ball spin motor RPM is equal to the magnitude of the spin vector
    let spinMotorRPM = Math.sqrt(0.5 * ((rpms.left - rpms.right) * (rpms.left - rpms.right)
        + (rpms.right - rpms.top) * (rpms.right - rpms.top)
        + (rpms.left - rpms.top) * (rpms.left - rpms.top)));
    // Adjust speed (in spin case only) based on motor alignment of spin
    if (spinMotorRPM > 0 && PHYSICSMODEL.spinAdjust.speedDampingFactor) {
        // const oldSpeed = ss.speed;
        ss.speed /= PHYSICSMODEL.spinAdjust.speedDampingFactor(ss.spinAxis);
        // console.log(`rpm2speedspin: speed adjust: ${oldSpeed} -> ${ss.speed}`);
    }
    // Spin motor RPM is the target ball spin. This is the place where we
    // can apply spin adjustment based on how the spin axis aligns with motors.
    if (PHYSICSMODEL.spinAdjust.spinDampingFactor) {
        // const oldSpin = spinMotorRPM;
        spinMotorRPM /= PHYSICSMODEL.spinAdjust.spinDampingFactor(ss.spinAxis);
        // console.log(`rpm2speedspin: spin adjust: ${oldSpin} -> ${spinMotorRPM}`);
    }
    // set spin
    ss.spin = motorspin2ballspin(spinMotorRPM);
    ss.spinLevel = motorspin2spinlevel(spinMotorRPM);
    return ss;
}
exports.rpm2speedspin = rpm2speedspin;
/**
 * speedspin2rpm
 * Convert a shot in speedspin format to motor RPM values.
 * @param speedSpin: shot in speedspin form
 * @returns RPM3 object of motor RPM values (top, left, right)
 */
function speedspin2rpm(speedSpin) {
    const rpms = { left: 0, top: 0, right: 0 };
    (0, assert_1.assert)(speedSpin.speed >= 0, `speedspin2rpm: illegal speed ${speedSpin.speed}: must be >=0`);
    // Spin can be specified using spin (ball RPMs) or spinLevel([0-10]) but not both.
    (0, assert_1.assert)(
    // eslint-disable-next-line max-len
    (speedSpin.spin === null && speedSpin.spinLevel !== null) || (speedSpin.spin !== null && speedSpin.spinLevel === null), "speedspin2rpm: exactly 1 of (spin, spinLevel) must be specified");
    let shotSpeed = speedSpin.speed;
    // Does this shot incorporate spin?
    const hasSpin = (speedSpin.spin !== null && speedSpin.spin > 0)
        || (speedSpin.spinLevel !== null && speedSpin.spinLevel > 0);
    // Adjust speed (in spin case only) based on motor alignment of spin
    if (hasSpin && PHYSICSMODEL.spinAdjust.speedDampingFactor) {
        // const oldSpeed = shotSpeed;
        const df = PHYSICSMODEL.spinAdjust.speedDampingFactor(speedSpin.spinAxis);
        shotSpeed *= df;
        // console.log(`speedspin2rpm: axis: ${speedSpin.spinAxis}, df: ${df}, speed adjust: ${oldSpeed} -> ${shotSpeed}`);
    }
    // convert launch speed mps to motor rpm
    // fast return if launch speed is 0
    const launchSpeedRPM = mps2rpm(shotSpeed);
    // eslint-disable-next-line max-len
    // console.log(`speedspin2rpm: mps=${shotSpeed}, RPM=${launchSpeedRPM}, spin=${speedSpin.spin}, L=${speedSpin.spinLevel}, A=${speedSpin.spinAxis}`);
    if (launchSpeedRPM === 0) {
        return rpms;
    }
    let spinMotorRPM = 0;
    let vx = 0;
    let vy = 0;
    if (hasSpin) {
        let spinQuantity = 0;
        let spinType = "";
        // spin: use units of motor RPM
        if (speedSpin.spinLevel !== null) {
            spinQuantity = speedSpin.spinLevel;
            spinType = "level";
            spinMotorRPM = spinlevel2motorspin(speedSpin.spinLevel);
            // console.log(`speedspin2rpm: using motor spin RPM: ${spinMotorRPM}`);
        }
        if (speedSpin.spin !== null) {
            spinQuantity = speedSpin.spin;
            spinType = "ball RPM";
            spinMotorRPM = ballspin2motorspin(speedSpin.spin);
        }
        // spin must be achievable!
        const maxSpinMotorRPM = maxmotorspin_for_rpmspeed(speedSpin.spinAxis, launchSpeedRPM);
        if (spinMotorRPM > maxSpinMotorRPM) {
            spinMotorRPM = maxSpinMotorRPM;
        }
        // Spin motor RPM is the target ball spin. This is the place where we apply
        // spin adjustment based on how the spin axis aligns with motors.
        if (PHYSICSMODEL.spinAdjust.spinDampingFactor) {
            // const oldSpin = spinMotorRPM;
            const df = PHYSICSMODEL.spinAdjust.spinDampingFactor(speedSpin.spinAxis);
            spinMotorRPM *= df;
            // console.log(`speedspin2rpm: axis: ${speedSpin.spinAxis}, df: ${df}, spin adjust: ${oldSpin} -> ${spinMotorRPM}`);
        }
        // compute spin vector (vx, vy)
        const [ux, uy] = [
            Math.cos(speedSpin.spinAxis * (Math.PI / 180.0)),
            Math.sin(speedSpin.spinAxis * (Math.PI / 180.0)),
        ];
        vx = ux * spinMotorRPM;
        vy = uy * spinMotorRPM;
    }
    // console.log(`ss2rpm: vx=${vx}, vy=${vy}`);
    // eslint-disable-next-line max-len
    // console.log(`speedspin2rpm NEW: Axis=${speedSpin.spinAxis}, launchRPM=${launchSpeedRPM}, spinRPM=${spinMotorRPM}, VX=${vx}, VY=${vy}`);
    // matrix operation to get motor RPMs
    rpms.top = (2.0 / 3) * vx + launchSpeedRPM;
    rpms.left = (-1.0 / 3) * vx + (1.0 / Math.sqrt(3)) * vy + launchSpeedRPM;
    rpms.right = (-1.0 / 3) * vx - (1.0 / Math.sqrt(3)) * vy + launchSpeedRPM;
    /*
    // make motor RPMs integers
    rpms.top = Math.round(rpms.top);
    rpms.left = Math.round(rpms.left);
    rpms.right = Math.round(rpms.right);
    */
    // console.log(`speedspin2rpm: TOP=${rpms.top}, LEFT = ${rpms.left}, RIGHT = ${rpms.right}`);
    return rpms;
}
exports.speedspin2rpm = speedspin2rpm;
/**
 * angle2Vector
 * Return the 2D unit vector on the (unit) circle corresponding to a specified
 * counterclockwise rotation angle (in degrees).
 * @param angDeg
 * returns array [vx, vy] of unit vector corresponding to the rotation angle
 */
function angle2Vector(angDeg) {
    const c = Math.cos(angDeg * Math.PI / 180.0);
    const s = Math.sin(angDeg * Math.PI / 180.0);
    return [c, s];
}
exports.angle2Vector = angle2Vector;
/**
 * vector2Angle
 * Return the rotation angle in degrees [0,360) of the
 * specified 2D vector (vx, vy).
 * @param vx  X coordinate of vector
 * @param vy  Y coordinate of vector
 * @returns  Rotation angle in degrees in [0, 360)
 */
function vector2Angle(vx, vy) {
    let ang = 0;
    if (vx === 0 || vy === 0) {
        if (vx === 0) {
            ang = (vy === 0) ? 0 : ((vy > 0) ? 90.0 : 270.0);
        }
        if (vy === 0) {
            ang = (vx === 0) ? 0 : ((vx > 0) ? 0.0 : 180.0);
        }
    }
    else {
        // quadrant 1
        ang = Math.atan(Math.abs(vy) / Math.abs(vx)) * 180.0 / Math.PI;
        // quadrant 4
        if (vx > 0 && vy < 0) {
            ang = 360.0 - ang;
        }
        // quadrant 2
        else if (vx < 0 && vy > 0) {
            ang = 180.0 - ang;
        }
        // quadrant 3
        else if (vx < 0 && vy < 0) {
            ang = 180.0 + ang;
        }
    }
    return ang;
}
exports.vector2Angle = vector2Angle;
/**
 * cameraHeight2HeadHeight
 * Return the Head height in meters corresponding to given cameraHeight specified in meters.
 * @param cameraHeightMeters Z coordinate of the camera in meters from the ground
 * @returns  Z coordinate of the head height in meters from the ground
 */
function cameraHeight2HeadHeight(cameraHeightMeters) {
    const cameraHeightOffset = PHYSICSMODEL.trainerGeometry.cameraOffsetFromHead.z;
    const headHeight = cameraHeightMeters - cameraHeightOffset;
    return headHeight;
}
exports.cameraHeight2HeadHeight = cameraHeight2HeadHeight;
/**
 * launchHeight2HeadHeight
 * Return the Head height in meters corresponding to given launchHeight in meters
 * @param launchHeightMeters Z coordinate of the launch point in meters from the ground
 * @returns  Z coordinate of the head height in meters from the ground
 */
function launchHeight2HeadHeight(launchHeightMeters) {
    const lph = PHYSICSMODEL.trainerGeometry.defaultLaunchPointOffset.z;
    return launchHeightMeters - lph;
}
exports.launchHeight2HeadHeight = launchHeight2HeadHeight;
/**
 * launchHeightInches2HeadHeight
 * Return the Head height in meters corresponding to given launchHeight in meters
 * @param launchHeightInches Z coordinate of the launch point in inches from the ground
 * @returns  Z coordinate of the head height in meters from the ground
 */
function launchHeightInches2HeadHeight(launchHeightInches) {
    const lph = PHYSICSMODEL.trainerGeometry.defaultLaunchPointOffset.z;
    return launchHeightInches * 0.0254 - lph;
}
exports.launchHeightInches2HeadHeight = launchHeightInches2HeadHeight;
