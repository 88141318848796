"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.coordOffset = exports.coordChange = exports.cartesianRotation = void 0;
/**
 * cartesianRotation
 * @param v: the 3D vector to rotate
 * @param axisName: cartesian axis around which to rotate the vector
 * @param angleDeg: angle around which to rotate the vector in degrees
 * @returns rotated vector
 */
function cartesianRotation(v, axisName, angleDeg) {
    const out = { x: v.x, y: v.y, z: v.z };
    if (angleDeg === 0) {
        return out;
    }
    const angleRad = (angleDeg * (Math.PI / 180.0));
    const c = Math.cos(angleRad);
    const s = Math.sin(angleRad);
    if (axisName === "x") {
        out.x = v.x;
        out.y = v.y * c + v.z * (-s);
        out.z = v.y * s + v.z * c;
    }
    else if (axisName === "y") {
        out.x = v.x * c + v.z * (-s);
        out.y = v.y;
        out.z = v.x * s + v.z * c;
    }
    else if (axisName === "z") {
        out.x = v.x * c + v.y * (-s);
        out.y = v.x * s + v.y * c;
        out.z = v.z;
    }
    return out;
}
exports.cartesianRotation = cartesianRotation;
/**
 * coordChange
 * Change coordinate systems for a point
 * @param p : the source point to be expressed in the new coord system (will not be changed)
 * @param newOrigin: the origin of the new coord system in source system coords
 * @param rotation: rotations in degress in x/y/z of new coord axes wrt source system
 * == NOTE ==: rotations are standard mathematical rotations around x, y, z axes, NOT
 * the roll, pitch, and yaw angles of the trainer.
 * @returns the coordinates of point in the new coord system
 */
function coordChange(p, newOrigin, rotation) {
    const v = { x: 0, y: 0, z: 0 };
    // translate
    v.x = p.x - newOrigin.x;
    v.y = p.y - newOrigin.y;
    v.z = p.z - newOrigin.z;
    // rotate
    let newCoords = cartesianRotation(v, "z", rotation.z * -1.0);
    newCoords = cartesianRotation(newCoords, "y", rotation.y * -1.0);
    newCoords = cartesianRotation(newCoords, "x", rotation.x * -1.0);
    // done
    return newCoords;
}
exports.coordChange = coordChange;
/**
 * coordOffset
 * Get the offset of a target coordinate system origin wrt a reference coord system given the
 * coords of the same point in both systems.
 * @param pRef: coordinates of the point in ref system
 * @param pTarget: coordinates of the point in target system
 * @param rotTarget: rotation (degrees) that takes ref system orientation to target system orientation
 * @returns the target coordinate system origin in reference system coords
 */
function coordOffset(pRef, pTarget, rotTarget) {
    const origin = { x: pRef.x, y: pRef.y, z: pRef.z };
    let rotPoint = cartesianRotation(pTarget, "x", rotTarget.x);
    rotPoint = cartesianRotation(rotPoint, "y", rotTarget.y);
    rotPoint = cartesianRotation(rotPoint, "z", rotTarget.z);
    origin.x -= rotPoint.x;
    origin.y -= rotPoint.y;
    origin.z -= rotPoint.z;
    return origin;
}
exports.coordOffset = coordOffset;
