{
    "half": [
        {
            "name": "Half Court 1: Beginner",
            "shots": [
                {
                    "id": "6579804f-d5f1-424d-87fd-e516b7e148a6",
                    "pan": 12,
                    "tilt": 25,
                    "spinLevel": 0,
                    "launchSpeed": 12.06973625391149,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "b4ba1268-63cf-4eeb-95ae-852a292f0970",
                    "pan": 4.5,
                    "tilt": 25,
                    "spinLevel": 0,
                    "launchSpeed": 12.06973625391149,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Half Court 2: Beginner 2",
            "shots": [
                {
                    "id": "e0618c23-30a5-40f2-8c6d-567f367a2bb3",
                    "pan": 11,
                    "tilt": 16.5,
                    "spinLevel": 0,
                    "launchSpeed": 13.41081805990165,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "ce8c10b3-92a6-44fe-8021-ee7d1ee8cd9b",
                    "pan": 3.5,
                    "tilt": 16.5,
                    "spinLevel": 0,
                    "launchSpeed": 13.41081805990165,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "03b0e9ad-9751-45ea-bbad-b13fe0b9fd28",
                    "pan": 7.5,
                    "tilt": 17,
                    "spinLevel": 0,
                    "launchSpeed": 13.41081805990165,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Half Court 3: Intermediate",
            "shots": [
                {
                    "id": "8e2391dd-9348-46c9-adfd-168e4baf5b98",
                    "pan": 11.5,
                    "tilt": 13,
                    "spinLevel": 0,
                    "launchSpeed": 15.19892713455521,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "34c80cde-972c-4b29-bfdf-1389b5f584b3",
                    "pan": 4,
                    "tilt": 13,
                    "spinLevel": 0,
                    "launchSpeed": 15.19892713455521,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "f1fba435-2ff3-4356-b301-ba60daca8364",
                    "pan": 9,
                    "tilt": 14,
                    "spinLevel": 0,
                    "launchSpeed": 15.19892713455521,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Half Court 4: Advanced",
            "shots": [
                {
                    "id": "676092cb-c984-4de9-8cc3-9addc7881050",
                    "pan": 9,
                    "tilt": 14,
                    "spinLevel": 2,
                    "launchSpeed": 16.54000894054537,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 45,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "09ac0a66-fc51-4725-8a60-1ef0e02e676e",
                    "pan": 12,
                    "tilt": 9.5,
                    "spinLevel": 1,
                    "launchSpeed": 16.76352257487707,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "1e31613f-1177-466d-97fc-480c21b36fb5",
                    "pan": 5,
                    "tilt": 8,
                    "spinLevel": 1,
                    "launchSpeed": 16.54000894054537,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "e5311ad6-6e86-4fe7-a162-2f9c2720e751",
                    "pan": 9.5,
                    "tilt": 6.5,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "ff659568-958e-46c0-9b70-36a6fcef525f",
                    "pan": 3.5,
                    "tilt": 5.5,
                    "spinLevel": 0,
                    "launchSpeed": 18.77514528386232,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "901b38f5-5b8e-49d5-ab24-9ebe5ffb8f8e",
                    "pan": 7,
                    "tilt": 5.5,
                    "spinLevel": 0,
                    "launchSpeed": 18.77514528386232,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Half Court 5: Pro",
            "shots": [
                {
                    "id": "dcb999e7-95a7-44a0-a5a3-b6b941d7bbff",
                    "pan": 7.5,
                    "tilt": 7.5,
                    "spinLevel": 0,
                    "launchSpeed": 18.32811801519892,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "3ee629f2-b539-4a8e-b5ea-56649f06e2be",
                    "pan": 4.5,
                    "tilt": 6.5,
                    "spinLevel": 0,
                    "launchSpeed": 18.32811801519892,
                    "panVariance": 0,
                    "tiltVariance": 2,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "31d34fca-7e6a-4aba-8218-0ede555e10cb",
                    "pan": 6.5,
                    "tilt": 7.5,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "d35c5916-a7cb-4ec0-89fb-c86de905b0e0",
                    "pan": 3,
                    "tilt": 8,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "90501433-7de6-402a-b387-764b2f55acd7",
                    "pan": 3,
                    "tilt": 6,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 180,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "9adb2d98-00ca-437e-bc58-6a84d805579b",
                    "pan": 7,
                    "tilt": 7.5,
                    "spinLevel": 2,
                    "launchSpeed": 16.09298167188198,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "81f4b1a4-7a23-4b82-a9a6-9acf959e05b7",
                    "pan": 5,
                    "tilt": 6.5,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "823b62d0-e6e1-4586-b2db-d48e1e852548",
                    "pan": 12,
                    "tilt": 7.5,
                    "spinLevel": 0,
                    "launchSpeed": 18.77514528386232,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        }
    ],
    "full": [
        {
            "name": "Full Court 1: Beginner",
            "shots": [
                {
                    "id": "3a27c811-78f1-425b-be33-90d9dc756f46",
                    "pan": 5,
                    "tilt": 17.5,
                    "spinLevel": 0,
                    "launchSpeed": 12.74027715690657,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "89ebf065-6ac0-4aa0-a77a-4b1713a46924",
                    "pan": -5.5,
                    "tilt": 17,
                    "spinLevel": 0,
                    "launchSpeed": 12.74027715690657,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Full Court 2: Beginner 2",
            "shots": [
                {
                    "id": "ee305a23-6f22-4e93-a621-719bb25b83f7",
                    "pan": 7.5,
                    "tilt": 14.5,
                    "spinLevel": 0,
                    "launchSpeed": 13.85784532856504,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "50079f8d-c928-47b9-bb25-9eecd530bd0b",
                    "pan": -7.5,
                    "tilt": 14.5,
                    "spinLevel": 0,
                    "launchSpeed": 13.85784532856504,
                    "panVariance": 2,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Full Court 3: Intermediate",
            "shots": [
                {
                    "id": "59d1a3ca-f4f5-4281-b8d4-dd3a4cfbcc8b",
                    "pan": 8,
                    "tilt": 9,
                    "spinLevel": 0,
                    "launchSpeed": 16.76352257487707,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "0ac391d5-01b3-41f8-b7cd-e8228d4156d7",
                    "pan": -8,
                    "tilt": 9,
                    "spinLevel": 0,
                    "launchSpeed": 16.76352257487707,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "06e6100d-f825-4d8a-abfc-348b5a720acd",
                    "pan": 0,
                    "tilt": 10.5,
                    "spinLevel": 0,
                    "launchSpeed": 16.54000894054537,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Full Court 4: Advanced",
            "shots": [
                {
                    "id": "2aa87449-f55f-4c77-9391-b97564508384",
                    "pan": 6.5,
                    "tilt": 8,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "c6001586-1e53-4efc-858d-e5ac95689cf5",
                    "pan": -4.5,
                    "tilt": 8,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "5d575c24-8e30-4a0f-b65a-2d62affe8418",
                    "pan": 0,
                    "tilt": 8.5,
                    "spinLevel": 0,
                    "launchSpeed": 17.43406347787215,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "d50035f5-b14e-4817-90e4-79395d82beb1",
                    "pan": -6,
                    "tilt": 6,
                    "spinLevel": 1,
                    "launchSpeed": 17.43406347787215,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "a8d2a465-d32b-4b45-aa23-d73c74c25f2b",
                    "pan": 6,
                    "tilt": 6,
                    "spinLevel": 1,
                    "launchSpeed": 17.43406347787215,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "608459dc-ebd8-4863-8153-5d39267b13bc",
                    "pan": -4.5,
                    "tilt": 5.5,
                    "spinLevel": 2,
                    "launchSpeed": 17.88109074653554,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "613d0262-b866-4e9b-b2ef-42bd15988bbb",
                    "pan": 3,
                    "tilt": 5.5,
                    "spinLevel": 2,
                    "launchSpeed": 17.88109074653554,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                }
            ]
        },
        {
            "name": "Full Court 5: Pro",
            "shots": [
                {
                    "id": "2d905a99-6289-4728-b2b8-1868a14ee420",
                    "pan": 4,
                    "tilt": 6.5,
                    "spinLevel": 2,
                    "launchSpeed": 18.77514528386232,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "c84c24c2-b890-4de7-9219-6d3bc0ee18ec",
                    "pan": -4,
                    "tilt": 7,
                    "spinLevel": 2,
                    "launchSpeed": 18.77514528386232,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "82cc9bb2-a30a-4899-b086-0e8f30cf4652",
                    "pan": -3,
                    "tilt": 8,
                    "spinLevel": 0,
                    "launchSpeed": 18.32811801519892,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "35b37a97-65cb-4c9b-93f1-85261e6c537b",
                    "pan": 3,
                    "tilt": 8,
                    "spinLevel": 0,
                    "launchSpeed": 18.32811801519892,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 0,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "ccf94ad7-52f9-4865-9b43-ea43ee50045b",
                    "pan": -3.5,
                    "tilt": 6.5,
                    "spinLevel": 2,
                    "launchSpeed": 17.43406347787215,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 90,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "94981572-d8e1-4aba-9922-16c6f8ef7ca5",
                    "pan": 2,
                    "tilt": 6.5,
                    "spinLevel": 2,
                    "launchSpeed": 17.43406347787215,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "be99e047-9639-4c96-ab52-ffb3f035b7f7",
                    "pan": -2,
                    "tilt": 6,
                    "spinLevel": 2,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 180,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "2c4548c0-c412-4568-bcf8-55c1ce77c77d",
                    "pan": -0.5,
                    "tilt": 6.5,
                    "spinLevel": 0,
                    "launchSpeed": 18.77514528386232,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 180,
                    "launchSpeedVariance": 0
                },
                {
                    "id": "9aca6075-5613-4d1e-941e-db4341d017bf",
                    "pan": -3.5,
                    "tilt": 6,
                    "spinLevel": 3,
                    "launchSpeed": 16.98703620920876,
                    "panVariance": 0,
                    "tiltVariance": 0,
                    "spinDirection": 270,
                    "launchSpeedVariance": 0
                }
            ]
        }
    ]
}
