import * as React from "react";

import { SxProps, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import AccordionSlider from "./AccordionSlider";

interface Props {
    label: string;
    disabled?: boolean;
    labelWrapperSx?: SxProps<Theme>;
    selectedDelay: number;
    sliderWrapperSx?: SxProps<Theme>;
    wrapperSx?: SxProps<Theme>;
    onDelayChanged: (updated: number) => void;
}

export default function DelaySlider({
    label,
    disabled = false,
    selectedDelay,
    labelWrapperSx = {},
    sliderWrapperSx = {},
    wrapperSx = {},
    onDelayChanged,
}: Props): JSX.Element {
    const [userDelay, setUserDelay] = React.useState(selectedDelay);

    React.useEffect(() => {
        setUserDelay(selectedDelay);
    }, [selectedDelay]);

    return (
        <Stack direction="row" spacing={0.5} alignItems="center" sx={wrapperSx}>
            <Box component="div" sx={labelWrapperSx}>
                <Typography
                    variant="caption"
                    sx={{
                        minWidth: "30px",
                    }}
                >
                    {label}
                </Typography>
            </Box>
            <Box component="div" sx={sliderWrapperSx}>
                <AccordionSlider
                    disabled={disabled}
                    min={0}
                    max={2}
                    step={0.25}
                    marks={[
                        { value: 0, label: "0s" },
                        { value: 0.5, label: "0.5s" },
                        { value: 1, label: "1s" },
                        { value: 1.5, label: "1.5s" },
                        { value: 2, label: "2s" },
                    ]}
                    value={userDelay}
                    track={false}
                    onChange={(_, v) => setUserDelay(v as number)}
                    onChangeCommitted={(_, v) => onDelayChanged(v as number)}
                    valueLabelFormat={(value) => `${value}s`}
                />
            </Box>
        </Stack>
    );
}
