import * as React from "react";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";

interface UnpairDialogProps {
    onUnpair: () => void;
}
export default function UnpairDialog({
    onUnpair,
}: UnpairDialogProps): JSX.Element {
    const { status } = useStatus();
    const [closedSession, setClosedSession] = React.useState(false);

    React.useEffect(() => {
        setClosedSession(true);
        if (status?.session?.id && !closedSession) {
            onUnpair();
        }
    }, [closedSession, onUnpair, status]);

    return (
        <>
            <CloseableDialogTitle>Please Wait</CloseableDialogTitle>
            <DialogContent dividers>
                <DialogContentText>
                    The trainer is finishing shutting down and will return you
                    to the home screen when done.
                </DialogContentText>
                <Box sx={{ textAlign: "center", mt: 2 }} component="div">
                    <CircularProgress size={80} color="info" />
                </Box>
            </DialogContent>
        </>
    );
}
