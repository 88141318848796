import * as React from "react";

import Button, { ButtonProps } from "@mui/material/Button";

import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutCreatePayload,
} from "@volley/data";

import DropdownButton, {
    DropdownButtonPartialProps,
} from "../../../common/DropdownButton";
import { useCurrentUser } from "../../../hooks/currentUser";

import PublishConfirmation from "./PublishConfirmationDialog";
import UnPublishConfirmation from "./UnPublishConfirmationDialog";

export interface PublishButtonProps {
    workout: AppWorkout | AppWorkoutCreatePayload;
    saveFunction: (publishId: number | null) => Promise<void>;
    buttonProps?: Partial<ButtonProps & DropdownButtonPartialProps>;
}

export default function PublishButton({
    workout,
    saveFunction,
    buttonProps = {},
}: PublishButtonProps): JSX.Element {
    const { currentUser } = useCurrentUser();

    const [publishing, setPublishing] = React.useState(false);
    const [unPublishing, setUnPublishing] = React.useState(false);
    const [publisherIndex, setPublisherIndex] = React.useState<number>(0);

    const publishOptions =
        currentUser?.contentProviderPublishers.map(
            (p) => p.contentProvider.label,
        ) ?? [];

    const canPublish = React.useMemo(() => {
        if (!currentUser && !workout) {
            return false;
        }

        if (currentUser?.contentProviderPublishers.length === 0) {
            return false;
        }

        const providerMatch = currentUser?.contentProviderPublishers.find(
            (p) => p.contentProviderId === workout?.contentProviderId,
        );
        if (providerMatch || workout?.contentProviderId === null) {
            return true;
        }

        return false;
    }, [currentUser, workout]);

    const currentProvider = React.useMemo(() => {
        if (currentUser && currentUser.contentProviderPublishers.length > 0) {
            return currentUser.contentProviderPublishers[publisherIndex]
                .contentProvider;
        }

        return null;
    }, [currentUser, publisherIndex]);

    return (
        <>
            {canPublish && !workout.contentProviderId && (
                <DropdownButton
                    sx={{
                        marginTop: "15px",
                    }}
                    onChanged={(index) => setPublisherIndex(index)}
                    selectedIndex={publisherIndex}
                    onSubmit={() => setPublishing(true)}
                    options={publishOptions}
                    labelPrefix="Publish to"
                    {...buttonProps}
                />
            )}
            {canPublish && workout.contentProviderId && (
                <Button
                    sx={{
                        marginTop: "15px",
                    }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={() => setUnPublishing(true)}
                    {...buttonProps}
                >
                    Un-publish Workout
                </Button>
            )}
            <PublishConfirmation
                open={publishing}
                providerName={currentProvider?.name ?? "Unknown"}
                onClose={() => setPublishing(false)}
                onConfirm={async () => {
                    if (currentProvider && workout) {
                        const pid = currentProvider.id;
                        await saveFunction(pid);
                        setPublishing(false);
                    }
                }}
            />
            <UnPublishConfirmation
                open={unPublishing}
                providerName={currentProvider?.name ?? "Unknown"}
                onClose={() => setUnPublishing(false)}
                onConfirm={async () => {
                    if (workout) {
                        await saveFunction(null);
                        setUnPublishing(false);
                    }
                }}
            />
        </>
    );
}
