/**
 * Generate a UUID
 *
 * This function first checks if the 'randomUUID' function is available in the 'crypto' object.
 * If available, it uses 'crypto.randomUUID()' to generate a secure UUID.
 * If 'randomUUID' is not available, it falls back to a less secure method using 'Math.random()'.
 *
 * @returns {string} A UUID in the version 4 format.
 */
export default function generateUUID(): string {
    if (crypto.randomUUID) {
        return crypto.randomUUID();
    }

    // UUID version 4 is 32 hexadecimal digits in the format of:
    // xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx
    // where x is any hexadecimal digit and y is one of 8, 9, A, or B
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
        const r = Math.floor(Math.random() * 16); // random hexadecimal digit
        const v =
            c === "x"
                ? r // replace x with a random hexadecimal digit
                : (r % 4) + 8; // replace y with a random hexadecimal digit that is either 8, 9, A, or B
        return v.toString(16); // convert to hexadecimal string
    });
}
