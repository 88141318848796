import * as React from "react";

import { useTheme } from "@mui/material";

import CardWrapper from "../../Shared/CardWrapper";
import { AppListItemProps } from "../types";

export default function ModuleCard({
    onClick,
    workout,
}: AppListItemProps): JSX.Element | null {
    const theme = useTheme();
    return (
        <CardWrapper
            onClick={onClick}
            workout={workout}
            backgroundColor={theme.palette.info.main}
            backgroundImage="groovepaper"
        />
    );
}
