import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Typography from "@mui/material/Typography";
import useTheme from "@mui/material/styles/useTheme";

import NotchedOutline from "../../../common/NotchedOutline";
import AccordionSlider from "../Shared/AccordionSlider";

import SpinIllustration from "./SpinIllustration";

interface SpinAccorionProps extends Omit<AccordionProps, "children"> {
    maxSpinLevel: number;
    selectedSpin: number;
    onSpinChanged: (value: number) => void;
    selectedSpinIntensity: number;
    onSpinIntensityChanged: (value: number) => void;
}

const spinOrientationMarks = [
    {
        value: -180,
        label: "Backspin",
    },
    {
        value: -135,
        header: "Left Backspin",
    },
    {
        value: -90,
        label: "Left",
    },
    {
        value: -45,
        header: "Left Topspin",
    },
    {
        value: 0,
        label: "Topspin",
    },
    {
        value: 45,
        header: "Right Topspin",
    },
    {
        value: 90,
        label: "Right",
    },
    {
        value: 135,
        header: "Right Backspin",
    },
    {
        value: 180,
        label: "Backspin",
    },
];

const spinIntensityMarks = [...Array(10).keys()].map((v) => ({
    value: v + 1,
    label: (v + 1).toString(),
}));

function relativeToFull(relative: number): number {
    if (relative > 0) {
        return 360 - relative;
    }

    return -relative;
}

function fullToRelative(full: number, isNegative: boolean): number {
    if (full > 180) {
        return 360 - full;
    }

    if (full === 180 && !isNegative) {
        return full;
    }

    return -full;
}

export function relativeSpinToLabel(spin: number): string {
    const match = spinOrientationMarks.find((s) => s.value === spin);
    if (match) {
        return match.label ?? match.header;
    }

    return "Unknown";
}

export default function SpinAccordion({
    maxSpinLevel,
    selectedSpin,
    onSpinChanged,
    selectedSpinIntensity,
    onSpinIntensityChanged,
    ...props
}: SpinAccorionProps): JSX.Element {
    const [isNegative, setIsNegative] = React.useState(false);
    const theme = useTheme();

    const enabled = React.useMemo(
        () => selectedSpinIntensity > 0,
        [selectedSpinIntensity],
    );

    const spinLabel = React.useMemo(
        () =>
            enabled
                ? relativeSpinToLabel(fullToRelative(selectedSpin, isNegative))
                : "None",
        [enabled, selectedSpin, isNegative],
    );

    const intensitySliderStyles = React.useMemo(() => {
        const darkGrey = theme.palette.grey[500];
        const lightGrey = theme.palette.grey[200];
        const darkGreyBreak = (maxSpinLevel - 1) * (100 / 9);
        const baseStyle: Record<string, unknown> = {
            zIndex: 1,
            "& .MuiSlider-rail": {
                opacity: 1,
                background: `linear-gradient(90deg, ${darkGrey} ${darkGreyBreak}%, ${lightGrey} 0%)`,
            },
        };
        [...Array(10).keys()].forEach((v) => {
            const key = `& .MuiSlider-markLabel[data-index="${v}"]`;
            let color = v < maxSpinLevel ? "primary.main" : "grey";
            let fontWeight = v < maxSpinLevel ? "400" : "100";
            if (v + 1 === maxSpinLevel) {
                color = "primary.light";
                fontWeight = "800";
            }
            baseStyle[key] = { color, fontWeight };
        });
        return baseStyle;
    }, [maxSpinLevel, theme]);

    React.useEffect(() => {
        if (selectedSpinIntensity > maxSpinLevel) {
            onSpinIntensityChanged(maxSpinLevel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [maxSpinLevel]);

    const spinValue = React.useMemo(
        () => fullToRelative(selectedSpin, isNegative),
        [selectedSpin, isNegative],
    );

    return (
        <Accordion {...props}>
            {!props.expanded && (
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Box component="div" sx={{ width: "100%" }}>
                        <Typography
                            variant="h3"
                            color="primary.main"
                            sx={{
                                display: "inline",
                            }}
                        >
                            Spin:&nbsp;
                        </Typography>
                        <Typography
                            variant="h4"
                            color="primary.main"
                            sx={{
                                display: "inline",
                            }}
                        >
                            {spinLabel}
                        </Typography>
                        <Typography variant="h4" color="primary.main">
                            {`Intensity: ${selectedSpinIntensity}`}
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            float: "right",
                        }}
                    >
                        <SpinIllustration
                            imgScale={0.25}
                            spinIntensity={selectedSpinIntensity}
                            spinOrientation={-selectedSpin}
                        />
                    </Box>
                </AccordionSummary>
            )}
            {props.expanded && (
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <Typography variant="h3" color="primary.main">
                        Spin
                    </Typography>
                </AccordionSummary>
            )}
            <AccordionDetails>
                <Stack spacing={3}>
                    {maxSpinLevel < 1 && (
                        <Typography variant="body2">
                            Lower the speed to enable spin for this shot.
                        </Typography>
                    )}
                    <Stack
                        direction="row"
                        spacing={1}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant="caption">Enable spin?</Typography>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography
                                sx={{
                                    color: enabled ? "grey" : "primary.main",
                                }}
                                variant="body2"
                            >
                                No
                            </Typography>
                            <Switch
                                checked={enabled}
                                onChange={(e) => {
                                    const { checked } = e.target;
                                    if (!checked) {
                                        onSpinChanged(0);
                                        onSpinIntensityChanged(0);
                                    } else {
                                        onSpinIntensityChanged(
                                            Math.ceil(maxSpinLevel / 2),
                                        );
                                    }
                                }}
                                sx={{
                                    "& .MuiSwitch-thumb": {
                                        color: enabled
                                            ? "primary.light"
                                            : "common.white",
                                    },
                                    "& .Mui-checked+ .MuiSwitch-track": {
                                        backgroundColor: enabled
                                            ? "#A1C8F5"
                                            : "grey.500",
                                    },
                                }}
                            />
                            <Typography
                                sx={{
                                    color: enabled ? "primary.light" : "grey",
                                }}
                                variant="body2"
                            >
                                Yes
                            </Typography>
                        </Stack>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <AccordionSlider
                            step={null}
                            marks={spinOrientationMarks}
                            track={false}
                            min={-200}
                            max={200}
                            value={spinValue}
                            onChange={(_, v) => {
                                const full = relativeToFull(v as number);
                                onSpinChanged(full);
                                setIsNegative((v as number) < 0);
                            }}
                            onChangeCommitted={(_, v) => {
                                const full = relativeToFull(v as number);
                                onSpinChanged(full);
                                setIsNegative((v as number) < 0);
                            }}
                            sx={{
                                maxWidth: "70%",
                                "& .MuiSlider-markLabel": {
                                    fontSize: "10px",
                                },
                            }}
                            disabled={!enabled}
                        />
                        <SpinIllustration
                            imgScale={0.33}
                            spinIntensity={selectedSpinIntensity}
                            spinOrientation={-selectedSpin}
                        />
                    </Stack>
                    <NotchedOutline label="Spin Intensity">
                        <Box
                            sx={{
                                padding: "0 20px",
                            }}
                        >
                            <AccordionSlider
                                min={1}
                                max={10}
                                step={null}
                                marks={spinIntensityMarks}
                                value={enabled ? selectedSpinIntensity : 1}
                                onChange={(_, v) => {
                                    const updated = Math.min(
                                        v as number,
                                        maxSpinLevel,
                                    );
                                    onSpinIntensityChanged(updated);
                                }}
                                onChangeCommitted={(_, v) => {
                                    const updated = Math.min(
                                        v as number,
                                        maxSpinLevel,
                                    );
                                    onSpinIntensityChanged(updated);
                                }}
                                disabled={!enabled}
                                sx={intensitySliderStyles}
                            />
                        </Box>
                    </NotchedOutline>
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
