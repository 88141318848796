import * as React from "react";

import { usePairingContext } from "../../hooks/pairingStatus";
import ConfirmDialog from "../ConfirmDialog";
import LowerHeadDialog from "../LowerHeadDialog";
import PoweringOffDialog from "../PoweringOffDialog";
import useDialog from "../useDialog";

const enum ShutdownSteps {
    Confirm,
    LowerArm,
    PowerDown,
    CleanUp,
    Done,
}

export default function ShutdownFlow(): JSX.Element | null {
    const { unpair } = usePairingContext();
    const { setDialogType } = useDialog();
    const [step, setStep] = React.useState(ShutdownSteps.Confirm);

    React.useEffect(() => {
        if (step === ShutdownSteps.CleanUp) {
            setDialogType("ShutdownCleanupChecklist");
            unpair();
        }
    }, [step, setDialogType, unpair]);

    if (step === ShutdownSteps.Confirm) {
        return (
            <ConfirmDialog
                title="Confirm Shut Down"
                contentText="This will end your session and shut down the trainer. It may take up to a minute for the two lights on the back to turn off. You can put the trainer away in the meantime."
                buttonLabel="Shut Down"
                onConfirm={() => setStep(ShutdownSteps.LowerArm)}
            />
        );
    }

    if (step === ShutdownSteps.LowerArm) {
        return (
            <LowerHeadDialog
                message="The trainer arm is moving to the lowest position for storage."
                reason="shutdown"
                onLowered={() => setStep(ShutdownSteps.PowerDown)}
                onStopped={() => setDialogType(null)}
                onError={() => setStep(ShutdownSteps.PowerDown)} // if we can't lower, continue with shutdown
            />
        );
    }

    if (step === ShutdownSteps.PowerDown) {
        return (
            <PoweringOffDialog
                onPoweredOff={() => setStep(ShutdownSteps.CleanUp)}
                full
            />
        );
    }

    return null;
}
