import * as React from "react";

import logger from "../../log";
import { fetchApi } from "../../util";
import { logFetchError } from "../../util/fetchApi";
import { checkForSession } from "../Sessions/util";
import FilterState from "../Trainer/AppWorkouts/apps/shared/filterState";
import LatestPositionState from "../Trainer/AppWorkouts/apps/shared/latestPosition";

import { AuthState, useCurrentUser } from "./currentUser";

export type PairingStatus =
    | "unknown"
    | "unpaired"
    | "requested"
    | "validating"
    | "paired";

type PairingAction =
    | { type: "pairingCancelled" }
    | { type: "sessionDetected"; trainerId: number; sessionId: number }
    | { type: "pairingVerified"; trainerId: number; sessionId: number }
    | { type: "promptToPair"; autoCalibrate: boolean }
    | { type: "unpair" }
    | { type: "sessionClosed" };

interface PairingStateInternal {
    status: PairingStatus;
    trainerId?: number;
    sessionId?: number;
    autoCalibrate: boolean;
}

const defaultState: PairingStateInternal = {
    status: "unknown",
    autoCalibrate: false,
};

function pairingReducer(
    state: PairingStateInternal,
    action: PairingAction,
): PairingStateInternal {
    logger.info(`[pairingState:reducer]: ${JSON.stringify(action)}`);
    if (["sessionClosed", "pairingCancelled", "unpair"].includes(action.type)) {
        FilterState.clearFilter();
        LatestPositionState.clearPosition();
    }
    switch (action.type) {
        case "sessionClosed":
            return {
                status: "unpaired",
                autoCalibrate: false,
            };
        case "pairingCancelled":
        case "unpair":
            return {
                ...state,
                status: "unpaired",
                autoCalibrate: false,
            };
        case "sessionDetected":
            return {
                status: "validating",
                autoCalibrate: false,
                trainerId: action.trainerId,
                sessionId: action.sessionId,
            };
        case "pairingVerified":
            return {
                ...state,
                status: "paired",
                autoCalibrate: false,
                trainerId: action.trainerId,
                sessionId: action.sessionId,
            };
        case "promptToPair":
            return {
                ...state,
                status: "requested",
                autoCalibrate: action.autoCalibrate,
            };
        default:
            logger.warn(
                `Invalid pairing state reducer action: ${JSON.stringify(action)}`,
            );
            return state;
    }
}

export interface PairingState {
    status: PairingStatus;
    trainerId?: number;
    sessionId?: number;
    autoCalibrate: boolean;
    pairingCancelled: () => void;
    pairingVerified: (trainerId: number, sessionId: number) => void;
    promptToPair: (autoCalibrate?: boolean) => void;
    registerSession: (trainerId: number, sessionId: number) => void;
    unpair: () => void;
}

export const PairingContext = React.createContext<PairingState>({
    status: "unknown",
    autoCalibrate: false,
    pairingCancelled: () => {},
    pairingVerified: () => {},
    promptToPair: () => {},
    registerSession: () => {},
    unpair: () => {},
});

export const PairingProvider = React.memo(
    ({ children }: React.PropsWithChildren) => {
        const { authState } = useCurrentUser();
        const [checking, setChecking] = React.useState(false);

        const [state, dispatch] = React.useReducer(
            pairingReducer,
            defaultState,
        );

        const pairingCancelled = React.useCallback(() => {
            logger.info("[pairing:pairingCancelled]");
            dispatch({ type: "pairingCancelled" });
        }, []);

        const pairingVerified = React.useCallback(
            (trainerId: number, sessionId: number) => {
                logger.info(
                    `[pairing:pairingVerified] Trainer: ${trainerId}, Session: ${sessionId}`,
                );

                if (state.trainerId && state.trainerId !== trainerId) {
                    logger.warn(
                        `[pairing:pairingVerified] trainerId ${trainerId} doesn't match previoud: ${state.trainerId}`,
                    );
                }

                if (state.sessionId && state.sessionId !== sessionId) {
                    logger.warn(
                        `[pairing:pairingVerified] sessionId ${sessionId} doesn't match previoud: ${state.sessionId}`,
                    );
                }

                dispatch({ type: "pairingVerified", sessionId, trainerId });
            },
            [state.trainerId, state.sessionId],
        );

        const promptToPair = React.useCallback((autoCalibrate = false) => {
            logger.info(
                `[pairing:promptToPair] Auto-calibrate ${autoCalibrate ? "yes" : "no"}`,
            );

            dispatch({ type: "promptToPair", autoCalibrate });
        }, []);

        const registerSession = React.useCallback(
            (trainerId: number, sessionId: number) => {
                dispatch({ type: "sessionDetected", trainerId, sessionId });
            },
            [],
        );

        const unpair = React.useCallback(() => {
            logger.info(
                "[pairing:unpaired] Resetting pairing state to 'unpaired'",
            );
            dispatch({ type: "unpair" });
        }, []);

        const value = React.useMemo<PairingState>(
            () => ({
                ...state,
                pairingCancelled,
                pairingVerified,
                promptToPair,
                registerSession,
                unpair,
            }),
            [
                state,
                pairingCancelled,
                pairingVerified,
                promptToPair,
                registerSession,
                unpair,
            ],
        );

        React.useEffect(() => {
            if (
                authState === AuthState.AUTHENTICATED &&
                state.status === "unknown" &&
                !checking
            ) {
                setChecking(true);
                logger.info("[pairing:checkForSession] - performing check");
                checkForSession()
                    .then((session) => {
                        if (session) {
                            logger.info(
                                "[pairing:checkForSession] Found session, attempting to validate and redirect",
                            );
                            const { trainerId, sessionId } = session;
                            registerSession(trainerId, sessionId);
                        } else {
                            unpair();
                        }
                    })
                    .catch((e) => {
                        logFetchError(
                            e,
                            "[pairing:checkForSession] - failed check for session",
                        );
                        setChecking(false);
                        unpair();
                    });
            }
        }, [authState, checking, state.status, registerSession, unpair]);

        React.useEffect(() => {
            async function closeSession() {
                if (state.sessionId) {
                    logger.info(
                        `Attempting to close session ${state.sessionId} due to status change.`,
                    );
                    await fetchApi(
                        `/api/sessions/${state.sessionId}/close`,
                        "PUT",
                    );
                }
            }
            if (state.status === "unpaired") {
                logger.info(
                    "[pairing:statusChangedHook - Pairing state changed to 'unpaired'",
                );
                closeSession().catch((e) => logFetchError(e));
            }
        }, [state.sessionId, state.status]);

        return (
            <PairingContext.Provider value={value}>
                {children}
            </PairingContext.Provider>
        );
    },
);

PairingProvider.displayName = "PairingProvider";

export function usePairingContext(): PairingState {
    return React.useContext(PairingContext);
}
