import * as React from "react";

import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import LinearProgress, {
    LinearProgressProps,
} from "@mui/material/LinearProgress";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";

import passwordStrength from "./passwordStrength";

interface PasswordProps {
    onPasswordChange: (s: string) => void;
}

export default function Password({
    onPasswordChange,
}: PasswordProps): JSX.Element {
    const theme = useTheme();
    const [password, setPassword] = React.useState("");
    const [unmaskPassword, setUnmaskPassword] = React.useState(false);

    const strength = React.useMemo(() => {
        if (!password.length) {
            return {
                percent: 0,
                cssColor: "",
                color: "inherit",
                value: "",
            };
        }

        const { value } = passwordStrength(password);
        switch (value) {
            case "Strong":
                return {
                    percent: 100,
                    cssColor: theme.palette.success.main,
                    color: "success",
                    value,
                };
            case "Medium":
                return {
                    percent: 66,
                    cssColor: theme.palette.info.main,
                    color: "info",
                    value,
                };
            case "Weak":
                return {
                    percent: 33,
                    cssColor: theme.palette.warning.main,
                    color: "warning",
                    value,
                };
            case "Too weak":
            default:
                return {
                    percent: 0,
                    cssColor: theme.palette.error.main,
                    color: "error",
                    value,
                };
        }
    }, [password, theme]);

    const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.currentTarget;
        setPassword(value);

        // if password is too weak return empty string to mark invalid password
        const passStrength = passwordStrength(value);
        if (passStrength.value === "Too weak") {
            onPasswordChange("");
        } else {
            onPasswordChange(value);
        }
    };

    return (
        <Box component="div">
            <TextField
                type={unmaskPassword ? "text" : "password"}
                id="password"
                name="password"
                autoComplete="new-password"
                label="Password"
                value={password}
                onChange={onChange}
                fullWidth
                slotProps={{
                    input: {
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    onClick={() =>
                                        setUnmaskPassword(!unmaskPassword)
                                    }
                                >
                                    {unmaskPassword ? (
                                        <VisibilityOffIcon />
                                    ) : (
                                        <VisibilityIcon />
                                    )}
                                </IconButton>
                            </InputAdornment>
                        ),
                    },
                }}
            />
            <Box component="div" sx={{ w: "100%", my: 1 }}>
                <LinearProgress
                    variant="determinate"
                    value={strength.percent}
                    color={strength.color as LinearProgressProps["color"]}
                />
                <Typography variant="body2" sx={{ color: strength.cssColor }}>
                    {strength.value}
                </Typography>
            </Box>
            <Typography variant="caption" fontSize="8pt">
                Use eight or more characters with a mix of letters, numbers,
                &amp; symbols
            </Typography>
        </Box>
    );
}
