import * as React from "react";

import SignalWifi1BarIcon from "@mui/icons-material/SignalWifi1Bar";
import SignalWifi2BarIcon from "@mui/icons-material/SignalWifi2Bar";
import SignalWifi3BarIcon from "@mui/icons-material/SignalWifi3Bar";
import SignalWifi4BarIcon from "@mui/icons-material/SignalWifi4Bar";
import SignalWifiOffIcon from "@mui/icons-material/SignalWifiOff";
import SignalWifiStatusbarNullIcon from "@mui/icons-material/SignalWifiStatusbarNull";

import { SystemStatusWifi } from "@volley/shared/coach-models";

export default function WifiStatusIcon({
    wifiStatus,
}: {
    wifiStatus: SystemStatusWifi | null;
}): JSX.Element {
    if (!wifiStatus || !wifiStatus.essid) {
        return <SignalWifiOffIcon />;
    }

    // new metric for network quality used by motion for the billboard
    if (wifiStatus.score) {
        if (wifiStatus.score < 20) {
            return <SignalWifi1BarIcon color="warning" />;
        }

        if (wifiStatus.score < 40) {
            return <SignalWifi1BarIcon color="success" />;
        }

        if (wifiStatus.score < 60) {
            return <SignalWifi2BarIcon color="success" />;
        }

        if (wifiStatus.score < 80) {
            return <SignalWifi3BarIcon color="success" />;
        }

        if (wifiStatus.score >= 80) {
            return <SignalWifi4BarIcon color="success" />;
        }
    }

    // backwards compat for trainers without the new metric in coach status
    if (wifiStatus.signal) {
        if (wifiStatus.signal > -50) {
            return <SignalWifi4BarIcon color="success" />;
        }

        if (wifiStatus.signal > -60) {
            return <SignalWifi3BarIcon color="success" />;
        }

        if (wifiStatus.signal > -70) {
            return <SignalWifi2BarIcon color="warning" />;
        }

        if (wifiStatus.signal > -80) {
            return <SignalWifi1BarIcon color="warning" />;
        }
    }

    return <SignalWifiStatusbarNullIcon color="error" />;
}
