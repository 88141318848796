import * as React from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";

interface Props {
    open: boolean;
    setDialogOpen: (open: boolean) => void;
}

export default function VisionSystemStartingDialo({
    open,
    setDialogOpen,
}: Props) {
    return (
        <Dialog open={open}>
            <CloseableDialogTitle onClose={() => setDialogOpen(false)}>
                Camera Unavailable
            </CloseableDialogTitle>
            <DialogContent>
                <Box component="div" sx={{ mb: 3 }}>
                    <Typography>
                        The camera system is still starting up. Please try again
                        in a few minutes. If the problem persists, please
                        restart the trainer.
                    </Typography>
                </Box>
            </DialogContent>
        </Dialog>
    );
}
