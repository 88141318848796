import * as React from "react";

import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

interface ScoreboardProps {
    playerCount: number;
    adScore: number;
    deuceScore: number;
}

function ScoreBox({ label, score }: { label: string; score: number }) {
    return (
        <Box
            component="div"
            display="flex"
            flexDirection="column"
            alignItems="center"
            flexGrow={1}
            padding={2}
        >
            <Typography variant="h4">{label}</Typography>
            <Typography variant="h1">{score}</Typography>
        </Box>
    );
}

export default function Scoreboard({
    playerCount,
    adScore,
    deuceScore,
}: ScoreboardProps) {
    if (playerCount === 1) {
        // if there is only one player, only show the ad score
        return (
            <Stack direction="row" alignItems="center">
                <ScoreBox label="" score={deuceScore} />
            </Stack>
        );
    }

    return (
        <Stack direction="row" alignItems="center">
            <ScoreBox label="Ad" score={adScore} />
            <ScoreBox label="Deuce" score={deuceScore} />
        </Stack>
    );
}
