import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";

import { useLogMountTime } from "../../log";
import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";

import useUnclosableDialog from "./common/useUnclosableDialog";
import useDialog from "./useDialog";

export default function CalibrationDialog(): JSX.Element {
    useLogMountTime("CalibrationDialog");
    useUnclosableDialog();
    const { setDialogType } = useDialog();
    const { startRapidPoll, removeRapidPollCheck, status, hasFault } =
        useStatus();
    const [calibrating, setCalibrating] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState("");

    React.useEffect(() => {
        startRapidPoll(1000, () => false, "calibration");

        return () => removeRapidPollCheck("calibration");
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        async function powerUp() {
            setCalibrating(true);
            await pairedFetchApi(status?.clientId, "/api/power-up", "POST");
        }

        if (status?.trainer.powerState !== "L0") {
            powerUp().catch((e) => {
                setCalibrating(false);
                setErrorMessage((e as Error).message);
                logFetchError(e, "Failed to power up from CalibratingDialog");
            });
        }
    }, [status?.clientId, status?.trainer.powerState]);

    // Send command to calibrate if not calibrated
    React.useEffect(() => {
        async function calibrate() {
            setCalibrating(true);
            await pairedFetchApi(status?.clientId, "/api/calibrate", "POST");
        }

        if (
            status?.trainer.calibrated === "Uncalibrated" &&
            status?.trainer.powerState === "L0"
        ) {
            calibrate().catch((e) => {
                setCalibrating(false);
                setErrorMessage((e as Error).message);
                logFetchError(e, "Failed to calibrate from CalibratingDialog");
            });
        }
    }, [
        status?.clientId,
        status?.trainer.calibrated,
        status?.trainer.powerState,
    ]);

    // if user refreshes page during calibration
    React.useEffect(() => {
        if (
            !errorMessage &&
            !calibrating &&
            status?.trainer.calibrated === "Calibrating"
        ) {
            setCalibrating(true);
        }
    }, [calibrating, errorMessage, status?.trainer.calibrated]);

    // Close dialog if calibrated
    React.useEffect(() => {
        if (
            status?.trainer.calibrated === "Calibrated" &&
            status?.trainer.powerState === "L0" &&
            status?.trainer.state === "IDLE"
        ) {
            setDialogType(null);
        }
    }, [
        status?.trainer.calibrated,
        setDialogType,
        status?.trainer.powerState,
        status?.trainer.state,
    ]);

    React.useEffect(() => {
        if (hasFault) {
            removeRapidPollCheck("calibration");
            setDialogType("Device");
        }
    }, [setDialogType, hasFault, removeRapidPollCheck]);

    return (
        <>
            <CloseableDialogTitle variant="h4">
                Starting Up
            </CloseableDialogTitle>
            <DialogContent dividers>
                {!errorMessage && (
                    <DialogContentText>
                        The trainer is calibrating the head unit. This
                        shouldn&apos;t take very long.
                    </DialogContentText>
                )}
                {!!errorMessage && (
                    <>
                        <DialogContentText>{errorMessage}</DialogContentText>
                        <DialogActions sx={{ mt: 2 }}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => setDialogType(null)}
                            >
                                Dismiss
                            </Button>
                        </DialogActions>
                    </>
                )}
                {!!calibrating && (
                    <Box component="div" sx={{ textAlign: "center", mt: 2 }}>
                        <CircularProgress size={80} color="info" />
                    </Box>
                )}
            </DialogContent>
        </>
    );
}
