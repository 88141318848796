import { Coord } from "../../../util/position-types";

import { ScaledDimensions } from "./types";

export type AvailableColors = "VOLLEY_GREEN" | "WHITE";

export const Colors: Record<AvailableColors, string> = {
    VOLLEY_GREEN: "#3CE97C",
    WHITE: "white",
};

export type LabelLocation = "top" | "left" | "right" | "bottom";

export interface DrawAttributes {
    location: Coord;
    centered?: boolean;
    width: number;
    height: number;
    alpha?: number;
    color?: AvailableColors;
    dropShadow?: boolean;
    yaw?: number;
    xOffset?: number;
    yOffset?: number;
}

export interface Label {
    text: string;
    alpha?: number;
    color?: AvailableColors;
    dropShadow?: boolean;
    position?: LabelLocation;
}

export interface LabelAttributes extends Label {
    location: Coord;
    textAlign: CanvasTextAlign;
    textPlacement?: LabelLocation;
}

export function clearCanvas(
    ctx: CanvasRenderingContext2D,
    scaled: ScaledDimensions,
) {
    const { width, height } = scaled;
    ctx.clearRect(0, 0, width, height);
}

export function drawImage(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    attr: DrawAttributes,
) {
    const { location, width, height } = attr;
    const { x, y } = location;
    ctx.drawImage(img, x, y, width, height);
}

export function drawImageWithShadow(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    attr: DrawAttributes,
) {
    const { location, width, height } = attr;
    const { x, y } = location;

    ctx.save();
    ctx.shadowColor = "black";
    ctx.shadowBlur = 10;
    ctx.shadowOffsetX = width / 8;
    ctx.shadowOffsetY = height / 8;

    if (attr.alpha) {
        ctx.globalAlpha = attr.alpha;
    }

    ctx.drawImage(img, x, y, width, height);

    ctx.restore();
}

export function drawImageCentered(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    attr: DrawAttributes,
) {
    const { location, width, height } = attr;
    const { x, y } = location;

    const xOffset = width / 2;
    const yOffset = height / 2;

    const offset = {
        x: x - xOffset,
        y: y - yOffset,
    };

    const updated = {
        ...attr,
        location: offset,
        width,
        height,
    };

    if (attr.dropShadow) {
        drawImageWithShadow(ctx, img, updated);
    } else {
        drawImage(ctx, img, updated);
    }
}

export function drawImageRotated(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    attr: DrawAttributes,
) {
    const {
        location: { x, y },
    } = attr;
    ctx.save();

    if (attr.dropShadow) {
        ctx.shadowColor = "black";
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = attr.width / 8;
        ctx.shadowOffsetY = attr.height / 8;
    }

    if (attr.alpha) {
        ctx.globalAlpha = attr.alpha;
    }

    const rx = x - attr.width * (attr.xOffset ?? 0.5);
    const ry = y - attr.height * (attr.yOffset ?? 0.5);

    ctx.translate(x, y);
    ctx.rotate(attr.yaw ?? 0);
    ctx.translate(-x, -y);
    ctx.drawImage(img, rx, ry, attr.width, attr.height);
    ctx.restore();
}

export function determineLabelPosition(
    attr: DrawAttributes,
    imgWidth: number,
): LabelLocation {
    const {
        location: { x },
        width,
    } = attr;
    const spaceLeft = Math.floor(x - width / 2);
    const spaceRight = Math.floor(imgWidth - x - width / 2);

    // Ensure we're not cutting off the text
    if (spaceLeft > spaceRight) {
        return "left";
    }
    if (spaceRight > spaceLeft) {
        return "right";
    }
    return "top";
}

export function determinTextAlign(location: LabelLocation): CanvasTextAlign {
    switch (location) {
        case "left":
            return "right";
        case "right":
            return "left";
        default:
            return "center";
    }
}

export function generateLabelAttr(
    attr: DrawAttributes,
    label: Label,
    location: LabelLocation,
): LabelAttributes {
    let offsetX = 0;
    let offsetY = 0;
    switch (location) {
        case "bottom":
            offsetY = attr.height * (attr.yOffset ?? 1.3);
            break;
        case "left":
            offsetX = -1.3 * (attr.width * (attr.xOffset ?? 1));
            break;
        case "right":
            offsetX = 1.3 * attr.width * (attr.xOffset ?? 1);
            break;
        case "top":
            offsetY = -1.3 * attr.height * (attr.yOffset ?? 1);
            break;
        default:
            offsetX = 0;
            offsetY = 0;
    }

    return {
        ...label,
        textAlign: determinTextAlign(location),
        textPlacement: location,
        location: {
            x: attr.location.x + offsetX,
            y: attr.location.y + offsetY,
        },
    };
}

export function drawLabel(
    ctx: CanvasRenderingContext2D,
    labelAttr: LabelAttributes,
) {
    ctx.save();

    if (labelAttr.dropShadow) {
        ctx.shadowColor = "black";
        ctx.shadowBlur = 10;
        ctx.shadowOffsetX = 4;
        ctx.shadowOffsetY = 4;
    }

    ctx.font = "12pt Gotham, Black";
    ctx.fillStyle = labelAttr.color
        ? Colors[labelAttr.color]
        : Colors.VOLLEY_GREEN;
    ctx.textAlign = labelAttr.textAlign ?? "center";

    if (labelAttr.alpha) {
        ctx.globalAlpha = labelAttr.alpha;
    }

    ctx.fillText(labelAttr.text, labelAttr.location.x, labelAttr.location.y);
    ctx.stroke();
    ctx.restore();
}

export function drawImageWithLabel(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    attr: DrawAttributes,
    label: Label,
) {
    if (attr.centered) {
        drawImageCentered(ctx, img, attr);
    } else {
        drawImage(ctx, img, attr);
    }

    const location = label.position ?? determineLabelPosition(attr, 200);
    const labelAttr = generateLabelAttr(attr, label, location);

    drawLabel(ctx, labelAttr);
}

export function drawImageRotatedWithLabel(
    ctx: CanvasRenderingContext2D,
    img: HTMLImageElement,
    attr: DrawAttributes,
    label: Label,
) {
    drawImageRotated(ctx, img, attr);

    const location = label.position ?? determineLabelPosition(attr, 200);
    const labelAttr = generateLabelAttr(attr, label, location);

    drawLabel(ctx, labelAttr);
}

export function drawSquareCentered(
    ctx: CanvasRenderingContext2D,
    attr: DrawAttributes,
) {
    ctx.strokeStyle = attr.color ?? "red";
    const { location } = attr;
    const { x, y } = location;
    ctx.strokeRect(
        x - attr.width / 2 - 10,
        y - attr.height / 2 - 10,
        attr.width + 20,
        attr.height + 20,
    );
}
