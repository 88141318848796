import * as React from "react";

import CloseIcon from "@mui/icons-material/Close";
import AppBar from "@mui/material/AppBar";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";

import { usePairingContext } from "../../hooks/pairingStatus";

import PairSelect from "./PairSelect";

export default function PairDialog(): JSX.Element {
    const { status, pairingCancelled } = usePairingContext();
    return (
        <Dialog
            fullScreen
            open={status === "requested"}
            PaperProps={{
                sx: {
                    background: "#fff",
                },
            }}
        >
            <AppBar>
                <Toolbar>
                    <Typography
                        variant="h3"
                        component="h1"
                        color="inherit"
                        sx={{ flex: 1 }}
                    >
                        Connect to the Trainer
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={() => pairingCancelled()}
                    >
                        <CloseIcon />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <Toolbar />
            <PairSelect />
        </Dialog>
    );
}
