import type { TutorialPage } from "./types";

const TUTORIALS_DATA: TutorialPage[] = [
    {
        title: "",
        slug: "app-welcome",
        text: "",
    },
    {
        title: "Volley App Navigation",
        slug: "app-menu",
        text: `On the lower navigation bar:
- Play lets you control a trainer, see your play history and more. When selected it will say Refresh

- See clips of your sessions with Videos

- Turn the clips into Projects to compare your form and/or solicit Pro feedback

- Manage your account information under Profile`,
    },
    {
        title: "Volley App Navigation",
        slug: "app-support",
        text: `To access our support, click the “?” button for live chat and support articles.

Volley also proactively monitors for errors, so you may see an option to chat proactively pop up during play. If you see this, it means a staff member has detected a problem with a trainer.

If your phone number is on file, Volley may text you when problems arise.`,
    },
    {
        title: "Volley App Navigation",
        slug: "app-sport",
        text: `If you need to change the sport you selected after your first login, you can do that on the Play screen.

Picking the right sport is important as all the content available for selection is based on the sport choice.

Volley makes 2 trainers, a dedicated one for pickleball and a second one for all other sports.`,
    },
    {
        title: "Volley App Navigation",
        slug: "app-connect",
        text: `When you're ready to get started with a trainer, press Connect at the top of the screen.

When you're connected to a trainer, this button will change to the Trainer number and is how you access options such as shut down.

The first time you click connect, we'll walk you through how to get ready for your time on court.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-batteries",
        text: `The Volley trainer can run for 3+ hours on a set of 2 batteries. Many clubs opt to buy 2 sets of batteries so there is always a backup.

If you want to check the charge level on an individual battery, click the clear/white button to the right of the bars on the battery.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-buttons",
        text: `You'll see 4 buttons on the back of the trainer:

- Green: Turn on the trainer after inserting batteries

- Red: E-stop button. To shut down the trainer normally, use the Volley app

Yellow: Lower the trainer head. This is often used to refill balls without needing a phone.

Blue: Resume or repeat playing. After refilling balls, this allows you to restart without your phone.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-buttons-green",
        text: `Green LED Light indicates machine is on and ready.

After turning on, the machine will say, “Volley starting.” At this point you can connect to the trainer.

If the green light never comes on, use the in-app chat to contact Volley.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-billboard",
        text: `The billboard screen shows WiFi strength, cell strength, & battery life.

If there is a connectivity issue with the cell or WiFi, the trainer will let you know it can't connect. In these cases, use the in-app chat to contact Volley.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-billboard-countdown",
        text: `The billboard's countdown timer shows you how much time until the next ball is thrown.

On the righthand side, you'll see a ball speed indicator.

For ball direction, we recommend watching the throwing head of the trainer as it will point correctly before throwing.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-pairing-code",
        text: `Connect to the trainer using the 4-digit code on the side of the trainer.

If a trainer has issues, Volley will swap the entire unit rather than repair on-site. In these cases, the connect code will change, so make sure to check each time even if you play at the same club.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-tomohopper-pin",
        text: `Most clubs opt to purchase a Volley-branded ball mower, though it is optional. If your club has one, you can assemble it in 3 quick steps:

1) Remove the pin, flip up the handle and insert the pin to lock it in place (cont)`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-tomohopper-arms",
        text: `2) Insert the trainer arms into bottom front, paying attention to the "This side up" sticker.

The arms should easily slide into the designated slots.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-tomohopper-arms2",
        text: "3) Use the attached bungees to fasten the arms. There is a small hole in the plastic where the bungees can easily be clipped to secure the arms in place.",
    },
    {
        title: "Connecting to the Trainer",
        slug: "trainer-pairing-code-entry",
        text: `Now that the equipment is set up, it's almost time to connect to the trainer!

After completing the tutorial, enter the 4-digit code on your trainer and press Connect to start your session.`,
    },
    {
        title: "Connecting to the Trainer",
        slug: "trainer-calibrate",
        text: `After pairing you be asked to quickly calibrate the trainer. The process just takes a few seconds.

During this time the trainer head/arm will move as it runs a self-check for any issues.`,
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-loading-balls-flip",
        text: "To load balls, flip up the cover. There is a magnet in the cover that allows it to stick to the top green bar for easy loading.",
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-loading-balls-worn",
        text: "Volley performs best with balls that are Xenon or Volley branded and aren't worn. Balls that are wet will cause jams, so ensure courts/balls are dry before beginning play.",
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-loading-balls-cover",
        text: "After filling, pull the cover back over the bin. The trainer will indicate when it is out of balls, so there is no need to re-check the inside of the bin.",
    },
    {
        title: "Trainer Navigation",
        slug: "trainer-end-session",
        text: `That's all you need to know! As a reminder, click the trainer number at the top to access the End Session/Shutdown options.

Enjoy your time on the court!`,
    },
];

export default TUTORIALS_DATA;
