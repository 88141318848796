import * as React from "react";

import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import logger from "../../log";
import { logFetchError, pairedFetchApi } from "../../util/fetchApi";
import useDialog from "../Dialog/useDialog";

import useRanges from "./ranges";
import { useStatus, RAPID_POLL } from "./status";
import usePrevious from "./usePrevious";

export type LiftStatus = "idle" | "requested" | "pending" | "lifting" | "error";

export interface LiftState {
    down: boolean;
    error: string | null;
    hasLift: boolean;
    height: number;
    isLifting: boolean;
    liftStatus: LiftStatus;
    liftRange: { min: number; max: number };
    lowerHead: () => Promise<void>;
    safeHeight: number;
    refillHeight: number;
    setHeight: (height: number, callback?: () => void) => Promise<void>;
    stop: () => Promise<void>;
    tickDown: () => Promise<number>;
    tickUp: () => Promise<number>;
    checkForLift: () => void;
}

export function useLift(): LiftState {
    const { lift } = useRanges();
    const { status, startRapidPoll, removeRapidPollCheck } = useStatus();
    const [error, setError] = React.useState<string | null>(null);
    const [liftRange, setLiftRange] = React.useState(lift);
    const [isLifting, setIsLifting] = React.useState(
        status?.trainer.isLifting ?? false,
    );
    const [startedLifting, setStartedLifting] = React.useState<Date | null>(
        null,
    );
    const [targetHeight, setTargetHeight] = React.useState<number | null>(null);
    const [liftStatus, setLiftStatus] = React.useState<LiftStatus>("idle");
    const onTargetAcheived = React.useRef<() => void>();

    // Ensure rapid polling is stopped when we unmount
    // eslint-disable-next-line react-hooks/exhaustive-deps
    React.useEffect(() => () => removeRapidPollCheck("lift"), []);

    React.useEffect(() => {
        if (!status) {
            return;
        }
        setLiftRange(status.trainer.lift.range);
        const lifting =
            startedLifting !== null || (status.trainer.isLifting ?? false);
        setIsLifting(lifting);
        if (lifting) {
            setLiftStatus("lifting");
        } else {
            setLiftStatus("idle");
        }
        if (targetHeight !== null) {
            // If we're already at the target height, no need to do anything
            if (Math.abs(targetHeight - (status?.trainer.height ?? 0)) < 1) {
                logger.info(`Desired height ${targetHeight} reached...`);
                setStartedLifting(null);
                setTargetHeight(null);
                setLiftStatus("idle");
                if (onTargetAcheived.current) {
                    logger.info("Calling onTargetAcheived callback");
                    onTargetAcheived.current();
                    onTargetAcheived.current = undefined;
                }
            }
            // Clear started lifting if we have gotten a sufficiently up-to-date status
            if (
                startedLifting !== null &&
                status !== null &&
                status.timestamp.getTime() > startedLifting.getTime() + 6000
            ) {
                setStartedLifting(null);
            }
        }
    }, [
        status,
        isLifting,
        startedLifting,
        targetHeight,
        setStartedLifting,
        setTargetHeight,
    ]);

    const stop = React.useCallback(async () => {
        try {
            await pairedFetchApi(status?.clientId, "/api/motors-stop", "POST");
        } catch (ex) {
            logFetchError(ex, "Failed to soft stop from useLift > stop method");
        }
    }, [status?.clientId]);

    const setHeight = React.useCallback(
        async (height: number, callback?: () => void) => {
            if (status?.trainer.height === height) {
                logger.info(
                    `Requested height: ${height}, actual height: ${status?.trainer.height} - no change required`,
                );
                if (callback) {
                    callback();
                }
                return;
            }
            if (isLifting) {
                logger.warn("Already lifting, ignoring request");
                return;
            }
            if (height < liftRange.min || height > liftRange.max) {
                setLiftStatus("error");
                setError(
                    `Height must be between ${liftRange.min} and ${liftRange.max} inches`,
                );
                return;
            }
            if (callback) {
                logger.info("Registered callback for lift complete");
                onTargetAcheived.current = callback;
            }
            setLiftStatus("requested");
            setStartedLifting(status?.timestamp ?? new Date());
            setTargetHeight(height);

            try {
                await pairedFetchApi(status?.clientId, "/api/height", "POST", {
                    height,
                });
                setLiftStatus("pending");
                setError(null);
                setIsLifting(true);
                startRapidPoll(RAPID_POLL, (c) => !c.trainer.isLifting, "lift");
            } catch (e) {
                logFetchError(e);
                setError(e instanceof Error ? e.message : JSON.stringify(e));
                setLiftStatus("error");
                setStartedLifting(null);
            }
        },
        [status, isLifting, liftRange, startRapidPoll],
    );

    const lowerHead = React.useCallback(async () => {
        if (isLifting) {
            return;
        }

        setLiftStatus("requested");
        setStartedLifting(status?.timestamp ?? new Date());
        setTargetHeight(liftRange.min);

        try {
            await pairedFetchApi(status?.clientId, "/api/lower-head", "POST");

            setLiftStatus("pending");
            setError(null);
            setIsLifting(true);
            startRapidPoll(RAPID_POLL, (c) => !c.trainer.isLifting, "lift");
        } catch (e) {
            logFetchError(e);
            setError(e instanceof Error ? e.message : JSON.stringify(e));
            setLiftStatus("error");
            setStartedLifting(null);
        }
    }, [
        status?.clientId,
        isLifting,
        liftRange.min,
        startRapidPoll,
        status?.timestamp,
    ]);

    const tickDown = React.useCallback(async (): Promise<number> => {
        if (status === null) {
            return 0;
        }
        const newHeight = Math.max(status.trainer.height - 0.6, liftRange.min);
        await setHeight(newHeight);
        return newHeight;
    }, [setHeight, liftRange, status]);

    const tickUp = React.useCallback(async (): Promise<number> => {
        if (status === null) {
            return 0;
        }
        const newHeight = Math.min(status.trainer.height + 0.6, liftRange.max);
        await setHeight(newHeight);
        return newHeight;
    }, [setHeight, liftRange, status]);

    const checkForLift = React.useCallback(() => {
        // only start polling rapidly for lift if the trainer height will change
        if (Math.abs(status?.trainer.height ?? 0) - (targetHeight ?? 0) > 1) {
            startRapidPoll(RAPID_POLL, (c) => !c.trainer.isLifting, "lift");
        }
    }, [status?.trainer.height, targetHeight, startRapidPoll]);

    if (status === null) {
        return {
            down: false,
            error,
            hasLift: false,
            height: 0,
            liftRange,
            lowerHead,
            liftStatus,
            isLifting: false,
            safeHeight: 50,
            refillHeight: 40,
            setHeight,
            stop,
            tickDown,
            tickUp,
            checkForLift,
        };
    }
    return {
        down: Math.abs(status.trainer.height - liftRange.min) <= 0.5,
        hasLift: status.trainer.lift.state !== "Disabled",
        error,
        height: status.trainer.height,
        liftStatus,
        isLifting,
        liftRange,
        lowerHead,
        safeHeight: 50,
        refillHeight: 40,
        setHeight,
        stop,
        tickDown,
        tickUp,
        checkForLift,
    };
}

export interface LiftModalProps {
    stop: () => Promise<void>;
    onStopped?: () => void;
    targetHeight?: number;
    message?: string | undefined;
}

export function LiftModal({
    stop,
    onStopped = () => {},
    targetHeight,
    message,
}: LiftModalProps): JSX.Element | null {
    const { dialogType } = useDialog();
    const { height, isLifting } = useLift();

    const [stopRequested, setStopRequested] = React.useState(false);
    const [startHeight, setStartHeight] = React.useState<number>();

    const wasLifting = usePrevious(isLifting);

    React.useEffect(() => {
        if (!wasLifting && isLifting) {
            logger.info(`Setting start height for lift dialog: ${height}`);
            setStartHeight(height);
        }
    }, [isLifting, wasLifting, height]);

    const totalLiftDistance = React.useMemo(() => {
        if (targetHeight && startHeight) {
            const dist = Math.abs(startHeight - targetHeight);
            logger.info(
                `Target height provided (${targetHeight}) - lift distance: ${dist}`,
            );
            return dist;
        }

        return undefined;
    }, [startHeight, targetHeight]);

    const percent = React.useMemo(() => {
        if (targetHeight && totalLiftDistance) {
            const distanceLeft = Math.abs(height - targetHeight);
            const complete =
                100 - Math.round((distanceLeft / totalLiftDistance) * 100);
            return complete;
        }
        return undefined;
    }, [totalLiftDistance, targetHeight, height]);

    // we don't want to open this lift dialog when we doing a diagnostic flow lift
    const diagnosticDialogOpen = React.useMemo(
        () => dialogType !== null,
        [dialogType],
    );

    const handleStop = React.useCallback(async () => {
        setStopRequested(true);
        await stop();
    }, [stop]);

    React.useEffect(() => {
        if (stopRequested && !isLifting) {
            setStopRequested(false);
            if (onStopped) {
                onStopped();
            }
        }
    }, [stopRequested, isLifting, onStopped]);

    return (
        <Dialog
            open={isLifting && !diagnosticDialogOpen}
            fullWidth
            maxWidth={false}
        >
            <DialogTitle
                variant="h4"
                color="primary.main"
                sx={{
                    p: 2,
                }}
            >
                Moving Trainer Arm
            </DialogTitle>
            <DialogContent dividers>
                <Stack spacing={3}>
                    <Box>
                        {message && (
                            <Typography variant="body2" textAlign="center">
                                {message}
                            </Typography>
                        )}
                    </Box>
                    <Box
                        sx={{
                            margin: "auto",
                            textAlign: "center",
                            position: "relative",
                        }}
                    >
                        <CircularProgress
                            variant={
                                targetHeight ? "determinate" : "indeterminate"
                            }
                            value={percent}
                            size={80}
                            sx={{
                                color: "primary.light",
                            }}
                        />
                        <Box
                            sx={{
                                top: 0,
                                left: 0,
                                bottom: 0,
                                right: 0,
                                position: "absolute",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Typography
                                variant="caption"
                                component="div"
                                color="primary.light"
                            >
                                {`${height}in`}
                            </Typography>
                        </Box>
                        <Box>
                            <LoadingButton
                                variant="contained"
                                color="error"
                                onClick={handleStop}
                                loading={stopRequested}
                            >
                                Stop
                            </LoadingButton>
                        </Box>
                    </Box>
                </Stack>
            </DialogContent>
        </Dialog>
    );
}
