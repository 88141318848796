import type { Side } from "@volley/shared/http/side";
import type { Coordinate, Position } from "@volley/shared/vision-models";

import { Coord, CoordLike, CourtDirection } from "./position-types";

export const SMALL_E_HALF_WIDTH_MM = 280;
export const COURT_WIDTH_FEET = 20;
export const COURT_WIDTH_MM = 6096;
export const COURT_LENGTH_MM = 13411;
export const COURT_LENGTH_FEET = 44;
const GOOD_LOCALIZATION_RADIUS_MM = 625;

export function positionHasError(p: Position): boolean {
    return p.error !== null && p.error !== "";
}

export function correctCourtPoint(rad: number, x: number, y: number): Coord {
    return {
        x: x - SMALL_E_HALF_WIDTH_MM * Math.cos(rad),
        y: y + SMALL_E_HALF_WIDTH_MM * Math.sin(rad),
    };
}

export function radToDeg(rad: number): number {
    return (rad * 180) / Math.PI;
}

export function degToRad(deg: number): number {
    return (deg * Math.PI) / 180;
}

export function calculateTrim(
    expectedYaw: number,
    detectedYaw: number,
): number {
    let trim = 0;
    if (Number.isNaN(detectedYaw) || expectedYaw === detectedYaw) {
        return trim;
    }

    if (
        (expectedYaw > 0 && detectedYaw > 0) ||
        (expectedYaw < 0 && detectedYaw < 0)
    ) {
        // Both are on the same side of zero or one value is zero
        const absExpectedYaw = Math.abs(expectedYaw);
        const absDetectedYaw = Math.abs(detectedYaw);
        trim =
            Math.max(absExpectedYaw, absDetectedYaw) -
            Math.min(absExpectedYaw, absDetectedYaw);
    } else {
        // Opposite sides of zero
        trim = Math.abs(expectedYaw) + Math.abs(detectedYaw);
    }

    if (expectedYaw < detectedYaw) {
        trim = -trim;
    }

    return trim;
}

// 'Round' to partial values (1/2, 1/4, etc)
export function round(value: number, step?: number): number {
    const internalStep = step ?? 1.0;
    const stepFactor = 1.0 / internalStep;
    return Math.round(value * stepFactor) / stepFactor;
}

export function feetToMm(feet: number): number {
    return round(feet * 304.8, 1);
}

export function mmToFeet(mm: number): number {
    return round(mm / 304.8, 0.25);
}

export function mmToInches(mm: number): number {
    return round(mm / 10 / 2.54, 0.25);
}

export function inchesToMm(inch: number): number {
    return round(inch * 2.54 * 10, 1);
}

export function computerToHuman(mm: number, direction: CourtDirection): number {
    const ft = mmToFeet(mm);
    if (direction === CourtDirection.X) {
        return COURT_WIDTH_FEET - ft;
    }

    return COURT_LENGTH_FEET - ft;
}

export function determineCoordSide(coord: CoordLike): Side {
    if (coord.x > COURT_WIDTH_MM / 2) {
        return "deuce";
    }

    return "ad";
}

export function determineCoordSideM(coord: CoordLike): Side {
    if (coord.x > 0) {
        return "deuce";
    }

    return "ad";
}

export function determineNetSide(coord: CoordLike): "player" | "trainer" {
    if (coord.y < 0) {
        return "trainer";
    }

    if (coord.y > COURT_LENGTH_MM / 2) {
        return "trainer";
    }

    return "player";
}

export function determineNetSideM(coord: CoordLike): "player" | "trainer" {
    if (coord.y < 0) {
        return "trainer";
    }

    return "player";
}

export function courtToPhysics(coord: CoordLike): CoordLike {
    const { x, y } = coord;

    const side = determineCoordSide(coord);
    const netSide = determineNetSide(coord);

    let x2: number;
    let y2: number;
    if (side === "ad" && netSide === "player") {
        x2 = -(COURT_WIDTH_MM / 2 - x) / 1000;
        y2 = (COURT_LENGTH_MM / 2 - y) / 1000;
    } else if (side === "ad" && netSide === "trainer") {
        x2 = -(COURT_WIDTH_MM / 2 - x) / 1000;
        y2 = -(y - COURT_LENGTH_MM / 2) / 1000;
    } else if (side === "deuce" && netSide === "player") {
        x2 = (x - COURT_WIDTH_MM / 2) / 1000;
        y2 = (COURT_LENGTH_MM / 2 - y) / 1000;
    } else {
        // side = deuce, netSide = trainer
        x2 = (x - COURT_WIDTH_MM / 2) / 1000;
        y2 = (COURT_LENGTH_MM / 2 - y) / 1000;
    }

    return {
        x: x2,
        y: y2,
    };
}

export function physicsToCourt(coord: CoordLike): CoordLike {
    const { x, y } = coord;

    const side = determineCoordSideM(coord);
    const netSide = determineNetSideM(coord);

    let x2: number;
    let y2: number;
    if (side === "ad" && netSide === "player") {
        x2 = COURT_WIDTH_MM / 2 + x * 1000;
        y2 = COURT_LENGTH_MM / 2 - y * 1000;
    } else if (side === "ad" && netSide === "trainer") {
        x2 = COURT_WIDTH_MM / 2 + x * 1000;
        y2 = COURT_LENGTH_MM / 2 - y * 1000;
    } else if (side === "deuce" && netSide === "player") {
        x2 = x * 1000 + COURT_WIDTH_MM / 2;
        y2 = -y * 1000 + COURT_LENGTH_MM / 2;
    } else {
        // side = deuce, netSide = trainer
        x2 = x * 1000 + COURT_WIDTH_MM / 2;
        y2 = COURT_LENGTH_MM / 2 + -y * 1000;
    }

    return {
        x: x2,
        y: y2,
    };
}

export function shouldImprovePosition(delta: Coordinate): boolean {
    return Math.sqrt(delta.x ** 2 + delta.y ** 2) > GOOD_LOCALIZATION_RADIUS_MM;
}
