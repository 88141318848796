import * as React from "react";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";

// eslint-disable-next-line import/no-cycle
import { getApp } from "..";
import { AppListItemProps } from "../types";

export default function LobCard({
    onClick,
}: AppListItemProps): JSX.Element | null {
    const app = getApp(3);
    return (
        <Card
            sx={{ minWidth: 255 }}
            variant="outlined"
            onClick={() => onClick(3, "/content/apps/workouts/plugin/play/3")}
        >
            <CardContent>
                <Typography variant="h5" component="div">
                    {app.name}
                </Typography>
                <Typography
                    sx={{ mb: 1.5, whiteSpace: "normal" }}
                    color="text.secondary"
                >
                    Click a target on the court and launch a lob to that point
                </Typography>
            </CardContent>
        </Card>
    );
}
